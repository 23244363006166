import { useState, useEffect, useContext, Fragment } from 'react';
import { Link } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import SpecialitiesService from '../../../services/specialitiesService';
import Speciality from '../../../models/speciality';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import { updateRowPosition } from '../../../utils';
import { GlobalContext } from '../../../GlobalContext';

function SpecialitiesPage() {

    const [specialities, setSpecialities] = useState([] as Speciality[]);

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);
    const {visitMotives} = useContext(GlobalContext);


    useEffect( () => {

        if(currentClient){
            updateSpecialities();
        }

        // eslint-disable-next-line
    }, [currentClient]);



    async function updateSpecialities(){

        if(!currentUser){
            return;
        }

        setIsLoading(true);

        try {
            const result = await SpecialitiesService.getSpecialities(currentClient.id, currentUser.locationId);

            setIsLoading(false);
            if(result){
                setSpecialities(result)
            }

        } catch(error) {
            setIsLoading(false);
        }
    }

    const columns: any[] = [
        { field: "color", headerName: " ", width: 50, renderCell: (params) => (
            <div className="kt-table-color-box" style={{backgroundColor: `${params.row.color}` }}> </div>
        )},
        { field: 'name', headerName: 'Name', width: 300, renderCell: (params) => (
            <Link to={`/settings/speciality/${params.row.id}`}>{params.row.name}</Link>
        )},
        { field: 'shortName', headerName: 'Kürzel', width: 300, renderCell: (params) => (
            <Link to={`/settings/speciality/${params.row.id}`}>{params.row.shortName}</Link>
        )},
        { field: 'isVideoCall', headerName: 'Videosprechstunden', width: 200, renderCell: (params) => (
            params.row.isVideoCall ? <CheckRoundedIcon /> : <Fragment />
        )},
        { field: 'delete', headerName: " ", width: 64, renderCell: (params) => (
            <IconButton onClick={ (e) => onDeleteRowClick(params.row)}>
                <DeleteIcon/>
            </IconButton>)
        }
      ];


    async function onDeleteRowClick(row){

        if(visitMotives) {
            const result = visitMotives.filter( v => v.specialityId === row.id);
            if(result.length > 0) {
                alert(`Die Besuchsgrund-Gruppe kann nicht gelöscht werden, da sie noch ${result.length} Besuchsgründe beinhaltet. Löschen Sie diese zuerst.`);
                return;
            }
        }

        const reallyDelete = window.confirm(`Wollen Sie die Besuchsgrund-Gruppe ${row.name} wirklich löschen?`);
        if(reallyDelete && currentUser) {
            await SpecialitiesService.deleteSpeciality(row.id, currentClient.id, currentUser.locationId);
            updateSpecialities();
        }
    }

    const handleRowOrderChange = async (params) => {
        setIsLoading(true);
        const newRows = updateRowPosition<Speciality>(
            params.oldIndex,
            params.targetIndex,
            specialities,
        );

        for (let i = 0; i < newRows.length; i++) {
            const e = newRows[i];
            e.cardinality = i+1;

            SpecialitiesService.updateSpeciality(e, currentClient.id, currentUser!.locationId);
        }
        setSpecialities(newRows);
        setIsLoading(false);
    };

    return (
        <Box className="kt-page">
            <h2>Besuchsgrund-Gruppen</h2>

            <Grid container>
                <Grid item>
                    <ButtonGroup>
                        <Button component={Link} to="/settings/speciality/create" startIcon={<AddCircleOutlineIcon/>}>
                            Besuchsgrund-Gruppe anlegen
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Box className="kt-data-grid-container">
                <DataGridPro
                    loading={isLoading}
                    rows={specialities}
                    columns={columns}
                    rowReordering
                    onRowOrderChange={handleRowOrderChange}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>

        </Box>
    );
}

export default SpecialitiesPage;