
import React from "react";

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import PlaceholderFormItem from "../../../src/shared/src/models/formEditor/placeholderFormItem";
import { FormItemCtrlProps } from "./formItemCtrl";



interface Props extends FormItemCtrlProps {
    item: PlaceholderFormItem
}

const PlaceholderFormItemCtrl: React.FC<Props> = (
    {
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode
    }) => {


    function renderHtml(): JSX.Element {

        return (
            <div className="kt-placeholder"
                style={
                    {
                        height: item.height+"px",
                        backgroundImage: "url('/images/cross.svg')",
                        backgroundSize: "contain"
                    }}>

            </div>
        );

    }

    function renderProperties(): JSX.Element {

        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <strong>Platzhalter</strong>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            inputProps={{ min: "1", max: "500", step: "1" }}
                            label="Höhe"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">px</InputAdornment>,
                            }}
                            value={item.height}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "height")}
                        />
                    </Grid>

                </Grid>
            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {

        return (
            <div className="kt-placeholder" style={{height: item.height+"px"}}>

            </div>
        );

    }


    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }

}


export default PlaceholderFormItemCtrl;