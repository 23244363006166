import database from "../components/database";

import GlobalSettings from "../shared/src/models/globalSettings";

const db = database.firestore();

const SettingsService = {
        
    async getGlobalSettings():Promise<GlobalSettings | null> {

        try {

            const doc = await db.collection("settings").doc("global").get();

            if (doc.exists) {
                const gs = new GlobalSettings();
                gs.fromObject(doc.data());
    
                return gs;
            } else {
                throw new Error("Could not load global settings.");                
            }
            

        } catch(error){
            console.log("Error getting global settings: ", error);
            return null;
        }

    }

   
}

export default SettingsService;