
import Utils from "../../utils/utils";
import CheckboxFormItem from "./checkboxFormItem";
import { FormItemEnum, FormItemType } from "./formItem";
import HeadlineFormItem from "./headlineFormItem";
import ImageFormItem from "./imageFormItem";
import InputTextFormItem from "./inputTextFormItem";
import LineFormItem from "./lineFormItem";
import ParagraphFormItem from "./paragraphFormItem";
import PlaceholderFormItem from "./placeholderFormItem";
import RadioFormItem from "./radioFormItem";
import SignatureFormItem from "./signatureFormItem";
import DropdownFormItem from "./dropdownFormItem";
import VideoFormItem from "./videoFormItem";



function isChildRowOfItem(childRow: FormRow, startItem: FormItemType): boolean {

    if(childRow && startItem && startItem.answers) {
        for (let a = 0; a < startItem.answers.length; a++) {
            const answer = startItem.answers[a];

            if(answer.formRows){
                for (let r = 0; r < answer.formRows.length; r++) {
                    const row = answer.formRows[r];

                    if(childRow.id === row.id) return true;

                    if(childRow.columns){
                        for (let c = 0; c < childRow.columns.length; c++) {
                            const column = childRow.columns[c];

                            if(column.formRows){
                                for (let sr = 0; sr < column.formRows.length; sr++) {
                                    const subRow = column.formRows[sr];

                                    const isChild = isChildRowOfItem(subRow, startItem);
                                    if(isChild) return true;
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    return false;

}

function getColumnById(id: string, formRows: FormRow[]): FormItemType | null {

    for (let r = 0; r < formRows.length; r++) {
        const row = formRows[r];
        for (let c = 0; c < row.columns.length; c++) {
            const column = row.columns[c];
            if (column.id === id) {
                return column;
            }

            if(column.answers){
                for (let a = 0; a < column.answers.length; a++) {
                    const answer = column.answers[a];

                    const subColumn = getColumnById(id, answer.formRows);

                    if(subColumn) return subColumn;
                }
            }
        }

    }

    return null;
}


export class FormRow {

    id: string = "";
    columns: FormItemType[];

    constructor() {
        this.id = Utils.getUUID();
        this.columns = [];
    }



    clone(): FormRow {

        const c = new FormRow();

        c.fromObject(this.toJSON(true));

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj = {
            id: this.id,

            columns: [] as any[]
        };

        for (let i = 0; i < this.columns.length; i++) {
            this.columns[i].parentId = this.id;
            obj.columns.push(this.columns[i].toJSON(includeUserInputs));
        }

        return obj;
    }

    fromObject(o: any) {

        this.id = o.id;

        this.columns = [];
        if (o.columns) {
            for (let i = 0; i < o.columns.length; i++) {
                const formItemObj = o.columns[i];
                let newFormItem: FormItemType | null = null;

                switch (formItemObj.type) {
                    case FormItemEnum.headline:
                        newFormItem = new HeadlineFormItem(this.id);
                        break;

                    case FormItemEnum.paragraph:
                        newFormItem = new ParagraphFormItem(this.id);
                        break;

                    case FormItemEnum.image:
                        newFormItem = new ImageFormItem(this.id);
                        break;

                    case FormItemEnum.inputText:
                        newFormItem = new InputTextFormItem(this.id);
                        break;

                    case FormItemEnum.checkbox:
                        newFormItem = new CheckboxFormItem(this.id);
                        break;

                    case FormItemEnum.radio:
                        newFormItem = new RadioFormItem(this.id);
                        break;

                    case FormItemEnum.signature:
                        newFormItem = new SignatureFormItem(this.id);
                        break;

                    case FormItemEnum.placeholder:
                        newFormItem = new PlaceholderFormItem(this.id);
                        break;

                    case FormItemEnum.line:
                        newFormItem = new LineFormItem(this.id);
                        break;

                    case FormItemEnum.dropdown:
                        newFormItem = new DropdownFormItem(this.id);
                        break;

                    case FormItemEnum.video:
                        newFormItem = new VideoFormItem(this.id);
                        break;

                    default:
                        break;
                }

                if (newFormItem !== null) {
                    newFormItem.fromObject(null, formItemObj);
                    newFormItem.parentId = this.id;
                    this.columns.push(newFormItem);
                }
            }
        }
    }




    static getFormRowById(id: string, formRows: FormRow[]): FormRow | null {

        for (let i = 0; i < formRows.length; i++) {
            if (formRows[i].id === id) {
                return formRows[i];
            }
        }

        // if row is not in root array, then search for sub rows
        for (let i = 0; i < formRows.length; i++) {
            const row = formRows[i];
            for (let c = 0; c < row.columns.length; c++) {
                const column = row.columns[c];

                if (column.answers) {
                    for (let a = 0; a < column.answers.length; a++) {
                        const answer = column.answers[a];

                        const subRow = FormRow.getFormRowById(id, answer.formRows);
                        if(subRow) return subRow;
                    }
                }

            }

        }
        return null;
    }

    static deleteRow(formRow: FormRow, formRows: FormRow[]): FormRow[] {

        const _formRows = [...formRows];

        const index = _formRows.findIndex((o) => {
            return o.id === formRow.id;
        })
        if (index !== -1) {
            _formRows.splice(index, 1);

        } else {
            // form row not found in root list, so search in sub rows
            for (let r = 0; r < _formRows.length; r++) {
                const row = _formRows[r];

                for (let c = 0; c < row.columns.length; c++) {
                    const column = row.columns[c];

                    if (column.answers) {
                        for (let a = 0; a < column.answers.length; a++) {
                            const answer = column.answers[a];

                            answer.formRows = FormRow.deleteRow(formRow, answer.formRows);
                        }
                    }
                }
            }
        }

        return _formRows;
    }
}


export default FormRow;