import DateUtils from "../shared/src/utils/dateUtils";
import Patient from "./patient";


class CampaignPatient extends Patient {

    called: boolean = false;        // by phone
    calledAt: Date | null = null;

    smsSend: boolean = false;
    emailSend: boolean = false;
    notificationSendAt: Date | null = null;

    appointmentMade: boolean = false;       // the patient has made an appointment
    appointmentMadeAt: Date | null = null;  // date when the appointment was created
    appointmentMadeFor: Date | null = null; // date of the confirmed appointment

    visitedLandingPage: boolean = false;        // if patient clicks in the sms or email on the appointment link and opens the landing page
    visitedLandingPageAt: Date | null = null;

    clone(): CampaignPatient {
        const p = new CampaignPatient();

        p.fromObject(this.id, this.toJSON());

        return p;
    }



    toJSON(useToDate: boolean = true): object {

        const obj: any = super.toJSON(useToDate);

        obj.called = this.called;
        obj.calledAt = this.called;

        obj.smsSend = this.smsSend;
        obj.emailSend = this.emailSend;
        obj.notificationSendAt = this.notificationSendAt;

        obj.appointmentMade = this.appointmentMade;
        obj.appointmentMadeAt = this.appointmentMadeAt;
        obj.appointmentMadeFor = this.appointmentMadeFor;

        obj.visitedLandingPage = this.visitedLandingPage;
        obj.visitedLandingPageAt = this.visitedLandingPageAt;

        return obj;

    }

    fromObject(id:string|null, o:any) {

        super.fromObject(id, o);

        this.called = o.called === true;
        this.calledAt = DateUtils.getDate(o.calledAt);

        this.smsSend = o.smsSend === true;
        this.emailSend = o.emailSend === true;
        this.notificationSendAt = DateUtils.getDate(o.appointmentMadeAt);

        this.appointmentMade = o.appointmentMade === true;
        this.appointmentMadeAt = DateUtils.getDate(o.appointmentMadeAt);
        this.appointmentMadeFor =DateUtils.getDate(o.appointmentMadeFor);

        this.visitedLandingPage = o.visitedLandingPage === true;
        this.visitedLandingPageAt = DateUtils.getDate(o.visitedLandingPageAt);

    }


};

export default CampaignPatient;