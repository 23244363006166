
import React from "react";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import LineFormItem from "../../../src/shared/src/models/formEditor/lineFormItem";
import { Align } from "../../../src/shared/src/models/formEditor/formItem";
import { FormItemCtrlProps } from "./formItemCtrl";
import ButtonGroup from "@mui/material/ButtonGroup";


interface Props extends FormItemCtrlProps {
    item: LineFormItem
}

const LineFormItemCtrl:React.FC<Props> = (
    {
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode
    }) => {


    function renderHtml(): JSX.Element {

        return (
            <div>
                <hr
                    style={{
                        width: item.lineWidth+"%",
                        marginLeft: item.lineAlign === Align.left ? "0" : "auto",
                        marginRight: item.lineAlign === Align.right ? "0" : "auto",
                        marginTop: item.spaceBefore+"px",
                        marginBottom: item.spaceAfter+"px",
                        borderWidth: item.lineThickness+"px",
                        borderStyle: item.lineBorderStyle,
                        borderColor: item.lineBorderColor,
                        backgroundColor: item.lineBorderColor
                    }}
                />
            </div>
        );

    }

    function renderProperties(): JSX.Element {
        return (
            <div>
                <Grid container>

                    <Grid item xs={12}>
                        <strong>Trennlinie</strong>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            inputProps={{ min: "1", max: "100", step: "1" }}
                            label="Breite"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            value={item.lineWidth}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "lineWidth")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            inputProps={{ min: "1", max: "100", step: "1" }}
                            label="Dicke"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">px</InputAdornment>,
                            }}
                            value={item.lineThickness}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "lineThickness")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            inputProps={{ min: "-100", max: "500", step: "1" }}
                            label="Abstand davor"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">px</InputAdornment>,
                            }}
                            value={item.spaceBefore}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "spaceBefore")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            inputProps={{ min: "-100", max: "500", step: "1" }}
                            label="Abstand danach"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">px</InputAdornment>,
                            }}
                            value={item.spaceAfter}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "spaceAfter")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="outlined" size="small">
                            <Button
                                startIcon={<FormatAlignLeftIcon/>}
                                variant={item.lineAlign === Align.left ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.left, "lineAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignCenterIcon/>}
                                variant={item.lineAlign === Align.center ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.center, "lineAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignRightIcon/>}
                                variant={item.lineAlign === Align.right ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.right, "lineAlign")}
                            />
                        </ButtonGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <label>Farbe</label>
                        <input
                            className="kt-color-picker"
                            type="color"
                            name="color"
                            value={item.lineBorderColor}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "lineBorderColor")}
                        />
                    </Grid>

                </Grid>

            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {

        return renderHtml();
    }


    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }


}


export default LineFormItemCtrl;