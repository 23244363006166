import React, { useEffect, useState } from "react";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ClientLocation from "../models/clientLocation";
import ClientLocationsService from "../services/clientLocationsService";

interface Props {
    fullWidth?: boolean;
    readOnly?: boolean;
    selectedId: string;
    clientId: string;
    onChange: (clientLocation: ClientLocation) => void;
}

const LocationsDropDown:React.FC<Props> = ({fullWidth, readOnly, selectedId, clientId, onChange}) => {

    const [locations, setLocations] = useState<ClientLocation[]>([]);

    useEffect(() => {

        async function getLocations() {
            const _locations = await ClientLocationsService.getAllLocations(clientId);
            if(_locations){
                setLocations(_locations);
            }
        }

        if(clientId){
            getLocations();
        }

    }, [clientId]);



    async function onSelectionChange(event){

        const clientLocation = await ClientLocationsService.getLocation(clientId, event.target.value);
        if(clientLocation){
            onChange(clientLocation);
        }

    }

    return (


        <FormControl fullWidth={fullWidth === true}>
            <InputLabel id="locationId-select-label">Standort</InputLabel>
            <Select
                required
                disabled={readOnly === true }
                name="locationId"
                labelId="locationId-select-label"
                label="Standort"
                id="locationId-select"
                value={selectedId}
                onChange={onSelectionChange}
            >
                <MenuItem value="">Wählen Sie einen Standort aus</MenuItem>

                {locations.map((clientLocation) =>
                    <MenuItem key={clientLocation.id} value={clientLocation.id}>{clientLocation.name}</MenuItem>
                )}

            </Select>
        </FormControl>

    );
}

export default LocationsDropDown;