import FormItem, { FormItemEnum, FormItemType } from "./formItem";


export class ParagraphFormItem extends FormItem {


    constructor(rowId: string) {
        super(rowId);

        this.type = FormItemEnum.paragraph;

        this.setLanguageProperty("de", "Schreiben Sie hier Ihren Text", "labels");
    }


    clone(): FormItemType {

        const c = new ParagraphFormItem(this.parentId);
        c.fromObject(null, this.toJSON(true));

        return c;
    }


}


export default ParagraphFormItem;