
import React from "react";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ButtonGroup from "@mui/material/ButtonGroup";

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import LogoFormItem from "../../../src/shared/src/models/formEditor/logoFormItem";
import { FormItemCtrlProps } from "./formItemCtrl";
import { Align } from "../../../src/shared/src/models/formEditor/formItem";


interface Props extends FormItemCtrlProps {
    item: LogoFormItem
}

const LogoFormItemCtrl: React.FC<Props> = (
    {
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode
    }) => {



    function renderHtml(): JSX.Element {

        return renderFinalHtml();

    }

    function renderProperties(): JSX.Element {
        return (
            <div>
                <Grid container>

                    <Grid item xs={12}>
                        <strong>Logo</strong>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            type="number"
                            inputProps={{ min: "1", max: "1000", step: "1" }}
                            label="Breite"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">px</InputAdornment>,
                            }}
                            value={item.imageWidth}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "imageWidth")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="outlined" size="small">
                            <Button
                                startIcon={<FormatAlignLeftIcon />}
                                variant={item.imageAlign === Align.left ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.left, "imageAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignCenterIcon />}
                                variant={item.imageAlign === Align.center ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.center, "imageAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignRightIcon />}
                                variant={item.imageAlign === Align.right ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.right, "imageAlign")}
                            />
                        </ButtonGroup>
                    </Grid>

                </Grid>

            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {

        const textAlignStrings: any = ["left", "center", "right"];
        const textAlignString = textAlignStrings[item.imageAlign];

        if (item.imageSource) {
            return (
                <div style={{ textAlign: textAlignString }}>
                    <img
                        width={item.imageWidth}
                        src={item.imageSource}
                        alt=""
                    />
                </div>
            );

        } else {
            return (
                <div style={{ textAlign: textAlignString }}>
                    <img
                        width={item.imageWidth}
                        src="../../images/image-placeholder.svg"
                        alt=""
                    />
                </div>
            );
        }

    }



    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }

}


export default LogoFormItemCtrl;