
class Speciality {
    id: string = "";
    name: string = "";
    shortName: string = "";
    color: string = "";
    cardinality: number = 1;
    __reorder__: string = "";
    selected: boolean = true;
    selectedOnDashboard: boolean = true;
    selectedOnCampaign: boolean = true;
    isEditable: boolean = true;
    isVideoCall: boolean = false;

    clone(): Speciality {
        const s = new Speciality();

        s.id = this.id;
        s.name = this.name;
        s.shortName = this.shortName;
        s.color = this.color;
        s.cardinality = this.cardinality;
        s.__reorder__ = this.__reorder__;
        s.selected = this.selected;
        s.selectedOnDashboard = this.selectedOnDashboard;
        s.selectedOnCampaign = this.selectedOnCampaign;
        s.isEditable = this.isEditable;
        s.isVideoCall = this.isVideoCall;

        return s;
    }

    toJSON(): object {
        return {
            id: this.id,
            name: this.name,
            shortName: this.shortName,
            color: this.color,
            cardinality: parseInt(this.cardinality + ""),
            isVideoCall: this.isVideoCall
        };
    }

    fromObject(id: string|null, o:any){

        this.id = id !== null ? id : o.id;

        this.name = o.name;
        this.shortName = o.shortName;
        this.color = o.color;
        this.cardinality = o.cardinality ? o.cardinality : 1;
        this.__reorder__ = o.name;
        this.isVideoCall = o.isVideoCall === true;

    }

    static cloneList(specialities: Speciality[]): Speciality[] {
        const clonedSpecialities: Speciality[] = [];

        specialities.forEach(spe => {
            const clonedSpe = spe.clone();
            clonedSpecialities.push(clonedSpe);
        });

        return clonedSpecialities;
    }
};

export default Speciality;