import TextField from '@mui/material/TextField';
import React, { useContext, useEffect, useState } from 'react';
import { CalendarVideoGroup } from '../../../shared/src/models/landingPageData';
import User from '../../models/user';
import Avatar from '../avatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { GlobalContext } from '../../GlobalContext';
import Calendar from '../../models/calendar';
import VideoGroupSelectorCtrl from '../videoGroupSelectorCtrl';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

interface Props {
    clientId: string;
    visible: boolean;
    customMessage?: string | JSX.Element
    videoGroups: CalendarVideoGroup[];
    onSave: (videos: CalendarVideoGroup[], videoThumbnailUrl: string) => void;
    onClose: () => void;
}

const CalendarVideosDlg: React.FC<Props> = ({ clientId, visible, customMessage, videoGroups, onSave, onClose }) => {

    const { calendars } = useContext(GlobalContext);

    const [_videoGroups, setVideoGroups] = useState<CalendarVideoGroup[]>([]);

    const [thumbnailUrl, setThumbnailUrl] = useState<string>("");

    useEffect(() => {
        setVideoGroups(videoGroups);
    }, [videoGroups]);


    function handleSave() {
        const videoGroupsToSave: CalendarVideoGroup[] = [];

        for (let i = 0; i < _videoGroups.length; i++) {
            const vidG = _videoGroups[i];

            if (vidG.calendarId !== "" && vidG.videoGroupId !== "") {
                videoGroupsToSave.push(vidG);
            }
        }

        onSave(videoGroupsToSave, thumbnailUrl);
    }

    function handleClose() {
        onClose();
    }

    function getVideoGroupIdForCalendar(calendarId: string): string {
        const vidG = _videoGroups.find(v => v.calendarId === calendarId);

        return vidG ? vidG.videoGroupId : "";
    }

    function setVideoGroupIdForCalendar(calendarId: string, videoGroupId: string) {

        const newVideos = [..._videoGroups];

        const vidG = _videoGroups.find(v => v.calendarId === calendarId);

        if (vidG) {
            vidG.videoGroupId = videoGroupId;
        } else {
            const newVid: CalendarVideoGroup = {
                videoGroupId: videoGroupId,
                calendarId: calendarId
            }
            newVideos.push(newVid);
        }

        setVideoGroups(newVideos);

    }

    function handleOnChange(calendarId: string, newVideoGroupId: string, videoThumbnailUrl: string) {
        setVideoGroupIdForCalendar(calendarId, newVideoGroupId);
        setThumbnailUrl(videoThumbnailUrl);
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={visible}
            maxWidth="lg"
            fullWidth={true}
            disableEnforceFocus={true}
        >
            <DialogTitle id="customized-dialog-title">
                Behandler-Videos
            </DialogTitle>

            <DialogContent dividers style={{ minHeight: '600px' }}>

                <Grid container>
                    <Grid item xs={12}>
                        {customMessage ? customMessage : <span>Hier können Sie individuelle Besuchsgrund-Videos je Kalender angeben.</span>}                        
                        <br />
                        Die Videos können Sie in wenigen Klicks mit unserem <Link to="/clonr">AI Video ClonR</Link> erstellen.
                    </Grid>
                    {(calendars as Calendar[]).map((row, index) =>
                        <div key={row.id} style={{ width: "100%", display: "flex", alignItems: "center", padding: "10px 20px" }}>
                            <Grid item xs={1}>
                                <Avatar src={row.avatarUrl} width={32} />
                            </Grid>
                            <Grid item xs={3}>
                                {row.name}
                            </Grid>
                            <Grid item xs={2}>
                                {(row.license !== "premium") &&
                                    <Tooltip title="Upgraden Sie die Lizenz für diesen Kalender um AI Videos für Ihre Patienten freizuschalten!">
                                        <div className="kt-btn kt-primary"><Link to={`/settings/calendar/${row.id}`}>
                                            <i className="fa fa-level-up"></i> UPGRADE</Link>
                                        </div>
                                    </Tooltip>
                                }
                            </Grid>
                            <Grid item xs={8}>
                                <VideoGroupSelectorCtrl
                                    clientId={clientId}
                                    videoGroupId={getVideoGroupIdForCalendar(row.id)}
                                    onChange={(newVideoGroupId, videoThumbnailUrl) => handleOnChange(row.id, newVideoGroupId, videoThumbnailUrl) }
                                    fullWidth
                                    readOnly={row.license !== "premium"}
                                />
                            </Grid>
                        </div>
                    )}


                    <Grid item xs={12} sm={6}>

                    </Grid>

                </Grid>


            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Abbrechen
                </Button>
                <Button onClick={handleSave} color="primary" >
                    OK
                </Button>
            </DialogActions>
        </Dialog >

    );
}

export default CalendarVideosDlg;
