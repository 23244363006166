import Utils from '../../src/shared/src/utils/utils';


export class Time {
    hour: number;
    minute: number;

    constructor (hour: number = 0, minute: number = 0) {
        this.hour = hour;
        this.minute = minute;
    }

    static isValidTimeString(inputString: string): boolean {
        return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(inputString);
    }

    static parse(timeString: string): Time | null {

        try {
            const [hourString, minuteString] = timeString.split(":");

            // does time have HOUR and MINUTE?
            if(hourString && minuteString) {
                const hour = parseInt(hourString);
                const minute = parseInt(minuteString);

                if(hour >= 0 && hour < 24 && minute >= 0 && minute < 60) {
                    return new Time(hour, minute);
                }
            }

        } catch (e){
            return null;
        }

        return null;
    }

    clone(): Time {
        return new Time(this.hour, this.minute);
    }

    toString(): string {
        return `${Utils.getWithLeadingZero(this.hour)}:${Utils.getWithLeadingZero(this.minute)}`;
    }

    toJSON(): object {
        return {
            hour: this.hour,
            minute: this.minute
        };
    }

    fromObject(o:any) {
        if(o){
            this.hour = o.hour
            this.minute = o.minute;
        }
    }

}

export class TimeSpan {
    start: Time;
    end: Time;

    constructor (start: Time = new Time(8), end: Time = new Time(18)) {
        this.start = start;
        this.end = end;
    }

    static parse(inputString: string): TimeSpan | null {

        try {
            const trimmedInputString = inputString.trim();

            if(trimmedInputString !== ""){
                const [startString, endString] = trimmedInputString.split("-");

                // do we have a START and END time?
                if(startString && Time.isValidTimeString(startString) && endString && Time.isValidTimeString(endString)) {
                    const startTime = Time.parse(startString);
                    const endTime = Time.parse(endString);

                    // is end time after start time?
                    if(startTime && endTime) {
                        if(startTime.hour < endTime.hour || (startTime.hour === endTime.hour && startTime.minute < endTime.minute)){
                            return new TimeSpan(startTime, endTime);
                        }
                    }
                }
            }

        } catch (e){
            return null;
        }

        return null;
    }

    static isValidTimeSpanString(inputString: string): boolean {
        return TimeSpan.parse(inputString) !== null;
    }

    clone(): TimeSpan {
        return new TimeSpan(this.start.clone(), this.end.clone());
    }

    toString(): string {
        return `${this.start.toString()}-${this.end.toString()}`;
    }

    toJSON(): object {
        return {
            start: this.start.toJSON(),
            end: this.end.toJSON()
        }
    }

    fromObject(o:any) {
        if(o){
            this.start.fromObject(o.start);
            this.end.fromObject(o.end);
        }
    }
}

export class WorkingDay {
    hasOpen: boolean;
    open: TimeSpan;
    pause: TimeSpan;
    hasPause: boolean;

    constructor (hasOpen: boolean = true, open: TimeSpan = new TimeSpan(), hasPause: boolean = false, pause: TimeSpan = new TimeSpan()) {
        this.hasOpen = hasOpen;
        this.open = open;
        this.pause = pause;
        this.hasPause = hasPause;
    }

    clone(): WorkingDay {
        return new WorkingDay(this.hasOpen, this.open?.clone(), this.hasPause, this.pause?.clone());
    }

    toJSON(timeAsString: boolean = false): object {

        if(timeAsString){

            return {
                hasOpen: this.hasOpen === true,
                hasPause: this.hasPause === true,
                open: this.open.toString(),
                pause: this.pause.toString()
            };

        } else {

            return {
                hasOpen: this.hasOpen === true,
                hasPause: this.hasPause === true,
                open: this.open.toJSON(),
                pause: this.pause.toJSON()
            };

        }

    }

    fromObject(o:any) {
        if(o){
            this.hasOpen = o.hasOpen === true;
            this.hasPause = o.hasPause === true;

            this.open.fromObject(o.open);
            this.pause.fromObject(o.pause);
        }
    }
}

export class OpeningHours {
    enabled: boolean;
    monday: WorkingDay;
    tuesday: WorkingDay;
    wednesday: WorkingDay;
    thursday: WorkingDay;
    friday: WorkingDay;
    saturday: WorkingDay;
    sunday: WorkingDay;

    constructor () {
        this.enabled = false;
        this.monday = new WorkingDay();
        this.tuesday = new WorkingDay();
        this.wednesday = new WorkingDay();
        this.thursday = new WorkingDay();
        this.friday = new WorkingDay();

        this.saturday = new WorkingDay();
        this.saturday.hasPause = false;

        this.sunday = new WorkingDay();
        this.sunday.hasPause = false;
    }

    clone(): OpeningHours {
        const o = new OpeningHours();

        o.enabled = this.enabled === true;
        o.monday = this.monday.clone();
        o.tuesday = this.tuesday.clone();
        o.wednesday = this.wednesday.clone();
        o.thursday = this.thursday.clone();
        o.friday = this.friday.clone();
        o.saturday = this.saturday.clone();
        o.sunday = this.sunday.clone();

        return o;
    }

    toJSON(timeAsString: boolean = false): object {
        return {
            enabled: this.enabled === true,
            monday: this.monday.toJSON(timeAsString),
            tuesday: this.tuesday.toJSON(timeAsString),
            wednesday: this.wednesday.toJSON(timeAsString),
            thursday: this.thursday.toJSON(timeAsString),
            friday: this.friday.toJSON(timeAsString),
            saturday: this.saturday.toJSON(timeAsString),
            sunday: this.sunday.toJSON(timeAsString)
        };
    }

    fromObject(o:any) {
        if(o) {
            this.enabled = o.enabled === true;
            this.monday.fromObject(o.monday);
            this.tuesday.fromObject(o.tuesday);
            this.wednesday.fromObject(o.wednesday);
            this.thursday.fromObject(o.thursday);
            this.friday.fromObject(o.friday);
            this.saturday.fromObject(o.saturday);
            this.sunday.fromObject(o.sunday);
        }
    }

    getWorkingDayByIndex(index: number): WorkingDay {
        switch (index) {
            case 0:
                return this.sunday;

            case 1:
                return this.monday;

            case 2:
                return this.tuesday;

            case 3:
                return this.wednesday;

            case 4:
                return this.thursday;

            case 5:
                return this.friday;

            case 6:
                return this.saturday;

            default:
                return this.sunday;
        }
    }
}

export default OpeningHours;