import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, Box, Stepper, Step, StepLabel, DialogActions, Button, Grid, Checkbox, FormControlLabel } from "@mui/material";


const FirstStepsWizard: React.FC = () => {

    const location = useLocation();

    const [isFirstStepsWizardVisible, setIsFirstStepsWizardVisible] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [dontShowAgain, setDontShowAgain] = useState(false);

    useEffect(() => {


        if (location.pathname.toLowerCase().indexOf("docs") !== -1) {
            setIsFirstStepsWizardVisible(false);
            return;
        }

        // show the firstStepsWizard the first time a user uses a new browser
        const _isFirstStepsWizardVisible = localStorage.getItem("isFirstStepsWizardVisible")
        if (_isFirstStepsWizardVisible !== null) {
            setIsFirstStepsWizardVisible(_isFirstStepsWizardVisible === "true");
        } else {
            setIsFirstStepsWizardVisible(true);
        }
        
    }, []);

    const steps = [
        'Page1',
        'Page2',
        'Page3',
        'Page4'
    ];

    function Page1Panel() {

        return <Grid container>
            <Grid item xs={4}>
                <h1>Page 1</h1>
                <p>Es gibt keine Verschmelzung von Terminen und digitalen Dokumenten, die perfekter und runder abläuft als bei CalendR Plus von Pickadoc.
                    Überzeugen Sie sich selbst, wie Livebot und pSignR zusammenspielen und Ihnen den Rücken frei halten.</p>
            </Grid>

            <Grid item xs={8}>
                <img src="http://cerawhite.com/final/assets/images/about/title2.png" width="100%" />
            </Grid>
        </Grid>
    }

    function Page2Panel() {

        return <Grid container>
            <Grid item xs={4}>
                <h1>Page 2</h1>
                <p>Das Wichtigste im Marketing ist Ihre Kompetenz und Fortschrittlichkeit zu vermitteln. Mit einem System, das Ihnen dieses ermöglicht generieren Sie Umsatz.
                    Zu den größten Verhaltensfehlern zählt: "Das haben wir immer so gemacht." Nun, passen Sie zu Pickadoc? Lassen Sie Innovationen zu?</p>
            </Grid>

            <Grid item xs={8}>
                <img src="http://cerawhite.com/final/assets/images/about/title5.png" width="100%" />
            </Grid>
        </Grid>
    }


    function hideFirstStepsWizard() {
        setIsFirstStepsWizardVisible(false);

        if(dontShowAgain) localStorage.setItem("isFirstStepsWizardVisible", "false");
    }

    function handleOnClose(event: object, reason: string) {        
        if (reason !== "backdropClick") hideFirstStepsWizard();
    }

    function handlePreviousClick() {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    }

    function handleNextClick() {
        if (activeStep < steps.length) {
            setActiveStep(activeStep + 1);
        }
    }

  
    if (!isFirstStepsWizardVisible) return null;

    return (
        <div className="kt-firstStepsWizard">
            <Dialog
                onClose={handleOnClose}
                aria-labelledby="customized-dialog-title"
                open={false}
                //open={isFirstStepsWizardVisible}
                maxWidth="lg"
                fullWidth={true}
                disableEnforceFocus={false}
            >
                <DialogTitle id="customized-dialog-title">
                    Erste Schritte
                </DialogTitle>

                <DialogContent dividers style={{ minHeight: '500px' }}>

                    <Box sx={{ width: '100%' }} mb={5}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label} sx={{ fontSize: "48px" }}>
                                    <StepLabel classes={{ label: "fontSize: 44px" }}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>

                    <Box sx={{ width: '90%' }} m='auto'>

                        {activeStep === 0 && <Page1Panel />}
                        {activeStep === 1 && <Page2Panel />}
                        {activeStep === 2 && <Page1Panel />}
                        {activeStep === 3 && <Page2Panel />}
                    </Box>


                </DialogContent>

                <DialogActions>
                    {activeStep > 0 &&
                        <Button onClick={handlePreviousClick} color="secondary" variant='outlined' style={{ marginRight: "auto" }}>
                            <i className="fal fa-angle-left" style={{ fontSize: "20px", marginRight: "5px" }}></i> ZURÜCK
                        </Button>
                    }
                    
                    <FormControlLabel control={<Checkbox value={dontShowAgain} onChange={e => setDontShowAgain(e.currentTarget.checked)}/>} label="Nicht mehr anzeigen"/>

                    {activeStep < steps.length - 1 ?
                        <Button onClick={handleNextClick} color="primary" variant='contained'>
                            WEITER <i className="fal fa-angle-right" style={{ fontSize: "20px", marginLeft: "5px" }}></i>
                        </Button>
                        :
                        <Button style={{alignItems:"center"}} onClick={e => handleOnClose(e, "buttonClick")} color="primary" variant='contained'>
                            SCHLIEßEN <i className="fal fa-times" style={{ fontSize: "20px", marginLeft: "5px" }}></i>
                        </Button>
                    }

                </DialogActions>
            </Dialog >

        </div>
    )
}

export default FirstStepsWizard;