import firebaseApp from "./../components/database";

import moment from 'moment';

import Rating from "../models/rating";
import Appointment, { PatientStatus } from "../models/appointment";
import PatientsService from "./patientsService";
import { isGermanMobileNumber, logDbReadOperations } from "../utils";
import UsersService from "./usersService";
import Client from "../models/client";
import ClientLocationsService from "./clientLocationsService";
import { NewSmsLine } from "./notificationsService";
import MessageStatus from "../models/messageStatus";
import SmsService from "./smsService";
import EmailService from "./emailService";
import DateUtils from '../../src/shared/src/utils/dateUtils';
import { InvoiceItemType } from "../../src/shared/src/models/invoice/invcoiceItem";
import Email from "../../src/shared/src/models/email";

const db = firebaseApp.firestore();

const RatingsService = {


    startListenForRatings(fromDate: Date, toDate: Date, locationId: string, changeCallback: (reminders: Rating[]) => void): () => void {

        if (!fromDate || !toDate || !locationId) {
            return () => {
                console.log("error in startListenForRatings: empty paramter");
            };
        }

        console.log(`startListenForRatings fromDate: ${fromDate} toDate: ${toDate} locationId: ${locationId}`);

        return db.collection("ratings")
            .orderBy("sendAt")
            .where("sendAt", ">=", fromDate)
            .where("sendAt", "<=", toDate)
            .where("locationId", "==", locationId)
            .onSnapshot(function (querySnapshot) {
                const ratingList: Rating[] = [];

                querySnapshot.forEach((doc) => {
                    const rating = new Rating();
                    rating.fromObject(doc.id, doc.data());
                    ratingList.push(rating);
                });

                logDbReadOperations("startListenForRatings", ratingList.length);

                changeCallback(ratingList);
            });
    },


    async getRatings(clientId: string, locationId: string, onlyRated: boolean): Promise<Rating[] | null> {

        const ratings: Rating[] = [];

        try {

            const query = db.collection("ratings")
                .where("clientId", "==", clientId)
                .where("locationId", "==", locationId);

            if (onlyRated) {
                query.where("rating", ">", 0);
            }

            const querySnapshot = await query.get();

            querySnapshot.forEach((doc) => {

                const rating = new Rating();
                rating.fromObject(doc.id, doc.data());

                ratings.push(rating);

            });

            logDbReadOperations("getRatings", ratings.length);

            return ratings;

        } catch (error) {
            console.log("Error getting ratings: ", error);
            return null;
        }

    },

    async getRatingsByDate(fromDate: Date, toDate: Date, clientId: string, locationId: string, onlyRated: boolean): Promise<Rating[] | null> {

        const ratings: Rating[] = [];

        try {

            const _fromDate = new Date(fromDate);
            const _toDate = new Date(toDate);

            _fromDate.setHours(0, 0, 0);
            _toDate.setHours(23, 59, 59);

            const query = db.collection("ratings")
                .orderBy("sendAt")
                .where("sendAt", ">=", _fromDate)
                .where("sendAt", "<=", _toDate)
                .where("clientId", "==", clientId)
                .where("locationId", "==", locationId)
                .where("messageStatus", "==", MessageStatus.successfullyDispatched);

            if (onlyRated) {
                query.where("rating", ">", 0);
            }

            const querySnapshot = await query.get();

            querySnapshot.forEach((doc) => {

                const rating = new Rating();
                rating.fromObject(doc.id, doc.data());

                ratings.push(rating);

            });

            logDbReadOperations("getRatingsByDate", ratings.length);

            return ratings;

        } catch (error) {
            console.log("Error getting ratings by date: ", error);
            return null;
        }

    },

    async getRatingsByPatientAndDate(patientId: string, fromDate: Date, toDate: Date, clientId: string, locationId: string): Promise<Rating[] | null> {

        const ratings: Rating[] = [];

        try {

            const _fromDate = new Date(fromDate);
            const _toDate = new Date(toDate);

            _fromDate.setHours(0, 0, 0);
            _toDate.setHours(23, 59, 59);

            const query = db.collection("ratings")
                .orderBy("createdAt")
                .where("createdAt", ">=", _fromDate)
                .where("createdAt", "<=", _toDate)
                .where("clientId", "==", clientId)
                .where("locationId", "==", locationId)
                .where("patientId", "==", patientId);

            const querySnapshot = await query.get();

            querySnapshot.forEach((doc) => {

                const rating = new Rating();
                rating.fromObject(doc.id, doc.data());

                ratings.push(rating);

            });

            logDbReadOperations("getRatingsByPatientAndDate", ratings.length);

            return ratings;

        } catch (error) {
            console.log("Error getting ratings by patient and date: ", error);
            return null;
        }

    },


    async getRatingsByAppointmentId(appointmentId: string): Promise<Rating[] | null> {
        const ratings: Rating[] = [];

        try {

            const querySnapshot = await db.collection("ratings")
                .where("appointmentId", "==", appointmentId)
                .get();


            querySnapshot.forEach((doc) => {

                const rating = new Rating();
                rating.fromObject(doc.id, doc.data());

                ratings.push(rating);

            });

            logDbReadOperations("getRatingsByAppointmentId", ratings.length);

            return ratings;

        } catch (error) {
            console.log("Error getting ratings by appointment id: ", error);
            return null;
        }
    },

    async getRating(ratingId: string): Promise<Rating | null> {

        if (!ratingId) return null;

        const doc = await db.collection("ratings").doc(ratingId)
            .get();

        try {

            if (doc.exists) {

                const rating = new Rating();
                rating.fromObject(ratingId, doc.data());

                logDbReadOperations("getRating", 1);

                return rating;

            } else {
                console.log("getRating: No such document: " + ratingId);
                return null;
            }

        } catch (error) {
            console.log("Error getting rating: ", error);
            return null;
        };

    },



    async updateRating(rating: Rating): Promise<string | null> {

        try {

            if (rating.id) {
                await db.collection("ratings").doc(rating.id)
                    .set(rating.toJSON(), { merge: true });

                return rating.id;

            } else {
                // create new Rating
                const docRef = await db.collection("ratings")
                    .add(rating.toJSON());

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating rating: ", error);
        };

        return null;
    },

    async updateVisibility(ratingId: string, isPublic: boolean): Promise<void> {
        try {
            const documentPath = `ratings/${ratingId}`;
            await db.doc(documentPath)
                .update({ "public": isPublic });

        } catch (error) {
            console.log("Error updating visibility: ", error);
        };
    },

    async deleteRating(ratingId: string): Promise<void> {

        try {
            const documentPath = `ratings/${ratingId}`;
            await db.doc(documentPath).delete();

        } catch (error) {
            console.log("Error deleting rating: ", error);
        };
    },

    // creates a new rating object in the DB
    // and sends a request via sms or email to the patient
    // with a link to the rating page
    async createRatingRequest(appointment: Appointment, client: Client): Promise<string | null> {

        try {
            const clientId = appointment.clientId;
            const locationId = appointment.locationId;

            const location = await ClientLocationsService.getLocation(clientId, locationId);
            if (!location) {
                console.error("Error creating rating request: could not find location");
                return null;
            }

            // first check if it is allowed to send rating requests for this client location
            if (!location.notificationsSettings.ratingSmsEnabled && !location.notificationsSettings.ratingEmailEnabled) {
                console.log("skipping creating rating request: not allowed for this client location");
                return null;
            }

            // check if the user has been treated
            // and if the appointment is realy in the past
            const today = new Date();
            if (appointment.patientStatus === PatientStatus.treated && appointment.start.getTime() < today.getTime()) {

                const patient = await PatientsService.getPatient(appointment.patient.id, clientId, locationId);
                if (!patient) {
                    console.error("Error creating rating request: could not find patient");
                    return null;
                }

                // ToDo: remove after QA
                // if(!(patient.lastName.toLowerCase() === "tzannis" || patient.lastName.toLowerCase() === "petsas")){
                //     return null;
                // }

                const doctor = await UsersService.getUserByCalendarId(clientId, locationId, appointment.calendar.id);
                if (!doctor) {
                    console.error("Error creating rating request: could not find doctor");
                    return null;
                }

                // now check if there is already a rating for that appointment
                const _ratings = await RatingsService.getRatingsByAppointmentId(appointment.id);
                if (_ratings && _ratings.length > 0) {
                    console.log("skipping creating rating request: found existing ratings/requests for that appointment");
                    return null;
                }

                // now check if there are already ratings for that patient in the last 90 days
                // we dont want to spam the patients with rating requests
                const today = new Date();
                today.setHours(23, 59, 59);
                const fromDate = moment(today).subtract(90, "days").toDate();
                const _nearRatings = await RatingsService.getRatingsByPatientAndDate(patient.id, fromDate, today, clientId, locationId);
                if (_nearRatings && _nearRatings.length > 0) {
                    console.log("skipping creating rating request: prevent sending too many requests, found existing ratings/requests for that patient around that date");
                    return null;
                }

                const request = new Rating();

                if (patient.emailAllowed && patient.email && location.notificationsSettings.ratingEmailEnabled) {
                    request.sendBy = "email";
                } else if (patient.smsAllowed && isGermanMobileNumber(patient.mobilePhoneNumber) && location.notificationsSettings.ratingSmsEnabled) {
                    request.sendBy = "sms";
                } else {
                    // no way to send the request or not allowed
                    // stop here and exit
                    request.sendBy = "";

                    console.error("Error creating rating request: not allowed by patient or no way to send via sms or email");
                    return null;
                }

                request.clientId = clientId;
                request.locationId = locationId;

                request.patientId = patient.id;
                request.patientGender = patient.gender;
                request.patientFirstName = patient.firstName;
                request.patientLastName = patient.lastName;
                request.patientPhone = patient.getMobileOrPhoneNumber();
                request.patientEmail = patient.email;

                request.appointmentId = appointment.id;
                request.appointmentDate = new Date(appointment.start);

                request.doctorId = doctor.id;
                request.doctorName = doctor.getFullName();

                request.visitMotiveId = appointment.visitMotive.id;
                request.visitMotiveName = appointment.visitMotive.nameForPatient.trim() !== "" ? appointment.visitMotive.nameForPatient : appointment.visitMotive.name;

                request.calendarId = appointment.calendar.id;

                request.createdAt = new Date();
                request.sendAt = new Date(); // we send the request now immediately, to catch angry patients
                //request.sendAt = moment().add(1, "day").toDate();


                const appointmentDate = DateUtils.getDateString(request.appointmentDate);

                // prepare the message
                if (request.sendBy === "sms") {
                    request.messageFrom = (location.notificationsSettings.useCustomSenderName && location.notificationsSettings.customSenderName) ? location.notificationsSettings.customSenderName : "Pickadoc";

                    const smsText = `Sind Sie zufrieden mit Ihrer Behandlung vom ${appointmentDate}? Wir würden uns über eine Bewertung freuen:${NewSmsLine}https://pickadoc.de/rating/[[ratingId]]${NewSmsLine}Ihr ${location.name}-Team!`;
                    request.messageText = smsText;

                } else if (request.sendBy === "email") {

                    request.messageFrom = `${location.name} <${location.email}>`;
                    request.messageText = `${patient.getGenderAndFullName()}, wir würden uns sehr über eine Bewertung Ihrer Behandlung vom ${appointmentDate} freuen.\r\nBitte bewerten Sie uns unter https://pickadoc.de/rating/[[ratingId]]\r\nIhr ${location.name}-Team!`;
                    request.messageHtml = `<p style="font-size:16px">${patient.getGenderAndFullName()},<br/>
                                            wir würden uns sehr über eine Bewertung Ihrer Behandlung vom ${appointmentDate} freuen.</p>
                                            <p>Bitte bewerten Sie uns unter https://pickadoc.de/rating/[[ratingId]]</p>
                                            <p style="font-size:16px">Ihr ${location.name}-Team!</p>`;
                }

                // save it in the DB
                // but only if we can send the request to the patient
                if (request.sendBy === "sms" || request.sendBy === "email") {
                    const ratingId = await RatingsService.updateRating(request);

                    if (ratingId) {
                        // we use placeholder here, in case we want to send on server side
                        request.messageText = (request.messageText as string).replace(/\[\[ratingId\]\]/g, ratingId);
                        request.messageHtml = (request.messageHtml as string).replace(/\[\[ratingId\]\]/g, ratingId);

                        try {

                            if (request.sendBy === "email") {
                                const email = new Email();

                                email.senderEmail = request.messageFrom;
                                email.senderName = location.name;

                                email.subject = `Bitte bewerten Sie Ihre Behandlung`;

                                email.textBody = request.messageText;
                                email.htmlBody = request.messageHtml;

                                email.toAddresses = [patient.email];

                                await EmailService.sendEmail(email);

                            } else if (request.sendBy === "sms") {
                                await SmsService.sendSms(patient.mobilePhoneNumber, request.messageText, request.messageFrom, InvoiceItemType.smsRating, clientId, locationId, appointment.id);
                            }

                            await db.collection("ratings")
                                .doc(ratingId)
                                .update({
                                    messageStatus: MessageStatus.successfullyDispatched
                                });

                        } catch (error) {

                            await db.collection("ratings")
                                .doc(ratingId)
                                .update({
                                    messageStatus: MessageStatus.notDelivered
                                });
                        }

                    }

                }

            }

        } catch (error) {
            console.error("Error creating rating request: ", error);
        }

        return null;
    }


}

export default RatingsService;