import database from "./../components/database";
import Calendar from "../models/calendar";
import UsersService from "./usersService";
import CalendarViewSettings from "../models/calendarViewSettings";
import PaymentsService from "./paymentsService";

const db = database.firestore();

const CalendarsService = {

    cache: {} as { [key: string]: Calendar },

    startListenForCalendars(clientId: string, locationId: string, changeCallback: (calendars: Calendar[]) => void): () => void {

        return db.collection("clients").doc(clientId)
            .collection("locations").doc(locationId)
            .collection("calendars")
            .onSnapshot(function (querySnapshot) {

                const calendars: Calendar[] = [];

                querySnapshot.forEach((doc) => {
                    const calendar = new Calendar();
                    calendar.fromObject(doc.id, doc.data());

                    if(!calendar.isDeleted) calendars.push(calendar);
                });

                calendars.sort((a, b) => a.cardinality > b.cardinality ? 1 : -1);

                changeCallback(calendars);

            });
    },

    async getCalendar(clientId: string, locationId: string, calendarId: string, ignoreCache: boolean = false): Promise<Calendar | null> {

        try {
            if (!ignoreCache && CalendarsService.cache[calendarId]) {
                return CalendarsService.cache[calendarId];
            }

            const doc = await db.collection("clients").doc(clientId)
                .collection("locations").doc(locationId)
                .collection("calendars").doc(calendarId)
                .get();

            if (doc.exists) {
                const calendar = new Calendar();
                calendar.fromObject(calendarId, doc.data());

                // update cache
                CalendarsService.cache[calendar.id] = calendar;

                return calendar;

            } else {
                console.log("getCalendar: No such document: " + calendarId);
                return null;
            }

        } catch (error) {
            console.log("Error getting calendar: ", error);
            return null;
        }

    },


    async getCalendars(returnAsFlatList: boolean, clientId: string, locationId: string): Promise<Calendar[] | null> {

        if (!clientId || !locationId) {
            console.log("Error getting calendars: missing argument");
            return null;
        }

        try {
            const querySnapshot = await db.collection("clients").doc(clientId)
                .collection("locations").doc(locationId)
                .collection("calendars")
                .get();

            const calendarList: Calendar[] = [];

            querySnapshot.forEach((doc) => {
                const calendar = new Calendar();
                calendar.fromObject(doc.id, doc.data());

                if(!calendar.isDeleted) calendarList.push(calendar);

                // update cache
                CalendarsService.cache[calendar.id] = calendar;

                if (returnAsFlatList) {
                    calendar.children.forEach(element => {
                        calendarList.push(element);
                    });

                    calendar.children = [];
                }
            });

            calendarList.sort((a, b) => a.cardinality > b.cardinality ? 1 : -1);

            return calendarList;

        } catch (error) {
            console.log("Error getting calendars: ", error);
            return null;
        }
    },

    async getCalendarsByUserId(userId: string, returnAsFlatList: boolean, clientId: string, locationId: string, ignoreCache: boolean = false): Promise<Calendar[] | null> {

        try {

            const calendarList: Calendar[] = [];

            if (!ignoreCache) {
                for (const calendarId in CalendarsService.cache) {
                    const calendar = CalendarsService.cache[calendarId];
                    if (calendar && calendar.userId === userId && !calendar.isDeleted) {
                        calendarList.push(calendar);
                    }
                }

                if (calendarList.length > 0) {
                    return calendarList;
                }
            }

            const querySnapshot = await db.collection("clients").doc(clientId)
                .collection("locations").doc(locationId)
                .collection("calendars")
                .where("userId", "==", userId)
                .get();


            querySnapshot.forEach((doc) => {
                const calendar = new Calendar();
                calendar.fromObject(doc.id, doc.data());

                calendarList.push(calendar);

                // update cache
                CalendarsService.cache[calendar.id] = calendar;

                if (returnAsFlatList) {
                    calendar.children.forEach(element => {
                        calendarList.push(element);
                    });

                    calendar.children = [];
                }
            });

            calendarList.sort((a, b) => a.cardinality > b.cardinality ? 1 : -1);

            return calendarList;

        } catch (error) {
            console.log("Error getting calendars by userId: ", error);
            return null;
        }
    },

    async updateCalendar(clientId: string, locationId: string, calendar: Calendar): Promise<string | null> {

        try {

            let calendarId = "";

            const user = await UsersService.getUser(clientId, calendar.userId);

            if (calendar.id) {
                await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("calendars").doc(calendar.id).set(calendar.toJSON(), { merge: true });

                // update cache
                CalendarsService.cache[calendar.id] = calendar;

                calendarId = calendar.id;

            } else {
                // create a new Calendar
                const docRef = await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("calendars").add(calendar.toJSON());

                // update cache
                CalendarsService.cache[docRef.id] = calendar;

                calendarId = docRef.id;
            }

            // save the calendar id to user
            if (user && !user.calendarIds.includes(calendarId)) {
                user.calendarIds.push(calendarId);

                await UsersService.updateUserCalendarIds(user.calendarIds, user.id, clientId);
            }

            return calendarId;

        } catch (error) {
            console.log("Error updating calendar: ", error);
        };

        return null;
    },

    async deleteCalendar(clientId: string, locationId: string, calendarId: string): Promise<void> {

        try {

            // first get user from calendar
            const user = await UsersService.getUserByCalendarId(clientId, locationId, calendarId);

            // now mark calendar as deleted
            await db.collection(`clients/${clientId}/locations/${locationId}/calendars`).doc(calendarId).update("isDeleted", true);

            // delete also from users calendar ids list
            if (user) {
                const newCalendarIds = user.calendarIds.filter(c => c !== calendarId);
                UsersService.updateUserCalendarIds(newCalendarIds, user.id, clientId);
            }

            // now unsubscripe in Stripe
            await PaymentsService.cancelStripeSubscription(calendarId, clientId, locationId);

        } catch (error) {
            console.log("Error deleting calendar: ", error);
        };
    },

    saveCalendarsSelection(calendars: Calendar[]) {
        if (localStorage) {
            const selectedResources: string[] = [];
            const selectedDashboardResources: string[] = [];
            const selectedCampaignResources: string[] = [];

            for (let i = 0; i < calendars.length; i++) {
                const cal = calendars[i];
                if (cal.selected) {
                    selectedResources.push(cal.id);
                }
                if (cal.selectedOnDashboard) {
                    selectedDashboardResources.push(cal.id);
                }
                if (cal.selectedOnCampaign) {
                    selectedCampaignResources.push(cal.id);
                }
            }

            localStorage.setItem("selectedResources", JSON.stringify(selectedResources));
            localStorage.setItem("selectedDashboardResources", JSON.stringify(selectedDashboardResources));
            localStorage.setItem("selectedCampaignResources", JSON.stringify(selectedCampaignResources));
        }
    },

    restoreCalendarsSelection(calendars: Calendar[]): Calendar[] {

        let selectionCounter = 0;
        let dashboardSelectionCounter = 0;
        let campaignSelectionCounter = 0;

        try {
            if (localStorage) {
                const selectedResourcesString = localStorage.getItem("selectedResources");
                if (selectedResourcesString) {
                    for (let i = 0; i < calendars.length; i++) {
                        const cal = calendars[i];
                        cal.selected = selectedResourcesString.indexOf(cal.id) !== -1;

                        if (cal.selected) {
                            selectionCounter++;
                        }
                    }
                }

                const selectedDashboardResourcesString = localStorage.getItem("selectedDashboardResources");
                if (selectedDashboardResourcesString) {
                    for (let i = 0; i < calendars.length; i++) {
                        const cal = calendars[i];
                        cal.selectedOnDashboard = selectedDashboardResourcesString.indexOf(cal.id) !== -1;

                        if (cal.selectedOnDashboard) {
                            dashboardSelectionCounter++;
                        }
                    }
                }

                const selectedCampaignResourcesString = localStorage.getItem("selectedCampaignResources");
                if (selectedCampaignResourcesString) {
                    for (let i = 0; i < calendars.length; i++) {
                        const cal = calendars[i];
                        cal.selectedOnCampaign = selectedCampaignResourcesString.indexOf(cal.id) !== -1;

                        if (cal.selectedOnCampaign) {
                            campaignSelectionCounter++;
                        }
                    }
                }

            }
        } catch (error) {
            console.log(error);
        }

        // if localstorage does not contain any selection then select all calendars
        if (selectionCounter === 0) {
            for (let i = 0; i < calendars.length; i++) {
                const cal = calendars[i];
                cal.selected = true;
            }
        }
        if (dashboardSelectionCounter === 0) {
            for (let i = 0; i < calendars.length; i++) {
                const cal = calendars[i];
                cal.selectedOnDashboard = true;
            }
        }
        if (campaignSelectionCounter === 0) {
            for (let i = 0; i < calendars.length; i++) {
                const cal = calendars[i];
                cal.selectedOnCampaign = true;
            }
        }

        return calendars;
    },


    saveCalendarInitialView(viewName: string) {
        if (localStorage) {
            localStorage.setItem("initialView", viewName);
        }
    },

    restoreInitialView() {

        try {
            if (localStorage) {
                const viewName = localStorage.getItem("initialView");
                if (viewName) {
                    return viewName;
                }
            }
        } catch (error) {
            console.log(error);
        }

        return "resourceTimeGridDay";
    },

    saveCalendarViewSettings(settings: CalendarViewSettings) {
        if (localStorage) {
            localStorage.setItem("calendarViewSettings", JSON.stringify(settings.toJSON()));
        }
    },

    restoreCalendarViewSettings(): CalendarViewSettings {

        const settings = new CalendarViewSettings();
        try {
            if (localStorage) {
                const _settings = localStorage.getItem("calendarViewSettings");
                if (_settings) {
                    settings.fromObject(JSON.parse(_settings));
                }
            }
        } catch (error) {
            console.log(error);
        }

        return settings;
    }
}

export default CalendarsService;