
import React, { useEffect, useState } from 'react';
import ProgressDialog from '../dialogs/progressDialog';
import SignatureFormItem from '../../../src/shared/src/models/formEditor/signatureFormItem';
import FormRow from '../../../src/shared/src/models/formEditor/formRow';
import SignatureDialog from '../dialogs/signatureDialog';
import FormRowCtrl from './formRowCtrl';
import { FormItemType } from '../../../src/shared/src/models/formEditor/formItem';
import FileUploadsService from '../../../src/services/fileUploadsService';
import PDocument from "../../../src/shared/src/models/formEditor/pdocument";
import MessageDialog from '../dialogs/messageDialog';
import PDFService from '../../../src/services/pdfService';
import TextEvaluationService from '../../../src/services/textEvaluationService';
import languages from "./../pages/documentsManager/languages.json";
import Button from '@mui/material/Button';

interface Props {
    patientId?: string,
    documentId: string,
    clientId: string,
    locationId: string,
    clientLocationLogoUrl: string,
    languageKey: string,
    formRowsProp: FormRow[],
    showRenderPDFButton: boolean,
    showNextButton: boolean,
    isSaving: boolean,
    formUser: "patient" | "doctor",
    onFormChange: (changedFormRows: FormRow[]) => void;
    onDocumentFilled?: () => void; // gets called if the patient filled the form and pressed the next button
}


const FormPreview: React.FC<Props> = ({ patientId, documentId, clientId, locationId, clientLocationLogoUrl, languageKey, formRowsProp, showRenderPDFButton, showNextButton, isSaving, formUser, onFormChange, onDocumentFilled }) => {

    const [formRows, setFormRows] = useState<FormRow[]>([]);
    const [selectedItem, setSelectedItem] = useState<FormItemType | null>(null);

    const [isUploadProgressDialogVisible, setIsUploadProgressDialogVisible] = useState(false);
    const [isPDFPreviewProgressDialogVisible, setIsPDFPreviewProgressDialogVisible] = useState(false);
    const [isSignatureDialogVisible, setIsSignatureDialogVisible] = useState(false);
    const [selectedSignatureId, setSelectedSignatureId] = useState("");

    const [isMessageDialogVisible, setIsMessageDialogVisible] = useState(false);
    const [messageDialogTitle, setMessageDialogTitle] = useState("");
    const [messageDialogLabel, setMessageDialogLabel] = useState("");

    useEffect(() => {

        setFormRows(formRowsProp);

    }, [formRowsProp]);

    function handleOnInputChange(formItem: FormItemType, newValue: any, propertyName: string) {
        if (formItem && formItem.hasOwnProperty(propertyName)) {
            formItem[propertyName] = newValue;
        }

        setFormRows([...formRows]);

        onFormChange(formRows);
    }

    function handleOnSignatureDialogStateChange(isVisible: boolean, signatureId: string) {
        setSelectedSignatureId(signatureId);
        setIsSignatureDialogVisible(isVisible);
    }

    function handleSignatureChange(newSignatureImage: string) {

        setIsSignatureDialogVisible(false);

        const signatureFormItem = getFormItemById(selectedSignatureId);

        if (signatureFormItem) {
            (signatureFormItem as SignatureFormItem).signature = newSignatureImage;
        }

        setFormRows([...formRows]);

        onFormChange(formRows);
    }

    function getFormItemById(id: string): FormItemType | null {
        for (let r = 0; r < formRows.length; r++) {
            const row = formRows[r];

            for (let c = 0; c < row.columns.length; c++) {
                const colum = row.columns[c];

                if (colum.id === id) {
                    return colum
                }
            }
        }

        return null;
    }

    async function handleSubmit(e: any): Promise<void> {
        e.preventDefault();



        // first check if all required signatures are signed
        const missingSignatures = SignatureFormItem.getAllUnsignedSignaturesBySigner("patient", formRows);

        if (missingSignatures.length > 0) {
            setIsPDFPreviewProgressDialogVisible(false);

            setMessageDialogTitle(`${missingSignatures.length === 1 ? "Fehlende Unterschrift" : "Fehlende Unterschriften"}`);

            let message = "Das Dokument ist noch nicht vollständig unterzeichnet. ";

            message += missingSignatures.length > 1 ? "Es fehlen die Unterschriften für die Felder " : "Es fehlt die Unterschrift für das Feld ";

            for (let s = 0; s < missingSignatures.length; s++) {
                const sign = missingSignatures[s];
                message += "'" + sign.getLanguageProperty("de", "labels") + "'";

                if (s === missingSignatures.length - 1) {
                    message += ".";
                } else {
                    message += ", ";
                }

            }

            message = TextEvaluationService.evaluateDocumentText(message);

            setMessageDialogLabel(message);
            setIsMessageDialogVisible(true);

        } else {

            if (showRenderPDFButton) {

                setIsPDFPreviewProgressDialogVisible(true);

                try {
                    // create a dummy pDocument
                    const pDoc = new PDocument();
                    pDoc.formRows = formRows;

                    // create the PDF
                    let pDocJSON = JSON.stringify(pDoc.toJSON(true));
                    pDocJSON = TextEvaluationService.evaluateDocumentText(pDocJSON);

                    await PDFService.createPDF(clientId, locationId, "temp", pDocJSON);

                    // open the PDF in a new browser tab
                    let downloadUrl = await FileUploadsService.getDownloadURL(`clients/${clientId}/locations/${locationId}/temp.pdf`);



                    if (downloadUrl) {

                        await new Promise(r => setTimeout(r, 500));

                        downloadUrl += "&rnd=" + Math.random();
                        const newWindow = window.open(downloadUrl, '_blank', 'noopener,noreferrer');
                        if (newWindow) newWindow.opener = null;

                    }

                } catch (e) {
                    console.error("error in generating PDF preview: " + e);
                }

            } else if (showNextButton && typeof (onDocumentFilled) === "function") {
                onDocumentFilled();
            }

        }



        setIsPDFPreviewProgressDialogVisible(false);
    }


    function i18n(langKey: string): string {
        let language = languageKey;

        let result = languages[language][langKey];

        if (!result) {
            result = languages["de"][langKey];
        }

        return result ?? langKey;
    }



    return (
        <div className="kt-form-preview">

            <SignatureDialog
                visible={isSignatureDialogVisible}
                title="Unterschrift"
                label="Bitte unterschreiben Sie hier"
                onSave={handleSignatureChange}
                onClose={() => setIsSignatureDialogVisible(false)}
            />

            <ProgressDialog
                visible={isUploadProgressDialogVisible}
                title="Datei Upload"
                message="Ihre Datei wird hochgeladen..."
            />

            <ProgressDialog
                visible={isPDFPreviewProgressDialogVisible}
                title="PDF Vorschau"
                message="Ihre PDF-Vorschau wird generiert..."
            />

            <MessageDialog
                visible={isMessageDialogVisible}
                title={messageDialogTitle}
                titleIconFaClassName='far fa-exclamation-triangle'
                message={messageDialogLabel}
                onClose={() => setIsMessageDialogVisible(false)}
            />



            <form className="kt-form-rendering" onSubmit={handleSubmit}>

                <div className="kt-form-rows-scroll">

                    <div className="kt-form-rows">

                        <img className="kt-client-location-logo" src={clientLocationLogoUrl} alt="Praxis Logo"></img>

                        {formRows.map((formRow, index) => (

                            <div
                                className={`kt-form-row`}
                                key={formRow.id}
                            >
                                <div className="kt-form-row-content">
                                    <FormRowCtrl
                                        patientId={patientId}
                                        documentId={documentId}
                                        row={formRow}
                                        languageKey={languageKey}
                                        handleOnInputChange={handleOnInputChange}
                                        handleOnSignatureDialogStateChange={handleOnSignatureDialogStateChange}
                                        formRows={formRows}
                                        setFormRows={setFormRows}
                                        onFormChange={onFormChange}
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        renderMode="final"
                                        formUser={formUser}
                                    />
                                </div>
                            </div>

                        ))}

                    </div>

                    {showRenderPDFButton &&
                        <Button variant="contained" type='submit'>
                            <i className="fal fa-file-pdf"></i> Test-PDF erstellen
                        </Button>
                    }

                    {showNextButton &&
                        <Button variant="contained" type='submit' disabled={isSaving}>
                            {isSaving ? <>{i18n("saving")} <i className="fal fa-spinner fa-spin"></i></>
                                : <>{i18n("next")} <i className="fal fa-angle-right"></i></>
                            }

                        </Button>
                    }

                    <br />
                </div>

            </form>

        </div>
    );
}

export default FormPreview;