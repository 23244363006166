import DateUtils from "../../utils/dateUtils";
import Utils from "../../utils/utils";

export default class ClonRAvatar {

    id: string = "";

    userId: string = ""; // for whom this clone should be assigned to

    name: string = "";

    videoUrl: string = "";
    videoFileExtension: string = "";
    thumbnailUrl: string = "";

    status: "noVideo" | "inProgress" | "completed" | "error" = "noVideo";

    errorReason: string = "";

    createdAt: Date;
    createdBy: string = ""; // userId

    isDeleted: boolean = false;
    deletedAt: Date | null = null;
    deletedBy: string = ""; // userId

    isSystem: boolean = false; // true if it is a system avatar, which cannot be deleted

    constructor() {
        this.id = Utils.getUUID();
        this.createdAt = new Date();
    }


    clone(): ClonRAvatar {
        const c = new ClonRAvatar();
        c.fromObject(this.id, this.toJSON());

        return c;
    }
    toJSON(): object {
        return {
            id: this.id,
            userId: this.userId,
            name: this.name,

            videoUrl: this.videoUrl,
            videoFileExtension: this.videoFileExtension,
            thumbnailUrl: this.thumbnailUrl,

            status: this.status,

            errorReason: this.errorReason,

            createdAt: this.createdAt,
            createdBy: this.createdBy,

            isDeleted: this.isDeleted,
            deletedAt: this.deletedAt,
            deletedBy: this.deletedBy,

            isSystem: this.isSystem

        };
    }

    fromObject(id: string | null, o: any) {

        this.id = id !== null ? id : o.id;

        this.userId = o.userId ?? "";

        this.name = o.name ?? "";

        this.videoUrl = o.videoUrl ?? "";

        this.videoFileExtension = o.videoFileExtension ?? "";

        this.thumbnailUrl = o.thumbnailUrl ?? "";

        this.status = o.status ?? "noVideo";

        this.errorReason = o.errorReason ?? "";

        this.createdAt = DateUtils.getDate(o.createdAt) ?? new Date();

        this.createdBy = o.createdBy ?? "";

        this.isDeleted = o.isDeleted === true;

        this.deletedAt = o.deletedAt ? DateUtils.getDate(o.deletedAt) : null;

        this.deletedBy = o.deletedBy ?? "";

        this.isSystem = o.isSystem === true;
    }


}