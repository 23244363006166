
class Message {
    id: string = "";
    senderId: string = "";
    data: any = {};
    

    constructor(senderId: string = "", data: any = null) {
        this.senderId = senderId;
        this.data = data;
    }


    clone(): Message {
        const s = new Message();

        s.fromObject(this.id, this.toJSON());
       
        return s;
    }

    toJSON(): object {
        return {
            id: this.id,
            senderId: this.senderId,
            data: JSON.stringify(this.data)
        };
    }

    fromObject(id: string|null, o:any){

        this.id = id !== null ? id : o.id;

        this.senderId = o.senderId;

        this.data = JSON.parse(o.data);

    }

};

export default Message;