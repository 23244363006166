import { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import DevicesService from '../../../services/devicesService';
import Device from '../../../models/device';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import { updateRowPosition } from '../../../utils';
import { GlobalContext } from '../../../GlobalContext';


function DevicesPage() {

    const [devices, setDevices] = useState([] as Device[]);

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);


    useEffect( () => {

        if(currentClient){
            updateDevices();
        }

        // eslint-disable-next-line
    }, [currentClient]);



    async function updateDevices(){

        if(!currentUser){
            return;
        }

        setIsLoading(true);

        try {
            const result = await DevicesService.getDevices(currentClient.id, currentUser.locationId, false);

            setIsLoading(false);
            if(result){
                setDevices(result)
            }

        } catch(error) {
            setIsLoading(false);
        }
    }

    const columns: any[] = [

        { field: 'name', headerName: 'Name', width: 300, renderCell: (params) => (
            <Link to={`/settings/device/${params.row.id}`}>{params.row.name}</Link>
        )},
        { field: 'delete', headerName: " ", width: 64, renderCell: (params) => (
            <IconButton onClick={ (e) => onDeleteRowClick(params.row)}>
                <DeleteIcon/>
            </IconButton>)
        }
      ];


    async function onDeleteRowClick(row){
        const reallyDelete = window.confirm(`Wollen Sie die Ressource ${row.name} wirklich löschen?`);
        if(reallyDelete && currentUser) {
            await DevicesService.deleteDevice(row.id, currentClient.id, currentUser.locationId);
            updateDevices();
        }
    }


    const handleRowOrderChange = async (params) => {
        setIsLoading(true);
        const newRows = updateRowPosition<Device>(
            params.oldIndex,
            params.targetIndex,
            devices,
        );

        for (let i = 0; i < newRows.length; i++) {
            const e = newRows[i];
            e.cardinality = i+1;

            DevicesService.updateDevice(e, currentClient.id, currentUser!.locationId);
        }
        setDevices(newRows);
        setIsLoading(false);
    };


    return (
        <Box className="kt-page">
            <h2>Ressourcen</h2>

            <Grid container>
                <Grid item>
                    <ButtonGroup>
                        <Button component={Link} to="/settings/device/create" startIcon={<AddCircleOutlineIcon/>}>
                            Ressource anlegen
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Box className="kt-data-grid-container">
                <DataGridPro
                    loading={isLoading}
                    rows={devices}
                    columns={columns}
                    rowReordering
                    onRowOrderChange={handleRowOrderChange}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>

        </Box>
    );
}

export default DevicesPage;