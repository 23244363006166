import moment from "moment";
import Appointment from "../models/appointment";
import ShortUrlsService from "./shortUrlsService";

const newSmsLine = "%0a";

const TextEvaluationService = {

    async evaluate(rawText: string, appointment: Appointment, dummyPreviewStatus?: string): Promise<string> {

        if(rawText) {

            if(!appointment || !appointment.start){
                return rawText;
            }

            const mStart = moment(appointment.start);

            const appointmentDateString = mStart.format("DD.MM.YYYY");
            const appointmentTimeString = mStart.format("H:mm");

            const mNow = moment();

            const nowDateString = mNow.format("DD.MM.YYYY");
            const nowTimeString = mNow.format("H:mm");

            let result = rawText;

            result = result.replaceAll(/\[\[Datum\]\]/gi, nowDateString);
            result = result.replaceAll(/\[\[Uhrzeit\]\]/gi, nowTimeString);


            result = result.replaceAll(/\[\[TerminDatum\]\]/gi, appointmentDateString);
            result = result.replaceAll(/\[\[TerminUhrzeit\]\]/gi, appointmentTimeString);

            if(result.indexOf("[[TerminLink]]") !== -1 || result.indexOf("[[AppointmentLink]]") !== -1 ||
                result.indexOf("[[FolgeterminLink]]") !== -1 || result.indexOf("[[SuccessorLink]]") !== -1 ||
                result.indexOf("[[RecallLink]]") !== -1 ){

                const longAppointmentUrl = TextEvaluationService.getAppointmentDetailsPageUrl(appointment, dummyPreviewStatus);
                const expiresAt = moment(appointment.start).add(6, "months").toDate();
                const shortAppointmentUrl = await ShortUrlsService.createShortUrl(longAppointmentUrl, expiresAt);

                result = result.replace(/\[\[TerminLink\]\]/gi, shortAppointmentUrl);
                result = result.replace(/\[\[AppointmentLink\]\]/gi, shortAppointmentUrl);

                // only for backward compatibility
                result = result.replace(/\[\[FolgeterminLink\]\]/gi, shortAppointmentUrl);
                result = result.replace(/\[\[SuccessorLink\]\]/gi, shortAppointmentUrl);
                result = result.replace(/\[\[RecallLink\]\]/gi, shortAppointmentUrl);

            }

            return result;
        }

        return "";
    },


    evaluateDocumentText(rawText: string): string {

        if(rawText) {

            const mNow = moment();

            const nowDateString = mNow.format("DD.MM.YYYY");
            const nowTimeString = mNow.format("H:mm");

            let result = rawText;

            result = result.replaceAll(/\[\[Datum\]\]/gi, nowDateString);
            result = result.replaceAll(/\[\[Uhrzeit\]\]/gi, nowTimeString);

            return result;
        }

        return "";
    },

    evaluateWithDummyData(rawText: string): string {
        const dummyAppointment = new Appointment();
        dummyAppointment.start = new Date();

        if(rawText) {

            const mStart = moment(dummyAppointment.start);

            const dateString = mStart.format("DD.MM.YYYY");
            const timeString = mStart.format("H:mm");

            let result = rawText;

            result = result.replaceAll(/\[\[TerminDatum\]\]/gi, dateString);
            result = result.replaceAll(/\[\[TerminUhrzeit\]\]/gi, timeString);

            result = result.replaceAll(/\[\[TerminLink\]\]/gi, "http://s.pickadoc.de/1234567890");
            result = result.replaceAll(/\[\[AppointmentLink\]\]/gi, "http://s.pickadoc.de/1234567890");

            result = result.replaceAll(/\[\[FolgeterminLink\]\]/gi, "http://s.pickadoc.de/1234567890");
            result = result.replaceAll(/\[\[SuccessorLink\]\]/gi, "http://s.pickadoc.de/1234567890");
            result = result.replaceAll(/\[\[RecallLink\]\]/gi, "http://s.pickadoc.de/1234567890");

            return result;
        }

        return rawText;
    },

    getAppointmentDetailsPageUrl(appointment: Appointment, dummyPreviewStatus?: string): string {
        let url = `https://pickadoc.de/appointment/${appointment.clientId}/${appointment.locationId}/${appointment.id}`;

        if(dummyPreviewStatus && dummyPreviewStatus.length > 2){
            url += "/" + dummyPreviewStatus;
        }
        return url;
    }
}

export default TextEvaluationService;