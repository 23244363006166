import database from "./../components/database";
import Device from "../models/device";

const db = database.firestore();

const DevicesService = {

    cache: {} as {[key: string]: Device},

    async getDevices(clientId: string, locationId: string, withDummyDevice: boolean = true): Promise<Device[] | null> {

        const devices: Device[] = [];

        if(withDummyDevice) {
            const device = new Device();
            device.id = "without-device";
            device.name = "Ohne"

            devices.push(device);
        }

        try {

            if(clientId && locationId){
                const querySnapshot = await db.collection("clients").doc(clientId)
                                            .collection("locations").doc(locationId)
                                            .collection("devices")
                                            .get();


                querySnapshot.forEach((doc) => {

                    const device = new Device();
                    device.fromObject(doc.id, doc.data());

                    // update cache
                    DevicesService.cache[device.id] = device;

                    devices.push(device);

                });

            }

            devices.sort((a,b) => a.cardinality > b.cardinality ? 1 : -1);

            return devices;

        } catch(error) {
            console.log("Error getting devices: ", error);
            return null;
        }

    },

    async getDevice(clientId: string, locationId: string, deviceId: string, ignoreCache:boolean = false): Promise<Device | null> {

        if(!deviceId) return null;

        if(!ignoreCache && DevicesService.cache[deviceId]){
            return DevicesService.cache[deviceId];
        }

        const doc = await db.collection("clients").doc(clientId)
                            .collection("locations").doc(locationId)
                            .collection("devices").doc(deviceId)
                            .get();

        try {

            if (doc.exists) {

                const device = new Device();
                device.fromObject(deviceId, doc.data());

                // update cache
                DevicesService.cache[device.id] = device;

                return device;

            } else {
                console.log("getDevice: No such document: " + deviceId);
                return null;
            }

        } catch(error) {
            console.log("Error getting device: ", error);
            return null;
        };

    },



    async updateDevice(device: Device, clientId: string, locationId: string): Promise<string | null> {

        try {

            if(device.id) {
                await db.collection("clients").doc(clientId)
                        .collection("locations").doc(locationId)
                        .collection("devices").doc(device.id)
                        .set(device.toJSON(), { merge: true });

                // update cache
                DevicesService.cache[device.id] = device;

                return device.id;

            } else {
                // create a new Device
                const docRef = await db.collection("clients").doc(clientId)
                                        .collection("locations").doc(locationId)
                                        .collection("devices")
                                        .add(device.toJSON());

                // update cache
                DevicesService.cache[docRef.id] = device;

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating device: ", error);
        };

        return null;
    },

    async deleteDevice(deviceId: string, clientId: string, locationId: string): Promise<void> {

        try {
            const documentPath = `clients/${clientId}/locations/${locationId}/devices/${deviceId}`;
            await db.doc(documentPath).delete();

        } catch(error) {
            console.log("Error deleting device: ", error);
        };
    },


    saveDevicesSelection(devices: Device[]){
        if(localStorage){
            const selectedDevices: string[] = [];

            for (let i = 0; i < devices.length; i++) {
                const dev = devices[i];
                if(dev.selected){
                    selectedDevices.push(dev.id);
                }
            }

            localStorage.setItem("selectedDevices", JSON.stringify(selectedDevices));
        }
    },

    restoreDevicesSelection(devices: Device[]): Device[] {

        let selectionCounter = 0;

        try{
            if(localStorage){
                const selectedDevicesString = localStorage.getItem("selectedDevices");
                if(selectedDevicesString){
                    for (let i = 0; i < devices.length; i++) {
                        const dev = devices[i];
                        dev.selected = selectedDevicesString.indexOf(dev.id) !== -1;

                        if(dev.selected) {
                            selectionCounter++;
                        }
                    }
                }
            }
        } catch(error){
            console.log(error);
        }

        // if localstorage does not contain any selection then select all devices
        if(selectionCounter === 0) {
            for (let i = 0; i < devices.length; i++) {
                const dev = devices[i];
                dev.selected = true;
            }
        }

        return devices;
    }

}

export default DevicesService;