import React, { useContext, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Button, DialogActions, Grid, LinearProgress, useMediaQuery, useTheme } from '@mui/material';
import { GlobalContext } from '../../../GlobalContext';
import ClonRScript from '../../../shared/src/models/clonR/clonRScript';
import CountryFlag from '../../countryFlag';
import ClonRAvatar from '../../../shared/src/models/clonR/clonRAvatar';
import ClonRVoice from '../../../shared/src/models/clonR/clonRVoice';
import ClonRVideosService from '../../../services/clonRVideosService';
import ClonRScriptsService from '../../../services/clonRScriptsService';
import { Toast } from '../../toaster';
import { useNavigate } from "react-router-dom";
import TextToSpeech from '../../../shared/src/models/clonR/textToSpeech';
import GlobalSettings from '../../../shared/src/models/globalSettings';
import PaymentsService from '../../../services/paymentsService';

const maxAllowedSeconds = 180;

interface Props {
    clonRScript: ClonRScript;
    clonRAvatar: ClonRAvatar;
    clonRVoice: ClonRVoice;
    language: string;
    visible: boolean;
    onClose: () => void;
    onScriptChanged: (newScript: ClonRScript) => void;
}

const ClonRConfirmVideoGenerationDlg: React.FC<Props> = ({ clonRScript, clonRAvatar, clonRVoice, language, visible, onClose, onScriptChanged }) => {

    const navigate = useNavigate();

    const { currentUser, currentClient, settings, setNewToast } = useContext(GlobalContext);

    const [audioLength, setAudioLength] = useState(0);
    const [isGeneratingScriptAudio, setIsGeneratingScriptAudio] = useState(true);

    const [selectedLanguage, setSelectedLanguage] = useState("de");
    const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(0);
    const [credits, setCredits] = useState(0);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    useEffect(() => {

        window.addEventListener('keydown', listenKeyboard, true);

        // clean up
        return () => {
            window.removeEventListener('keydown', listenKeyboard, true);
        }

    }, []);

    useEffect(() => {

        if (currentClient && currentUser) {

            const unsubscribeCredits = PaymentsService.startListenForCredits(currentClient.id, (newValue) => setCredits(newValue));

            return () => {
                unsubscribeCredits();
            }
        }

    }, [currentClient]);

    useEffect(() => {
        if (visible) {

            if (language && clonRScript && clonRScript.translations) {
                const i = clonRScript.translations.findIndex(t => t.language === language);
                if (i !== -1) setSelectedLanguageIndex(i);
            }

            setSelectedLanguage(language);

            setTimeout(() => {
                getOrGenerateScriptAudio(language);
            }, 200);
        }

    }, [visible, language]);

    function listenKeyboard(event) {
        if (event.keyCode === 27) {
            onClose();
        }
    }

    const stopAudioPlayback = () => {
        const audioPlayer = document.getElementById("audio-preview") as HTMLAudioElement;

        if (!audioPlayer) return;

        audioPlayer.pause();
        audioPlayer.currentTime = 0;
        audioPlayer.src = "";
    }

    const getOrGenerateScriptAudio = async (lang: string) => {

        if (clonRScript && lang && clonRVoice && currentUser) {

            //console.log("getOrGenerateScriptAudio: " + lang);

            stopAudioPlayback();

            setIsGeneratingScriptAudio(true);

            ClonRScriptsService.getOrGenerateScriptAudio(clonRScript, lang, clonRVoice, currentUser.clientId, handleOnTTSFinished);

        }

    }

    const handleOnTTSFinished = async (tts: TextToSpeech) => {

        // console.log(`handleOnTTSFinished: ${tts.language} ${tts.audioUrl}`);

        const _script = clonRScript.clone();
        const translation = _script.translations.find(t => t.language === tts.language);
        const audioPlayer = document.getElementById("audio-preview") as HTMLAudioElement;

        setIsGeneratingScriptAudio(false);

        stopAudioPlayback();

        if (!audioPlayer || !translation) return;

        audioPlayer.removeEventListener("loadedmetadata", handleOnLoadedmetadata, true);

        // console.log(`translation: ${translation}`);
        // console.log(`tts: ${tts}`);

        if (tts.audioUrl && tts.status === "finished") {

            translation.audioUrl = tts.audioUrl;

            ClonRScriptsService.updateScript(_script, currentUser.clientId);
            onScriptChanged(_script);

            audioPlayer.addEventListener("loadedmetadata", handleOnLoadedmetadata);

            audioPlayer.src = tts.audioUrl;

        } else if (tts.status === "fromcache" && translation.audioUrl) {
            
            audioPlayer.src = translation.audioUrl;
            setAudioLength(translation.audioLength ?? 0);
        }

    }

    const handleOnLoadedmetadata = () => {
        // console.log("loadedmetadata");

        const _script = clonRScript.clone();
        const translation = _script.translations.find(t => t.language === selectedLanguage);
        const audioPlayer = document.getElementById("audio-preview") as HTMLAudioElement;

        // get audio duration from audio player and save
        if (translation) {
            setAudioLength(audioPlayer.duration ?? 0);
            translation.audioLength = audioPlayer.duration ?? 0;
            ClonRScriptsService.updateScript(_script, currentUser.clientId);
            onScriptChanged(_script);
        }
    }


    const handleCloseClick = () => {
        onClose();
    }


    const generateVideo = async () => {

        ClonRVideosService.generateClonrVideo(currentUser.clientId, clonRAvatar.id, clonRVoice.id, clonRScript.id, selectedLanguage, currentUser.id);

        setNewToast(new Toast("success", `Neues Video wird nun erstellt. Sie werden per Email benachrichtigt, sobald es fertig ist.`));

        if (selectedLanguageIndex === clonRScript.translations.length - 1) {
            // goto videos page
            navigate(`/clonr/video`);

        } else {
            // goto next language
            handleOnNextVideoClick();
        }
    }

    const getPriceString = () => {
        return PaymentsService.getVideoPriceInCredits(settings as GlobalSettings, audioLength).toFixed(0) + " Credits"
    }



    const handleOnPreviousVideoClick = () => {
        stopAudioPlayback();

        if (selectedLanguageIndex > 0 && clonRScript.translations.length > 0) {
            const sI = selectedLanguageIndex - 1;
            const transl = clonRScript.translations[sI];
            setSelectedLanguage(transl.language);
            setSelectedLanguageIndex(sI);

            getOrGenerateScriptAudio(transl.language);
        }
    }

    const handleOnNextVideoClick = () => {
        stopAudioPlayback();

        if (selectedLanguageIndex < clonRScript.translations.length - 1) {
            const sI = selectedLanguageIndex + 1;
            const transl = clonRScript.translations[sI];
            setSelectedLanguage(transl.language);
            setSelectedLanguageIndex(sI);

            getOrGenerateScriptAudio(transl.language);
        }
    }

    const previousButtonEnabled = () => {
        return selectedLanguageIndex > 0 && clonRScript.translations.length > 1;
    }

    const nextButtonEnabled = () => {
        return selectedLanguageIndex < clonRScript.translations.length - 1;
    }


    function hasEnoughBalance(): boolean {
        return credits >= PaymentsService.getVideoPriceInCredits(settings as GlobalSettings, audioLength);
    }

    return (

        <Dialog
            open={visible}
            disableEnforceFocus={true}
            fullWidth={true}
            fullScreen={fullScreen}
        >
            <DialogTitle>Video erstellen</DialogTitle>

            <DialogContent dividers sx={{ textAlign: "center" }}>

                <h3>Zusammenfassung</h3>
                <Grid container sx={{alignContent:"center", alignItems:"center", marginBottom:"10px"}}>
                    <Grid item sx={{textAlign:"left"}} xs={6}>
                        <ul>
                            <li>Videoclone: {clonRAvatar.name}</li>
                            <li>Stimme: {clonRVoice.name}</li>
                            <li>Skript: {clonRScript.name}</li>
                            <li>Sprache: {selectedLanguage.toUpperCase()} <CountryFlag countryCode={selectedLanguage} width={16} /></li>
                        </ul>
                    </Grid>
                    <Grid item xs={6}>
                        <img src={clonRAvatar.thumbnailUrl} style={{ maxWidth: "120px", maxHeight: "120px", border: "1px solid lightgrey", borderRadius: "5px" }} />
                    </Grid>
                </Grid>


                <audio id="audio-preview" src={clonRScript.getTranslationByLanguagKey(selectedLanguage)?.audioUrl} controls />

                {isGeneratingScriptAudio ?
                    <>
                        <p>Der Preis für dieses Video wird berechnet...</p>
                        <LinearProgress />
                    </>
                    :
                    <>
                        <p>Ihr Videoskript beinhaltet {clonRScript.getTranslationTextByLanguagKey(selectedLanguage).length} Zeichen.</p>
                        <p>Videolänge: {audioLength.toFixed(2)} Sekunden.</p>

                        {audioLength <= maxAllowedSeconds ?
                            <>
                                <p>Dieses Video wird Sie <strong>{getPriceString()}</strong> kosten. <small>(GUTHABEN: <strong>{credits}</strong> Credits)</small></p>

                                {
                                    hasEnoughBalance() ?
                                        <p>Wollen Sie dieses Video nun generieren?</p>
                                        :
                                        <div style={{ marginBottom: "30px" }}>
                                            <p style={{color:"red"}}>Sie besitzen nicht genug Guthaben, um dieses Video zu generieren.</p>
                                            <Button 
                                                component="a"
                                                href='/clonr/credits'
                                                target='_self'
                                                rel='noopener noreferrer'
                                                variant='contained' 
                                                size='large'
                                            >
                                                <i className='fa fa-coins'></i>&nbsp;Mehr Credits kaufen
                                            </Button>
                                        </div>
                                }


                            </>
                            :
                            <>
                                <p style={{ color: "red" }}>Ihr Skript ist zu lang.</p>
                                <p>Aktuell können nur Videos generiert werden, die maximal 3 Minuten lang sind.</p>
                                <p>Bitte kürzen Sie Ihr Skript.</p>
                            </>
                        }


                        <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                            <Button onClick={handleOnPreviousVideoClick} color='secondary' variant='outlined' disabled={!previousButtonEnabled()}>
                                <i className="fas fa-chevron-left"></i>&nbsp;ZURÜCK
                            </Button>

                            <Button onClick={generateVideo} color='primary' variant='contained' disabled={!hasEnoughBalance() || audioLength > maxAllowedSeconds}>
                                GENERIEREN
                            </Button>

                            <Button onClick={handleOnNextVideoClick} color='secondary' variant='outlined' disabled={!nextButtonEnabled()}>
                                WEITER&nbsp;<i className="fas fa-chevron-right"></i>
                            </Button>
                        </div>
                    </>
                }

            </DialogContent>

            <DialogActions>
                <Button onClick={handleCloseClick}>
                    SCHLIEßEN
                </Button>
            </DialogActions>

        </Dialog >

    );
}

export default ClonRConfirmVideoGenerationDlg;
