import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";

import PDocument from "../../src/shared/src/models/formEditor/pdocument";
import DocumentsService from "./documentsService";
import Appointment from "../../src/models/appointment";
import FormItem, { FormItemEnum, InputFormItemInterface } from "../../src/shared/src/models/formEditor/formItem";
import FormRow from "../../src/shared/src/models/formEditor/formRow";
import UsersService from "./usersService";
import moment from 'moment';
import SignatureFormItem from '../../src/shared/src/models/formEditor/signatureFormItem';

const db = firebaseApp.firestore();
const functions = firebase.app().functions('europe-west3');


const PatientDocsService = {

    async createNewDocumentFromTemplate(clientId: string, locationId: string, templateId: string, patientId: string): Promise<string | null> {

        try {

            // load the template
            const template = await DocumentsService.getDocumentTemplate(clientId, locationId, templateId);

            if (template) {

                template.templateId = templateId;

                // create a new PDocument for patient
                const docRef = await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("patients").doc(patientId)
                    .collection("pdocuments")
                    .add(template.toJSON(false));

                return docRef.id;
            }


        } catch (error) {
            console.log("Error creating new document from template: ", error);
        };

        return null;
    },


    async getDocuments(patientId: string, clientId: string, locationId: string): Promise<PDocument[] | null> {

        const pdocuments: PDocument[] = [];


        try {

            if (patientId && clientId && locationId) {
                const querySnapshot = await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("patients").doc(patientId)
                    .collection("pdocuments")
                    .get();


                querySnapshot.forEach((doc) => {

                    const pdocument = new PDocument();
                    pdocument.fromObject(doc.id, doc.data());

                    pdocuments.push(pdocument);
                });
            }

            return pdocuments;

        } catch (error) {
            console.log("Error getting patient documents: ", error);
            return null;
        }

    },


    async getDocument(clientId: string, locationId: string, patientId: string, documentId: string): Promise<PDocument | null> {

        if (!documentId) return null;

        const doc = await db.collection("clients").doc(clientId)
            .collection("locations").doc(locationId)
            .collection("patients").doc(patientId)
            .collection("pdocuments").doc(documentId)
            .get();

        try {

            if (doc.exists) {

                const pdocument = new PDocument();
                pdocument.fromObject(documentId, doc.data());

                return pdocument;

            } else {
                console.log("getDocument: No such document: " + documentId);
                return null;
            }

        } catch (error) {
            console.log("Error getting document: ", error);
            return null;
        };

    },



    async updateDocument(pdocument: PDocument, clientId: string, locationId: string, patientId: string): Promise<string | null> {

        try {

            if (pdocument.id) {
                await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("patients").doc(patientId)
                    .collection("pdocuments").doc(pdocument.id)
                    .set(pdocument.toJSON(true), { merge: true });

                return pdocument.id;

            } else {
                // create a new Document
                const docRef = await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("patients").doc(patientId)
                    .collection("pdocuments")
                    .add(pdocument.toJSON(true));

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating document: ", error);
        };

        return null;
    },

    async updateDocumentAnonymous(document: PDocument, clientId: string, locationId: string, patientId: string): Promise<boolean> {
        try {

            const updateDocumentAnonymous = functions.httpsCallable('updateDocumentAnonymous');
            await updateDocumentAnonymous(
                {
                    document: document.toJSON(true, true, false),
                    clientId: clientId,
                    locationId: locationId,
                    patientId: patientId
                }
            );

            return true;

        } catch (error) {
            console.error(`error in updateDocumentAnonymous: ${error}`);
            return false;
        }
    },

    // calculates if patient has any missing or expired documents
    async updatePatientDocumentsStatus(clientId: string, locationId: string, patientId: string): Promise<boolean> {
        try {

            const updatePatientDocumentsStatus = functions.httpsCallable('updatePatientDocumentsStatus');
            await updatePatientDocumentsStatus(
                {
                    clientId: clientId,
                    locationId: locationId,
                    patientId: patientId
                }
            );

            return true;

        } catch (error) {
            console.error(`error in updatePatientDocumentsStatus: ${error}`);
            return false;
        }
    },

    async saveDocumentAndCreatePDF(document: PDocument, clientId: string, locationId: string, patientId: string): Promise<boolean> {
        try {

            const utcOffset = moment().utcOffset();

            const docJson = JSON.stringify(document.toJSON(true, true));

            const saveDocumentAndCreatePDF = functions.httpsCallable('saveDocumentAndCreatePDF');
            await saveDocumentAndCreatePDF(
                {
                    clientId: clientId,
                    locationId: locationId,
                    patientId: patientId,
                    document: docJson,
                    utcOffset: utcOffset
                }
            );

            return true;

        } catch (error) {
            console.error(`error in saveDocumentAndCreatePDF: ${error}`);
            return false;
        }
    },

    async deleteDocument(clientId: string, locationId: string, patientId: string, documentId: string,): Promise<void> {

        try {
            const documentPath = `clients/${clientId}/locations/${locationId}/patients/${patientId}/pdocuments/${documentId}`;
            await db.doc(documentPath).delete();

        } catch (error) {
            console.log("Error deleting document: ", error);
        };
    },

    async fixDocumentSentStatus(appointments: Appointment[]): Promise<boolean> {

        console.log("setDocumentStatus started");

        try {
            for (let a = 0; a < appointments.length; a++) {
                const appointment = appointments[a];

                if (appointment && appointment.patient && appointment.patient.id && appointment.clientId && appointment.locationId) {

                    console.log(`proccessing appointment ${a + 1} of ${appointments.length}`);

                    const docs = await PatientDocsService.getDocuments(appointment.patient.id, appointment.clientId, appointment.locationId);
                    if (docs) {
                        for (let d = 0; d < docs.length; d++) {
                            const doc = docs[d];

                            if (doc.status === "none" && doc.id) {
                                doc.status = "sent";

                                await PatientDocsService.updateDocument(doc, appointment.clientId, appointment.locationId, appointment.patient.id);
                            }

                        }
                    }

                }

            }

            console.log("setDocumentStatus finished");
            return true;

        } catch (err) {
            console.error(err);
            return false;
        }

    },

    getDocumentsLink(patientId: string, clientId: string, locationId: string) {
        return `https://cal.pickadoc.de/docs/${patientId}/${clientId}/${locationId}`;
    },

    // check all documents, if there are fields that the doctor has to fill out and are missing
    async hasMissingDoctorInputs(documents: PDocument[], clientId: string): Promise<boolean> {

        for (let i = 0; i < documents.length; i++) {
            const document = documents[i];

            for (let r = 0; r < document.formRows.length; r++) {
                const row = document.formRows[r];

                for (let c = 0; c < row.columns.length; c++) {
                    const formItem = row.columns[c];

                    if ((formItem as InputFormItemInterface).inputUser === "doctor") {

                        // if its a signature, check if doctor has saved his signature to be autofilled
                        if (formItem.type === FormItemEnum.signature) {

                            const doctor = await UsersService.getUser(clientId, document.doctorId);
                            if (!(formItem as SignatureFormItem).signature && doctor && !doctor.signature) {
                                return true;
                            }

                        } else if (!formItem.hasValue()) { // now check if input has already a value
                            return true;
                        }

                    }
                }

            }
        }

        return false;
    },

    // check all documents, if there are fields that the doctor has to fill out and are missing
    async getIncompleteDocuments(documents: PDocument[], clientId: string): Promise<PDocument[]> {

        const incompleteDocs: PDocument[] = [];

        for (let i = 0; i < documents.length; i++) {

            const incompleteDoc = new PDocument();

            const document = documents[i];

            for (let r = 0; r < document.formRows.length; r++) {
                const row = document.formRows[r];

                for (let c = 0; c < row.columns.length; c++) {
                    const formItem = row.columns[c];

                    if ((formItem as InputFormItemInterface).inputUser === "doctor") {

                        // now check if input has already a value
                        if (formItem.hasValue()) {
                            continue;
                        }

                        // if its a signature, check if doctor has saved his signature to be autofilled
                        if (formItem.type === FormItemEnum.signature && document.doctorId) {

                            const doctor = await UsersService.getUser(clientId, document.doctorId);
                            if (doctor && doctor.signature) {
                                continue;
                            }
                        }


                        const newFormRow = new FormRow();
                        newFormRow.columns.push(formItem);

                        incompleteDoc.formRows.push(newFormRow);
                    }
                }

            }

            if (incompleteDoc.formRows.length > 0) {

                incompleteDoc.id = document.id;
                incompleteDoc.patientId = document.patientId;
                incompleteDoc.appointmentId = document.appointmentId;
                incompleteDoc.doctorId = document.doctorId;
                incompleteDoc.name = document.name;

                incompleteDocs.push(incompleteDoc);
            }

        }

        return incompleteDocs;
    },

    // returns doctor signature FormItems of a document
    getDoctorSignatures(document: PDocument, onlyMissingOnes: boolean): FormItem[] {

        const result: FormItem[] = [];

        for (let r = 0; r < document.formRows.length; r++) {
            const row = document.formRows[r];

            for (let c = 0; c < row.columns.length; c++) {
                const formItem = row.columns[c];

                if ((formItem as InputFormItemInterface).inputUser === "doctor") {

                    if (formItem.type === FormItemEnum.signature) {

                        if(onlyMissingOnes){
                            if (!(formItem as SignatureFormItem).signature) {
                                result.push(formItem);
                            }    
                        } else {
                            result.push(formItem);
                        }
                        
                    }

                }
            }
        }

        return result;
    }

}

export default PatientDocsService;