import DateUtils from "../../src/shared/src/utils/dateUtils";
import CalendarItem, { CalendarItemType } from "./calendarItem";


class PublicHoliday implements CalendarItem{
    id: string = "";
    resourceId: string = "";
    ressourceIds: string[] = [];

    states: string[] = []; // Bundesländer

    start: Date = new Date();
    end: Date = new Date();

    title: string = ""; // needed for calendar control
    color: string = "";

    allDay: boolean = true; // needed for calendar control

    calendarItemType: CalendarItemType = "publicHoliday";

    comments: string = "";

    clone(): PublicHoliday {
        const p = new PublicHoliday();

        p.id = this.id;

        this.resourceId = p.resourceId;
        this.ressourceIds.forEach(resId => {
            p.ressourceIds.push(resId);
        });

        this.states.forEach(state => {
            p.states.push(state);
        });

        p.start = new Date(this.start);
        p.end = new Date(this.end);
        p.title = this.title;
        p.resourceId = this.resourceId;
        p.color = this.color;

        p.allDay = true;

        p.comments = this.comments;

        return p;
    }

    toJSON(): object {
        return {
            id: this.id,

            states: this.states,

            start: this.start,
            end: this.end,

            title: this.title ? this.title : ""
        }

    }

    fromObject(id:string|null, o:any) {

        this.id = id !== null ? id : o.id;

        this.ressourceIds = [];

        this.states = o.states;

        const start = DateUtils.getDate(o.start);
        this.start = start ?? new Date();

        const end = DateUtils.getDate(o.end);
        this.end = end ?? new Date();

        this.title = o.title;

        this.allDay = true;
    }

    isSameDay(dateToTest: Date): boolean{
        return this.start.getFullYear() === dateToTest.getFullYear() &&
            this.start.getMonth() === dateToTest.getMonth() &&
            this.start.getDate() === dateToTest.getDate();
    }

};



export default PublicHoliday;



/*

Bundesländer (state):

    state is stored as lower case in DB

    BW - Baden-Würtemberg
    BY - Bayern
    BE - Berlin
    BB - Brandenburg
    HB - Bremen
    HH - Hamburg
    HE - Hessen
    MV - Mecklenburg-vorpommern
    NI - Niedersachsen
    NW - Nordrhein-Westfalen
    RP - Rheinland-Pfalz
    SL - Saarland
    SN - Sachsen
    ST - Sachsen-Anhalt
    SH - Schleswig-Holstein
    TH - Thüringen

*/