import React from 'react';


interface Props {
    rating: number;
    showNumber?: boolean
    fontSize?: string;
}

const StarsCtrl: React.FC<Props> = ({ rating, showNumber, fontSize }) => {

    function renderStars() {

        const stars = [] as any;

        for (let i = 0; i < 5; i++) {

            if (rating > i && rating < i + 1) {
                stars.push(<i key={i} className="fas fa-star-half-alt" style={{ fontSize: fontSize === undefined ? "auto" : fontSize }}></i>);
            } else if (rating > i) {
                stars.push(<i key={i} className="fas fa-star" style={{ fontSize: fontSize === undefined ? "auto" : fontSize }}></i>);
            } else {
                stars.push(<i key={i} className="far fa-star" style={{ fontSize: fontSize === undefined ? "auto" : fontSize }}></i>);
            }
        }

        return (
            <div className="kt-stars-wrapper">
                {stars}
            </div>
        );
    }

    return (

        <div className="kt-stars-ctrl">
            {renderStars()}
            {(showNumber === undefined || showNumber === true) &&
                <span className="kt-rating-value">
                    {rating.toFixed(1)} / 5
                </span>
            }
        </div>

    );
}

export default StarsCtrl;