enum UserRole {
    superuser = "superuser",
    doctor = "doctor",
    patient = "patient",
    assistant = "assistant",
    hotline = "hotline",
    secretary = "secretary",
    administrativeAgent = "administrativeAgent",
    manager = "manager",
    technician = "technician"
}

export function getUserRoleString(role: UserRole): string {

    switch (role) {
        case UserRole.superuser:
            return "Super User";
    
        case UserRole.doctor:
            return "Arzt/Ärztin";

        case UserRole.patient:
            return "Patient/in";

        case UserRole.assistant:
            return "Assistent/in";

        case UserRole.hotline:
            return "Hotline";

        case UserRole.secretary:
            return "Sekretär/in";

        case UserRole.administrativeAgent:
            return "Verwaltungsmitarbeiter/in";

        case UserRole.manager:
            return "Praxismanager/in";

        case UserRole.technician:
            return "Techniker/in";

        
        default:
            return "";
    }

}

export function getUserRoleCardinality(role: UserRole): number {

    switch (role) {
        
        case UserRole.doctor:
            return 10;

        case UserRole.assistant:
            return 20;
        
        case UserRole.technician:
            return 30;

        case UserRole.hotline:
            return 40;

        case UserRole.secretary:
            return 50;

        case UserRole.administrativeAgent:
            return 60;

        case UserRole.manager:
            return 70;

        case UserRole.patient:
            return 200;

        case UserRole.superuser:
            return 300;

        default:
            return 0;
    }

}

export default UserRole;