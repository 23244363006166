
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import DialogContentText from '@mui/material/DialogContentText/DialogContentText';

import React, { useEffect, useState, useContext} from 'react';
import Appointment from '../../models/appointment';
import Gender from '../../models/gender';
import Patient from '../../models/patient';
import VisitMotive from '../../models/visitMotive';
import NotificationsService from '../../services/notificationsService';
import VisitMotivesService from '../../services/visitMotivesService';
import DateUtils from '../../shared/src/utils/dateUtils';
import Utils from '../../shared/src/utils/utils';
import { GlobalContext } from '../../GlobalContext';


interface Props {
    visible: boolean;
    visitMotiveId: string;
    onClose: () => void;
}


const SmsWorkflowPreviewDialog: React.FC<Props> = ({ visible, visitMotiveId, onClose }) => {

    const {currentUser} = useContext(GlobalContext);
    const {currentClient} = useContext(GlobalContext);
    const {currentLocation} = useContext(GlobalContext);

    const [visitMotive, setVisitMotive] = useState(new VisitMotive());
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");

    const [status, setStatus] = useState("none");

    const [confirmationSMSDone, setConfirmationSMSDone] = useState(false);
    const [reminderSMSDone, setReminderSMSDone] = useState(false);

    const [successorBookingReminderSMSDone, setSuccessorBookingReminderSMSDone] = useState(false);
    const [successorConfirmationSMSDone, setSuccessorConfirmationSMSDone] = useState(false);
    const [successorReminderSMSDone, setSuccessorReminderSMSDone] = useState(false);

    const [recallBookingReminderSMSDone, setRecallBookingReminderSMSDone] = useState(false);
    const [recallConfirmationSMSDone, setRecallConfirmationSMSDone] = useState(false);
    const [recallReminderSMSDone, setRecallReminderSMSDone] = useState(false);

    useEffect(() => {
        initDialog();
    }, [visible]);

    useEffect(() => {
        updateVisitMotive();
    }, [visitMotiveId]);


    function initDialog() {
        setStatus("none");

        setConfirmationSMSDone(false);
        setReminderSMSDone(false);

        setSuccessorBookingReminderSMSDone(false);
        setSuccessorConfirmationSMSDone(false);
        setSuccessorReminderSMSDone(false);

        setRecallBookingReminderSMSDone(false);
        setRecallConfirmationSMSDone(false);
        setRecallReminderSMSDone(false);

        const phone = localStorage.getItem("testPhoneNumber");
        if(phone){
            setMobilePhoneNumber(phone);
        }
    }


    async function updateVisitMotive() {
        if (visitMotiveId && currentUser) {
            const vMotive = await VisitMotivesService.getVisitMotive(visitMotiveId, true, currentUser.clientId, currentUser.locationId);
            if (vMotive) setVisitMotive(vMotive);
        }
    }

    async function handleStartPreview() {

        if(!Utils.isGermanMobileNumber(mobilePhoneNumber)){
            alert("Bitte geben Sie eine gültige deutsche Handynummer ein.");
            return;
        }

        setStatus("sending");

        // save mobile phone number to local storage
        localStorage.setItem("testPhoneNumber", mobilePhoneNumber);

        await sendAppointmentSMS();

        if(visitMotive.successorEnabled) {
            await DateUtils.sleep(2000);
            await sendSuccessorAppointmentSMS();
        }

        if(visitMotive.recurrenceCount !== 0) {
            await DateUtils.sleep(2000);
            await sendRecallApointmentSMS();
        }

        setStatus("finished");

    }

    function handleClose() {
        onClose();
    }

    function getDummyPatient(): Patient {
        const pat = new Patient();

        pat.gender = Gender.male;
        pat.firstName = "Hans";
        pat.lastName = "Mustermann";
        pat.mobilePhoneNumber = mobilePhoneNumber;

        return pat;
    }

    function getDummyAppointment(type: string): Appointment {

        const patient = getDummyPatient();

        const startDate = new Date();
        startDate.setDate(startDate.getDate() + 14); // must be in future

        // create dummy appointment
        const appointment = new Appointment();
        appointment.id = type + "-" + visitMotive.id;
        appointment.patient = patient;
        appointment.visitMotive = visitMotive;
        appointment.start = startDate;
        appointment.clientId = currentClient.id;
        appointment.locationId = currentUser!.locationId;

        if(type === "successor") {
            appointment.predecessorId = "dummy";
            appointment.createdBy = "predecessor";
            appointment.status = "needsConfirmation";
        }

        if(type === "recall") {
            appointment.parentRecallId = "dummy";
            appointment.createdBy = "recaller";
            appointment.status = "needsConfirmation";
        }

        return appointment;
    }

    async function sendAppointmentSMS() {

        const appointment = getDummyAppointment("appointment");
        appointment.status = "confirmed";

        // send confirmation SMS
        await NotificationsService.sendConfirmation(appointment, appointment.patient, currentClient, currentLocation, "confirmed");
        setConfirmationSMSDone(true);

        // send reminder SMS
        await NotificationsService.sendPreviewReminder(appointment, visitMotive, appointment.patient, currentLocation, "confirmed");
        setReminderSMSDone(true);

    }

    async function sendSuccessorAppointmentSMS() {
        const appointment = getDummyAppointment("successor");

        // send booking reminder SMS
        await NotificationsService.sendPreviewReminder(appointment, visitMotive, appointment.patient, currentLocation);
        setSuccessorBookingReminderSMSDone(true);

        appointment.status = "confirmed";

        // send confirmation SMS
        await DateUtils.sleep(1000);
        await NotificationsService.sendConfirmation(appointment, appointment.patient, currentClient, currentLocation, "confirmed");
        setSuccessorConfirmationSMSDone(true);

        // send reminder SMS
        await DateUtils.sleep(1000);
        await NotificationsService.sendPreviewReminder(appointment, visitMotive, appointment.patient, currentLocation, "confirmed");
        setSuccessorReminderSMSDone(true);
    }

    async function sendRecallApointmentSMS() {
        const appointment = getDummyAppointment("recall");

        // send booking reminder SMS
        await NotificationsService.sendPreviewReminder(appointment, visitMotive, appointment.patient, currentLocation);
        setRecallBookingReminderSMSDone(true);

        appointment.status = "confirmed";

        // send confirmation SMS
        await DateUtils.sleep(1000);
        await NotificationsService.sendConfirmation(appointment, appointment.patient, currentClient, currentLocation, "confirmed");
        setRecallConfirmationSMSDone(true);

        // send reminder SMS
        await DateUtils.sleep(1000);
        await NotificationsService.sendPreviewReminder(appointment, visitMotive, appointment.patient, currentLocation, "confirmed");
        setRecallReminderSMSDone(true);
    }


    return (
        <Dialog open={visible} onClose={() => handleClose()}>
            <DialogTitle>Vorschau des Termin-Workflows {visitMotive.name}</DialogTitle>
            <DialogContent>

                SMS-Workflow:<br />
                <ul>
                    <li>Termin-Bestätigung {confirmationSMSDone && <i className='fa fa-check kt-green-color'></i>}</li>
                    <li>Termin-Erinnerung {reminderSMSDone && <i className='fa fa-check kt-green-color'></i>}</li>

                    {visitMotive.successorEnabled && <>
                        <li style={{marginTop: "10px"}}>Folgetermin-Buchungs-Aufforderung {successorBookingReminderSMSDone && <i className='fa fa-check kt-green-color'></i>}</li>
                        <li>Folgetermin-Bestätigung {successorConfirmationSMSDone && <i className='fa fa-check kt-green-color'></i>}</li>
                        <li>Folgetermin-Erinnerung {successorReminderSMSDone && <i className='fa fa-check kt-green-color'></i>}</li>
                    </>}

                    {visitMotive.recurrenceCount !== 0 && <>
                        <li style={{marginTop: "10px"}}>Recalltermin-Buchungs-Aufforderung {recallBookingReminderSMSDone && <i className='fa fa-check kt-green-color'></i>}</li>
                        <li>Recalltermin-Bestätigung {recallConfirmationSMSDone && <i className='fa fa-check kt-green-color'></i>}</li>
                        <li>Recalltermin-Erinnerung {recallReminderSMSDone && <i className='fa fa-check kt-green-color'></i>}</li>
                    </>}

                </ul>

                {(status === "sending") &&
                    <DialogContentText>
                        Die SMS-Nachrichten werden nun verschickt... <i className="fas fa-spinner fa-pulse"></i>
                    </DialogContentText>
                }

                {(status === "finished") &&
                    <DialogContentText>
                        Die SMS-Nachrichten wurden alle verschickt. <i className='fa fa-check kt-green-color'></i>
                    </DialogContentText>
                }

                {(status === "none") && <>
                    <DialogContentText>
                        Geben Sie nachfolgend eine Handynummer ein, an die alle Termin-Worklfow-SMS geschickt werden sollen.
                        Sie erhalten dann alle SMS die ein echter Patient erhalten würde.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="mobilephonenumber"
                        label="Handynummer"
                        type="tel"
                        fullWidth
                        variant="standard"
                        value={mobilePhoneNumber}
                        onChange={e => setMobilePhoneNumber(e.target.value)}
                    />
                </>}

            </DialogContent>

            <DialogActions>
                <Button onClick={() => handleClose()}>Schließen</Button>
                <Button disabled={status !== "none"} onClick={() => handleStartPreview()}>Vorschau starten</Button>
            </DialogActions>
        </Dialog>

    );
}

export default SmsWorkflowPreviewDialog;
