import React from "react";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import HeadlineFormItem, { HeadlineSize } from "../../../src/shared/src/models/formEditor/headlineFormItem";
import { FormItemCtrlProps } from "./formItemCtrl";
import { Align } from "../../../src/shared/src/models/formEditor/formItem";
import TextEvaluationService from "../../../src/services/textEvaluationService";
import ButtonGroup from "@mui/material/ButtonGroup";


interface Props extends FormItemCtrlProps {
    item: HeadlineFormItem
}

const HeadlineFormItemCtrl: React.FC<Props> = (
    {
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode
    }) => {


    function renderHtml(): JSX.Element {

        const textAlignStrings: any = ["left", "center", "right"];
        const textAlignString = textAlignStrings[item.textAlign];

        return (
            <React.Fragment>
                <input style={{textAlign: textAlignString}}
                    type="text"
                    value={item.getLanguageProperty(languageKey, "labels")}
                    onChange={(e) => handleOnInputChange(item, e.target.value, "labels")}
                    className={`kt-formHeadline-${item.textSize}`}
                />
            </React.Fragment>
        );

    }

    function renderProperties(): JSX.Element {
        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <strong>Überschrift</strong>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="outlined" size="small">
                            <Button
                                startIcon={<FormatSizeIcon/>}
                                variant={item.textSize === HeadlineSize.small ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, HeadlineSize.small, "textSize")}
                            >
                                klein
                            </Button>
                            <Button
                                startIcon={<FormatSizeIcon/>}
                                variant={item.textSize === HeadlineSize.normal ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, HeadlineSize.normal, "textSize")}
                            >
                                normal
                            </Button>
                            <Button
                                startIcon={<FormatSizeIcon/>}
                                variant={item.textSize === HeadlineSize.big ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, HeadlineSize.big, "textSize")}
                            >
                                groß
                            </Button>
                        </ButtonGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <ButtonGroup variant="outlined" size="small">
                            <Button
                                startIcon={<FormatAlignLeftIcon/>}
                                variant={item.textAlign === Align.left ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.left, "textAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignCenterIcon/>}
                                variant={item.textAlign === Align.center ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.center, "textAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignRightIcon/>}
                                variant={item.textAlign === Align.right ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.right, "textAlign")}
                            />
                        </ButtonGroup>
                    </Grid>
                </Grid>

            </div>
        );
    }



    function renderFinalHtml(): JSX.Element {

        const textAlignStrings: any = ["left", "center", "right"];
        const textAlignString = textAlignStrings[item.textAlign];

        let label = item.getLanguageProperty(languageKey, "labels");

        label = TextEvaluationService.evaluateDocumentText(label);

        return (
            <React.Fragment>
                <div style={{textAlign: textAlignString}}
                    className={`kt-formHeadline-${item.textSize}`}
                >
                    {label}
                </div>
            </React.Fragment>
        );

    }


    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }

}


export default HeadlineFormItemCtrl;