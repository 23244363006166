import { useContext } from 'react';

import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import LoginPage from "./loginPage";
import PasswordRecoveryPage from "./passwordRecoveryPage";

import VideoCallPage from './videoCallPage';
import DocsPage from './documentsManager/docsPage';

import DisabledPage from './disabledPage';
import LogoutPage from './logoutPage';
import { GlobalContext } from '../../GlobalContext';
import ProtectedRoute from './protectedRoute';
import LoadingPage from './loadingPage';
import VideoSidebar from './videoSidebar';
import TopNavBar from '../topNavBar';

import CalendarPage from "./calendarPage";
import PatiensPage from "./patientsPage";
import SettingsPage from "./settingsPage";
import WaitingRoomPage from "./waitingRoomPage";
import TodosPage from "./todosPage";


import ClientLocationsPage from "./settings/clientLocationsPage";
import ClientLocationPage from "./settings/clientLocationPage";
import UsersPage from './settings/usersPage';
import UserPage from './settings/userPage';
import SpecialityPage from './settings/specialityPage';
import SpecialitiesPage from './settings/specialitiesPage';
import VisitMotivesPage from './settings/visitMotives/visitMotivesPage';
import VisitMotivePage from './settings/visitMotives/visitMotivePage';
import CalendarEditPage from './settings/calendarPage';
import CalendarsPage from './settings/calendarsPage';
import NotificationsPage from './settings/notificationsPage';

import RoomPage from './settings/roomPage';
import RoomsPage from './settings/roomsPage';

import DevicePage from './settings/devicePage';
import DevicesPage from './settings/devicesPage';


import DashboardPage from './dashboardPage';
import DocumentsPage from './settings/documentsPage';
import DocumentPage from './settings/documentPage';
import InvoicesPage from "./settings/invoicesPage";
import InvoicePage from "./settings/invoicePage";
import LogItemsPage from './settings/logItemsPage';
import RecallerPage from './recaller/recallerPage';
import CampaignPage from './recaller/campaignPage';
import CreateCampaignPage from './recaller/createCampaignPage';
import SettingsSideMenu from './settingsSideMenu';
import ClonRPage from './clonRPage';
import ClientsPage from './administration/clientsPage';
import ClientPage from './administration/clientPage';
import FirstStepsWizard from '../firstStepsWizard';
import DemoLoginPage from './demoLoginPage';



function AppRoutes() {

    const { currentClient } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);

    const { progressMessage } = useContext(GlobalContext);
    const { isProgressMessageVisible } = useContext(GlobalContext);
    const { videoSidebarVisible } = useContext(GlobalContext);
    const { isLoading } = useContext(GlobalContext);


    var userLang = navigator.language;

    console.log("render MainRoutes");

    if (currentUser && currentClient && currentClient.isEnabled === false) {
        return <DisabledPage />
    }

    if (isLoading) {
        return <LoadingPage />
    }


    return (
        <BrowserRouter>

            <div className={videoSidebarVisible ? "App kt-video-sidebar" : "App"}>

                {isProgressMessageVisible &&
                    <div className="kt-progress-wrapper">
                        <div className="kt-progress-panel">
                            <span>{progressMessage}</span>
                            <i className="fal fa-spinner-third fa-spin"></i>
                        </div>
                    </div>
                }

                <Routes>

                    {/* PROTECTED SUPERUSER ROUTES */}
                    <Route element={<ProtectedRoute isAllowed={currentUser && currentUser.id} />} >
                        <Route path="/administration/clients" element={<>
                            <TopNavBar />
                            <ClientsPage />
                        </>} />
                    </Route>

                    <Route element={<ProtectedRoute isAllowed={currentUser && currentUser.id} />} >
                        <Route path="/administration/client/:clientId" element={<>
                            <TopNavBar />
                            <ClientPage />
                        </>} />
                    </Route>


                    {/* PROTECTED ROUTES */}

                    <Route element={<ProtectedRoute isAllowed={currentUser && currentUser.id} />} >
                        <Route path="/patients" element={<>
                            <TopNavBar />
                            <PatiensPage />
                        </>} />


                        <Route path="/waitingroom" element={<>
                            <TopNavBar />
                            <WaitingRoomPage />
                        </>} />


                        <Route path="/todos" element={<>
                            <TopNavBar />
                            <TodosPage />
                        </>} />

                        <Route element={<ProtectedRoute isAllowed={currentUser && currentUser.id && currentUser.isAdmin} currentUser={currentUser} />} >

                            <Route path="/settings" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu />
                                    <SettingsPage />
                                </div>
                            </>} />

                            <Route path="/settings/clientlocations" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="clientlocations" />
                                    <ClientLocationsPage />
                                </div>
                            </>} />

                            <Route path="/settings/clientlocation/:clientLocationId" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="clientlocations" />
                                    <ClientLocationPage />
                                </div>
                            </>} />

                            <Route path="/settings/user/:userId" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="users" />
                                    <UserPage />
                                </div>
                            </>} />

                            <Route path="/settings/users" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="users" />
                                    <UsersPage />
                                </div>
                            </>} />

                            <Route path="/settings/calendar/:calendarId" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="calendars" />
                                    <CalendarEditPage />
                                </div>
                            </>} />

                            <Route path="/settings/calendars" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="calendars" />
                                    <CalendarsPage />
                                </div>
                            </>} />

                            <Route path="/settings/speciality/:specialityId" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="specialities" />
                                    <SpecialityPage />
                                </div>
                            </>} />

                            <Route path="/settings/specialities" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="specialities" />
                                    <SpecialitiesPage />
                                </div>
                            </>} />

                            <Route path="/settings/visitMotive/:visitMotiveId/:specialityId?" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="visitmotives" />
                                    <VisitMotivePage />
                                </div>
                            </>} />

                            <Route path="/settings/visitMotives/:specialityId?" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="visitmotives" />
                                    <VisitMotivesPage />
                                </div>
                            </>} />

                            <Route path="/settings/room/:roomId" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="rooms" />
                                    <RoomPage />
                                </div>
                            </>} />

                            <Route path="/settings/rooms" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="rooms" />
                                    <RoomsPage />
                                </div>
                            </>} />

                            <Route path="/settings/device/:deviceId" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="devices" />
                                    <DevicePage />
                                </div>
                            </>} />

                            <Route path="/settings/devices" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="devices" />
                                    <DevicesPage />
                                </div>
                            </>} />

                            <Route path="/settings/notifications" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="notifications" />
                                    <NotificationsPage />
                                </div>
                            </>} />

                            <Route path="/settings/events" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="events" />
                                    <LogItemsPage />
                                </div>
                            </>} />

                            <Route path="/settings/document/:documentId" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="documents" />
                                    <DocumentPage />
                                </div>
                            </>} />

                            <Route path="/settings/documents" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="documents" />
                                    <DocumentsPage />
                                </div>
                            </>} />

                            <Route path="/settings/invoice/:invoiceId" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="invoices" />
                                    <InvoicePage />
                                </div>
                            </>} />

                            <Route path="/settings/invoices" element={<>
                                <TopNavBar />
                                <div className="kt-page-with-side-menu">
                                    <SettingsSideMenu location="invoices" />
                                    <InvoicesPage />
                                </div>
                            </>} />

                        </Route>

                        <Route path="/dashboard" element={<>
                            <TopNavBar />
                            <div className="kt-page-with-side-menu">
                                <DashboardPage />
                            </div>
                        </>} />

                        <Route path="/recaller" element={<>
                            <TopNavBar />
                            <RecallerPage />
                        </>} />

                        <Route path="/createcampaign/:campaignId" element={<>
                            <TopNavBar />
                            <CreateCampaignPage />
                        </>} />

                        <Route path="/createcampaign" element={<>
                            <TopNavBar />
                            <CreateCampaignPage />
                        </>} />

                        <Route path="/campaign/:campaignId" element={<>
                            <TopNavBar />
                            <CampaignPage />
                        </>} />

                        <Route path="/notifications" element={<>
                            <TopNavBar />
                            <CalendarPage
                                locale={userLang}
                                mode={"notifications"}
                            />
                        </>} />

                        <Route path="/clonr/:view?/:itemId?" element={<>
                            <ClonRPage/>
                        </>} />

                        <Route path="/" element={<>
                            <TopNavBar />
                            <CalendarPage
                                locale={userLang}
                                mode={"appointment"}
                            />
                        </>} />


                    </Route>


                    {/* PUBLIC ROUTES */}

                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/demologin" element={<DemoLoginPage />} />

                    <Route path="/logout" element={<LogoutPage />} />

                    <Route path="/videocall/:videoRoomId/:userId" element={<VideoCallPage />} />

                    <Route path="/docs/:patientId/:clientId/:locationId" element={<DocsPage />} />

                    <Route path="/password-recovery" element={<PasswordRecoveryPage />} />

                    <Route path="*" element={<>
                        <TopNavBar />
                        <div>Die Seite konnte nicht gefunden werden. Bitte überprüfen Sie die URL.</div>
                    </>} />

                </Routes>

                <VideoSidebar />
                <FirstStepsWizard />
                
            </div>

        </BrowserRouter>

    )
}

export default AppRoutes;
