import React from 'react';


interface Props {
    title: string;
    icon: string;
    text: string;
}

const CardWidget:React.FC<Props> = ({title, icon, text}) => {

    return (

        <div className="kt-card-widget">
            <div className="kt-card-widget-head">
                {title}
            </div>
            <div className="kt-card-widget-body">
                <i className={`${icon}`}></i><span className="kt-card-widget-text">{text}</span>
            </div>
        </div>

    );
}

export default CardWidget;