import { useState, useEffect, useContext, Fragment } from 'react';
import { Link } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import UsersService from "../../../services/usersService";

import User from '../../../models/user';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import { getUserRoleString } from '../../../models/userRole';
import Avatar from '../../avatar';
import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import { updateRowPosition } from '../../../utils';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { GlobalContext } from '../../../GlobalContext';


function UsersPage() {

    const [users, setUsers] = useState<User[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient, currentUser} = useContext(GlobalContext);

    useEffect( () => {

        updateUsers();

        // eslint-disable-next-line
    }, [currentClient]);

    async function updateUsers(){
        setIsLoading(true);

        try {
            const result = await UsersService.getUsers(currentClient.id);

            setIsLoading(false);
            if(result){
                setUsers(result);
            }

        } catch(error) {
            setIsLoading(false);
        }
    }

    const columns: any[] = [
        { field: 'avatarUrl', headerName: ' ', width: 70, renderCell: (params) => (
            <Link to={`/settings/user/${params.row.id}`}><Avatar src={params.row.avatarUrl} width={32} /></Link>
        )},
        { field: 'firstName', headerName: 'Vorname', width: 300, renderCell: (params) => (
            <Link to={`/settings/user/${params.row.id}`}>{params.row.firstName}</Link>
        )},
        { field: 'lastName', headerName: 'Nachname', width: 300, renderCell: (params) => (
            <Link to={`/settings/user/${params.row.id}`}>{params.row.lastName}</Link>
        )},
        { field: 'role', headerName: 'Rolle', width: 300, renderCell: (params) => (
            <span>{getUserRoleString(params.row.role)}</span>
        )},
        {
            field: 'isAdmin', headerName: 'Admin', width: 100, renderCell: (params) => (
                params.row.isAdmin ? <CheckRoundedIcon /> : <Fragment />
            )
        },
        { field: 'locationName', headerName: 'Standort', width: 300 },
        { field: 'delete', headerName: " ", width: 64, renderCell: (params) => (
            <IconButton onClick={ (e) => onDeleteRowClick(params.row)}>
                <DeleteIcon/>
            </IconButton>)
        }
      ];



    function getAllAdministratorsCount(): number {
        return users ? users.filter( u => u.isAdmin).length : 0;
    }

    async function onDeleteRowClick(row){

        // first check if we are trying to delete the only one user with admin rights
        if(row.isAdmin && getAllAdministratorsCount() === 1) {
            alert("Sie können nicht den einzigen Benutzer mit Administrator Rechten löschen.");
            return;
        }

        const reallyDelete = window.confirm(`Wollen Sie den Mitarbeiter ${row.firstName} ${row.lastName} wirklich löschen?`);
        if(reallyDelete) {
            await UsersService.deleteUser(row.id, currentClient.id);
            updateUsers();
        }
    }


    const handleRowOrderChange = async (params) => {
        setIsLoading(true);
        const newRows = updateRowPosition<User>(
            params.oldIndex,
            params.targetIndex,
            users,
        );

        for (let i = 0; i < newRows.length; i++) {
            const e = newRows[i];
            e.cardinality = i+1;

            UsersService.updateUser(e, currentClient.id);
        }
        setUsers(newRows);
        setIsLoading(false);
    };

    return (
        <Box className="kt-page">
            <h2>Mitarbeiter</h2>

            <Grid container>
                <Grid item>
                    <ButtonGroup>
                        <Button component={Link} to="/settings/user/create" startIcon={<AddCircleOutlineIcon/>}>
                            Mitarbeiter anlegen
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Box className="kt-data-grid-container">
                <DataGridPro
                    loading={isLoading}
                    rows={users}
                    columns={columns}
                    rowReordering
                    onRowOrderChange={handleRowOrderChange}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>

        </Box>
    );
}

export default UsersPage;