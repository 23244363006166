// ClonR settings for a user
export default class ClonRSettings {

    id: string = ""; // the user id

    defaultLanguage: string = "de"; // the base language from which we generate the other translations

    isAutoTranslateEnabled: boolean = true; // if true, then the default language script gets automatically translated to the languages defined in autoLanguageKeys
    autoLanguageKeys: string[] = []; // defines the languages to which the default language script gets automatically translated 

    constructor(userId: string) {
    
        this.id = userId;
        
    }

    clone(): ClonRSettings {
        const c = new ClonRSettings(this.id);
        c.fromObject(this.id, this.toJSON());

        return c;
    }

    toJSON(): object {
        return {
            id: this.id,

            defaultLanguage: this.defaultLanguage,

            isAutoTranslateEnabled: this.isAutoTranslateEnabled,
            autoLanguageKeys: this.autoLanguageKeys            
        };
    }

    fromObject(id: string | null, o: any) {

        this.id = id !== null ? id : o.id;

        this.defaultLanguage = o.defaultLanguage ?? "de";

        this.isAutoTranslateEnabled = o.isAutoTranslateEnabled === true;
                
        this.autoLanguageKeys = o.autoLanguageKeys ? [...o.autoLanguageKeys] : [];
    }

}