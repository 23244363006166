enum MessageStatus {
    unknown,
    pending, // will be send in the future
    successfullySendToGateway,
    successfullyDispatched, // erfolgreich zugestellt
    notDeliveredYet, // not delivered yet (for example mobile phone is off or network temporarily unavailable)
    notDelivered, // probably not delivered (wrong number, SIMcard not active)
    notDeliveredWithinTwoDays // could not be delivered within 48 hours
}

export default MessageStatus;