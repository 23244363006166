// import { useEffect, useState } from "react";
// import firebaseApp from "./../database";

function DisabledPage() {

    // const logoutStartTime = 30;

    // const [logoutTime, setLogoutTime] = useState(logoutStartTime);

    // useEffect(() => {

    //     const timer = setInterval(() => {

    //         setLogoutTime((prevTime) => prevTime - 1);

    //     }, 1000);

    //     return () => clearInterval(timer);

    // }, []);

    // useEffect(() => {
    //     if(logoutTime <= 0){
    //         firebaseApp.auth().signOut();
    //     }
    // }, [logoutTime]);

    return <div className="kt-disabled-page">

        <div className="kt-wrapper">
            <h1>Pickadoc</h1>

            <img src="/images/logo.svg" alt="logo" className='kt-logo' />

            <div>
                <p>
                    Vielen Dank, dass Sie Pickadoc getestet haben.
                </p>
                Aus Sicherheitsgründen müssen wir sicherstellen, dass Sie ein Angehöriger eines Heilberufes sind.
                <br />
                Um Ihren kostenlosen Testzugang weiter nutzen zu können, bitten wir Sie uns kurz zu kontaktieren.
                <br />
                <a href="mailto:info@pickadoc.de">info@pickadoc.de</a>

                {/* <p>
                    Sie werden nun in {logoutTime} Sekunden abgemeldet...
                </p> */}
            </div>

        </div>
    </div>;
}

export default DisabledPage;