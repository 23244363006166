
import React from "react";

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from "@mui/material/FormLabel";


import InputTextFormItem from "../../../src/shared/src/models/formEditor/inputTextFormItem";
import TextEvaluationService from "../../../src/services/textEvaluationService";
import { FormItemCtrlProps } from "./formItemCtrl";


interface Props extends FormItemCtrlProps {
    item: InputTextFormItem
}

const InputTextFormItemCtrl: React.FC<Props> = (
    {
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode,
        formUser
    }) => {


    function renderHtml(): JSX.Element {

        const langQuestion = item.getLanguageProperty(languageKey, "labels");

        return (
            <div >
                <div className="kt-inline-input-wrapper">
                    {item.required && <span>*</span>}

                    <input
                        type="text"
                        value={langQuestion}
                        onChange={(e) => handleOnInputChange(item, e.target.value, "labels")}
                        placeholder="Beschriftung"
                    />
                </div>

                <div className="kt-input-text-placeholder"></div>
            </div>
        );

    }

    function renderProperties(): JSX.Element {

        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <strong>Texteingabe</strong>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label="Pflichtfeld"
                            control={
                                <Checkbox
                                    checked={item.required}
                                    onChange={(e) => handleOnInputChange(item, e.target.checked, "required")}
                                />
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Darf ausfüllen:</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="patient"
                                name="radio-buttons-group"
                                value={item.inputUser}
                                onChange={(e) => handleOnInputChange(item, e.target.value, "inputUser")}
                            >
                                <FormControlLabel value="patient" control={<Radio />} label="Patient" />
                                <FormControlLabel value="doctor" control={<Radio />} label="Arzt" />
                                <FormControlLabel value="all" control={<Radio />} label="Jeder" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {

        let langQuestion = item.getLanguageProperty(languageKey, "labels");
        langQuestion = TextEvaluationService.evaluateDocumentText(langQuestion);

        return (
            <div className="kt-form-input-wrapper kt-white-input">

                <TextField
                    fullWidth
                    label={langQuestion}
                    required={item.required}
                    InputLabelProps={{ required: false }}
                    value={item.value}
                    onChange={(e) => handleOnInputChange(item, e.target.value, "value")}
                    disabled={item.inputUser === "doctor" && formUser === "patient"}
                />

            </div>
        );

    }

    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }
}


export default InputTextFormItemCtrl;