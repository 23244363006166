
import FormItem, { FormItemEnum, FormItemType } from "./formItem";

export class PlaceholderFormItem extends FormItem {

    height:number = 50; // px

    constructor(rowId: string) {
        super(rowId);

        this.type = FormItemEnum.placeholder;
    }

    clone(): FormItemType {

        const c = new PlaceholderFormItem(this.parentId);
        c.fromObject(null, this.toJSON(true));

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj: any = super.toJSON(includeUserInputs);

        obj.height = this.height;

        return obj;
    }

    fromObject(id: string|null, o:any){
        super.fromObject(id, o);

        this.height = o.height ?? 50;
    }


}


export default PlaceholderFormItem;