import DateUtils from "../../utils/dateUtils";
import Utils from "../../utils/utils";

export default class ClonRVoice {

    id: string = "";

    customVoiceId: string = ""; // elevenlabs voice id

    userId: string = ""; // for whom this voice should be assigned to

    name: string = "";

    audioUrl: string = "";
    audioFileExtension: string = "";

    status: "noAudio" | "inProgress" | "completed" | "error" = "noAudio";

    errorReason: string = "";

    createdAt: Date;
    createdBy: string = ""; // userId

    isDeleted: boolean = false;
    deletedAt: Date | null = null;
    deletedBy: string = ""; // userId

    isSystem: boolean = false; // true if it is a system voice, which cannot be deleted

    constructor() {
        this.id = Utils.getUUID();
        this.createdAt = new Date();
    }


    clone(): ClonRVoice {
        const v = new ClonRVoice();
        v.fromObject(this.id, this.toJSON());

        return v;
    }
    toJSON(): object {
        return {
            id: this.id,
            customVoiceId: this.customVoiceId,

            userId: this.userId,
            name: this.name,

            audioUrl: this.audioUrl,
            audioFileExtension: this.audioFileExtension,

            status: this.status,

            errorReason: this.errorReason,

            createdAt: this.createdAt,
            createdBy: this.createdBy,

            isDeleted: this.isDeleted,
            deletedAt: this.deletedAt,
            deletedBy: this.deletedBy,

            isSystem: this.isSystem

        };
    }

    fromObject(id: string | null, o: any) {

        this.id = id !== null ? id : o.id;

        this.customVoiceId = o.customVoiceId ?? "";

        this.userId = o.userId ?? "";

        this.name = o.name ?? "";

        this.audioUrl = o.audioUrl ?? "";

        this.audioFileExtension = o.audioFileExtension ?? "";

        this.status = o.status ?? "noAudio";

        this.errorReason = o.errorReason ?? "";

        this.createdAt = DateUtils.getDate(o.createdAt) ?? new Date();

        this.createdBy = o.createdBy ?? "";

        this.isDeleted = o.isDeleted === true;

        this.deletedAt = o.deletedAt ? DateUtils.getDate(o.deletedAt) : null;

        this.deletedBy = o.deletedBy ?? "";

        this.isSystem = o.isSystem === true;
    }


}