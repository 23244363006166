import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PatientsService from '../../../services/patientsService';
import DateUtils from '../../../shared/src/utils/dateUtils';
import Utils from '../../../shared/src/utils/utils';
import PatientDialog from '../../dialogs/patientDialog/patientDialog';
import { DataGridPro, GridRowSelectionModel, deDE } from '@mui/x-data-grid-pro';
import { Campaign, CampaignType } from '../../../shared/src/models/campaign/campaign';
import CampaignsService from '../../../services/campaignsService';
import SaveButton from '../../saveButton';
import BackButton from '../../backButton';
import SaveStatus from '../../../models/saveStatus';
import DeleteIcon from "@mui/icons-material/Delete";
import RecallerPatientsFilter from './recallerPatientsFilter';
import ProgressDialog from '../../dialogs/progressDialog';
import VisitMotivePicker from '../../visitMotivePicker';
import VisitMotive from '../../../models/visitMotive';
import SmsEditor from '../../smsEditor';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import MessageDialog from '../../dialogs/messageDialog';
import LandingPageCtrl from '../../landingPageCtrl';
import LandingPageData from '../../../shared/src/models/landingPageData';
import Calendar from "../../../models/calendar"
import CalendarsService from '../../../services/calendarsService';
import UsersService from '../../../services/usersService';
import CampaignPatient from '../../../models/campaignPatient';
import Link from '@mui/material/Link';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { GlobalContext } from '../../../GlobalContext';


const CampaignPage: React.FC = () => {

    const formRef = useRef<any>();

    const navigate = useNavigate();

    const { campaignId } = useParams();

    const {currentUser} = useContext(GlobalContext);
    const {currentClient} = useContext(GlobalContext);
    const {visitMotives} = useContext(GlobalContext);
    const {specialities} = useContext(GlobalContext);
    const {calendars} = useContext(GlobalContext);
    
    const [patients, setPatients] = useState<CampaignPatient[]>([]);
    const [selectedPatients, setSelectedPatients] = useState<CampaignPatient[]>([]);
    const [invalidPhoneNumberPatients, setInvalidPhoneNumberPatients] = useState<CampaignPatient[]>([]);
    const [isPatientDialogVisible, setIsPatientDialogVisible] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(new CampaignPatient());

    const [isLoading, setIsLoading] = useState(false);
    const [saveStatus, setSaveStatus] = useState(SaveStatus.none);


    const [campaign, setCampaign] = useState<Campaign>(new Campaign());
    const [oldCampaign, setOldCampaign] = useState<Campaign>(new Campaign()); // to track unsaved changes

    const [selectedTab, setSelectedTab] = useState(0);

    const [isMessageDialogVisible, setIsMessageDialogVisible] = useState(false);
    const [messageDialogTitle, setMessageDialogTitle] = useState("");
    const [messageDialogLabel, setMessageDialogLabel] = useState("");

    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

    const [isCreateCampaignDialogVisible, setIsCreateCampaignDialogVisible] = useState(false);

    useEffect(() => {

        if (campaignId && currentClient && currentUser) {
            loadCampaign();
        }

    }, [campaignId, currentClient, currentUser]);

    async function loadCampaign() {
        console.log("load campaign: " + campaignId);

        if (currentUser && campaignId) {

            setIsLoading(true);

            const camp = await CampaignsService.getCampaign(currentClient.id, currentUser.locationId, campaignId);

            if (camp) {
                setCampaign(camp);
                setOldCampaign(camp.clone());

                const patResult = await CampaignsService.getCampaignPatients(campaignId, currentClient.id, currentUser.locationId);

                if (patResult) {
                    setPatients(patResult);
                    setSelectedPatients(patResult);

                    setSelectionModel(patResult.map(p => p.id));
                }
            }

            setIsLoading(false);
        }
    }

    async function handleSubmit(event?, redirect?: boolean) {
        if(event) event.preventDefault();

        let _redirect = true;
        if(redirect !== undefined) _redirect = redirect === true;

        if (selectedPatients.length === 0) {
            showMessageDialog("Fehler", "Bitte wählen Sie mindestens einen Patienten aus, um diese Kampagne zu erstellen.");

        } else if (campaign.type === CampaignType.sms && campaign.smsMessage === "") {
            showMessageDialog("Fehler", "Bitte geben Sie einen SMS Text ein.");

        } else if (campaign !== null && currentUser) {
            setSaveStatus(SaveStatus.isSaving);

            campaign.patientsSelected = selectedPatients.length;
            const newCampaignId = await CampaignsService.updateCampaign(campaign, currentUser.clientId, currentUser.locationId);

            if (newCampaignId) {

                campaign.id = newCampaignId;
                setCampaign(campaign.clone());
                setOldCampaign(campaign.clone());

                // save selected Patients
                await Promise.all(selectedPatients.map((pat) => CampaignsService.updateCampaignPatient(pat, newCampaignId, currentUser.clientId, currentUser.locationId)));

                setSaveStatus(SaveStatus.saved);

                if(_redirect) navigate(`/createcampaign/${newCampaignId}`);

            } else {
                showMessageDialog("Fehler", "Die Kampagne konnte nicht gespeichert werden!");
                setSaveStatus(SaveStatus.none);
            }

        }

    }

    function onInputChange(event) {
        if (campaign) {
            const { name, value } = event.target;

            const camp = campaign.clone();

            if (event.target.type === "checkbox") {
                camp[name] = event.target.checked;
            } else {
                camp[name] = value;
            }

            setCampaign(camp);
        }
    }

    async function handleCampaignCalendarChange(calId) {
        if (campaign && currentUser) {

            const camp = campaign.clone();

            camp.calendarId = calId;

            const cal = await CalendarsService.getCalendar(currentClient.id, currentUser.locationId, calId);
            if (cal) {
                camp.calendarName = cal.name;
                camp.doctorId = cal.userId;

                const doctor = await UsersService.getUser(currentClient.id, cal.userId);
                if (doctor) {
                    camp.doctorName = doctor.getFullName();
                }
            }

            setCampaign(camp);
        }
    }

    function handleLandingPageChange(landingPage: LandingPageData) {
        if (campaign) {
            const c = campaign.clone();

            c.landingPage = landingPage.clone();

            setCampaign(c);
        }
    }




    async function onDeleteRowClick(patient) {
        if (window.confirm(`Wollen Sie den Patienten ${patient.firstName} ${patient.lastName} wirklich endgültig aus Ihrer Kartei löschen?`) && currentUser) {
            await PatientsService.deletePatient(patient.id, currentUser.clientId, currentUser.locationId);

            const newList = Utils.removeItemFromArray<CampaignPatient>(patient, patients);
            setPatients(newList);
        }
    }

    function getLastAppointmentDateString(lastAppointmentDate: Date) {
        const result = DateUtils.getDateString(lastAppointmentDate);

        if (result.indexOf("1900") !== -1) return "";

        return result;
    }


    const columns: any[] = [
        {
            field: "gender", headerName: "Anrede", width: 100, renderCell: (params) => (
                <span>{Utils.getGenderString(params.row.gender)}</span>
            )
        },
        {
            field: "firstName", headerName: "Vorname", width: 200, renderCell: (params) => (
                <Link onClick={() => handlePatientRowClick(params.row)} color="textPrimary" className="kt-underline">{params.row.firstName}</Link>
            )
        },
        {
            field: "lastName", headerName: "Nachname", width: 200, renderCell: (params) => (
                <Link onClick={() => handlePatientRowClick(params.row)} color="textPrimary" className="kt-underline">{params.row.lastName}</Link>
            )
        },
        {
            field: "phoneNumber", headerName: "Tel.", width: 200, renderCell: (params) => (
                <span>{params.row.getMobileOrPhoneNumber()}</span>
            )
        },
        {
            field: "birthDate", headerName: "Geburtstag", width: 200, renderCell: (params) => (
                <span>{DateUtils.getBirthdayString(params.row.birthDate)}</span>
            )
        },
        {
            field: "privateInsurance", headerName: "Priv.versichert", width: 120, renderCell: (params) => (
                <span>{params.row.privateInsurance ? <CheckRoundedIcon /> : null}</span>
            )
        },
        {
            field: "lastAppointmentDate", headerName: "letzter Termin", width: 120, renderCell: (params) => (
                <span>{getLastAppointmentDateString(params.row.lastAppointmentDate)}</span>
            )
        },
        {
            field: 'delete', headerName: " ", width: 64, renderCell: (params) => (
                <IconButton onClick={(e) => onDeleteRowClick(params.row)}>
                    <DeleteIcon />
                </IconButton>)
        }
    ];




    function handlePatientRowClick(patient: CampaignPatient) {
        setSelectedPatient(patient);
        setIsPatientDialogVisible(true);
    }



    function handleVisitMotiveChange(newVisitMotive: VisitMotive | null) {
        if (newVisitMotive && campaign) {
            const camp = campaign.clone();

            camp.visitMotiveId = newVisitMotive.id;
            camp.visitMotiveName = newVisitMotive.name;

            if (camp.smsMessage === "" && newVisitMotive.recallSmsText !== "") {
                camp.smsMessage = newVisitMotive.recallSmsText;
            }

            // fill landing page data from visit motive if user has not edited the campaign landing page
            if (camp.landingPage.headline === "" && camp.landingPage.description === "" && camp.landingPage.image === "") {
                camp.landingPage = newVisitMotive.landingPage.clone();
            }

            setCampaign(camp);
        }
    }



    function onCampaignSmsMessageChange(newMessage: string) {
        if (campaign) {
            const camp = campaign.clone();

            camp.smsMessage = newMessage;

            setCampaign(camp);
        }
    }

    function gotoSecondTab() {

        if (selectedPatients.length === 0) {
            showMessageDialog("Fehler", "Bitte wählen Sie mindestens einen Patienten aus, um fortzufahren.");

        } else {
            setSelectedTab(1);
        }
    }

    function gotoThirdTab() {

        // trigger form validation
        if (formRef && formRef.current) {
            if (formRef.current.reportValidity()) {

                // check for invalid phone numbers if SMS campaign
                const inv = PatientsService.getInvalidMobilePhoneNumberPatients(selectedPatients);
                setInvalidPhoneNumberPatients(inv);

                setSelectedTab(2);
            }
        }
    }


    function onTabChange(newTab: number) {
        if (selectedTab === 0 && newTab === 1) {
            gotoSecondTab();
        } if(newTab === 2){
            gotoThirdTab();
        } else {
            setSelectedTab(newTab);
        }
    }



    function showMessageDialog(title: string, label: string) {
        setMessageDialogTitle(title);
        setMessageDialogLabel(label);
        setIsMessageDialogVisible(true);
    }

    function getBillablePatientsCount(): number {
        return selectedPatients.length - invalidPhoneNumberPatients.length;
    }

    function getCostPerCampaignSms(): number {
        return 0.25;
    }

    function getTotalCampaignCost(): number {
        return getBillablePatientsCount() * getCostPerCampaignSms();
    }

    async function onStartCampaignClick() {
        setIsCreateCampaignDialogVisible(false);

        await handleSubmit(null, false);
        CampaignsService.startCampaign(currentClient.id, currentUser!.locationId, campaign.id);

        setMessageDialogTitle("Recall Kampagne gestartet");
        setMessageDialogLabel("Ihre Recall Kampagne ist nun gestartet. In den Kampagnen Details können Sie in den nächsten Tagen nachverfolgen wie performant Ihre Recall Kampagne läuft.");
        setIsMessageDialogVisible(true);

        // goto campaign details view
        setTimeout(()=> {
            navigate(`/campaign/${campaign.id}`);
        }, 3000);

    }


    if (!campaign || !currentUser) return <></>;

    return (
        <Box className="kt-page kt-campaign-page">

            <ProgressDialog
                visible={saveStatus === SaveStatus.isSaving}
                title="Neue Recall Kampagne erstellen"
                message="Ihre Recall Kampagne wird nun erstellt und eingerichtet..."
            />

            <MessageDialog
                visible={isMessageDialogVisible}
                title={messageDialogTitle}
                titleIconFaClassName='far fa-exclamation-triangle'
                message={messageDialogLabel}
                onClose={() => setIsMessageDialogVisible(false)}
            />

            <PatientDialog
                visible={isPatientDialogVisible}
                patient={selectedPatient}
                onClose={() => setIsPatientDialogVisible(false)}
                onSave={() => setIsPatientDialogVisible(false)}
            />

            <Dialog
                aria-labelledby="customized-dialog-title"
                open={isCreateCampaignDialogVisible}
            >
                <DialogTitle id="customized-dialog-title">
                    Kampagne starten
                </DialogTitle>

                <DialogContent
                    dividers
                >

                    <Grid container>
                        <Grid item xs={12}>
                            <span>Bitte bestätigen Sie, dass Sie diese Kampagne starten wollen.<br/>
                            Es werden {getBillablePatientsCount()} SMS verschickt.<br/>
                            Wollen Sie nun fortfahren?</span>
                        </Grid>
                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={()=>setIsCreateCampaignDialogVisible(false)}>
                        ABBRECHEN
                    </Button>
                    <Button onClick={onStartCampaignClick} autoFocus color='primary'>
                        FORTFAHREN
                    </Button>
                </DialogActions>

            </Dialog>



            <h2>Neue Recall Kampagne erstellen</h2>

            <Tabs
                value={selectedTab}
                onChange={(e, newTab) => onTabChange(newTab)}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="1. Patienten filtern" />
                <Tab label="2. Kampagne beschreiben" disabled={selectedPatients.length === 0}/>
                <Tab label="3. Zusammenfassung" disabled={selectedPatients.length === 0} />
            </Tabs>



            <form onSubmit={handleSubmit} autoComplete="turnitoff" style={{ display: "inline" }} ref={formRef}>

                <div className="kt-form-section" hidden={selectedTab !== 0}>

                    <RecallerPatientsFilter
                        onSearchResultChange={p => setPatients(p)}
                        onIsLoading={l => setIsLoading(l)}
                    />

                    <p>{selectedPatients.length} Patienten selektiert</p>

                    <Box className="kt-data-grid-container" mb={2} sx={{ height: "500px" }}>
                        <DataGridPro
                            loading={isLoading}
                            checkboxSelection
                            rows={patients}
                            columns={columns}
                            // onSelectionModelChange={(ids) => {

                            //     const selectedIDs = new Set(ids);
                            //     const selectedRows = patients.filter((row) =>
                            //         selectedIDs.has(row.id),
                            //     );

                            //     setSelectedPatients(selectedRows);
                            //     setSelectionModel(selectedRows.map(p => p.id));
                            // }}
                            // selectionModel={selectionModel}
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Box>

                    <div className="kt-page-footer">
                        <BackButton location='/recaller' />

                        <Button variant="contained" color="primary" endIcon={<ArrowForwardIosRoundedIcon />} onClick={gotoSecondTab}>WEITER</Button>
                    </div>
                </div>



                <div className="kt-form-section" hidden={selectedTab !== 1}>

                    <Grid container>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size='small'
                                name="name"
                                label="Name der Kampagne"
                                value={campaign.name}
                                onChange={onInputChange}
                                required
                                inputProps={{ minLength: 1 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="calendar-select-label">Behandler</InputLabel>
                                <Select
                                    name="campaign.calendar"
                                    labelId="calendar-select-label"
                                    label="Behandler"
                                    id="calendar-select"
                                    value={campaign.calendarId}
                                    onChange={e => handleCampaignCalendarChange(e.target.value)}
                                    required
                                >
                                    {Calendar.getAsFlatList(calendars).map(calendar => <MenuItem key={calendar.id} value={calendar.id}>{calendar.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="campaignType-select-label">Kampagnenart</InputLabel>
                                <Select
                                    name="type"
                                    labelId="campaignType-select-label"
                                    label="Kampagnenart"
                                    value={campaign.type}
                                    onChange={onInputChange}
                                >
                                    <MenuItem value={CampaignType.sms}>SMS</MenuItem>
                                    {/* <MenuItem value={CampaignType.email}>Email</MenuItem> */}
                                    <MenuItem value={CampaignType.phone}>Telefon</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <VisitMotivePicker
                                selectedVisitMotiveId={campaign.visitMotiveId}
                                visitMotives={visitMotives}
                                specialities={specialities}
                                appointmentStart={new Date()}
                                appointmentEnd={new Date()}
                                showDuration={false}
                                onVisitMotiveChange={handleVisitMotiveChange}
                                onDurationChange={() => { }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {campaign.type === CampaignType.sms &&
                                <SmsEditor
                                    templateMode={true}
                                    smsText={campaign.smsMessage}
                                    clientId={currentUser.clientId}
                                    locationId={currentUser.locationId}
                                    onValueChange={onCampaignSmsMessageChange}
                                    name="campaignSmsMessage"
                                />
                            }
                        </Grid>

                    </Grid>


                    <div className='kt-form-section' style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <h4>Landing Page</h4>

                        <LandingPageCtrl
                            id="landingPage"
                            landingPage={campaign.landingPage}                            
                            clientId={currentClient.id}
                            locationId={currentUser.id}
                            showButtonsCheckbox={false}
                            previewUrl={`https://pickadoc.de/appointment/${currentUser.clientId}/${currentUser.locationId}/campaign-${campaign.id}`}
                            previewButtonDisabled={(campaign.id === "" || JSON.stringify(campaign) !== JSON.stringify(oldCampaign))}
                            onChange={handleLandingPageChange}
                        />
                    </div>


                    <div className="kt-page-footer">
                        <Button variant="outlined" startIcon={<ArrowBackIosRoundedIcon />} onClick={() => setSelectedTab(0)}>ZURÜCK</Button>

                        {/* <SaveButton saveStatus={saveStatus} color="default" /> */}
                        <SaveButton saveStatus={saveStatus} />

                        <Button variant="contained" color="primary" endIcon={<ArrowForwardIosRoundedIcon />} onClick={gotoThirdTab}>WEITER</Button>
                    </div>
                </div>




                <div className="kt-form-section" hidden={selectedTab !== 2}>

                    <Grid container>

                        <Grid item xs={12} sm={6}>
                            <p>Sie haben {selectedPatients.length} Patienten ausgewählt.</p>
                            {(campaign.type === CampaignType.sms && invalidPhoneNumberPatients.length > 0) &&
                                <p>Davon haben {invalidPhoneNumberPatients.length} keine gültige Handynummer für den SMS Versand</p>
                            }
                        </Grid>


                    </Grid>

                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nr.</TableCell>
                                    <TableCell>Position</TableCell>
                                    <TableCell align="right">
                                        Menge
                                    </TableCell>
                                    <TableCell align="right">
                                        Preis
                                    </TableCell>
                                    <TableCell align="right">
                                        Summe
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>

                                <TableRow>
                                    <TableCell>1</TableCell>
                                    <TableCell>Patienten</TableCell>
                                    <TableCell align="right">{getBillablePatientsCount()} </TableCell>
                                    <TableCell align="right">
                                        {" "}
                                        {getCostPerCampaignSms().toFixed(2)}{" "}
                                    </TableCell>
                                    <TableCell align="right">
                                        {(getCostPerCampaignSms() * getBillablePatientsCount()).toFixed(2)}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align="right">
                                        <strong>Summe</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        {getTotalCampaignCost().toFixed(2)}{" €"}
                                    </TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </TableContainer>


                    <br />

                    <Box style={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ fontSize: '30px' }}
                            onClick={()=> setIsCreateCampaignDialogVisible(true)}
                        >
                            STARTEN&nbsp;<i className='far fa-play-circle'></i>
                        </Button>
                    </Box>

                    <div className="kt-page-footer">
                        <Button variant="outlined" startIcon={<ArrowBackIosRoundedIcon />} onClick={() => setSelectedTab(1)}>ZURÜCK</Button>

                        {/* <SaveButton saveStatus={saveStatus} color="default" /> */}
                        <SaveButton saveStatus={saveStatus} />
                    </div>
                </div>


            </form>


        </Box>
    )
}


export default CampaignPage;


