import { useEffect } from 'react';

import firebaseApp from "./../database";


function LogoutPage() {

    useEffect(() => {
        logoutAndShowLoginPage();
    }, [])

    async function logoutAndShowLoginPage() {
        await firebaseApp.auth().signOut();
        window.location.href = "/login";
    }

    return (
        <div className="kt-login-page">
            <form>
                <h1>Pickadoc</h1>

                <img src="/images/logo.svg" alt="logo" className='kt-logo' />
            </form>
        </div>
    );

}

export default LogoutPage;