import {useEffect, useState, useContext} from 'react';

import {isMobileDevice} from "../../utils";

import ToDosService from "../../services/toDosService";
import ToDo from '../../models/todo';
import DateUtils from '../../../src/shared/src/utils/dateUtils';
import { GlobalContext } from '../../GlobalContext';


const DashboardPage:React.FC = () => {

    const [toDos, setToDos] = useState<ToDo[]>([]);

    const [dateTimeString, setDateTimeString] = useState<string>("");

    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);

    useEffect(()=>{

        if(currentClient && currentUser && currentUser.locationId){

            const unsubscribeToDos = ToDosService.startListenForToDos(currentClient.id, currentUser.locationId, handleToDosChange);

            return () => {
                unsubscribeToDos();
            }
        }

    }, [currentClient]);

    useEffect(() => {
        const interval = setInterval(() => {
            updateDateTimeString();
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    function updateDateTimeString() {
        const now = new Date();
        const weekday = now.toLocaleString("default", { weekday: "long" })
        const _dateTimeString = isMobileDevice() ? `${DateUtils.getDateString(now)} - ${DateUtils.getTimeString(now)}` : `${weekday} ${DateUtils.getDateString(now)} - ${DateUtils.getTimeString(now)}`
        setDateTimeString(_dateTimeString);
    }


    function handleToDosChange(result: ToDo[]) {
        setToDos(result);
    }



    function setToDoStatus(toDo: ToDo, isChecked: boolean) {
        toDo.done = isChecked;

        if(currentUser){
            ToDosService.updateToDo(toDo, currentClient.id, currentUser.locationId);
        }
    }

    function createToDo() {

        let name: string | null;

        name = prompt("Beschreiben Sie bitte kurz Ihre Aufgabe.");

        if(name && currentUser){

            // allow only 200 characters
            name = name.substring(0, 200);

            const newToDo = new ToDo();
            newToDo.name = name;

            console.log("creating new ToDo: " + newToDo.name);
            ToDosService.updateToDo(newToDo, currentClient.id, currentUser.locationId);

        }
    }

    function editToDo(toDo: ToDo){
        let name: string | null;

        name = prompt("Aufgabe bearbeiten.", toDo.name);

        if(name && currentUser){
            const newToDo = toDo.clone();
            newToDo.name = name;

            ToDosService.updateToDo(newToDo, currentClient.id, currentUser.locationId);
        }
    }

    function deleteToDo(toDo: ToDo) {
        const realyDelete = window.confirm("Wollen Sie diese Aufgabe wirklich löschen?");

        if(realyDelete && currentUser){
            ToDosService.deleteToDo(toDo, currentClient.id, currentUser.locationId);
        }
    }


    function renderToDos(){

        if(toDos.length === 0){
            return <div>Es sind keine Aufgaben zu erledigen.</div>
        }

        return <table className="kt-waiting-room">
                    <tbody>
                        {toDos.map((toDo) =>
                            <tr key={toDo.id} className={toDo.done ? "kt-line-through" : ""}>
                                <td style={{width:"30px"}}><input type="checkbox" checked={toDo.done} onChange={(e) => setToDoStatus(toDo, e.target.checked)}/></td>
                                <td>{toDo.name}</td>
                                <td>{DateUtils.getDateString(toDo.createdAt)}</td>
                                <td className="kt-btn-table-column" style={{width:"20px", textAlign:"right"}} onClick={(e) => editToDo(toDo)}><i className="fa fa-pencil"></i></td>
                                <td className="kt-btn-table-column" style={{width:"20px", textAlign:"right"}} onClick={(e) => deleteToDo(toDo)}><i className="fa fa-trash"></i></td>
                            </tr>
                        )}
                    </tbody>
               </table>
    }


    return (
        <div className="kt-dashboard-page kt-grey-page">
            <h2>AUFGABEN - {dateTimeString}</h2>

            <div className="card-container">

                <div className="card full card-todo">
                    <div className="card-head">
                        <span><i className="fal fa-clipboard-list-check"></i>{`AUFGABEN: ${toDos.length}`}</span>
                        <div className="kt-button-wrapper">
                            <div><button className="kt-btn kt-btn-tiny" onClick={createToDo}><i className="fal fa-plus-circle"></i> ERSTELLEN</button></div>
                        </div>
                    </div>

                    <div className="card-body">
                        {renderToDos()}
                    </div>
                </div>


            </div>
        </div>
    );
}

export default DashboardPage;