import React, { useState } from 'react';
import ClonRVideo, { VideoGroup } from '../../shared/src/models/clonR/clonRVideo';
import DateUtils from '../../shared/src/utils/dateUtils';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

interface Props {
    videoGroup: VideoGroup,
    onClickVideo: (video: ClonRVideo, videoGroup: VideoGroup) => void,
    onDeleteVideo: (video: ClonRVideo) => void
}

const ClonRVideoGroupCardCtrl: React.FC<Props> = ({ videoGroup, onClickVideo, onDeleteVideo }) => {

    const [selectedVideo, setSelectedVideo] = useState<ClonRVideo>(videoGroup.videos[0]);


    return (
        <Card sx={{ width: 260, height: 240 }} className='kt-video-card'>
            <CardMedia
                sx={{ height: 140, backgroundSize: "contain"}}
                image={selectedVideo.thumbnailUrl}
                title={videoGroup.name}
                onClick={() => onClickVideo(selectedVideo, videoGroup)}                
            />
            {selectedVideo.status === "inprogress" ?
                <>
                    <div style={{ position: "relative", left: "100px", top: "-90px", height: 0, color: "grey" }}><i className='far fa-spinner-third fa-spin' style={{ fontSize: "36px" }}></i></div>
                    <div className='kt-video-status'>
                        <div style={{ color: "orange", backgroundColor: "lightorange" }}><span><i className='far fa-sync-alt'></i> In Bearbeitung</span></div>
                    </div>
                </>
                :
                <>
                    <div onClick={() => onClickVideo(selectedVideo, videoGroup)} className='kt-video-play-overlay-button-wrapper' style={{ position: "relative", left: "100px", top: "-90px" }}>
                        <div className='kt-video-play-overlay-button'><i className='fas fa-play'></i></div>
                    </div>
                    {/* <div className='kt-video-status'><div><span><i className='far fa-video'></i> {Math.floor(selectedVideo.length)}s - {selectedVideo.engine}</span></div></div> */}
                    <div className='kt-video-status'><div><span><i className='far fa-video'></i> {Math.floor(selectedVideo.length)}s</span></div></div>
                </>
            }

            <div style={{ position: "relative", top: "50px", left: "220px", height: 0 }}>
                <div onClick={() => onDeleteVideo(selectedVideo)}><span><i className='far fa-trash'></i></span></div>
            </div>
            <CardContent style={{padding: "5px 15px"}}>
                <Typography variant="body2" color="text.secondary">
                    <span style={{ fontSize: "12px" }}><strong>{videoGroup.name}</strong></span>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <span style={{ fontSize: "12px" }}>{videoGroup.videos.map(video =>
                        video.id === selectedVideo.id ?
                            <span key={video.id} className='kt-language-button kt-selected' onClick={() => setSelectedVideo(video)}>{video.language.toUpperCase()}</span>
                            :
                            <span key={video.id} className='kt-language-button' onClick={() => setSelectedVideo(video)}>{video.language.toUpperCase()}</span>

                    )
                    }</span>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <span style={{ fontSize: "12px" }}>{DateUtils.getDateTimeString(selectedVideo.createdAt)} - {selectedVideo.credits} Credits</span>
                </Typography>
            </CardContent>
        </Card>
    );
}

export default ClonRVideoGroupCardCtrl;