import React from 'react';
import { PatientScore } from '../models/patient';


interface Props {
    rating: PatientScore;
    onChange: (newRating: PatientScore) => void;
}

const StarsInputCtrl:React.FC<Props> = ({rating, onChange}) => {

    function renderStars(){

        const stars = []  as any;

        for (let i = 1 as PatientScore; i < 6; i++) {

            if(rating >= i){
                stars.push(<i key={i} className="fas fa-star" onClick={() => onChange(i)}></i>);
            } else {
                stars.push(<i key={i} className="far fa-star" onClick={() => onChange(i)}></i>);
            }
        }

        return (
            <div className="kt-stars-wrapper">
                {stars}
            </div>
        );
    }

    return (

        <div className="kt-stars-input-ctrl">
            {renderStars()}
        </div>

    );
}

export default StarsInputCtrl;