import React from 'react';


interface Props {
    activeLight: "none" | "red" | "yellow" | "green"
}

const TrafficLightCtrl: React.FC<Props> = ({ activeLight }) => {

    return (

        <div className="traffic-light">
            <div className={`light green ${activeLight === "green" && "active"}`}></div>
            <div className={`light yellow ${activeLight === "yellow" && "active"}`}></div>
            <div className={`light red ${activeLight === "red" && "active"}`}></div>            
        </div>

    );
}

export default TrafficLightCtrl;