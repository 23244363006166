
import React, { useEffect, useContext, useState } from 'react';
import FormItem, { FormItemEnum, FormItemType } from '../../../src/shared/src/models/formEditor/formItem';
import HeadlineFormItem from '../../../src/shared/src/models/formEditor/headlineFormItem';
import ParagraphFormItem from '../../../src/shared/src/models/formEditor/paragraphFormItem';
import ImageFormItem from '../../../src/shared/src/models/formEditor/imageFormItem';
import DocumentsService from '../../services/documentsService';
import ProgressDialog from '../dialogs/progressDialog';
import InputTextFormItem from '../../../src/shared/src/models/formEditor/inputTextFormItem';
import CheckboxFormItem from "../../../src/shared/src/models/formEditor/checkboxFormItem";
import DropdownFormItem from "../../../src/shared/src/models/formEditor/dropdownFormItem";

import RadioFormItem from '../../../src/shared/src/models/formEditor/radioFormItem';
import SignatureFormItem from '../../../src/shared/src/models/formEditor/signatureFormItem';
import PlaceholderFormItem from '../../../src/shared/src/models/formEditor/placeholderFormItem';
import LineFormItem from '../../../src/shared/src/models/formEditor/lineFormItem';
import FormRow from '../../../src/shared/src/models/formEditor/formRow';
import { getElementByClassNameSearchUp } from '../../utils';
import Utils from "../../../src/shared/src/utils/utils";
import FormRowCtrl from './formRowCtrl';
import AnswerFormItemCtrl from './answerFormItemCtrl';
import CheckboxFormItemCtrl from './checkboxFormItemCtrl';
import RadioFormItemCtrl from './radioFormItemCtrl';
import HeadlineFormItemCtrl from './headlineFormItemCtrl';
import ImageFormItemCtrl from './imageFormItemCtrl';
import InputTextFormItemCtrl from './inputTextFormItemCtrl';
import LineFormItemCtrl from './lineFormItemCtrl';
import LogoFormItemCtrl from './logoFormItemCtrl';
import ParagraphFormItemCtrl from './paragraphFormItemCtrl';
import PlaceholderFormItemCtrl from './placeholderFormItemCtrl';
import SignatureFormItemCtrl from './signatureFormItemCtrl';
import DropdownFormItemCtrl from './dropdownFormItemCtrl';
import VideoFormItem from '../../shared/src/models/formEditor/videoFormItem';
import VideoFormItemCtrl from './videoFormItemCtrl';
import { GlobalContext } from '../../GlobalContext';

interface ToolbarButtonInterface {
    label: string,
    type: FormItemEnum
}

interface Props {
    documentId: string,
    languageKey: string,
    formRowsProp: FormRow[],
    onFormChange: (changedFormRows: FormRow[]) => void;
    onPreviewClick: () => void;
}


const FormEditor: React.FC<Props> = ({ documentId, languageKey, formRowsProp, onFormChange, onPreviewClick }) => {

    const [formRows, setFormRows] = useState<FormRow[]>([]);
    const [isProgressDialogVisible, setIsProgressDialogVisible] = useState(false);

    const [isMaximized, setIsMaximized] = useState(false);

    const [selectedItem, setSelectedItem] = useState<FormItemType | null>(null);

    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);

    useEffect(() => {

        setFormRows(formRowsProp);

    }, [formRowsProp]);


    useEffect(() => {

        onFormChange(formRows);

        //debugPrint(formRows);

    }, [formRows]);


    useEffect(() => {
        console.log(`formEditor useEffect selectedItem: ${selectedItem?.id}`);
    }, [selectedItem]);


    function debugPrint(formRows: FormRow[]) {
        for (let r = 0; r < formRows.length; r++) {
            const row = formRows[r];

            console.log(`row: ${r} ${row.id}`);

            for (let c = 0; c < row.columns.length; c++) {
                const column = row.columns[c];

                const label = column.getLanguageProperty("de", "labels");

                console.log(`column: ${c} id: ${column.id} label: ${label} type: ${column.type}`);

                if (column.answers) {
                    for (let a = 0; a < column.answers.length; a++) {
                        const answer = column.answers[a];

                        debugPrint(answer.formRows);
                    }
                }
            }
        }
    }

    function deleteRow(formRow: FormRow) {

        setSelectedItem(null);

        const newRows = FormRow.deleteRow(formRow, formRows);

        setFormRows(newRows);
    }

    function addFormItem(rowType: FormItemEnum, atRowIndex?: number) {



        const _atIndex = atRowIndex === undefined ? formRows.length : atRowIndex;

        const fRows = [...formRows];
        let newItem: FormItemType | null = null;

        const newRow = new FormRow();

        switch (rowType) {
            case FormItemEnum.headline:
                newItem = new HeadlineFormItem(newRow.id);
                break;

            case FormItemEnum.paragraph:
                newItem = new ParagraphFormItem(newRow.id);
                break;

            case FormItemEnum.image:
                newItem = new ImageFormItem(newRow.id);
                break;

            case FormItemEnum.inputText:
                newItem = new InputTextFormItem(newRow.id);
                break;

            case FormItemEnum.checkbox:
                newItem = new CheckboxFormItem(newRow.id);
                break;

            case FormItemEnum.radio:
                newItem = new RadioFormItem(newRow.id);
                break;

            case FormItemEnum.signature:
                newItem = new SignatureFormItem(newRow.id);
                break;

            case FormItemEnum.placeholder:
                newItem = new PlaceholderFormItem(newRow.id);
                break;

            case FormItemEnum.line:
                newItem = new LineFormItem(newRow.id);
                break;

            case FormItemEnum.dropdown:
                newItem = new DropdownFormItem(newRow.id);
                break;

            case FormItemEnum.video:
                newItem = new VideoFormItem(newRow.id);
                break;

            default:
                break;
        }

        if (newItem) {
            newRow.columns.push(newItem);

            fRows.splice(_atIndex, 0, newRow);
        }

        setFormRows(fRows);

        setSelectedItem(newItem);
    }


    async function handleOnInputChange(formItem: FormItemType, newValue: any, propertyName: string, files: FileList) {

        switch (propertyName) {
            case "labels":
            case "subHeaderLabel":
            case "question":
                formItem.setLanguageProperty(languageKey, newValue, propertyName);
                break;

            case "imageSource":
                if (files && files.length > 0 && currentUser) {

                    // first check file size (has to be <= 20mb)
                    if (files[0] && files[0].size > 20000000) {
                        alert("Diese Datei ist zu groß. Sie können nur Dateien mit einer Größe von maximal 20 MB hochladen.");
                        return "";
                    }

                    setIsProgressDialogVisible(true);
                    const downloadUrl = await DocumentsService.uploadTemplateFile(formItem.id, files[0], currentClient.id, currentUser.locationId, documentId);
                    setIsProgressDialogVisible(false);

                    (formItem as ImageFormItem).imageSource = downloadUrl;
                } else {
                    //(formItem as ImageFormItem).imageSource = "";
                }
                break;

            default:
                if (formItem && formItem.hasOwnProperty(propertyName)) {
                    formItem[propertyName] = newValue;
                }
                break;
        }


        setFormRows([...formRows]);
    }


    function renderFormItemCtrl(formItem: FormItemType, renderMode: "editor" | "properties" | "final"): JSX.Element {

        const props = {
            documentId,
            item: formItem as any,
            languageKey,
            handleOnInputChange,
            formRows,
            setFormRows,
            onFormChange,
            selectedItem,
            setSelectedItem,
            renderMode,
            formUser: "doctor" as "patient" | "doctor"
        };

        switch (formItem.type) {
            case FormItemEnum.radioAnswer:
            case FormItemEnum.checkboxAnswer:
                return <AnswerFormItemCtrl required={false} {...props}></AnswerFormItemCtrl>

            case FormItemEnum.checkbox:
                return <CheckboxFormItemCtrl {...props}></CheckboxFormItemCtrl>

            case FormItemEnum.radio:
                return <RadioFormItemCtrl {...props}></RadioFormItemCtrl>

            case FormItemEnum.headline:
                return <HeadlineFormItemCtrl {...props}></HeadlineFormItemCtrl>

            case FormItemEnum.image:
                return <ImageFormItemCtrl {...props}></ImageFormItemCtrl>

            case FormItemEnum.inputText:
                return <InputTextFormItemCtrl {...props}></InputTextFormItemCtrl>

            case FormItemEnum.line:
                return <LineFormItemCtrl {...props}></LineFormItemCtrl>

            case FormItemEnum.logo:
                return <LogoFormItemCtrl {...props}></LogoFormItemCtrl>

            case FormItemEnum.paragraph:
                return <ParagraphFormItemCtrl {...props}></ParagraphFormItemCtrl>

            case FormItemEnum.placeholder:
                return <PlaceholderFormItemCtrl {...props}></PlaceholderFormItemCtrl>

            case FormItemEnum.signature:
                return <SignatureFormItemCtrl {...props}></SignatureFormItemCtrl>

            case FormItemEnum.dropdown:
                return <DropdownFormItemCtrl {...props}></DropdownFormItemCtrl>

            case FormItemEnum.video:
                return <VideoFormItemCtrl {...props}></VideoFormItemCtrl>

            default:
                return <React.Fragment></React.Fragment>
        }
    }


    function renderItemProperties(item: FormItemType | null) {

        if (!item) return <div className='kt-properties-label'>Eigenschaften</div>;

        return (
            <React.Fragment>
                <div className='kt-properties-label'>Eigenschaften</div>
                <br />
                {renderFormItemCtrl(item, "properties")}
            </React.Fragment>
        );
    }

    let mouseDownTarget: any = null;
    function handleOnMouseDown(e: any) {
        mouseDownTarget = e.target;
    }

    function handleOnMouseUp(e: any) {
        mouseDownTarget = null;
    }

    function handleOnDragStartRow(e: React.DragEvent<HTMLDivElement>, formRow: FormRow) {

        e.stopPropagation();

        if (mouseDownTarget && (mouseDownTarget.classList.contains("kt-row-gripper") || (mouseDownTarget.parentNode && mouseDownTarget.parentNode.classList.contains("kt-row-gripper")))) {

            const target = e.target as HTMLDivElement;

            const element = getElementByClassNameSearchUp(target, "kt-form-rows");
            if (element) {
                element.classList.add("kt-is-dragging-row");
            }

            const id = `row:${formRow.id}`;
            e.dataTransfer.setData("text/plain", id);

        } else {
            e.preventDefault();
            return false;
        }
    }



    function handleOnDragEndRow(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();

        const target = e.target as HTMLDivElement;

        const element = getElementByClassNameSearchUp(target, "kt-form-rows");
        if (element) {
            element.classList.remove("kt-is-dragging-row");
        }

        mouseDownTarget = null;
    }



    function handleOnDragEnter(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();

        const target = e.target as HTMLDivElement;
        target.classList.add("kt-on-drag-enter");
    }

    function handleOnDragLeave(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();

        const target = e.target as HTMLDivElement;

        target.classList.remove("kt-on-drag-enter");
    }

    function handleOnDragOver(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();

        e.dataTransfer.dropEffect = "move";
    }

    function handleOnDrop(e: React.DragEvent<HTMLDivElement>, targetRow: FormRow | null, offset: number) {
        e.preventDefault();
        e.stopPropagation();

        const target = e.target as HTMLDivElement;
        target.classList.remove("kt-on-drag-enter");

        const data = e.dataTransfer.getData("text/plain");

        if (!data) return;

        let targetIndex = formRows.findIndex(row => row.id === targetRow?.id);

        //console.log(`onDrop row: ${targetRow?.id} offset: ${offset} targetIndex: ${targetIndex}`);

        if (data.indexOf("row:") === 0) {
            const sourceRowId = data.replace("row:", "");

            const sourceIndex = formRows.findIndex(row => row.id === sourceRowId);

            // drag from top to bottom
            if (sourceIndex < targetIndex) {
                targetIndex -= (1 - offset);
            } else {
                // drag from bottom to top
                targetIndex += (offset);
            }

            //console.log(`new target index: ${targetIndex}`);

            if (sourceIndex > -1 && targetIndex > -1 && sourceIndex !== targetIndex) {
                const rows = Utils.reorder<FormRow>(formRows, sourceIndex, targetIndex);

                setFormRows(rows);
            }

        } else if (data.indexOf("create:") === 0) {
            const buttonType = data.replace("create:", "");

            addFormItem(parseInt(buttonType), targetIndex + offset);
        }

        mouseDownTarget = null;
    }


    function handleOnDragStartButton(e: React.DragEvent<HTMLDivElement>, button: ToolbarButtonInterface) {

        const element = document.getElementsByClassName("kt-form-rows")[0];
        if (element) {
            element.classList.add("kt-is-dragging-row");
        }

        const data = `create:${button.type}`;
        e.dataTransfer.setData("text/plain", data);
    }

    function handleOnDragEndButton(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();

        const element = document.getElementsByClassName("kt-form-rows")[0];
        if (element) {
            element.classList.remove("kt-is-dragging-row");
        }
    }


    function getToolbarButtons(): ToolbarButtonInterface[] {

        return [
            { label: "Trennlinie", type: FormItemEnum.line },
            { label: "Platzhalter", type: FormItemEnum.placeholder },
            { label: "Überschrift", type: FormItemEnum.headline },
            { label: "Textfeld", type: FormItemEnum.paragraph },
            { label: "Bild", type: FormItemEnum.image },
            { label: "Video", type: FormItemEnum.video },
            { label: "Texteingabe", type: FormItemEnum.inputText },
            { label: "Single-Choice-Frage", type: FormItemEnum.radio },
            { label: "Multiple-Choice-Frage", type: FormItemEnum.checkbox },
            { label: "Auswahlliste", type: FormItemEnum.dropdown },
            { label: "Unterschrift", type: FormItemEnum.signature }
        ];
    }


    return (
        <div className={isMaximized ? "kt-form-editor kt-maximized" : "kt-form-editor"}>

            <ProgressDialog
                visible={isProgressDialogVisible}
                title="Datei Upload"
                message="Ihre Datei wird hochgeladen..."
            />


            <h3>Formular Editor</h3>

            <div className="kt-form-rendering">

                <div className="kt-edit-form-tool-box">

                    {getToolbarButtons().map((button, index) => (
                        <div
                            key={index}
                            draggable={true}
                            onDragStart={(e) => handleOnDragStartButton(e, button)}
                            onDragEnd={(e) => handleOnDragEndButton(e)}
                        >
                            <div
                                className="kt-edit-form-tool-box-btn"
                                onClick={(e) => addFormItem(button.type)}>
                                <i className={`far ${FormItem.getIconByType(button.type)}`}></i><span>{button.label}</span>
                            </div>

                        </div>
                    ))}

                </div>


                <div className="kt-form-rows-scroll">

                    <div className="kt-form-rows">

                        {formRows.length === 0 &&
                            <div
                                key="dummyrow"
                                className='kt-form-row-wrapper kt-dummy-row'
                            >
                                <div
                                    className="kt-drop-area kt-drop-area-top"
                                    onDragEnter={(e) => handleOnDragEnter(e)}
                                    onDragLeave={(e) => handleOnDragLeave(e)}
                                    onDragOver={(e) => handleOnDragOver(e)}
                                    onDrop={(e) => handleOnDrop(e, null, 1)}
                                ></div>
                                <p><i className="fal fa-hand-pointer"></i>Fügen Sie Elemente per Drag &amp; Drop hinzu.</p>
                            </div>
                        }
                        {formRows.map((formRow, index) => (
                            <div
                                key={formRow.id}
                                onDragStart={(e) => handleOnDragStartRow(e, formRow)}
                                onDragEnd={(e) => handleOnDragEndRow(e)}
                                draggable={true}
                                className='kt-form-row-wrapper'
                            >

                                <div
                                    className="kt-drop-area kt-drop-area-top"
                                    onDragEnter={(e) => handleOnDragEnter(e)}
                                    onDragLeave={(e) => handleOnDragLeave(e)}
                                    onDragOver={(e) => handleOnDragOver(e)}
                                    onDrop={(e) => handleOnDrop(e, formRow, 0)}
                                ></div>
                                <div
                                    className={`kt-form-row`}
                                    key={formRow.id}
                                >
                                    <div className="kt-row-gripper"
                                        onMouseDown={(e) => handleOnMouseDown(e)}
                                        onMouseUp={(e) => handleOnMouseUp(e)}
                                    >
                                        <i className={`far fa-grip-lines`}></i>
                                    </div>
                                    <div className="kt-form-row-content">
                                        <FormRowCtrl
                                            documentId={documentId}
                                            row={formRow}
                                            languageKey={languageKey}
                                            handleOnInputChange={handleOnInputChange}
                                            formRows={formRows}
                                            setFormRows={setFormRows}
                                            onFormChange={onFormChange}
                                            selectedItem={selectedItem}
                                            setSelectedItem={setSelectedItem}
                                            renderMode="editor"
                                            formUser="doctor"
                                        />
                                    </div>

                                    <div className="kt-row-edit-buttons">
                                        <div onClick={() => deleteRow(formRow)}>
                                            <i className='far fa-trash' />
                                        </div>
                                    </div>

                                </div>
                                <div
                                    className="kt-drop-area kt-drop-area-bottom"
                                    onDragEnter={(e) => handleOnDragEnter(e)}
                                    onDragLeave={(e) => handleOnDragLeave(e)}
                                    onDragOver={(e) => handleOnDragOver(e)}
                                    onDrop={(e) => handleOnDrop(e, formRow, 1)}
                                ></div>

                            </div>
                        ))}

                    </div>
                </div>

                <div className="kt-form-row-properties">
                    <div className='kt-window-buttons'>
                        <i className='fas fa-eye' onClick={() => onPreviewClick()}></i>
                        {isMaximized ?
                            <i className='fal fa-compress-alt' onClick={() => setIsMaximized(false)}></i>
                            :
                            <i className='fal fa-expand-alt' onClick={() => setIsMaximized(true)}></i>
                        }
                    </div>
                    {renderItemProperties(selectedItem)}
                </div>


            </div>


        </div>
    );
}

export default FormEditor;