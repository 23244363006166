import firebase from 'firebase/compat/app';
import database from "../components/database";
import Patient from "../models/patient";
import { Campaign } from "../shared/src/models/campaign/campaign";
import CampaignPatient from '../models/campaignPatient';

const db = database.firestore();
const functions = firebase.app().functions('europe-west3');

const CampaignsService = {

    async getCampaigns(clientId: string, locationId: string): Promise<Campaign[] | null> {

        const campaigns: Campaign[] = [];

        try {

            if(clientId && locationId){
                const querySnapshot = await db.collection("clients").doc(clientId)
                                            .collection("locations").doc(locationId)
                                            .collection("campaigns")
                                            .orderBy("createdAt", "desc")
                                            .get();


                querySnapshot.forEach((doc) => {

                    const campaign = new Campaign();
                    campaign.fromObject(doc.id, doc.data());

                    campaigns.push(campaign);
                });

            }

            return campaigns;

        } catch(error) {
            console.log("Error getting campaigns: ", error);
            return null;
        }

    },

    async getCampaign(clientId: string, locationId: string, campaignId: string): Promise<Campaign | null> {

        if(!campaignId) return null;

        const doc = await db.collection("clients").doc(clientId)
                            .collection("locations").doc(locationId)
                            .collection("campaigns").doc(campaignId)
                            .get();

        try {

            if (doc.exists) {

                const campaign = new Campaign();
                campaign.fromObject(campaignId, doc.data());

                return campaign;

            } else {
                console.log("getCampaign: No such document: " + campaignId);
                return null;
            }

        } catch(error) {
            console.log("Error getting campaign: ", error);
            return null;
        };

    },



    async updateCampaign(campaign: Campaign, clientId: string, locationId: string): Promise<string | null> {

        try {

            if(campaign.id) {
                await db.collection("clients").doc(clientId)
                        .collection("locations").doc(locationId)
                        .collection("campaigns").doc(campaign.id)
                        .set(campaign.toJSON(), { merge: true });

                return campaign.id;

            } else {
                // create a new Campaign
                const docRef = await db.collection("clients").doc(clientId)
                                        .collection("locations").doc(locationId)
                                        .collection("campaigns")
                                        .add(campaign.toJSON());

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating campaigns: ", error);
        };

        return null;
    },

    async deleteCampaign(campaignId: string, clientId: string, locationId: string): Promise<void> {

        try {
            const documentPath = `clients/${clientId}/locations/${locationId}/campaigns/${campaignId}`;
            await db.doc(documentPath).delete();

            // now also delete all campaign patients
            const collection = `${documentPath}/patients`;
            const deleteCollection = functions.httpsCallable('deleteCollection');
            await deleteCollection(
                {
                    collection: collection
                }
            );

        } catch(error) {
            console.log("Error deleting campaign: ", error);
        };
    },


    async updateCampaignPatient(patient: CampaignPatient, campaignId: string, clientId: string, locationId: string): Promise<void> {

        try {
            console.log("updateCampaignPatients " + patient.id);

            if(patient.id) {
                await db.collection("clients").doc(clientId)
                        .collection("locations").doc(locationId)
                        .collection("campaigns").doc(campaignId)
                        .collection("patients").doc(patient.id)
                        .set(patient.toJSON(), { merge: true });
            }

        } catch (error) {
            console.log("Error updating campaign patient: ", error);
        };

    },

    async getCampaignPatients(campaignId:string, clientId: string, locationId: string): Promise<CampaignPatient[] | null> {

        const patients: CampaignPatient[] = [];

        try {

            if(campaignId && clientId && locationId){
                const querySnapshot = await db.collection("clients").doc(clientId)
                                            .collection("locations").doc(locationId)
                                            .collection("campaigns").doc(campaignId)
                                            .collection("patients")
                                            .orderBy("lastAppointmentDate")
                                            .get();


                querySnapshot.forEach((doc) => {

                    const patient = new CampaignPatient();
                    patient.fromObject(doc.id, doc.data());

                    patients.push(patient);
                });

            }

            return patients;

        } catch(error) {
            console.log("Error getting campaign patients: ", error);
            return null;
        }

    },

    async startCampaign(clientId: string, locationId: string, campaignId: string): Promise<void> {

        const startCampaignFunc = functions.httpsCallable("startCampaign");

        await startCampaignFunc(
            {
                clientId: clientId,
                locationId: locationId,
                campaignId: campaignId
            });

    }


}

export default CampaignsService;