import Paper from '@mui/material/Paper';
import React from 'react';
import ClonRAvatar from '../../shared/src/models/clonR/clonRAvatar';
import { Tooltip } from '@mui/material';


interface Props {
    className?: string,
    ownAvatars: ClonRAvatar[],
    publicAvatars: ClonRAvatar[],
    selectedClonRAvatar: ClonRAvatar,
    onSelectionChange: (clonRAvatar: ClonRAvatar) => void
}


const ClonRAvatarListCtrl: React.FC<Props> = ({ className, ownAvatars, publicAvatars, selectedClonRAvatar, onSelectionChange }) => {

    return (
        <div className={`kt-clonr-avatar-list-ctrl ${className ?? ""}`}>
            <Paper className='kt-paper kt-video-avatar-list-wrapper'>
                <h3 style={{ width: "100%" }}>Eigene VideoClones ({ownAvatars.length})</h3>
                <div className='kt-video-avatar-list'>
                    {ownAvatars.map(avatar =>
                        <>
                            {avatar.status === "completed" &&
                                <div
                                    className={avatar.id === selectedClonRAvatar.id ? "kt-selected" : ""}
                                    key={avatar.id}
                                    onClick={() => onSelectionChange(avatar)}
                                    style={{ backgroundImage: `url(${avatar.thumbnailUrl})` }}
                                >
                                    <img src={avatar.thumbnailUrl} />
                                </div>
                            }
                            {avatar.status === "inProgress" &&
                            <Tooltip title="Video wird von unserem Team überprüft..." arrow placement="top" >
                                <div
                                    key={avatar.id}
                                    style={{ backgroundImage: `url(${avatar.thumbnailUrl})` }}
                                    className='kt-avatar-in-progress'
                                >
                                    <img src={avatar.thumbnailUrl} />
                                    <div className='kt-primary-color kt-avatar-spinner'><i className='far fa-spinner-third fa-spin' style={{ fontSize: "36px" }}></i></div>                                    
                                </div>
                                </Tooltip>
                            }
                        </>
                    )}
                </div>

                <h3 style={{ width: "100%" }}>Bibliothek ({publicAvatars.length})</h3>
                <div className='kt-video-avatar-list'>
                    {publicAvatars.map(avatar =>
                        <div
                            className={avatar.id === selectedClonRAvatar.id ? "kt-selected" : ""}
                            key={avatar.id}
                            onClick={() => onSelectionChange(avatar)}
                            style={{ backgroundImage: `url(${avatar.thumbnailUrl})` }}
                        >
                            <img src={avatar.thumbnailUrl} />
                        </div>
                    )}
                </div>
            </Paper>
        </div>
    );
}

export default ClonRAvatarListCtrl;