import database from "../components/database";
import Appointment from "../models/appointment";
import VideoRoom, { Attendee } from "../models/videoRoom";
import UsersService from "./usersService";

const db = database.firestore();

const VideoRoomsService = {

    
    cache: {} as {[key: string]: VideoRoom},


    async getRoom(roomId: string, ignoreCache: boolean = false): Promise<VideoRoom | null> {
            
        if(!roomId) return null;

        if(!ignoreCache && VideoRoomsService.cache[roomId]){
            return VideoRoomsService.cache[roomId];
        }

        const doc = await db.collection("videoRooms").doc(roomId)
                            .get();

        try {

            if (doc.exists) {
                
                const room = new VideoRoom();
                room.fromObject(roomId, doc.data());

                // update cache
                VideoRoomsService.cache[room.id] = room;

                return room;

            } else {
                console.log("getRoom: No such document: " + roomId);
                return null;
            }

        } catch(error) {
            console.error("Error getting video room: ", error);
            return null;
        };
        
    },


    
    async updateRoom(room: VideoRoom): Promise<string | null> {

        try {

            if(room.id) {
                await db.collection("videoRooms").doc(room.id)
                        .set(room.toJSON(), { merge: true });

                // update cache
                VideoRoomsService.cache[room.id] = room;

                return room.id;

            } else {
                // create a new VideoRoom
                const docRef = await db.collection("videoRooms")
                                        .add(room.toJSON());

                // update cache
                VideoRoomsService.cache[docRef.id] = room;

                return docRef.id;
            }


        } catch (error) {
            console.error("Error updating video room: ", error);
        };

        return null;
    },

    async deleteRoom(roomId: string): Promise<void> {

        try {
            const documentPath = `videoRooms/${roomId}`;
            await db.doc(documentPath).delete();
                
        } catch(error) {
            console.error("Error deleting video room: ", error);
        };
    },

    async createRoomFromAppointment(appointment: Appointment): Promise<void> {
        try {

            const videoRoom = new VideoRoom();
                    
            videoRoom.id = appointment.id;
            
            videoRoom.start = new Date(appointment.start);
            videoRoom.end = new Date(appointment.end);

            // add patient data
            const patient = new Attendee();
            patient.id = appointment.patient.id;
            patient.gender = appointment.patient.gender;
            patient.firstName = appointment.patient.firstName;
            patient.lastName = appointment.patient.lastName;

            patient.mobilePhoneNumber = appointment.patient.mobilePhoneNumber;

            videoRoom.attendees.push(patient);


            // add doctor (host) data
            const doctor = await UsersService.getUser(appointment.clientId, appointment.calendar.userId);
            if(doctor) {
                const host = new Attendee();
                host.id = doctor.id;
                host.gender = doctor.gender;
                host.firstName = doctor.firstName;
                host.lastName = doctor.lastName;
                host.isHost = true;

                videoRoom.attendees.push(host);
            }


            await VideoRoomsService.updateRoom(videoRoom);


        } catch(error) {
            console.error("Error creating video room from appointment: ", error);
        }        
    },

    startListenForVideoRoom(videoRoomId: string, changeCallback: (videoRoom: VideoRoom)=>void): () => void {

        return db.collection("videoRooms").doc(videoRoomId)
            .onSnapshot(function(querySnapshot) {
                
                const videoRoom = new VideoRoom();
                    
                videoRoom.fromObject(querySnapshot.id, querySnapshot.data());

                changeCallback(videoRoom);
            });
    },

    async setAttendeesOnlineStatus(videoRoomId: string, userId: string, isOnline: boolean): Promise<void> {

        if(videoRoomId && userId) {
            try {
                const room = await VideoRoomsService.getRoom(videoRoomId);
                if(room){
                    room.attendees.forEach( attendee => {
                        if(userId === attendee.id){
                            attendee.isOnline = isOnline;
                        }
                    });

                    await VideoRoomsService.updateRoom(room);
                }
            } catch(error){
                console.error("Error setting attendees online status: ", error);
            }
            
        }
    }
    
}

export default VideoRoomsService;