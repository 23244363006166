import { useState, useRef } from 'react';
import { debounce } from "../utils";

import PatientService from "../services/patientsService";
import Patient from '../models/patient';
import TextField from '@mui/material/TextField';

function SearchInput(props) {

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [patients, setPatients] = useState<Patient[]>([]);
    const [searchString, setSearchString] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(0);

    const searchResult = useRef<Patient[]>([]); // here we store last search result to reduce db reads

    function listenKeyboard(event) {
        let index = selectedIndex;

        switch (event.keyCode) {
            case 40: // arrow down
                event.preventDefault();

                if (patients.length > 0) {
                    index = index >= patients.length - 1 ? index = 0 : index + 1;
                } else {
                    index = 0;
                }

                setSelectedIndex(index);

                break;

            case 38: // arrow up
                event.preventDefault();

                if (patients.length > 0) {
                    index = index <= 0 ? index = patients.length - 1 : index - 1;
                } else {
                    index = 0;
                }

                setSelectedIndex(index);

                break;

            case 13: // ENTER
                event.preventDefault();

                if (patients.length > 0 && searchString !== "" && props.value !== "") {
                    handleSelectItem(selectedIndex);
                }

                break;

            default:
                break;
        }
    }

    function handleSelectItem(index: number) {
        if (index >= 0 && index < patients.length) {
            const patient = patients[index];

            setDropdownVisible(false);

            props.onPatientChange(patient);
        }
    }


    function handleInputChange(event) {

        // "-kt" postfix is to prevent form auto complete
        // here we remove it again from the input name
        // must be used in combination with autoComplete="turnitoff"
        event.target.name = event.target.name.replace("-kt", "");

        const target = event.target;
        const value = target.value;

        props.onChange(event);

        updateResultListDebounced(value);
    }

    const updateResultListDebounced = useRef(debounce((value) => updateResultList(value), 400)).current;

    async function updateResultList(value) {
        if (value && value.length > 1) {

            let result: Patient[] | null = [];

            // get patients from db
            result = await PatientService.getPatients(value, props.clientId, props.locationId);

            if (result && result.length > 0) {
                setDropdownVisible(true);
                setPatients(result);
                searchResult.current = result;
                setSearchString(value);
            } else {
                setDropdownVisible(false);
                setPatients([]);
            }


        } else {
            setDropdownVisible(false);
            setPatients([]);
        }
    }

    function highlightString(searchString, toHighlightString, allUpperCase) {

        console.log(`searchString: ${searchString} - toHighlightString: ${toHighlightString}`)

        let _filter = searchString.toLowerCase().trim();
        _filter = _filter.replaceAll(',', ' ');

        const filters = _filter.split(" ");

        for (let f = 0; f < filters.length; f++) {
            let filtr = filters[f];
            filtr = filtr.trim();

            if(filtr === "") continue;

            let searchIndex = toHighlightString.toLowerCase().indexOf(filtr.toLowerCase());

            if (searchIndex === 0) {
                var firstPart = toHighlightString.slice(0, filtr.length);
                var lastPart = toHighlightString.slice(filtr.length, toHighlightString.length);

                firstPart = allUpperCase ? firstPart.toUpperCase() : firstPart;
                lastPart = allUpperCase ? lastPart.toUpperCase() : lastPart;

                return <><strong>{firstPart}</strong><span>{lastPart}</span></>;
            }
        }

        return allUpperCase ? toHighlightString.toUpperCase() : toHighlightString;
    }

    function handleResultItemClick(event, index: number) {
        setDropdownVisible(false);
        handleSelectItem(index);
    }

    function renderResultItem(patient: Patient, index: number) {

        if (patient && patient.id) {
            return (
                <li key={patient.id} onClick={(e) => handleResultItemClick(e, index)} className={index === selectedIndex ? "kt-selected" : ""}>
                    <span>{highlightString(searchString, patient.lastName, true)}</span>
                    &nbsp;
                    <span>{highlightString(searchString, patient.firstName, false)}</span>
                </li>
            );
        }

        return <li></li>;
    }

    function handleFocusLost() {
        setTimeout(() => {
            setDropdownVisible(false);

            if (props.onBlur && typeof props.onBlur === "function") {
                props.onBlur();
            }
        }, 300);
    }

    return (
        <div className={props.className ? `${props.className} kt-searchInput` : "kt-searchInput"} onKeyDown={listenKeyboard}>

            <TextField
                id={props.id}
                fullWidth
                required={props.required}
                label={props.placeholder}
                disabled={props.disabled}
                value={props.value}
                onChange={handleInputChange}
                onBlur={handleFocusLost}
                autoComplete={props.autoComplete}
                autoFocus={props.autofocus}
                helperText={props.helperText ?? ""}
                placeholder={props.simplePlaceholder}
            // InputProps={{
            //     startAdornment: (
            //       props.searchIcon && <InputAdornment position="start">
            //         <SearchIcon />
            //       </InputAdornment>
            //     )}
            // }
            />

            {dropdownVisible && patients.length > 0 ? (
                <div className={props.className === "kt-border-left" ? `kt-searchInput-dropdown kt-rightAlign` : "kt-searchInput-dropdown"}>
                    <ul>
                        {patients.map((patient, index) => (
                            renderResultItem(patient, index)
                        ))}
                    </ul>
                </div>
            ) : ""}

        </div>
    );
};

export default SearchInput;
