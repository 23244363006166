import { useContext, useEffect } from "react";
import { GlobalContext } from "../../GlobalContext";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from "dayjs";
import ResourceFilter from "../resourceFilter";

interface Props {
    startDate: Date | undefined;
    endDate: Date | undefined;

    onStartDateChange: (newdate: Date) => void;
    onEndDateChange: (newdate: Date) => void;
}


const DashboardSideMenu: React.FC<Props> = ({startDate, endDate, onStartDateChange, onEndDateChange}) => {

    const { isSidebarVisible } = useContext(GlobalContext);


    useEffect(() => {
        setDashboardDates(7);

        const newDateInThePast = new Date();
        newDateInThePast.setDate(newDateInThePast.getDate() - 365);

    }, []);

    function handleStartDateChange(newDate: dayjs.Dayjs | null) {
        if (newDate) {
            const _newDate = new Date(newDate.toDate());
            _newDate.setHours(0, 0, 0);
            onStartDateChange(_newDate);

            if ((endDate && _newDate.getTime() > endDate.getTime()) || !endDate) {
                _newDate.setHours(23, 59, 59);
                onEndDateChange(_newDate);
            }
        }
    }

    function handleEndDateChange(newDate: dayjs.Dayjs | null) {
        if (newDate) {
            const _newDate = new Date(newDate.toDate());
            _newDate.setHours(23, 59, 59);

            onEndDateChange(_newDate);

            if ((startDate && _newDate.getTime() < startDate.getTime()) || !startDate) {
                _newDate.setHours(0, 0, 0);
                onStartDateChange(_newDate);
            }
        }

    }

    function setDashboardDates(days: number) {
        const today = new Date();
        const _startDate = dayjs().subtract(days - 1, "d").toDate();

        _startDate.setHours(0, 0, 0);
        today.setHours(23, 59, 59);

        onStartDateChange(_startDate);
        onEndDateChange(today)
    }

    return (
        <div className={isSidebarVisible ? "kt-side-menu noselect kt-dashboard" : "kt-side-menu kt-hide-on-mobile noselect kt-dashboard"}>
            <div className="kt-side-menu-item">
                <DatePicker
                    label="Von"
                    value={dayjs(startDate)}
                    onChange={(d) => handleStartDateChange(d)}

                />

                <DatePicker
                    label="Bis"
                    value={dayjs(endDate)}
                    onChange={(d) => handleEndDateChange(d)}
                />

                <div className="kt-days-button-wrapper">
                    <button type="button" onClick={() => setDashboardDates(1)}>HEUTE</button>
                    <button type="button" onClick={() => setDashboardDates(2)}>2 TAGE</button>
                    <button type="button" onClick={() => setDashboardDates(7)}>7 TAGE</button>
                    <button type="button" onClick={() => setDashboardDates(30)}>30 TAGE</button>
                    <button type="button" onClick={() => setDashboardDates(30 * 3)}>3 MONATE</button>
                    <button type="button" onClick={() => setDashboardDates(30 * 6)}>6 MONATE</button>
                </div>
            </div>

            <ResourceFilter mode="dashboard" showOnlyCalendars={false} />

        </div>
    )
}

export default DashboardSideMenu;