import { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import RoomsService from '../../../services/roomsService';
import Room from '../../../models/room';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import { updateRowPosition } from '../../../utils';
import { GlobalContext } from '../../../GlobalContext';

function RoomsPage() {

    const [rooms, setRooms] = useState([] as Room[]);

    const [isLoading, setIsLoading] = useState(false);
    const {currentUser, currentClient} = useContext(GlobalContext);


    useEffect( () => {

        if(currentClient){
            updateRooms();
        }

        // eslint-disable-next-line
    }, [currentClient]);



    async function updateRooms(){

        if(!currentUser){
            return;
        }

        setIsLoading(true);

        try {
            const result = await RoomsService.getRooms(currentClient.id, currentUser.locationId, false);

            setIsLoading(false);
            if(result){
                setRooms(result)
            }

        } catch(error) {
            setIsLoading(false);
        }
    }

    const columns: any[] = [
        { field: 'name', headerName: 'Name', width: 300, renderCell: (params) => (
            <Link to={`/settings/room/${params.row.id}`}>{params.row.name}</Link>
        )},
        { field: 'delete', headerName: " ", width: 64, renderCell: (params) => (
            <IconButton onClick={ (e) => onDeleteRowClick(params.row)}>
                <DeleteIcon/>
            </IconButton>)
        }
      ];


    async function onDeleteRowClick(row){
        const reallyDelete = window.confirm(`Wollen Sie den Raum ${row.name} wirklich löschen?`);
        if(reallyDelete && currentUser) {
            await RoomsService.deleteRoom(row.id, currentClient.id, currentUser.locationId);
            updateRooms();
        }
    }

    const handleRowOrderChange = async (params) => {
        setIsLoading(true);
        const newRows = updateRowPosition<Room>(
            params.oldIndex,
            params.targetIndex,
            rooms,
        );

        for (let i = 0; i < newRows.length; i++) {
            const e = newRows[i];
            e.cardinality = i+1;
            console.log(`${e.name} - index: ${i}`);

            RoomsService.updateRoom(e, currentClient.id, currentUser!.locationId);
        }
        setRooms(newRows);
        setIsLoading(false);
    };


    return (
        <Box className="kt-page">
            <h2>Räume</h2>

            <Grid container>
                <Grid item>
                    <ButtonGroup>
                        <Button
                            component={Link}
                            to="/settings/room/create"
                            startIcon={<AddCircleOutlineIcon/>}
                        >
                            Raum anlegen
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Box className="kt-data-grid-container">
                <DataGridPro
                    loading={isLoading}
                    rows={rooms}
                    columns={columns}
                    rowReordering
                    onRowOrderChange={handleRowOrderChange}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>

        </Box>
    );
}

export default RoomsPage;