import React, { useContext, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ClonRAvatar from '../../../shared/src/models/clonR/clonRAvatar';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import VideoRecorderCtrl from '../../videoRecorderCtrl';
import FileUploader from '../../fileUploader';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';

import { GlobalContext } from '../../../GlobalContext';
import ProgressDialog from '../progressDialog';
import { StorageError, TaskState } from 'firebase/storage';
import FileUploadsService from '../../../services/fileUploadsService';
import QRCode from 'react-qr-code';
import PaymentsService from '../../../services/paymentsService';
import GlobalSettings from '../../../shared/src/models/globalSettings';
import ConfirmDialog from '../confirmDialog';
import ClientsService from '../../../services/clientsService';


interface Props {
    clonRAvatar: ClonRAvatar;
    visible: boolean;
    onSave: (newClonRAvatar: ClonRAvatar) => void;
    onCancel: () => void;
}

const ClonRAvatarDialog: React.FC<Props> = ({ clonRAvatar, visible, onSave, onCancel }) => {

    const [newClonRAvatar, setNewClonRAvatar] = useState(new ClonRAvatar());

    const [credits, setCredits] = useState(0);

    const [isConfirmCreateDialogOpen, setIsConfirmCreateDialogOpen] = useState(false)
    const [isVideoRecorderVisible, setIsVideoRecorderVisible] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadState, setUploadState] = useState<TaskState>("paused");

    const [recordedFile, setRecordedFile] = useState<File | null>(null);

    const { currentUser, currentClient, settings } = useContext(GlobalContext);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setIsVideoRecorderVisible(false);
        setNewClonRAvatar(clonRAvatar);
    }, [visible]);

    useEffect(() => {

        window.addEventListener('keydown', listenKeyboard, true);

        // clean up
        return () => {
            window.removeEventListener('keydown', listenKeyboard, true);
        }

    }, []);

    useEffect(() => {

        if (currentClient && currentUser) {

            const unsubscribeCredits = PaymentsService.startListenForCredits(currentClient.id, (newValue) => setCredits(newValue));

            return () => {
                unsubscribeCredits();
            }
        }

    }, [currentClient]);

    function listenKeyboard(event) {
        if (event.keyCode === 27) {
            onCancel();
        }
    }


    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const _avatar = newClonRAvatar.clone();
        _avatar.name = e.currentTarget.value;

        setNewClonRAvatar(_avatar);
    }

    const handleCloseClick = () => {
        onCancel();
    }


    function uploadVideo() {

        setIsConfirmCreateDialogOpen(false);
        
        if (recordedFile) {
            const targetPath = `clients/${currentUser.clientId}/clonRAvatars`;
            const targetFileName = newClonRAvatar.id;

            // start upload
            setProgress(0);
            const uploadTask = FileUploadsService.uploadFileWithProgress(recordedFile, targetPath, targetFileName, true, handleUploadStateChanged, handleUploadFinishedAndSave, handleUploadError);
        }
    }

    const handleSaveClick = async () => {

        if (recordedFile) {
            // first inform how much it will cost
            setIsConfirmCreateDialogOpen(true);

        } else {
            onSave(newClonRAvatar);
        }
    }

    const handleUploadFinished = async (downloadUrl: string, fileExtension: string, videoThumbnailUrl: string): Promise<ClonRAvatar> => {
        let _avatar = newClonRAvatar.clone();
        _avatar.videoUrl = downloadUrl;
        _avatar.videoFileExtension = fileExtension;
        _avatar.thumbnailUrl = videoThumbnailUrl;
        setNewClonRAvatar(_avatar); // set state so that the video gets loaded into the player

        // setNewClonRAvatar(_avatar);
        setUploadState("success");

        return _avatar;
    }

    const handleUploadFinishedAndSave = async (downloadUrl: string, fileExtension: string, videoThumbnailUrl: string) => {
        const _avatar = await handleUploadFinished(downloadUrl, fileExtension, videoThumbnailUrl);

        // charge client for avatar creation
        await ClientsService.addClientCredits(currentUser.clientId, -PaymentsService.getClonRAvatarPriceInCredits(settings as GlobalSettings));

        onSave(_avatar);
    }

    const handleUploadStateChanged = (progress: number, state: TaskState) => {
        setUploadState(state);
        setProgress(progress);
    }

    const handleUploadError = (error: StorageError) => {

    }

    const handleResetVideo = () => {
        const _avatar = newClonRAvatar.clone();
        _avatar.videoUrl = "";
        _avatar.videoFileExtension = "";
        _avatar.thumbnailUrl = "";

        setNewClonRAvatar(_avatar);
    }


    const handleFinishedRecording = (videoUrl: string, videoBlob: Blob) => {

        const _avatar = newClonRAvatar.clone();
        _avatar.videoUrl = videoUrl;
        setNewClonRAvatar(_avatar);

        const file = new File([videoBlob], "video.mp4", { type: "video/mp4" });

        setRecordedFile(file);

        // we upload the file only on save
    }

    function hasEnoughBalance(): boolean {
        return credits >= PaymentsService.getClonRAvatarPriceInCredits(settings as GlobalSettings);
    }

    const getDialogText = () => {

        switch (newClonRAvatar.status) {

            case "noVideo":
                // firts check if client has enought balance
                if (!hasEnoughBalance()) {
                    return <>
                        <br/>
                        <p>Sie haben nicht genug Guthaben auf Ihrem Konto.</p>
                        <p>Bitte kaufen Sie weitere Credits, um einen Videoklon erstellen zu können.</p>
                        <p>Ein Videoklon kostet Sie einmalig <strong>{PaymentsService.getClonRAvatarPriceInCredits(settings as GlobalSettings)} Credits</strong>.</p>
                        <br/>
                        <Button
                            component="a"
                            href='/clonr/credits'
                            target='_self'
                            rel='noopener noreferrer'
                            variant='contained'
                            size='large'
                        >
                            <i className='fa fa-coins'></i>&nbsp;Mehr Credits kaufen
                        </Button>
                    </>
                }

                return <>
                    <p>Laden Sie nun eine Videodatei von sich hoch oder nehmen Sie jetzt ein Video von sich auf. Daraus erstellt unser Team dann Ihren Videoklon.</p>
                    <ul>
                        <li>Das Video sollte Sie von vorne zeigen und ca. 2 Minuten lang sein.</li>
                        <li>Achten Sie auf eine gute Belichtung und einen ruhigen Hintergrund.</li>
                        <li>Schauen Sie gerade in die Kamera und zentrieren Sie Sich mittig im Bild.</li>
                        <li>Ihr Mund sollte über den ganzen Zeitraum geschlossen bleiben.</li>
                    </ul>
                </>

            case "inProgress":
                return <p>Ihr Video wurde zur Bearbeitung an unser Team gesendet. Sobald wir Ihren Videoklon erstellt haben erhalten Sie eine Email.</p>

            case "completed":
                return <p>Ihr Videoklon ist nun fertig. Kombinieren Sie nun noch Stimme und Skript und Sie erhalten Ihr personalisiertes Video.</p>

            case "error":
                return <p style={{ textAlign: "center" }}>
                    <span className='kt-error-color'><i className='far fa-exclamation-triangle'></i> Ihr Video konnte leider nicht zur Erstellung des Videoklons genutzt werden.</span>
                    {(newClonRAvatar.errorReason !== "") && <span><br /><strong>Begründung: </strong>{newClonRAvatar.errorReason}</span>}
                </p>

            default:
                break;
        }
    }

    return (
        <>
            <ConfirmDialog
                visible={isConfirmCreateDialogOpen}
                title='Videoklon erstellen?'
                onReject={() => setIsConfirmCreateDialogOpen(false)}
                onConfirm={uploadVideo}
                label={<div style={{ textAlign: "center" }}>
                    <p>Wollen Sie diesen Videoklon nun in Auftrag geben?</p>
                    <p>Dies kostet Sie einmalig <strong>{PaymentsService.getClonRAvatarPriceInCredits(settings as GlobalSettings)} Credits</strong>.</p>
                </div>}
            />

            <ProgressDialog
                visible={uploadState === "running"}
                progress={progress}
                title='Ihre Datei wird hochgeladen'
                message='Bitte haben Sie einen Moment Geduld...'
            />

            <Dialog
                aria-labelledby="customized-dialog-title"
                open={visible}
                maxWidth="xl"
                disableEnforceFocus={true}
                fullWidth={true}
                fullScreen={fullScreen}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{ display: "grid", gridTemplateColumns: "auto auto", alignItems: "center" }}
                >
                    {clonRAvatar.id ? "Videoklon" : "Neuen Videoklon erstellen"}
                    <TextField
                        value={newClonRAvatar.name}
                        onChange={handleNameChange}
                        label="Name"
                        disabled={newClonRAvatar.isSystem}
                    />
                </DialogTitle>

                <DialogContent dividers style={{ backgroundColor: "#24374e" }} >

                    <Paper style={{ padding: "10px", minHeight: "400px" }}>
                        <Box
                            height={"100%"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                        >
                            {getDialogText()}
                            {hasEnoughBalance() && <>
                                {newClonRAvatar.videoUrl === "" && <>
                                    {isVideoRecorderVisible ?
                                        <VideoRecorderCtrl onFinishedRecording={handleFinishedRecording} />
                                        :
                                        <Button onClick={() => setIsVideoRecorderVisible(true)} variant="outlined" startIcon={<PhotoCameraFrontIcon />}>
                                            Video aufnehmen
                                        </Button>
                                    }

                                    <br />

                                    <FileUploader
                                        targetFileName={newClonRAvatar.id}
                                        buttonLabel={"Videodatei hochladen"}
                                        targetPath={`clients/${currentUser.clientId}/clonRAvatars`}
                                        showProgressDialog={true}
                                        onUploadFinished={handleUploadFinished}
                                        maxFileSizeMB={100}
                                        mimeType='video'
                                        generateVideoThumbnail={true}
                                    />

                                    <br />

                                    <Box sx={{ display: { xs: 'none', lg: 'block' } }} textAlign={"center"}>
                                        <p>
                                            Sie können auch Ihr Smartphone benutzen um ein Video von sich aufzunehmen.
                                            <br />
                                            Scannen Sie dazu einfach diesen QR-Code:
                                        </p>
                                        <QRCode
                                            value="https://cal.pickadoc.de/clonr/videoclone"
                                            size={120}
                                        />
                                    </Box>

                                </>}

                                {newClonRAvatar.videoUrl && <>
                                    <video
                                        id="clonRAvatarPreviewVideo"
                                        src={newClonRAvatar.videoUrl}
                                        playsInline
                                        controls
                                        //width={300}
                                        height={720}
                                        crossOrigin='anonymous'
                                        preload='metadata'
                                        poster={newClonRAvatar.thumbnailUrl}
                                        style={{ borderRadius: "10px" }}
                                        autoPlay
                                        muted
                                    />

                                    <br />

                                    {newClonRAvatar.status === "noVideo" && <Button onClick={handleResetVideo} variant='outlined'>Neues Video</Button>}
                                </>}

                            </>}

                        </Box>

                    </Paper>

                </DialogContent>

                {newClonRAvatar.isSystem ?
                    <DialogActions>
                        <Button onClick={handleCloseClick} autoFocus color="primary" variant='outlined'>Ok</Button>
                    </DialogActions>
                    :
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div><strong><i className='fal fa-coins'></i> Guthaben: {credits} Credits</strong></div>
                        <div>
                            <Button onClick={handleCloseClick} color="secondary" variant='outlined' style={{ marginRight: '8px' }}>
                                Abbrechen
                            </Button>
                            <Button
                                onClick={handleSaveClick}
                                autoFocus
                                color="primary"
                                variant='outlined'
                                disabled={newClonRAvatar.name.length === 0 || newClonRAvatar.videoUrl === ""}
                            >
                                {newClonRAvatar.status === "noVideo" ? "Erstellen" : "Speichern"}
                            </Button>
                        </div>
                    </DialogActions>
                }

            </Dialog >

        </>

    );
}

export default ClonRAvatarDialog;
