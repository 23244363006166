import React, { useEffect } from "react";
import {v4 as uuid} from "uuid";

interface Props {
    toasterId: string;
    newToast: Toast;
}

export type ToastType = "success" | "info" | "danger" | "warning";

export class Toast {
    id: string = "";
    type: ToastType = "success";
    message: string = "";

    constructor(type: ToastType, message: string) {
        this.id = uuid();
        this.type = type;
        this.message = message;
    }
}

const Toaster:React.FC<Props> = ({toasterId, newToast}) => {

    const visibilityDuration = 5; // seconds

    useEffect(()=> {
        
        if(newToast && newToast.message !== ""){
            addToast(newToast);
        }

    }, [newToast]);


    function addToast(newToast: Toast){
        const toasterElement = document.getElementById(toasterId);

        if(toasterElement && !document.getElementById(newToast.id)){

            // create the new toast item
            const newToastNode = document.createElement("div");
            newToastNode.className= `kt-toast kt-${newToast.type} fade-in`;
            newToastNode.setAttribute("id", newToast.id);

            const iconClassName = getIconClassName(newToast.type);
            newToastNode.innerHTML = `<i class='fal ${iconClassName}'></i><span>${newToast.message}</span>`;

            // add new item on top
            toasterElement.prepend(newToastNode);

            // fade out and remove from DOM after fade out animation 
            setTimeout(()=>{                
                document.getElementById(newToast.id)?.classList.remove("fade-in");
                document.getElementById(newToast.id)?.classList.add("fade-out");

                // remove now from DOM
                setTimeout(() => document.getElementById(newToast.id)?.remove(), 10 * 1000);
            }, visibilityDuration * 1000);

        }
        
    }

    function getIconClassName(type: ToastType): string {

        switch (type) {
            case "success":
                return "fa-check-circle";

            case "info":
                return "fa-info-circle";

            case "warning":
                return "fa-exclamation-circle";

            case "danger":
                return "fa-exclamation-triangle";
        
            default:
                return "";
        }

    }

    return (
        <div className="kt-toaster" id={toasterId}>
            
        </div>
    );
}

export default Toaster;