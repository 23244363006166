
import AnswerFormItem from "./answerFormItem";
import FormItem, { FormItemEnum, FormItemType, InputFormItemInterface, InputUserType } from "./formItem";

export class CheckboxFormItem extends FormItem implements InputFormItemInterface{

    required: boolean = false; // just for the interface

    inputUser: InputUserType = "patient";

    answers: AnswerFormItem[] = [];

    constructor(rowId: string) {
        super(rowId);

        this.type = FormItemEnum.checkbox;

        this.setLanguageProperty("de", "Formulieren Sie Ihre Frage", "labels");

        // add some sample ansers
        const answer1 = new AnswerFormItem(this.id);
        answer1.setLanguageProperty("de", "Antwort", "labels");

        const answer2 = new AnswerFormItem(this.id);
        answer2.setLanguageProperty("de", "Antwort", "labels");

        this.answers = [answer1, answer2];
    }

    clone(): FormItemType {

        const c = new CheckboxFormItem(this.parentId);
        c.fromObject(null, this.toJSON(true));

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj: any = super.toJSON(includeUserInputs);

        obj.inputUser = this.inputUser;

        obj.answers = this.answers.map(answer => answer.toJSON(includeUserInputs));

        return obj;
    }

    fromObject(id: string | null, o: any) {
        super.fromObject(id, o);

        this.inputUser = o.inputUser ?? "patient";

        this.answers = [];

        if (o.answers) {
            for (let i = 0; i < o.answers.length; i++) {
                const oAnswer = o.answers[i];

                if (oAnswer) {
                    const answer = new AnswerFormItem(this.id);
                    answer.fromObject(null, oAnswer);

                    this.answers.push(answer);
                }
            }
        }
    }

    hasOneOrMoreAnswersChecked(): boolean {
        for (let i = 0; i < this.answers.length; i++) {
            if(this.answers[i].checked) return true;
        }

        return false;
    }


}


export default CheckboxFormItem;