import { useContext, useEffect } from "react";
import { GlobalContext } from "../../GlobalContext";
import { useLocation } from "react-router-dom";


const VideoSidebar:React.FC = () => {

    const location = useLocation();
    
    const {videoSidebarVisible, setVideoSidebarVisible} = useContext(GlobalContext);

    useEffect(() => {

        
        if(location.pathname.toLowerCase().indexOf("docs") !== -1) {
            setVideoSidebarVisible(false);
            return;
        }
        
        // show the video side bar with the video tutorials the first time a user uses a new browser
        const _videoSideobarVisible = localStorage.getItem("videoSideobarVisible")
        if (_videoSideobarVisible !== null) {
            setVideoSidebarVisible(_videoSideobarVisible === "true");
        } else {
            setVideoSidebarVisible(true);
        }

    }, []);


    function hideVideoSidebar() {
        setVideoSidebarVisible(false);
        localStorage.setItem("videoSideobarVisible", "false");
    }

    if(!videoSidebarVisible) return null;

    return (
        <div className='kt-videos-sidebar'>
            <div className='kt-head'>
                <h1>Tutorials</h1>
                <button onClick={() => hideVideoSidebar()}><i className="fa fa-times"></i></button>
            </div>


            <div className='kt-video-container'>
                <div className='kt-title'>1. SMS Absender</div>
                <div className='kt-video'>
                    <iframe
                        title="video-tutorial"
                        className=""
                        allowFullScreen={true}
                        src={`https://www.youtube.com/embed/hH4caubVJCA?rel=0`}>
                    </iframe>
                </div>
                <div className='kt-description'>
                    Erste Schritte mit Pickadoc. Eine kurze Programübersicht und SMS Absendernamen festlegen.
                </div>
            </div>

            <div className='kt-video-container'>
                <div className='kt-title'>2. Standorte, Räume, Ressourcen</div>
                <div className='kt-video'>
                    <iframe
                        title="video-tutorial"
                        className=""
                        allowFullScreen={true}
                        src={`https://www.youtube.com/embed/OoFAUyYWuWM?rel=0`}>
                    </iframe>
                </div>
                <div className='kt-description'>
                    Wir erklären Ihnen in diesem Video wie Sie Standorte, Räume und Ressourcen in Pickadoc anlegen.
                </div>
            </div>

            <div className='kt-video-container'>
                <div className='kt-title'>3. Mitarbeiter und Kalender</div>
                <div className='kt-video'>
                    <iframe
                        title="video-tutorial"
                        className=""
                        allowFullScreen={true}
                        src={`https://www.youtube.com/embed/NcteOh5PDew?rel=0`}>
                    </iframe>
                </div>
                <div className='kt-description'>
                    Erfahren Sie wie Sie in Pickadoc Mitarbeiter und Kalender anlegen können.
                </div>
            </div>

            <div className='kt-video-container'>
                <div className='kt-title'>4. Gruppierte Besuchsgründe</div>
                <div className='kt-video'>
                    <iframe
                        title="video-tutorial"
                        className=""
                        allowFullScreen={true}
                        src={`https://www.youtube.com/embed/V34WHC3dVY4?rel=0`}>
                    </iframe>
                </div>
                <div className='kt-description'>

                </div>
            </div>

            <div className='kt-video-container'>
                <div className='kt-title'>5. Folgetermine und Recalls</div>
                <div className='kt-video'>
                    <iframe
                        title="video-tutorial"
                        className=""
                        allowFullScreen={true}
                        src={`https://www.youtube.com/embed/neDKvcFGYLE?rel=0`}>
                    </iframe>
                </div>
                <div className='kt-description'>

                </div>
            </div>

            <div className='kt-video-container'>
                <div className='kt-title'>6. Landingpage</div>
                <div className='kt-video'>
                    <iframe
                        title="video-tutorial"
                        className=""
                        allowFullScreen={true}
                        src={`https://www.youtube.com/embed/gsrIFqsV_Ew?rel=0`}>
                    </iframe>
                </div>
                <div className='kt-description'>

                </div>
            </div>

            <div className='kt-video-container'>
                <div className='kt-title'>7. pSign</div>
                <div className='kt-video'>
                    <iframe
                        title="video-tutorial"
                        className=""
                        allowFullScreen={true}
                        src={`https://www.youtube.com/embed/8e5oCi61DF4?rel=0`}>
                    </iframe>
                </div>
                <div className='kt-description'>

                </div>
            </div>

            <div className='kt-video-container'>
                <div className='kt-title'>8. Der Livebot</div>
                <div className='kt-video'>
                    <iframe
                        title="video-tutorial"
                        className=""
                        allowFullScreen={true}
                        src={`https://www.youtube.com/embed/WkQKDaG9gzE?rel=0`}>
                    </iframe>
                </div>
                <div className='kt-description'>

                </div>
            </div>

            <div className='kt-video-container'>
                <div className='kt-title'>9. Termin erstellen</div>
                <div className='kt-video'>
                    <iframe
                        title="video-tutorial"
                        className=""
                        allowFullScreen={true}
                        src={`https://www.youtube.com/embed/v9uGhaN6jMU?rel=0`}>
                    </iframe>
                </div>
                <div className='kt-description'>

                </div>
            </div>

        </div>
    )
}

export default VideoSidebar;