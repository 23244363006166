import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';

import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import TranslationsService from '../../services/translationsService';
import CountryFlag from '../countryFlag';
import ClonRSettings from '../../shared/src/models/clonR/clonRSettings';


type LanguageSetting = {
    key: string;
    name: string;
    enabled: boolean;
}

interface Props {
    clonRSettings: ClonRSettings;
    onSettingsChange: (newSettings: ClonRSettings) => void
}

const ClonRSettingsCtrl: React.FC<Props> = ({ clonRSettings, onSettingsChange }) => {

    const [languages, setLanguages] = useState<LanguageSetting[]>([]);

    useEffect(() => {

        const tempLanguages: LanguageSetting[] = [];
        TranslationsService.languages.forEach(t => {
            const lang: LanguageSetting = {
                key: t.key,
                name: t.name,
                enabled: clonRSettings.autoLanguageKeys.indexOf(t.key) !== -1
            }

            tempLanguages.push(lang);
        });

        setLanguages(tempLanguages);

    }, []);

    const setIsDefaultLanguage = (newLanguage: string) => {
        const _settings = clonRSettings.clone();
        _settings.defaultLanguage = newLanguage;
        onSettingsChange(_settings);
    }

    const setIsAutoTranslateEnabled = (isEnabled: boolean) => {
        const _settings = clonRSettings.clone();
        _settings.isAutoTranslateEnabled = isEnabled;
        onSettingsChange(_settings);
    }

    const setIsAutoTranslateForLanguage = (language: string, isEnabled: boolean) => {
        const _settings = clonRSettings.clone();
        const index = _settings.autoLanguageKeys.indexOf(language);

        if (index === -1) {
            if (isEnabled) _settings.autoLanguageKeys.push(language);

        } else {
            if (!isEnabled) _settings.autoLanguageKeys.splice(index, 1);
        }

        onSettingsChange(_settings);
    }

    return (
        <>

            <Paper className="kt-paper">

                <div className='kt-paper-title'>Einstellungen</div>

                <h3>Übersetzungen</h3>

                <Grid container>

                    <Grid item xs={12} sm={6}>
                        <FormControl >
                            <InputLabel id="default-language-select-label">Standartsprache</InputLabel>
                            <Select
                                labelId="name=default-language-select-label"
                                label="Standartsprache"
                                id="default-language-select"
                                value={clonRSettings.defaultLanguage}
                                onChange={e => setIsDefaultLanguage(e.target.value)}
                            >
                                {TranslationsService.languages.map(language =>
                                    <MenuItem key={language.key} value={language.key}>
                                        <CountryFlag countryCode={language.key} width={20} /> <span style={{ marginLeft: "5px" }}>{language.name}</span>
                                    </MenuItem>
                                )}
                            </Select>
                            <span>Änderungen der Standartsprache wirken sich nur auf neu erstellte Skripte aus.</span>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <FormControlLabel
                            control={<Checkbox checked={clonRSettings.isAutoTranslateEnabled} onChange={e => setIsAutoTranslateEnabled(e.currentTarget.checked)} color="default" />}
                            label="Automatische Übersetzung in ausgewählte Sprachen"
                        />
                    </Grid>

                    {clonRSettings.isAutoTranslateEnabled &&
                        <Grid item xs={12}>
                            {languages.map(t =>
                                <>
                                    {
                                        t.key !== clonRSettings.defaultLanguage  && <FormControlLabel
                                            key={t.key}
                                            control={<Checkbox checked={t.enabled} onChange={e => setIsAutoTranslateForLanguage(t.key, e.currentTarget.checked)} color="default" />}
                                            label={<div style={{ width: "150px" }}>
                                                <CountryFlag key={t.key} countryCode={t.key} width={20} />
                                                <span> {t.name}</span>
                                            </div>
                                            }
                                        />
                                    }
                                </>
                            )}
                        </Grid>
                    }
                </Grid>

            </Paper>
        </>
    );
}

export default ClonRSettingsCtrl;