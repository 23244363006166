import { useState, useContext, useEffect } from 'react';


import { Link, Navigate, useNavigate } from "react-router-dom";

import firebaseApp from "../database";
import { GlobalContext } from '../../GlobalContext';

const demoEmail = "demo@pickadoc.de";
const demoPassword = "12345678";

function DemoLoginPage() {

    const { setIsLoading } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);


    useEffect(() => {
        doDemoLogin();
    }, []);

    async function doDemoLogin() {

        setIsLoading(true);

        try {
            await firebaseApp.auth().signInWithEmailAndPassword(demoEmail, demoPassword);

            console.log(`logged in with: ${demoEmail}`);

            window.location.href = "/";

        } catch (error: any) {
            var errorCode = error.code;

            switch (errorCode) {
                case "auth/invalid-email":
                case "auth/user-not-found":
                case "auth/wrong-password":
                    alert("Die von Ihnen eingegebene E-Mail-Adresse oder das Passwort ist falsch. Bitte versuchen Sie es erneut");
                    break;

                case "auth/user-disabled":
                    alert("Dieser Account ist gesperrt, bitte wenden Sie Sich telefonisch an uns.");
                    break;

                default:
                    alert(error.message);
                    break;
            }
        };

        setIsLoading(false);

    }

    // if (currentUser) {
    //     const targetPath = new URLSearchParams(window.location.search).get("target");

    //     if (targetPath) {
    //         return <Navigate to={`${targetPath}`} replace />
    //     }

    //     return <Navigate to={`/`} replace />
    // }

    return <div className="kt-login-page">

        <h1>Pickadoc</h1>

        <img src="/images/logo.svg" alt="logo" className='kt-logo' />

        <div className="kt-grid">

        </div>


    </div>;
}

export default DemoLoginPage;