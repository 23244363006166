import {useState, useEffect, useContext} from "react";
import {useParams} from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import RoomsService from "../../../services/roomsService";

import LoadingPage from "../administration/loadingPage";
import SaveStatus from "../../../models/saveStatus";
import SaveButton from "../../saveButton";
import Room from "../../../models/room";
import BackButton from "../../backButton";
import { GlobalContext } from "../../../GlobalContext";


function RoomPage() {

    const { roomId } = useParams();

    const [room, setRoom] = useState<Room | null>(null);

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);

    const [saveStatus, setSaveStatus] = useState(SaveStatus.none);


    useEffect(() => {

        if(roomId && currentClient){
            updateData(roomId);
        }

        // eslint-disable-next-line
    }, [roomId, currentClient]);



    async function updateData(rId:string) {

        if(!currentUser){
            return;
        }

        try {
            const cId = currentClient.id;

            setIsLoading(true);

            if(rId && rId.toLowerCase() === "create" && cId){
                const newRoom = new Room();

                setRoom(newRoom);


            } else {

                const result = await RoomsService.getRoom(currentClient.id, currentUser.locationId, rId, true);

                if(result) {
                    setRoom(result);
                }
            }


            setIsLoading(false);

        } catch(error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if(room !== null && currentUser) {
            setSaveStatus(SaveStatus.isSaving);


            const newId = await RoomsService.updateRoom(room, currentClient.id, currentUser.locationId);

            if(newId){

                setSaveStatus(SaveStatus.saved);

                window.history.back();

            } else {
                setSaveStatus(SaveStatus.none);
                alert("Der Raum konnte nicht gespeichert werden!");
            }
        }
    }

    function onInputChange(event){
        if(room){
            const {name, value} = event.target;

            const r = room.clone();

            if(event.target.type === "checkbox"){
                r[name] = event.target.checked;
            } else {
                r[name] = value;
            }

            setRoom(r);
        }
    }




    if(isLoading) return <LoadingPage/>;

    if(!room) return (
        <div className="kt-page-content">
            <h2>Raum</h2>

            <div>Dieser Raum existiert nicht. Bitte überprüfen Sie Ihre URL.</div>
        </div>
    );

    return (
        <Box className="kt-page">
            <h2>Raum</h2>

            <form onSubmit={handleSubmit} autoComplete="turnitoff">

                <div className="kt-form-section">

                    <Grid container>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="name"
                                label="Name"
                                value={room.name}
                                onChange={onInputChange}
                                required
                                inputProps={{minLength:1}}
                            />
                        </Grid>

                    </Grid>
                </div>

                <div className="kt-page-footer">
                    <BackButton />

                    <SaveButton saveStatus={saveStatus} />
                </div>

            </form>
        </Box>
    );
}

export default RoomPage;