
import { CalendarVideoGroup } from "../landingPageData";
import FormItem, { Align, FormItemEnum, FormItemType } from "./formItem";


export class VideoFormItem extends FormItem {

    videoWidth: number = 200; // in pixel
    videoId: string = ""; // for example the ClonRVideo ID
    videoSource: string = "";
    videoGroups: CalendarVideoGroup[] = []; 
    videoThumbnailUrl: string = "";
    videoAlign: Align = Align.center;

    constructor(rowId: string) {
        super(rowId);

        this.type = FormItemEnum.video;
        this.setLanguageProperty("de", "Videolink", "labels");
    }

    clone(): FormItemType {

        const c = new VideoFormItem(this.parentId);
        c.fromObject(null, this.toJSON(true));

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj: any = super.toJSON(includeUserInputs);

        obj.videoWidth = this.videoWidth;
        obj.videoId = this.videoId;
        obj.videoSource = this.videoSource;
        obj.videoGroups = this.videoGroups;
        obj.videoThumbnailUrl = this.videoThumbnailUrl;
        obj.videoAlign = this.videoAlign;

        return obj;
    }

    fromObject(id: string|null, o:any){
        super.fromObject(id, o);

        this.videoWidth = o.videoWidth ? parseInt(o.videoWidth) : 200;
        this.videoId = o.videoId ?? "";
        this.videoSource = o.videoSource ?? "";
        this.videoGroups = o.videoGroups ? [...o.videoGroups] : [];
        this.videoThumbnailUrl = o.videoThumbnailUrl ?? "";
        this.videoAlign = o.videoAlign !== undefined ? o.videoAlign : Align.center;
    }


}


export default VideoFormItem;