import React from "react";
import {useNavigate } from "react-router-dom";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import Button from "@mui/material/Button";


interface Props {
    location?: string
}

const BackButton:React.FC<Props> = ({location}) => {

    const navigate = useNavigate();

    function onClick() {
        if(location) {
            navigate(location);
        } else {
            window.history.back();
        }
    }

    return (
        <Button
            variant="outlined"
            startIcon={<ArrowBackIosRoundedIcon />}
            onClick={onClick}
            color="secondary"
        >
            ZURÜCK
        </Button>

    );
}

export default BackButton;