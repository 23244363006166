import {useState, useContext} from "react";
import {Link, useParams} from "react-router-dom";

import VideoRoomCtrl from "../videoRoomCtrl";
import Box from "@mui/material/Box";
import { GlobalContext } from "../../GlobalContext";



const VideoCallPage: React.FC = () => {

    const { videoRoomId, userId } = useParams();

    const {currentUser} = useContext(GlobalContext);

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    function TopNavBar() {
        return (
            <header className="topNavBar noselect">
                <nav>

                    <div className="navbar-brand">
                        <svg className="kt-logo" width="30" height="30" version="1.1" id="Ebene_1" focusable="false" x="0px" y="0px" viewBox="0 0 612 612">
                            <path className="kt-logo-svg-background" d="M612,510c0,56.1-45.9,102-102,102H102C45.9,612,0,566.1,0,510V102C0,45.9,45.9,0,102,0h408
                                c56.1,0,102,45.9,102,102V510z"/>
                            <path className="kt-logo-svg-foreground" d="M102.6,355.2c0-16-13-29.1-29.1-29.1s-29.1,13-29.1,29.1c0,9.9,5,19,13.1,24.3v58.4c0,15.5,12.6,28.1,28.1,28.1
                                h131.9c82.8,0,151.1-63.3,159.2-144h105.1l0,0.1c30.1,1,53.7,25.4,53.7,55.5c0,30.6-24.9,55.6-55.6,55.6
                                c-23.2,0-43.1-14.3-51.4-34.5c5.5-5.3,9-12.8,9-21c0-16.1-13.1-29.2-29.2-29.2s-29.2,13.1-29.2,29.2c0,11.9,7.1,22.1,17.3,26.6
                                c11.3,35.3,44.4,60.9,83.4,60.9c48.3,0,87.6-39.3,87.6-87.6c0-22.9-8.8-44.5-24.7-60.9c-15.9-16.4-37.7-25.9-60.5-26.6H376.7
                                c-8.1-80.7-76.4-144-159.2-144H85.6c-15.5,0-28.1,12.6-28.1,28.1v58.4c-8.1,5.3-13.1,14.3-13.1,24.3c0,16,13,29.1,29.1,29.1
                                s29.1-13,29.1-29.1c0-9.9-5-19-13.1-24.3V178h128c70.6,0,128,57.4,128,128s-57.4,128-128,128h-128v-54.5
                                C97.6,374.2,102.6,365.2,102.6,355.2z"/>
                        </svg>
                        <span className="navbar-brand-name">Pickadoc</span>
                    </div>

                    <ul className="navbar-nav kt-main-nav">
                        {/* <li>
                            <span>{`Gesprächsdauer: ${duration}`}</span>
                        </li>                                                         */}

                    </ul>


                    <ul className="navbar-settings kt-desktop">
                        <li className="kt-username">{currentUser?.lastName.toUpperCase()}</li>
                        <li className="dropdown">
                            <div className="nav-link dropdown-toggle dropdown-toggle-button" onClick={()=>setIsDropdownVisible(!isDropdownVisible)}>
                                <i className="fa fa-user-cog dropdown-toggle-button"></i> <i className="fas fa-caret-down dropdown-toggle-button"></i>
                            </div>


                            { isDropdownVisible && <div className="dropdown-menu">
                                {(currentUser && currentUser.isAdmin) && <Link to="/settings" className="dropdown-item"><i className="fa fa-wrench"></i> Einstellungen</Link>}

                            </div>}
                        </li>

                        <li className="nav-item nav-item-desktop">
                            <Link to="/" className="nav-link">
                                <i className="fa fa-question-circle"></i>
                            </Link>
                        </li>

                    </ul>


                </nav>
            </header>
        );
    }

    return (
        <div className="kt-videoCall-page">
            <TopNavBar />
            <Box className="kt-page" margin={0} padding={2}>

                {(userId && videoRoomId) && <VideoRoomCtrl
                    userId={userId}
                    videoRoomId={videoRoomId}
                    locationId={currentUser ? currentUser.locationId : ""}
                />}

            </Box>
        </div>
    );
}

export default VideoCallPage;