import { Link } from "react-router-dom";
import User from "../../models/user";


interface Props {
    currentUser: null | User
}



const NotAllowedPage:React.FC<Props> = ({currentUser}) => {

    return (
        <div className="kt-loading-page">

            <div className="kt-loading-page-div">
                <h1>Pickadoc</h1>

                <img src="/images/logo.svg" alt="logo" className='kt-logo' />

                <div className="kt-grid">
                    <h2>Geschützter Bereich</h2>
                    {(currentUser && currentUser.id) ?
                        <span>Sie besitzen nicht genügend Rechte um diese Seite anzuzeigen.</span>
                        :
                        <span>Bitte melden Sie Sich zuerst an. <br/><Link to={`/login?target=${window.location.pathname}`} className="kt-btn">ANMELDEN</Link></span>
                    }

                </div>
            </div>
        </div>
    )
}

export default NotAllowedPage;