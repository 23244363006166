import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";

import { isGermanMobileNumber } from "./../utils";

import { InvoiceItemType } from '../../shared/src/models/invoice/invcoiceItem';

const db = firebaseApp.firestore();
const functions = firebase.app().functions('europe-west3');

const SmsService = {


    async sendSms(toMobilePhoneNumber: string, message: string, senderName: string, type: InvoiceItemType, clientId: string, locationId: string, eventId?: string): Promise<void> {

        try {

            if(isGermanMobileNumber(toMobilePhoneNumber)){

                const sendSms = functions.httpsCallable('sendSms');
                await sendSms(
                    {
                        toMobilePhoneNumber: toMobilePhoneNumber,
                        message: message,
                        senderName: senderName,
                        type: type,
                        clientId: clientId,
                        locationId: locationId,
                        eventId: eventId
                    }
                );

            } else {
                throw new Error("invalidPhoneNumber");
            }

        } catch (error) {
            console.log("Error sending SMS: ", error);

            throw new Error(error as any);
        };

    }

}

export default SmsService;