import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import ClientsService from "../../../services/clientsService";
// import UsersService from '../../../services/usersService';
// import LoadingPage from './loadingPage';
import Client from '../../../models/client';
// import ClientSettings from '../../../models/clientSettings';
// import User from '../../../models/user';
import { GlobalContext } from '../../../GlobalContext';
import ClientLocationsService from '../../../services/clientLocationsService';
import CalendarsService from '../../../services/calendarsService';
import LoadingPage from '../loadingPage';
import Calendar from '../../../models/calendar';
import Button from '@mui/material/Button';
import UsersService from '../../../services/usersService';
import DateUtils from '../../../shared/src/utils/dateUtils';

function ClientPage() {

    const navigate = useNavigate();

    let { clientId } = useParams();

    const [client, setClient] = useState(new Client());
    
    const [calendars, setCalendars] = useState<any>({});

    const [isLoading, setIsLoading] = useState(false);

    const [isLoginIn, setIsLoginIn] = useState(false);

    useEffect(() => {

        loadData();

        // eslint-disable-next-line
    }, [clientId]);

    
    async function loadData() {

        if (!clientId) return;


        setIsLoading(true);

        try {

            const _client = await ClientsService.getClient(clientId);


            if (_client) {
                
                _client.locations = await ClientLocationsService.getAllLocations(clientId) ?? [];

                setClient(_client);

                const _calendars = {} as any;

                for (let i = 0; i < _client.locations.length; i++) {
                    const _location = _client.locations[i];
                    
                    _calendars[_location.id] = await CalendarsService.getCalendars(true, clientId, _location.id) ?? [];
                }

                setCalendars(_calendars);

                console.log(_calendars);
            }


        } catch (error) {
            console.log(error);
        }

        setIsLoading(false);

    }

    async function handleOnLoginClick(locationId: string) {
        setIsLoginIn(true);
        const result = await UsersService.createNewSupportUser(clientId!, locationId);

        await signInWithEmailAndPassword(getAuth(), result.email, result.password);
        
        navigate(`/`);
    }

    if (isLoading) return <LoadingPage/>;
    if (isLoginIn) return <LoadingPage customMessage={"Sie werden nun in den Kunden-Account eingelogt..."}/>;


    return <>
        <Box className="kt-page">
            <h2>Kunde</h2>

            <Box>
                <div>Name: <strong>{client.name}</strong></div>
                <div>Deaktiviert: {!client.isEnabled ? "Ja" : "Nein"}</div>
                <div>Erstellt am: {DateUtils.getDateTimeString(client.createdAt)}</div>
                <div>Credits: {client.credits}</div>
                <br/>
                   <div>Standorte:</div>                   
                   <ul>
                    {client.locations.map((location) => (
                        <li key={location.id} style={{marginTop: "10px"}}>
                            <strong>{location.name} </strong>
                            <Button variant="contained" size="small" onClick={() => handleOnLoginClick(location.id)}>Login</Button>
                            <div>Kalender:</div>
                            <ul>
                                {calendars[location.id].map((calendar: Calendar) => (
                                    <li key={calendar.id}>{calendar.name} - <span>{calendar.license}</span></li>
                                )                           
                            )}
                            </ul>
                        </li>
                            
                        ))}
                   </ul>
            </Box>

        </Box>
    </>
}

export default ClientPage;