import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import moment from "moment";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Patient from '../../../models/patient';
import PatientsService from '../../../services/patientsService';

import LanguageIcon from '@mui/icons-material/Language';
import AlternateEmail from '@mui/icons-material/AlternateEmail';
import Phone from '@mui/icons-material/Phone';
import PhoneIphone from '@mui/icons-material/PhoneIphone';

import DateUtils from '../../../../src/shared/src/utils/dateUtils';
import languages from "./languages.json";
import PatientDocsService from '../../../../src/services/patientDocsService';
import FormPreview from '../../../../src/components/formEditor/formPreview';
import PDocument from "../../../../src/shared/src/models/formEditor/pdocument";
import FormRow from "../../../../src/shared/src/models/formEditor/formRow";
import SignatureFormItem from '../../../../src/shared/src/models/formEditor/signatureFormItem';

import UsersService from '../../../../src/services/usersService';
import DocumentsService, { PatientDocumentsModeData } from '../../../../src/services/documentsService';
import Utils from '../../../../src/shared/src/utils/utils';
import dayjs from 'dayjs';



interface Props {

}

const DocsPage: React.FC<Props> = () => {

    let { patientId, clientId, locationId } = useParams();

    const [patient, setPatient] = useState<Patient | null>(null);

    const [language, setLanguage] = useState("de");

    const [currentDocument, setCurrentDocument] = useState<PDocument | null>(null);
    const [allDocuments, setAllDocuments] = useState<PDocument[]>([]);

    const [pageIndex, setPageIndex] = useState(-1);

    const [isVerifyingPatient, setIsVerifyingPatient] = useState(false);

    const [clientLocationLogoUrl, setClientLocationLogoUrl] = useState("");

    const [isEasyLoginDialogVisible, setIsEasyLoginDialogVisible] = useState(true);

    const [patientVerification, setPatientVerification] = useState("");

    const [verificationError, setVerificationError] = useState("");

    const [locationPhoneNumber, setLocationPhoneNumber] = useState("");

    const [isSaving, setIsSaving] = useState(false);

    useEffect(()=>{
        setIsSaving(false);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            const scrollContainer = document.getElementsByClassName("kt-form-rows-scroll")[0];
            if (scrollContainer) scrollContainer.scrollTop = 0;
        }, 200);

    }, [pageIndex]);


    async function updatePatientAndDocuments() {
        if (patientId && clientId && locationId) {

            setIsVerifyingPatient(true);


            const result = await DocumentsService.getPatientDocumentsModeData(clientId, locationId, patientId, patientVerification);


            if (result.success && result.data) {

                const data = result.data as PatientDocumentsModeData;

                setClientLocationLogoUrl(data.clientLogoUrl);

                setPatient(data.patient);

                if (data.documents && data.documents.length > 0) {

                    data.documents.sort((a, b) => a.sortIndex - b.sortIndex);

                    setAllDocuments(data.documents);
                    setCurrentDocument(null);
                    setPageIndex(-1);

                    updateSignatures(data.documents);
                }

                setIsEasyLoginDialogVisible(false);

            } else {
                setVerificationError(result.error);

                if (result.error === "aborted" && typeof result.data === "string") {
                    setLocationPhoneNumber(result.data);
                }
            }

            setIsVerifyingPatient(false);
        }
    }

    async function updateSignatures(docs: PDocument[]) {

        if (!clientId) return;

        let doctors: any = {};

        for (let d = 0; d < docs.length; d++) {
            const document = docs[d];

            const missingSignatures = SignatureFormItem.getAllUnsignedSignaturesBySigner("doctor", document.formRows);

            for (let s = 0; s < missingSignatures.length; s++) {
                const signatureItem = missingSignatures[s];

                if (!doctors[document.doctorId]) {
                    const doctor = await UsersService.getUser(clientId, document.doctorId);
                    doctors[document.doctorId] = doctor;
                }

                if (doctors[document.doctorId].signature) {
                    signatureItem.signature = doctors[document.doctorId].signature;
                }

            }
        }

        setAllDocuments(docs);


    }


    function handleFormEditorChange(formRows: FormRow[]) {

        if (currentDocument) {
            currentDocument.formRows = formRows;
        }
    }

    async function handleOnDocumentFilled() {

        // first save changes from patient to current document
        if (currentDocument && clientId && locationId && patientId) {

            setIsSaving(true);

            currentDocument.status = "signed";

            if(currentDocument.expiresAfter && currentDocument.expiresAfter !== "0-m"){
                // number of days between signed and expired
                const daysTillGetsExpired = DateUtils.getDaysFromTimeString(currentDocument.expiresAfter);

                if(daysTillGetsExpired !== null && daysTillGetsExpired > 0){
                    currentDocument.expiresAt = moment().add(daysTillGetsExpired, "d").toDate();
                }
            }

            // call to gotoNextPage will change currentDocument, thats why we need to clone it
            // this way we do not need to wait for cloud functions to finish to go to the next page
            const docToSave = currentDocument.clone();

            PatientDocsService.saveDocumentAndCreatePDF(docToSave, clientId, locationId, patientId);

        }

        setIsSaving(false);

        gotoNextPage();

    }

    function gotoNextPage() {
        const newPageIndex = pageIndex + 1;

        if (newPageIndex >= 0 && newPageIndex < allDocuments.length) {
            setCurrentDocument(allDocuments[newPageIndex]);
        } else {
            setCurrentDocument(null);
        }

        setPageIndex(newPageIndex);
    }


    function getTitle() {
        if (patient) {
            let title = `${patient.lastName} ${patient.firstName}`;
            if (currentDocument) {
                title += ` - ${currentDocument.getLanguageProperty(language, "name")}`;
            }
            return title;
        } else {
            return `p-sign - Papierlose Gesundheitsfragebögen`;
        }
    }

    function getProgressLabel() {
        if (allDocuments.length > 0 && pageIndex !== allDocuments.length) {
            return <div className='kt-header-center-label'><span className='kt-hide-on-mobile'>{i18n("form")} </span>{pageIndex + 2} {i18n("of")} {allDocuments.length + 1}</div>;
        }

        return <div></div>;
    }

    function getLanguagesCtrl() {
        return (
            <div className="kt-languages">
                <LanguageIcon />
                <div className="kt-language-buttons">
                    <Button color="inherit" onClick={() => setLanguage("de")}>DEUTSCH</Button>
                    <Button color="inherit" onClick={() => setLanguage("en")}>ENGLISH</Button>
                    <Button color="inherit" onClick={() => setLanguage("el")}>ΕΛΛΗΝΙΚΑ</Button>
                    <Button color="inherit" onClick={() => setLanguage("tr")}>TÜRK</Button>
                    <Button color="inherit" onClick={() => setLanguage("zh")}>中国人</Button>
                </div>
            </div>
        );
    }


    function i18n(langKey: string): string {

        let result = languages[language][langKey];

        if (!result) {
            result = languages["de"][langKey];
        }

        return result ?? langKey;
    }


    async function handlePatientDataSubmit(e: any): Promise<void> {
        e.preventDefault();

        if (patient && clientId && locationId) {
            PatientsService.updatePatientAnonymous(patient, clientId, locationId);
        }

        gotoNextPage();
    }

    function handleOnPatientInputChange(value: any, propertyName: string) {
        const modifiedPatient = patient ? patient.clone() : new Patient();

        if (propertyName === "birthDate") {
            if (value) {
                const bDate = (value as dayjs.Dayjs).toDate();
                if (bDate) {
                    modifiedPatient.birthDate = bDate;
                }
            }
        } else {

            if(propertyName === "privateInsurance"){
                modifiedPatient[propertyName] = value === "true";
            } else {
                modifiedPatient[propertyName] = value;
            }
        }
        setPatient(modifiedPatient);
    }

    function getBirthDate(): dayjs.Dayjs {
        if (patient && patient.birthDate) {
            const bDate = DateUtils.getDate(patient.birthDate);

            if (bDate) {
                const month = Utils.getWithLeadingZero(bDate.getMonth() + 1);
                const day = Utils.getWithLeadingZero(bDate.getDate());

                return dayjs(`${bDate.getFullYear()}-${month}-${day}`);
            }
        }

        return dayjs();
    }

    function getVerificationErrorMessage() {
        switch (verificationError) {
            case "permission-denied":
                return "Ihre Eingabe ist nicht korrekt. Bitte versuchen Sie es erneut.";

            case "aborted":
                return <span>Sie haben Sich zu oft falsch eingeloggt.<br />
                    Wir haben Ihren Zugang vorübergehend gesperrt.<br />
                    Bitte rufen Sie uns an, um Ihren Zugang wieder freizuschalten.
                    {locationPhoneNumber && <><br /><br /><strong><a href={`tel:${locationPhoneNumber}`}>Tel.: {locationPhoneNumber}</a></strong></>}
                </span>;

            case "not-found":
            case "unknown":
                return "Wir konnten keine Daten zu diesem Link finden. Bitte prüfen Sie die URL.";

            default:
                return "";
        }
    }

    function renderPatientDataForm() {
        return <div className="kt-form-preview">
            <form className="kt-form-rendering" onSubmit={handlePatientDataSubmit}>

                <div className="kt-form-rows-scroll">

                    <div className="kt-form-rows">

                        <img className="kt-client-location-logo" src={clientLocationLogoUrl} alt="Praxis Logo"></img>

                        <h2>{i18n("isYourDataCorrect")}</h2>
                        <p>{i18n("youCanChangeYourData")}</p>

                        <Grid container>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="mobilePhoneNumber"
                                    value={patient?.mobilePhoneNumber}
                                    label={i18n("mobileNumber")}
                                    required={patient?.smsAllowed}
                                    onChange={(e) => handleOnPatientInputChange(e.target.value, "mobilePhoneNumber")}
                                    autoComplete="turnitoff"
                                    InputLabelProps={{ required: false }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIphone />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    value={patient?.phoneNumber.toString()}
                                    label={i18n("phoneNumber")}
                                    className="kt-border-left"
                                    onChange={(e) => handleOnPatientInputChange(e.target.value, "phoneNumber")}
                                    autoComplete="turnitoff"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Phone />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    type="email"
                                    value={patient?.email}
                                    label={i18n("email")}
                                    required={patient?.emailAllowed}
                                    onChange={(e) => handleOnPatientInputChange(e.target.value, "email")}
                                    autoComplete="turnitoff"
                                    InputLabelProps={{ required: false }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <AlternateEmail />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="privateInsurance-select-label">{i18n("insurance")}</InputLabel>
                                    <Select
                                        labelId="privateInsurance-select-label"
                                        label="Versicherung"
                                        id="privateInsurance-select"
                                        value={patient?.privateInsurance.toString()}
                                        onChange={(e) => handleOnPatientInputChange(e.target.value, "privateInsurance")}
                                    >
                                        <MenuItem value="false">{i18n("legallyInsured")}</MenuItem>
                                        <MenuItem value="true">{i18n("privateInsured")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={i18n("firstName")}
                                    required
                                    InputLabelProps={{ required: false }}
                                    value={patient?.firstName}
                                    onChange={(e) => handleOnPatientInputChange(e.target.value, "firstName")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={i18n("lastName")}
                                    required
                                    InputLabelProps={{ required: false }}
                                    value={patient?.lastName}
                                    onChange={(e) => handleOnPatientInputChange(e.target.value, "lastName")}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <DatePicker
                                    className="kt-keyboardDatePicker"
                                    label={i18n("birthDate")}
                                    value={getBirthDate()}
                                    onChange={(newDate) => handleOnPatientInputChange(newDate, "birthDate")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            </Grid>

                            <Grid item xs={12}>
                                <h4>{i18n("remindOfNextAppointments")}</h4>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={patient?.smsAllowed}
                                            onChange={(e) => handleOnPatientInputChange(e.target.checked, "smsAllowed")}
                                            name="smsAllowed"
                                            color="primary"
                                        />
                                    }
                                    label={i18n("allowSms")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={patient?.emailAllowed}
                                            onChange={(e) => handleOnPatientInputChange(e.target.checked, "emailAllowed")}
                                            name="emailAllowed"
                                            color="primary"
                                        />
                                    }
                                    label={i18n("allowEmail")}
                                />
                            </Grid>
                        </Grid>

                    </div>

                    <Button type='submit' variant='contained'>
                        {i18n("next")} <i className="fal fa-angle-right"></i>
                    </Button>

                </div>
            </form>
        </div>
    }

    function renderContent() {
        if (pageIndex === -1 && allDocuments.length > 0) {
            return renderPatientDataForm();

        } else if (pageIndex === allDocuments.length) {
            return <div className='kt-text-center'>
                <h3>Vielen Dank, Sie haben alle erforderlichen Unterlagen ausgefüllt.</h3>
                <p>Wir bedanken uns für Ihre Zusammenarbeit!</p>
                {/* <p>Bitte bringen Sie nun das Tablett wieder zurück zur Rezeption.</p> */}
            </div>

        } else if (isEasyLoginDialogVisible === false) {
            return <div className='kt-text-center'>
                <h3>Aktuell gibt es keine Dokumente, die Sie ausfüllen müssen.</h3>
            </div>;
        }

        return <></>

    }

    return (
        <div className="kt-docs-page">

            <Dialog
                open={isEasyLoginDialogVisible}
                style={{ textAlign: "center" }}
            >

                <DialogTitle>Gesundheitsfragen</DialogTitle>

                {isVerifyingPatient ?
                    <>
                        <DialogContent>
                            <DialogContentText>
                                Bitte haben Sie einen Moment Geduld.<br /><br />
                                Wir verifizieren Ihre Eingabe...<br /><br />
                                <i className="fal fa-spinner-third fa-spin" style={{ fontSize: "40px" }}></i>
                            </DialogContentText>
                        </DialogContent>

                    </>
                    :
                    <>
                        <form onSubmit={(e) => { e.preventDefault(); updatePatientAndDocuments() }}>

                            <DialogContent>

                                {(verificationError !== "aborted") &&
                                    <>
                                        <DialogContentText>
                                            Für Ihren nächsten Arztbesuch benötigen wir noch einige Angaben.<br /><br />
                                            Um sicherzustellen, dass kein Unbefugter den Fragebogen ausfüllt,<br />
                                            geben Sie bitte die <strong>ersten drei Buchstaben Ihres Nachnamens</strong> ein.
                                        </DialogContentText>
                                        <TextField
                                            autoFocus
                                            id="name"
                                            type="text"
                                            variant="standard"
                                            value={patientVerification}
                                            onChange={(e) => setPatientVerification(e.target.value)}
                                            autoComplete="turnitoff"
                                            required
                                            inputProps={
                                                {
                                                    maxLength: 3,
                                                    minLength: 2,
                                                    style: {
                                                        fontSize: "40px",
                                                        textAlign: "center",
                                                        textTransform: "uppercase",
                                                        width: "120px"
                                                    }
                                                }
                                            }
                                        />
                                    </>
                                }

                                <DialogContentText>
                                    <span className="kt-red-color">{getVerificationErrorMessage()}</span>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {(verificationError !== "aborted") &&
                                    <Button type='submit' variant='contained' style={{ margin: "auto" }} size='large' onClick={() => updatePatientAndDocuments()}>WEITER</Button>
                                }
                            </DialogActions>
                        </form>
                    </>
                }



            </Dialog>


            <AppBar position="static">
                <Toolbar>

                    <Typography variant="h6">
                        {getTitle()}
                    </Typography>

                    {getProgressLabel()}

                    {getLanguagesCtrl()}
                </Toolbar>
            </AppBar>
            <Box margin={5}>

                {(currentDocument && patient && clientId && locationId) ?
                    <div>
                        <FormPreview
                            patientId={patient.id}
                            documentId={currentDocument.id}
                            clientId={clientId}
                            locationId={locationId}
                            clientLocationLogoUrl={clientLocationLogoUrl}
                            languageKey={language}
                            formRowsProp={[...currentDocument.formRows]}
                            onFormChange={handleFormEditorChange}
                            showNextButton={true}
                            isSaving={isSaving}
                            showRenderPDFButton={false}
                            onDocumentFilled={handleOnDocumentFilled}
                            formUser="patient"
                        />
                    </div>
                    :
                    renderContent()
                }

            </Box>
        </div>
    );
}

export default DocsPage;
