import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";

import VisitMotive from "../models/visitMotive";
import Appointment from "../models/appointment";
import AppointmentsService from "./appointmentsService";
import Speciality from '../models/speciality';
import LandingPageData from '../shared/src/models/landingPageData';

const db = firebaseApp.firestore();
const functions = firebase.app().functions('europe-west3');

const VisitMotivesService = {

    cache: {} as {[key:string]:VisitMotive},

    getCacheId(id: string, clientId?: string, locationId?: string): string {
        return (clientId && locationId) ? clientId + locationId + id : id;
    },

    async getVisitMotive(visitMotiveId: string, ignoreCache: boolean = false, clientId?: string, locationId?: string): Promise<VisitMotive | null> {

        const cacheId = VisitMotivesService.getCacheId(visitMotiveId, clientId, locationId);

        if(!ignoreCache && VisitMotivesService.cache[cacheId]){
            return VisitMotivesService.cache[cacheId];
        }

        const documentPath = (clientId && locationId) ? `clients/${clientId}/locations/${locationId}/visitMotives/${visitMotiveId}` : `visitMotives/${visitMotiveId}`;
        const doc = await db.doc(documentPath).get();

        try {

            if (doc.exists) {

                const visitMotive = new VisitMotive();
                visitMotive.fromObject(visitMotiveId, doc.data());


                // load now landing page data
                const successorVMotive = await VisitMotivesService.getSuccessorVisitMotive(visitMotive, ignoreCache, clientId, locationId);
                visitMotive.successorLandingPage = successorVMotive ? successorVMotive.landingPage.clone() : new LandingPageData();


                const recallVMotive = await VisitMotivesService.getRecallVisitMotive(visitMotive, ignoreCache, clientId, locationId);
                visitMotive.recallLandingPage = recallVMotive ? recallVMotive.landingPage.clone() : new LandingPageData();



                // update cache
                VisitMotivesService.cache[cacheId] = visitMotive;

                return visitMotive;

            } else {
                console.log("getVisitMotive: No such document: " + visitMotiveId);
                return null;
            }

        } catch(error) {
            console.log("Error getting visit motive: ", error);
            return null;
        };

    },

    async getSuccessorVisitMotive(visitMotive: VisitMotive, ignoreCache: boolean = false, clientId?: string, locationId?: string): Promise<VisitMotive | null> {

        if(!visitMotive.successorEnabled || !visitMotive.successorId) return null;

        const cacheId = VisitMotivesService.getCacheId(visitMotive.successorId, clientId, locationId);

        if(!ignoreCache && VisitMotivesService.cache[cacheId]){
            return VisitMotivesService.cache[cacheId];
        }

        const documentPath = (clientId && locationId) ? `clients/${clientId}/locations/${locationId}/visitMotives/${visitMotive.successorId}` : `visitMotives/${visitMotive.successorId}`;
        const doc = await db.doc(documentPath).get();

        try {

            if (doc.exists) {

                const successorVisitMotive = new VisitMotive();
                successorVisitMotive.fromObject(visitMotive.successorId, doc.data());

                // update cache
                VisitMotivesService.cache[cacheId] = visitMotive;

                return successorVisitMotive;

            } else {
                console.log("getSuccessorVisitMotive: No such document: " + visitMotive.successorId);
                return null;
            }

        } catch(error) {
            console.log("Error getting successor visit motive: ", error);
            return null;
        };

    },

    async getRecallVisitMotive(visitMotive: VisitMotive, ignoreCache: boolean = false, clientId?: string, locationId?: string): Promise<VisitMotive | null> {

        if(visitMotive.recurrenceCount === 0 || !visitMotive.recallId) return null;

        const cacheId = VisitMotivesService.getCacheId(visitMotive.recallId, clientId, locationId);

        if(!ignoreCache && VisitMotivesService.cache[cacheId]){
            return VisitMotivesService.cache[cacheId];
        }

        const documentPath = (clientId && locationId) ? `clients/${clientId}/locations/${locationId}/visitMotives/${visitMotive.recallId}` : `visitMotives/${visitMotive.recallId}`;
        const doc = await db.doc(documentPath).get();

        try {

            if (doc.exists) {

                const recallVisitMotive = new VisitMotive();
                recallVisitMotive.fromObject(visitMotive.successorId, doc.data());

                // update cache
                VisitMotivesService.cache[cacheId] = visitMotive;

                return recallVisitMotive;

            } else {
                console.log("getRecallVisitMotive: No such document: " + visitMotive.successorId);
                return null;
            }

        } catch(error) {
            console.log("Error getting recall visit motive: ", error);
            return null;
        };

    },



    async getVisitMotives(clientId?: string, locationId?: string): Promise<VisitMotive[] | null> {

        try {

            const collectionPath = (clientId && locationId) ? `clients/${clientId}/locations/${locationId}/visitMotives` : `visitMotives`;

            const querySnapshot = await db.collection(collectionPath)
                                        .get();

            const visitMotiveList: VisitMotive[] = [];

            querySnapshot.forEach((doc) => {
                const visitMotive = new VisitMotive();
                visitMotive.fromObject(doc.id, doc.data());

                if(clientId && locationId){
                    //if(clientId === visitMotive.clientId){
                        // just to make we show only own visit motives
                        // (because of old bug, where visitmotives contained wrong client id after copy)
                        visitMotiveList.push(visitMotive);
                    //}
                } else {
                    visitMotiveList.push(visitMotive);
                }

                // update cache
                const cacheId = VisitMotivesService.getCacheId(visitMotive.id, clientId, locationId);
                VisitMotivesService.cache[cacheId] = visitMotive;
            });

            visitMotiveList.sort((a,b) => a.cardinality - b.cardinality);

            return visitMotiveList;

        } catch(error) {
            console.log("Error getting visit motives: ", error);
            return null;
        }
    },

    async updateVisitMotive(visitMotive: VisitMotive, clientId?: string, locationId?: string): Promise<string | null> {

        try {

            if(visitMotive.id) {
                const documentPath = (clientId && locationId) ? `clients/${clientId}/locations/${locationId}/visitMotives/${visitMotive.id}` : `visitMotives/${visitMotive.id}`;

                await db.doc(documentPath).set(visitMotive.toJSON(), { merge: true });

                // update cache
                const cacheId = VisitMotivesService.getCacheId(visitMotive.id, clientId, locationId);
                VisitMotivesService.cache[cacheId] = visitMotive;

                return visitMotive.id;

            } else {
                // create a new visitMotive
                const collectionPath = clientId ? `clients/${clientId}/locations/${locationId}/visitMotives` : `visitMotives`;
                const docRef = await db.collection(collectionPath).add(visitMotive.toJSON());

                // update cache
                const cacheId = VisitMotivesService.getCacheId(docRef.id, clientId, locationId);
                VisitMotivesService.cache[cacheId] = visitMotive;

                return docRef.id;
            }

        } catch (error) {
            console.log("Error updating visit motive: ", error);
        };

        return null;
    },

    async deleteVisitMotive(visitMotiveId: string, clientId?: string, locationId?: string): Promise<void> {

        try {
            const documentPath = (clientId && locationId) ? `clients/${clientId}/locations/${locationId}/visitMotives/${visitMotiveId}` : `visitMotives/${visitMotiveId}`;
            await db.doc(documentPath).delete();

        } catch(error) {
            console.log("Error deleting visit motive: ", error);
        };
    },


    async getParentRecallVisitMotive(appointment: Appointment): Promise<VisitMotive | null> {
        try {
            if(appointment && appointment.parentRecallId) {
                const parentRecallAppointment = await AppointmentsService.getAppointment(appointment.parentRecallId, appointment.clientId, appointment.locationId);

                if(parentRecallAppointment){
                    return VisitMotivesService.getVisitMotive(parentRecallAppointment.visitMotive.id, false, appointment.clientId, appointment.locationId);
                }
            }

        } catch(error) {
            console.log("Error getting parent recall visit motive: ", error);
        }

        return null;
    },

    async getPredecessorVisitMotive(appointment: Appointment): Promise<VisitMotive | null> {
        try {
            if(appointment && appointment.predecessorId) {
                const predecessorAppointment = await AppointmentsService.getAppointment(appointment.predecessorId, appointment.clientId, appointment.locationId);

                if(predecessorAppointment){
                    return VisitMotivesService.getVisitMotive(predecessorAppointment.visitMotive.id, false, appointment.clientId, appointment.locationId);
                }
            }

        } catch(error) {
            console.log("Error getting predecessor visit motive: ", error);
        }

        return null;
    },



    async cloneFromMasterVisitMotives(clientId: string, locationId: string): Promise<void> {
        const cloneFromMasterVisitMotivesFunc = functions.httpsCallable('cloneFromMasterVisitMotives');
        await cloneFromMasterVisitMotivesFunc({
            clientId: clientId,
            locationId: locationId
        });
    },

    async cloneToMasterVisitMotives(clientId: string, locationId: string): Promise<void> {
        const cloneToMasterVisitMotivesFunc = functions.httpsCallable('cloneToMasterVisitMotives');
        await cloneToMasterVisitMotivesFunc({
            clientId: clientId,
            locationId: locationId
        });
    },

    // the user can sort speciallities and visitMotives
    // this function returns the top most sorted visitMotive
    // we need this for example for the visitMotivePicker if we create a new appointment
    getFirstVisitMotive(specialities: Speciality[], visitMotives: VisitMotive[]): VisitMotive | null {

        // first get the first speciality
        if(specialities.length > 0) {
            const firstSpeciality = specialities.sort((a,b) => a.cardinality > b.cardinality ? 1 : -1)[0];

            // first filter visitMotives for that speciality
            const filteredVisitMotives = visitMotives.filter( v => v.specialityId === firstSpeciality.id);

            // now return the first visitMotive out of the filtered and sorted list
            if(filteredVisitMotives.length > 0){
                return filteredVisitMotives.sort((a,b) => a.cardinality > b.cardinality ? 1 : -1)[0];
            }
        }

        return null;
    }


}

export default VisitMotivesService;