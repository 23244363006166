import database from "./../components/database";
import Speciality from "../models/speciality";

const db = database.firestore();

const SpecialitiesService = {

    cache: {} as {[key: string]: Speciality},

    async getSpecialities(clientId: string, locationId: string): Promise<Speciality[] | null> {

        if(!clientId || !locationId){

            console.log("Error getting specialities: missing argument");
            return null;
        }

        const specialities: Speciality[] = [];

        try {

            const querySnapshot = await db.collection("clients").doc(clientId)
                                        .collection("locations").doc(locationId)
                                        .collection("specialities")
                                        .get();


            querySnapshot.forEach((doc) => {

                const speciality = new Speciality();
                speciality.fromObject(doc.id, doc.data());

                // update cache
                SpecialitiesService.cache[speciality.id] = speciality;

                specialities.push(speciality);

            });

            specialities.sort((a,b) => a.cardinality > b.cardinality ? 1 : -1);

            return specialities;

        } catch(error) {
            console.log("Error getting specialities: ", error);
            return null;
        }

    },

    async getSpeciality(clientId: string, locationId: string, specialityId: string, ignoreCache:boolean = false): Promise<Speciality | null> {

        try {

            if(!ignoreCache && SpecialitiesService.cache[specialityId]){
                return SpecialitiesService.cache[specialityId];
            }

            const doc = await db.collection("clients").doc(clientId)
                                .collection("locations").doc(locationId)
                                .collection("specialities").doc(specialityId)
                                .get();

            if (doc.exists) {

                const speciality = new Speciality();
                speciality.fromObject(specialityId, doc.data());

                // update cache
                SpecialitiesService.cache[speciality.id] = speciality;

                return speciality;

            } else {
                console.log("getSpeciality: No such document: " + specialityId);
                return null;
            }

        } catch(error) {
            console.log("Error getting speciality: ", error);
            return null;
        };

    },



    async updateSpeciality(speciality: Speciality, clientId: string, locationId: string): Promise<string | null> {

        try {

            if(speciality.id) {
                await db.collection("clients").doc(clientId)
                        .collection("locations").doc(locationId)
                        .collection("specialities").doc(speciality.id)
                        .set(speciality.toJSON(), { merge: true });

                // update cache
                SpecialitiesService.cache[speciality.id] = speciality;

                return speciality.id;

            } else {
                // create a new Speciality
                const docRef = await db.collection("clients").doc(clientId)
                                        .collection("locations").doc(locationId)
                                        .collection("specialities")
                                        .add(speciality.toJSON());

                // update cache
                SpecialitiesService.cache[docRef.id] = speciality;

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating speciality: ", error);
        };

        return null;
    },

    async deleteSpeciality(specialityId: string, clientId: string, locationId: string): Promise<void> {

        try {
            const documentPath = `clients/${clientId}/locations/${locationId}/specialities/${specialityId}`;
            await db.doc(documentPath).delete();

        } catch(error) {
            console.log("Error deleting speciality: ", error);
        };
    },


    saveSpecialitiesSelection(specialities: Speciality[]){
        if(localStorage){
            const selectedSpecialities: string[] = [];
            const selectedDashboardSpecialities: string[] = [];
            const selectedCampaignSpecialities: string[] = [];

            for (let i = 0; i < specialities.length; i++) {
                const spe = specialities[i];
                if(spe.selected){
                    selectedSpecialities.push(spe.id);
                }
                if(spe.selectedOnDashboard){
                    selectedDashboardSpecialities.push(spe.id);
                }
                if(spe.selectedOnCampaign){
                    selectedCampaignSpecialities.push(spe.id);
                }
            }

            localStorage.setItem("selectedSpecialities", JSON.stringify(selectedSpecialities));
            localStorage.setItem("selectedDashboardSpecialities", JSON.stringify(selectedDashboardSpecialities));
            localStorage.setItem("selectedCampaignSpecialities", JSON.stringify(selectedCampaignSpecialities));
        }
    },

    restoreSpecialitiessSelection(specialities: Speciality[]): Speciality[] {

        let selectionCounter = 0;
        let dashboardSelectionCounter = 0;
        let campaignSelectionCounter = 0;

        try{
            if(localStorage){
                const selectedSpecialitiesString = localStorage.getItem("selectedSpecialities");
                const selectedDashboardSpecialitiesString = localStorage.getItem("selectedDashboardSpecialities");
                const selectedCampaignSpecialitiesString = localStorage.getItem("selectedCampaignSpecialities");

                if(selectedSpecialitiesString){
                    for (let i = 0; i < specialities.length; i++) {
                        const spe = specialities[i];
                        spe.selected = selectedSpecialitiesString.indexOf(spe.id) !== -1;

                        if(spe.selected) {
                            selectionCounter++;
                        }
                    }
                }

                if(selectedDashboardSpecialitiesString){
                    for (let i = 0; i < specialities.length; i++) {
                        const spe = specialities[i];
                        spe.selectedOnDashboard = selectedDashboardSpecialitiesString.indexOf(spe.id) !== -1;

                        if(spe.selectedOnDashboard) {
                            dashboardSelectionCounter++;
                        }
                    }
                }

                if(selectedCampaignSpecialitiesString){
                    for (let i = 0; i < specialities.length; i++) {
                        const spe = specialities[i];
                        spe.selectedOnCampaign = selectedCampaignSpecialitiesString.indexOf(spe.id) !== -1;

                        if(spe.selectedOnCampaign) {
                            campaignSelectionCounter++;
                        }
                    }
                }
            }
        } catch(error){
            console.log(error);
        }

        // if localstorage does not contain any selection then select all
        if(selectionCounter === 0) {
            for (let i = 0; i < specialities.length; i++) {
                const cal = specialities[i];
                cal.selected = true;
            }
        }
        if(dashboardSelectionCounter === 0) {
            for (let i = 0; i < specialities.length; i++) {
                const cal = specialities[i];
                cal.selectedOnDashboard = true;
            }
        }
        if(campaignSelectionCounter === 0) {
            for (let i = 0; i < specialities.length; i++) {
                const cal = specialities[i];
                cal.selectedOnDashboard = true;
            }
        }

        return specialities;
    }

}

export default SpecialitiesService;