import { useState, useEffect, useContext, Fragment } from 'react';
import { Link, useParams } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';

import VisitMotivesService from '../../../../services/visitMotivesService';
import VisitMotive from '../../../../models/visitMotive';

import Speciality from '../../../../models/speciality';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import DateUtils from "../../../../shared/src/utils/dateUtils";
import { updateRowPosition } from '../../../../utils';
import SmsWorkflowPreviewDialog from '../../../dialogs/smsWorkflowPreviewDialog';
import { GlobalContext } from '../../../../GlobalContext';


function VisitMotivesPage() {

    const { specialityId } = useParams();

    const [visitMotives, setVisitMotives] = useState<VisitMotive[]>([]);
    const [filteredVisitMotives, setFilteredVisitMotives] = useState([] as VisitMotive[]);

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);
    const {specialities} = useContext(GlobalContext);
    const [selectedSpeciality, setSelectedSpeciality] = useState(new Speciality());
    const [selectedVisitiMotiveId, setSelectedVisitMotiveId] = useState("");

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        if (specialities && specialities.length > 0) {

            let selectedSpec: null | Speciality = null;

            for (let i = 0; i < specialities.length; i++) {
                const speciality = specialities[i];
                if (specialityId === speciality.id) {
                    selectedSpec = speciality;
                }
            }

            if (selectedSpec) {
                setSelectedSpeciality(selectedSpec);
            } else {
                setSelectedSpeciality(specialities[0]);
            }

        }
    }, [specialities, specialityId]);

    useEffect(() => {

        if (currentClient && specialities) {
            updateVisitMotives();
        }

        // eslint-disable-next-line
    }, [currentClient, specialities]);

    useEffect(() => {
        if (visitMotives && selectedSpeciality) {
            const filteredItems = visitMotives.filter((visitMotive) => visitMotive.specialityId === selectedSpeciality.id);
            setFilteredVisitMotives(filteredItems);
        }
    }, [visitMotives, selectedSpeciality]);

    async function updateVisitMotives() {
        setIsLoading(true);

        try {
            const result = await VisitMotivesService.getVisitMotives(currentClient.id, currentUser?.locationId);

            setIsLoading(false);
            if (result) {
                for (let i = 0; i < result.length; i++) {
                    const visitMotive = result[i];
                    visitMotive.setColorFromSpeciality(specialities);
                }
                setVisitMotives(result);
            }

        } catch (error) {
            setIsLoading(false);
        }
    }

    function formatDuration(minutes: number): string {
        if (minutes !== null && minutes !== undefined) {
            if (minutes < 60) {
                return `${minutes} Minuten`;
            } else {

                const hours = Math.floor(minutes / 60);
                const min = Math.floor((minutes % 60));

                if (min === 0) {
                    return `${hours} ${hours === 1 ? "Stunde" : "Stunden"}`;
                } else {
                    return `${Math.floor(minutes / 60)} Std. ${min} Min.`;
                }

            }

        }

        return "";
    }

    function formatRecurrenceCount(count: number): string {
        if (count !== null && count !== undefined) {
            switch (count) {
                case -1:
                    return "\u221E";

                case 0:
                    return "";

                default:
                    if (count > 0) {
                        return `${count}`;
                    }
            }
        }

        return "";
    }

    function getRecall(recurrenceCount: number, recallId: string): string {

        if (recurrenceCount !== 0) {
            const recall = visitMotives.find(v => v.id === recallId);

            if (recall) {
                return recall.name;
            }
        }

        return "";
    }

    function getSuccessor(enabled: boolean, successorId: string): string {

        if (enabled) {
            const successor = visitMotives.find(v => v.id === successorId);

            if (successor) {
                return successor.name;
            }
        }

        return "";
    }

    function renderNameCell(row) {
        const errors = (row as VisitMotive).getErrors();

        if (errors.length > 0) {
            let errorText = "";
            for (let i = 0; i < errors.length; i++) {
                const err = errors[i];
                errorText += err + " ";
            }

            return (
                <Tooltip title={errorText}>
                    <Link to={`/settings/visitMotive/${row.id}`} className="kt-error-color">{row.name}</Link>
                </Tooltip>
            );
        }

        return <Link to={`/settings/visitMotive/${row.id}`}>{row.name}</Link>;
    }

    const columns: any[] = [
        {
            field: "color", headerName: " ", width: 50, renderCell: (params) => (
                <div className="kt-table-color-box" style={{ backgroundColor: `${params.row.color}` }}> </div>
            )
        },
        {
            field: 'name', headerName: 'Besuchsgrund', width: 300, renderCell: (params) => (
                renderNameCell(params.row)
            )
        },
        {
            field: 'duration', headerName: 'Dauer', width: 120, renderCell: (params) => (
                <span>{formatDuration(params.row.duration)}</span>
            )
        },
        {
            field: 'allowOnlineBooking', headerName: 'Online buchbar', width: 120, renderCell: (params) => (
                params.row.allowOnlineBooking ? <CheckRoundedIcon /> : <Fragment />
            )
        },
        {
            field: 'recall', headerName: 'Recalltermin', width: 200, renderCell: (params) => (
                <span>{getRecall(params.row.recurrenceCount, params.row.recallId)}</span>
            )
        },
        {
            field: 'recurrenceCount', headerName: 'Recallanzahl', width: 200, renderCell: (params) => (
                <span>{formatRecurrenceCount(params.row.recurrenceCount)}</span>
            )
        },
        {
            field: 'recurrenceInterval', headerName: 'Recallintervall', width: 120, renderCell: (params) => (
                params.row.recurrenceCount !== 0 ? <span>{DateUtils.formatInterval(params.row.recurrenceInterval)}</span> : <Fragment />
            )
        },
        {
            field: 'successorEnabled', headerName: 'Folgetermin', width: 200, renderCell: (params) => (
                <span>{getSuccessor(params.row.successorEnabled, params.row.successorId)}</span>
            )
        },
        {
            field: 'successorInterval', headerName: 'Nach', width: 100, renderCell: (params) => (
                <span>{params.row.successorEnabled && DateUtils.formatInterval(params.row.successorInterval)}</span>
            )
        },
        {
            field: 'delete', headerName: " ", width: 64, renderCell: (params) => (
                <IconButton onClick={(e) => onDeleteRowClick(params.row)}>
                    <DeleteIcon />
                </IconButton>)
        },
        {
            field: 'preview', headerName: " ", width: 64, renderCell: (params) => (
                <IconButton onClick={(e) => onPreviewRowClick(params.row)}>
                    <VisibilityIcon />
                </IconButton>)
        }
    ];


    async function onDeleteRowClick(row) {
        const reallyDelete = window.confirm(`Wollen Sie den Besuchsgrund ${row.name} wirklich löschen?`);
        if (reallyDelete) {
            await VisitMotivesService.deleteVisitMotive(row.id, currentClient.id, currentUser?.locationId);
            updateVisitMotives();
        }
    }

    async function onPreviewRowClick(row) {

        setSelectedVisitMotiveId(row.id);
        setIsDialogOpen(true);

    }

    const handleRowOrderChange = async (params) => {
        setIsLoading(true);
        const newRows = updateRowPosition<VisitMotive>(
            params.oldIndex,
            params.targetIndex,
            filteredVisitMotives,
        );

        for (let i = 0; i < newRows.length; i++) {
            const e = newRows[i];
            e.cardinality = i + 1;

            VisitMotivesService.updateVisitMotive(e, currentClient.id, currentUser!.locationId);
        }
        setFilteredVisitMotives(newRows);
        setIsLoading(false);
    };

    return (
        <>
            <SmsWorkflowPreviewDialog
                visible={isDialogOpen}
                visitMotiveId={selectedVisitiMotiveId}
                onClose={() => setIsDialogOpen(false)}
            />

            <Box className="kt-page">
                <h2>{`Besuchsgründe - ${selectedSpeciality.name}`}</h2>

                <Grid container>
                    <Grid item>
                        <ButtonGroup>
                            <Button component={Link} to={`/settings/visitMotive/create/${selectedSpeciality.id}`} startIcon={<AddCircleOutlineIcon />}>
                                Besuchsgrund anlegen
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>


                <div className="kt-tabs">
                    {specialities && specialities.map((speciality) =>
                        <Link
                            to={`/settings/visitMotives/${speciality.id}`}
                            key={speciality.id}
                            className={speciality.id === selectedSpeciality.id ? "kt-tab kt-selected" : "kt-tab"}
                        >
                            {speciality.shortName}
                        </Link>
                    )}
                </div>


                <Box className="kt-data-grid-container">
                    <DataGridPro
                        loading={isLoading}
                        rows={filteredVisitMotives}
                        columns={columns}
                        rowReordering
                        onRowOrderChange={handleRowOrderChange}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Box>

            </Box>

        </>

    );
}

export default VisitMotivesPage;