import database from "./../components/database";
import Message from "../models/message";

const db = database.firestore();

const MessagesService = {

    async addMessage(message: Message): Promise<string | null> {

        try {

            const docRef = await db.collection("messages")
                                    .add(message.toJSON());

            return docRef.id;    

        } catch(error){
            console.error("Error adding message");

            return null;
        }
    },


    async deleteMessage(messageId: string): Promise<void> {

        try {
            const documentPath = `messages/${messageId}`;
            await db.doc(documentPath).delete();
                
        } catch(error) {
            console.log("Error deleting message: ", error);
        };
    },

    startListenForMessages(changeCallback: (messages: Message[])=>void): () => void {

        return db.collection("messages")            
            .onSnapshot(function(querySnapshot) {
                const messageList: Message[] = [];

                querySnapshot.forEach((doc) => {
                    const message = new Message();
                    message.fromObject(doc.id, doc.data());

                    messageList.push(message);
                });

                changeCallback(messageList);
            });
    }
    
}

export default MessagesService;