import OpeningHours from "./openingHours";
import ClientLocation from "./clientLocation";
import Calendar from "./calendar";
import DateUtils from "../shared/src/utils/dateUtils";

class Client {
    id: string = "";
    name: string = "";

    locations: ClientLocation[] = [];

    street: string = "";
    city: string = "de";
    postalCode: string = "";
    state: string = "";
    country: string = "";

    phoneNumber: string = "";
    faxNumber: string = "";
    homepage: string = "";
    hasElevator: boolean = false;
    floor: number = 0;
    handicapFriendly: boolean = false;
    languages: string[] = [];
    features: {
        hasRecaller: boolean;
        hasSmsCockpit: boolean;
        hasStaffRostering: boolean;
        hasVacationScheduler: boolean;
        hasToDo: boolean;
        hasDashboard: boolean;
    };
    isEnabled: boolean = true;
    profile: string = "";
    accessInformation: string[] = [];
    publicTransports: string[] = [];
    userIds: string[] = [];
    openingHours: OpeningHours = new OpeningHours();

    stripeCustomerId: string = "";
    credits: number = 0;

    createdAt: Date | null = new Date();

    constructor() {
        this.features = {
            hasRecaller: true,
            hasSmsCockpit: true,
            hasStaffRostering: true,
            hasVacationScheduler: true,
            hasToDo: true,
            hasDashboard: true
        };

    }

    clone(): Client {
        const c = new Client();

        c.id = this.id;
        c.name = this.name;

        for (let i = 0; i < this.locations.length; i++) {
            const element = this.locations[i].clone();
            c.locations.push(element);
        }

        c.street = this.street;
        c.city = this.city
        c.postalCode = this.postalCode;
        c.state = this.state;
        c.country = this.country;

        c.phoneNumber = this.phoneNumber;
        c.faxNumber = this.faxNumber;
        c.homepage = this.homepage;
        c.hasElevator = this.hasElevator === true;
        c.floor = this.floor;
        c.handicapFriendly = this.handicapFriendly === true;
        c.languages = [...this.languages];
        c.features = {
            hasRecaller: this.features.hasRecaller === true,
            hasSmsCockpit: this.features.hasSmsCockpit === true,
            hasStaffRostering: this.features.hasStaffRostering === true,
            hasVacationScheduler: this.features.hasVacationScheduler === true,
            hasToDo: this.features.hasToDo === true,
            hasDashboard: this.features.hasDashboard === true
        }
        c.isEnabled = this.isEnabled === true;
        c.profile = this.profile;
        c.accessInformation = [...this.accessInformation];
        c.publicTransports = [...this.publicTransports];
        c.userIds = [...this.userIds];
        c.openingHours = this.openingHours.clone();

        c.stripeCustomerId = this.stripeCustomerId;
        c.credits = this.credits;

        c.createdAt = this.createdAt;

        return c;
    }

    toJSON(): object {

        const clientLocations: any[] = [];

        for (let i = 0; i < this.locations.length; i++) {
            const element = this.locations[i];
            clientLocations.push(element.toJSON());
        }

        return {
            id: this.id,
            name: this.name,

            locations: clientLocations,

            street: this.street,
            city: this.city,
            postalCode: this.postalCode,
            state: this.state,
            country: this.country,

            phoneNumber: this.phoneNumber,
            faxNumber: this.faxNumber,
            homepage: this.homepage,
            hasElevator: this.hasElevator === true,
            floor: this.floor,
            handicapFriendly: this.handicapFriendly === true,
            languages: this.languages,
            features: {
                hasRecaller: this.features.hasRecaller === true,
                hasSmsCockpit: this.features.hasSmsCockpit === true,
                hasStaffRostering: this.features.hasStaffRostering === true,
                hasVacationScheduler: this.features.hasVacationScheduler === true,
                hasToDo: this.features.hasToDo === true,
                hasDashboard: this.features.hasDashboard === true
            },
            isEnabled: this.isEnabled === true,
            profile: this.profile,
            accessInformation: this.accessInformation,
            publicTransports: this.publicTransports,
            userIds: this.userIds,
            openingHours: this.openingHours.toJSON(),
            stripeCustomerId: this.stripeCustomerId,
            credits: this.credits,
            createdAt: this.createdAt,
        };
    }

    fromObject(id:string|null, o:any) {

        this.id = id !== null ? id : o.id;

        this.name = o.name ? o.name : "";

        this.locations = [];
        if(o.locations){
            for (let i = 0; i < o.locations.length; i++) {
                const element = o.locations[i];
                const newLocation = new ClientLocation();
                newLocation.fromObject(null, element);

                this.locations.push(newLocation);
            }
        }

        this.street = o.street ? o.street : "";
        this.city = o.city ? o.city : "";
        this.postalCode = o.postalCode ? o.postalCode : "";
        this.state = o.state ? o.state : "";
        this.country = o.country ? o.country : "de";

        this.phoneNumber = o.phoneNumber ? o.phoneNumber : "";
        this.faxNumber = o.faxNumber ? o.faxNumber : "";
        this.homepage = o.homepage ? o.homepage : "";
        this.hasElevator = o.hasElevator === true;
        this.floor = o.floor ? o.floor : 0;
        this.handicapFriendly = o.handicapFriendly === true;
        this.languages = o.languages ? o.languages : [];

        if(o.features){
            this.features = {
                hasRecaller: o.features.hasRecaller === true,
                hasSmsCockpit: o.features.hasSmsCockpit === true,
                hasStaffRostering: o.features.hasStaffRostering === true,
                hasVacationScheduler: o.features.hasVacationScheduler === true,
                hasToDo: o.features.hasToDo === true,
                hasDashboard: o.features.hasDashboard === true
            };
        } else {
            this.features = {
                hasRecaller: false,
                hasSmsCockpit: false,
                hasStaffRostering: false,
                hasVacationScheduler: false,
                hasToDo: false,
                hasDashboard: false
            };
        }

        this.isEnabled = o.isEnabled === true;
        this.profile = o.profile ? o.profile : "";
        this.accessInformation = o.accessInformation ? o.accessInformation : [];
        this.publicTransports = o.publicTransports ? o.publicTransports : [];
        this.userIds = o.userIds ? o.userIds : [];

        if(o.openingHours){
            this.openingHours.fromObject(o.openingHours);
        } else {
            this.openingHours = new OpeningHours();
        }

        this.stripeCustomerId = o.stripeCustomerId ? o.stripeCustomerId : "";

        this.credits = o.credits ? o.credits : 0;

        this.createdAt = DateUtils.getDate(o.createdAt);
    }

    static hasVideoClonR(_calendars: Calendar[]): boolean {
        return _calendars.some(calendar => (!calendar.isDeleted && calendar.license === "premium"));
    }
};


export default Client;

