
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import CloseIcon from "@mui/icons-material/Close";
import React, { useRef } from 'react';

import SignatureCanvas from 'react-signature-canvas'


interface Props {
    visible: boolean;
    title: string;
    label: string;
    onSave: (signature: string) => void;
    onClose: () => void;
}

const SignatureDialog: React.FC<Props> = ({ visible, title, label, onClose, onSave }) => {

    const signaturePadRef = useRef();

    function handleSave() {
        if (signaturePadRef.current) {
            const signaImage = (signaturePadRef.current as any).toDataURL('image/png');
            onSave(signaImage);
        }
    }

    function handleClose() {
        onClose();
    }

    // clears the canvas
    function handleClearSignature() {
        if (signaturePadRef.current) {
            (signaturePadRef.current as any).clear();
        }
    }

    return (
        <Dialog
            maxWidth="lg"
            aria-labelledby="customized-dialog-title"
            open={visible}
            className="kt-signature-dialog"
        >
            <DialogTitle id="customized-dialog-title">
                {title}
            </DialogTitle>

            <IconButton
                edge="end"
                color="inherit"
                onClick={() => handleClose()}
                aria-label="close"
                style={{
                    position: 'absolute',
                    right: 20,
                    top: 8
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent
                dividers
            >
                <span>{label}</span>

                <div className='kt-signature-wrapper'>
                    <SignatureCanvas
                        ref={(ref) => { signaturePadRef.current = ref }}
                        penColor='#000099'
                        clearOnResize={false}
                        canvasProps={{
                            className: 'kt-signature'
                        }}
                    />
                </div>

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClearSignature}>Löschen</Button>
                <Button onClick={handleSave}>Speichern</Button>
            </DialogActions>

        </Dialog>

    );
}

export default SignatureDialog;
