import React, { useContext, useState } from 'react';
import Calendar, { CalendarLicense } from '../models/calendar';
import ProgressDialog from './dialogs/progressDialog';
import { GlobalContext } from '../GlobalContext';
import PaymentsService from '../services/paymentsService';
import CalendarSelectorDlg from './dialogs/calendarSelectorDlg';


interface Props {
    calendar: Calendar,
    license: CalendarLicense,
    price: number,
    description: string,
    features: string,
    dropshadow?: boolean,
    onError: () => void
}

const LicenseCard: React.FC<Props> = ({ calendar, license, price, description, features, dropshadow, onError }) => {

    const { currentUser, currentClient, calendars } = useContext(GlobalContext);

    const [isMessageDialogVisible, setIsMessageDialogVisible] = useState(false);
    const [isUpdateMessageDialogVisible, setIsUpdateMessageDialogVisible] = useState(false);
    const [isCalendarSelectorDlgVisible, setIsCalendarSelectorDlgVisible] = useState(false);

    function LicenseButton(license: CalendarLicense): JSX.Element {
        const clientHasAFreeCalendar = (calendars as Calendar[]).find(cal => cal.license === "free") !== undefined;

        switch (license) {
            case calendar.license:
                return <a className="kt-active-license"><i className='fa fa-check'></i>&nbsp;Ihre aktuelle Lizens</a>

            case "free":
                if (clientHasAFreeCalendar) {
                    return <a className="kt-no-free-license">Sie besitzen schon eine kostenlose Lizens</a>
                } else {
                    return <a onClick={() => handleOnButtonClick(license)}>Bestellen</a>
                }

            default:
                return <a onClick={() => handleOnButtonClick(license)}>Bestellen</a>
        }
    }

    function handleOnButtonClick(license: CalendarLicense) {
        // check if we need to select a calendar
        if(!calendar.id) {
            // first check if we have only one calendar, if not then show the calendar selector dialog
            if(calendars.length > 1){
                setIsCalendarSelectorDlgVisible(true);
            } else if (calendars.length === 1) {
                const cal = calendars[0];
                addOrUpdateNewLicense(cal, license);
            }
            
        } else {
            addOrUpdateNewLicense(calendar, license);
        }
    }


    async function addOrUpdateNewLicense(_calendar: Calendar, license: CalendarLicense) {

        try {

            if (_calendar.subscriptionId) {
                // updgrade or downgrade subscripton
                setIsUpdateMessageDialogVisible(true);

                await PaymentsService.updateStripeSubscription(_calendar, license, currentClient.id, currentUser.id)

                window.location.href = "/settings/calendars";

            } else {
                // create a new subscripton
                setIsMessageDialogVisible(true);

                const sessionUrl = await PaymentsService.createStripeSubscriptionCheckout(_calendar, license, currentClient.id, currentUser.id);
                window.location.href = sessionUrl;
            }

        } catch (error) {
            setIsMessageDialogVisible(false);
            setIsUpdateMessageDialogVisible(false);

            console.error(error);

            onError();
        }
    }


    return (

        <>
            <ProgressDialog
                visible={isMessageDialogVisible}
                title='Weiterleitung zum Zahlungsprovider'
                message={
                    <div style={{ textAlign: "center" }}>
                        <div style={{ fontSize: "40px" }}><i className='fal fa-shopping-cart'></i></div>
                        <h3>Sie werden nun zu unserem Zahlungsprovider umgeleitet...</h3>
                    </div>
                }
            />

            <ProgressDialog
                visible={isUpdateMessageDialogVisible}
                title='Ihr Abonnement wird angepasst'
                message={
                    <div style={{ textAlign: "center" }}>
                        <div style={{ fontSize: "40px" }}><i className='fal fa-shopping-cart'></i></div>
                        <h3>Wir richten nun die neue Lizens für Ihren Kalender ein...</h3>
                    </div>
                }
            />

            <CalendarSelectorDlg
                visible={isCalendarSelectorDlgVisible}
                title='Kalender auswählen'
                message='Wählen Sie den Kalender aus, für den Sie die Premium-Lizenz erwerben möchten.'
                onClose={() => setIsCalendarSelectorDlgVisible(false)}
                onCalendarSelect={(_calendar) => {
                    setIsCalendarSelectorDlgVisible(false);
                    addOrUpdateNewLicense(_calendar, license);
                }}
            />

            <div className={`kt-license-card ${license !== "free" && "active"} ${dropshadow && "kt-dropshadow"}`} style={{ borderRadius: "15px" }}>

                <div className="generic_head_price">

                    <div className="generic_head_content">

                        <div className="head_bg"></div>
                        <div className="head">
                            <span>{Calendar.getLicenseDisplayName(license)} {license === "premium" && <i className='fa fa-star'></i>}</span>
                        </div>

                    </div>

                    <div className="generic_price_tag">
                        <span className="price">
                            <span className="sign">€</span>
                            <span className="currency">{price}</span>

                            <span className="month">/MONAT</span>
                        </span>
                    </div>

                </div>

                <div className="kt-description">
                    <h6 style={{ paddingLeft: "20px", paddingRight: "20px", textAlign: "justify", color: "#8b8b8b" }}>
                        {description}
                    </h6>
                </div>

                <div className="kt-price-btn">
                    {LicenseButton(license)}
                </div>

                <div className="generic_feature_list">
                    <h6 style={{ paddingLeft: "20px", paddingRight: "10px", textAlign: "left", fontWeight: 800 }}>beinhaltet:</h6>
                    <h6 style={{ paddingLeft: "40px", paddingRight: "20px", textAlign: "left", fontWeight: 200, color: "#8b8b8b" }}>
                        {features}
                    </h6>
                </div>
            </div>

        </>
    );
}

export default LicenseCard;