import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";

import { logDbReadOperations } from './../utils';
import PatientsService from "./patientsService";
import Appointment, { PatientStatus } from "../models/appointment";
import Patient from "../models/patient";
import Client from "../models/client";
import VisitMotivesService from "./visitMotivesService";
import CalendarsService from "./calendarsService";
import VideoRoomsService from "./videoRoomsService";
import NotificationsService from "./notificationsService";

import moment from "moment";
import RatingsService from "./ratingsService";
import PublicHolidaysService from "./publicHolidaysService";
import PublicHoliday from "../models/publicHoliday";
import SpecialitiesService from "./specialitiesService";
import DateUtils from "../../src/shared/src/utils/dateUtils";
import UsersService from "./usersService";
import ClientLocationsService from "./clientLocationsService";
import LoggingService from "./loggingService";
import { LogItemType } from "../../src/shared/src/models/logItem";
import ClientLocation from '../models/clientLocation';


const db = firebaseApp.firestore();
const functions = firebase.app().functions('europe-west3');

export enum EventType {
    create,
    update,
    delete
}

const AppointmentsService = {

    cache: {} as { [key: string]: Appointment },

    async getAppointmentsByDate(fromDate: Date, toDate: Date, clientId: string, locationId: string, calendarIds: string[] | null, visitMotiveFilterId?: string): Promise<Appointment[] | null> {

        try {
            const _fromDate = new Date(fromDate);
            const _toDate = new Date(toDate);

            _fromDate.setHours(0, 0, 0);
            _toDate.setHours(23, 59, 59);

            console.log(_fromDate.toString() + " " + _toDate.toString());

            let query = db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("appointments")
                .orderBy("start")
                .where("start", ">=", _fromDate)
                .where("start", "<=", _toDate);


            if (calendarIds && calendarIds.length > 0 && calendarIds[0] !== "all" && calendarIds[0] !== "") {
                query = query.where("calendar.id", "in", calendarIds);
            }

            if(visitMotiveFilterId && visitMotiveFilterId !== "all") {
                query = query.where("visitMotive.id", "==", visitMotiveFilterId);
            }

            const querySnapshot = await query.get();

            const appointmentList: Appointment[] = [];

            querySnapshot.forEach((doc) => {
                const appointment = new Appointment();
                appointment.fromObject(doc.id, doc.data());
                appointmentList.push(appointment);
            });

            logDbReadOperations("getAppointmentsByDate", appointmentList.length);

            return appointmentList;

        } catch (error) {
            console.log(`error in getAppointmentsByDate: ${error}`);
            return null;
        }

    },


    startListenForAppointments(fromDate: Date, toDate: Date, clientId: string, locationId: string, changeCallback: (appointments: Appointment[]) => void): () => void {

        if (!fromDate || !toDate || !clientId || !locationId) {
            return () => {
                console.log("error in startListenForAppointments: empty paramter");
            };
        }

        return db.collection("clients")
            .doc(clientId)
            .collection("locations")
            .doc(locationId)
            .collection("appointments")
            .orderBy("start")
            .where("start", ">=", fromDate)
            .where("start", "<=", toDate)
            .onSnapshot(function (querySnapshot) {
                const appointmentList: Appointment[] = [];

                querySnapshot.forEach((doc) => {
                    const appointment = new Appointment();
                    appointment.fromObject(doc.id, doc.data());

                    // appointments without a patient are temporary appointments
                    // created in the booking process and the will be reserved for some time
                    // until the patient confirms his appointment with his login
                    // or this are "absence" appointments
                    if (((appointment.patient && appointment.patient.id !== "") || appointment.calendarItemType === "absence") && !appointment.isMultiDay) {
                        appointmentList.push(appointment);
                        AppointmentsService.cache[appointment.id] = appointment;
                    }
                });

                logDbReadOperations("startListenForAppointments", appointmentList.length);

                changeCallback(appointmentList);
            });
    },

    // we need to listen also to those appointments which have their start date before and their end date after our search date range
    // otherwise for example a 3 weeks holiday appointment is not visible in the calendar
    startListenForMultiDayAppointments(fromDate: Date, toDate: Date, clientId: string, locationId: string, changeCallback: (appointments: Appointment[]) => void): () => void {

        if (!fromDate || !toDate || !clientId || !locationId) {
            return () => {
                console.log("error in startListenForMultiDayAppointments: empty paramter");
            };
        }

        return db.collection("clients")
            .doc(clientId)
            .collection("locations")
            .doc(locationId)
            .collection("appointments")
            .orderBy("end")
            .where("isMultiDay", "==", true)
            .where("end", ">=", fromDate)
            .onSnapshot(function (querySnapshot) {
                const appointmentList: Appointment[] = [];

                querySnapshot.forEach((doc) => {
                    const appointment = new Appointment();
                    appointment.fromObject(doc.id, doc.data());

                    // appointments without a patient are temporary appointments
                    // created in the booking process and the will be reserved for some time
                    // until the patient confirms his appointment with his login
                    // or this are "absence" appointments
                    if ((appointment.patient && appointment.patient.id !== "") || appointment.calendarItemType === "absence") {
                        appointmentList.push(appointment);
                        AppointmentsService.cache[appointment.id] = appointment;
                    }
                });

                logDbReadOperations("startListenForMultiDayAppointments", appointmentList.length);

                changeCallback(appointmentList);
            });
    },

    async getAppointment(appointmentId: string, clientId: string, locationId: string, ignoreCache: boolean = false): Promise<Appointment | null> {

        try {
            if (!ignoreCache && AppointmentsService.cache[appointmentId]) {
                return AppointmentsService.cache[appointmentId];
            }

            const doc = await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("appointments")
                .doc(appointmentId)
                .get();

            if (doc.exists) {
                const appointment = new Appointment();
                appointment.fromObject(appointmentId, doc.data());

                logDbReadOperations("getAppointment", 1);

                return appointment;

            } else {
                console.log("getAppointment: No such document: " + appointmentId);
                return null;
            }

        } catch (error) {
            console.log(`error in getAppointment: ${error}`);
            return null;
        }
    },

    async getAppointments(appointmentIds: string[], clientId: string, locationId: string): Promise<(Appointment | null)[]> {
        const promises: Promise<Appointment | null>[] = [];

        appointmentIds.forEach(id => {
            promises.push(AppointmentsService.getAppointment(id, clientId, locationId));
        });

        return Promise.all(promises);
    },

    async getAppointmentsByPatientId(patientId: string, clientId: string, locationId: string): Promise<(Appointment[] | null)> {
        try {

            let query = db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("appointments")
                .orderBy("start")
                .where("patient.id", "==", patientId);


            const querySnapshot = await query.get();

            const appointmentList: Appointment[] = [];

            querySnapshot.forEach((doc) => {
                const appointment = new Appointment();
                appointment.fromObject(doc.id, doc.data());
                appointmentList.push(appointment);
            });

            logDbReadOperations("getAppointmentsByPatientId", appointmentList.length);

            return appointmentList;

        } catch (error) {
            console.log(`error in getAppointmentsByPatientId: ${error}`);
            return null;
        }
    },

    // gets the latest appointment where the patient was treated
    async getLatestTreatedAppointmentByPatientId(patientId: string, clientId: string, locationId: string): Promise<(Appointment | null)> {
        try {

            let query = db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("appointments")
                .orderBy("start", "desc")
                .where("patient.id", "==", patientId)
                .where("patientStatus", "==", PatientStatus.treated);


            const querySnapshot = await query.get();

            if(!querySnapshot.empty) {
                const doc = querySnapshot.docs[0];
                const appointment = new Appointment();
                appointment.fromObject(doc.id, doc.data());

                return appointment;
            }

        } catch (error) {
            console.log(`error in getLatestTreatedAppointmentByPatientId: ${error}`);
        }

        return null;
    },

    async getAppointmentsByDateRange(fromDate: Date, toDate: Date, clientId: string, locationId: string, patientId?: string): Promise<(Appointment[] | null)> {
        try {

            let query = db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("appointments")
                .orderBy("start")
                .where("start", ">=", fromDate)
                .where("start", "<=", toDate);

            if(patientId !== undefined) {
                query = query.where("patient.id", "==", patientId);
            }


            const querySnapshot = await query.get();

            const appointmentList: Appointment[] = [];

            querySnapshot.forEach((doc) => {
                const appointment = new Appointment();
                appointment.fromObject(doc.id, doc.data());
                appointmentList.push(appointment);
            });

            logDbReadOperations("getAppointmentsByPatientDateRange", appointmentList.length);

            return appointmentList;

        } catch (error) {
            console.log(`error in getAppointmentsByPatientDateRange: ${error}`);
            return null;
        }
    },

    async getAppointmentsByDateRangeAndCalendar(fromDate: Date, toDate: Date, userId: string, clientId: string, locationId: string): Promise<(Appointment[] | null)> {
        try {

            let query = db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("appointments")
                .orderBy("start")
                .where("start", ">=", fromDate)
                .where("start", "<=", toDate)
                .where("resourceId", "==", userId);


            const querySnapshot = await query.get();

            const appointmentList: Appointment[] = [];

            querySnapshot.forEach((doc) => {
                const appointment = new Appointment();
                appointment.fromObject(doc.id, doc.data());
                appointmentList.push(appointment);
            });

            logDbReadOperations("getAppointmentsByDateRangeAndUser", appointmentList.length);

            return appointmentList;

        } catch (error) {
            console.log(`error in getAppointmentsByDateRangeAndUser: ${error}`);
            return null;
        }
    },

    async updateAppointment(appointment: Appointment, patient: Patient, client: Client, clientLocation: ClientLocation, userId: string, updateChainItems?: boolean, newAppointmentId?: string, onAppointmentUpdated?: (eventType: EventType, newAppointment: Appointment) => void): Promise<string | null> {

        try {

            const calendar = await CalendarsService.getCalendar(appointment.clientId, appointment.locationId, appointment.calendar.id);

            appointment.calendar = calendar ? calendar : appointment.calendar;
            appointment.resourceId = (appointment.calendar && appointment.calendar.id) ? appointment.calendar.id : "";

            appointment.documentsExpireAt = patient.documentsExpireAt;

            if (appointment.patient && patient && appointment.calendarItemType !== "absence") {
                appointment.patient.newPatient = patient.newPatient;
            }


            if (appointment.id) {
                // update existing appointment

                // check if start date/time has changed, only then resend a reminder
                let sendReminderAgain = false;
                
                if (appointment.calendarItemType !== "absence") {
                    // first load the old appointment
                    const oldAppointment = await AppointmentsService.getAppointment(appointment.id, client.id, appointment.locationId, false);
                    if (oldAppointment) {
                        sendReminderAgain = oldAppointment.start.getTime() !== appointment.start.getTime();

                        if(sendReminderAgain){
                            const message = `${appointment.getTitle()} für ${appointment.patient.lastName} vom ${DateUtils.getDateTimeString(oldAppointment.start)} auf den ${DateUtils.getDateTimeString(appointment.start)} verschoben`;
                            LoggingService.log(LogItemType.userAction, appointment.id, "", appointment.patient.id, appointment.calendar.userId, userId, message, appointment.clientId, appointment.locationId);
                        }

                        // if status changed to treated, send rating request to patient
                        if (oldAppointment.patientStatus !== appointment.patientStatus && appointment.patientStatus === PatientStatus.treated && !calendar?.internal) {
                            RatingsService.createRatingRequest(appointment, client);
                        }

                        // if patient treated, check if we have to create recalls or follow up appointments
                        if (oldAppointment.patientStatus !== appointment.patientStatus && appointment.patientStatus === PatientStatus.treated) {
                            if (appointment.recallId === "") {
                                await AppointmentsService.updateRecalls(appointment, patient, client, clientLocation, userId, onAppointmentUpdated);
                            }

                            if (appointment.successorId === "") {
                                await AppointmentsService.updateSuccessors(appointment, patient, client, clientLocation, userId, onAppointmentUpdated);
                            }
                        }
                    }
                }


                await db.collection("clients")
                    .doc(client.id)
                    .collection("locations")
                    .doc(appointment.locationId)
                    .collection("appointments")
                    .doc(appointment.id)
                    .set(appointment.toJSON(), { merge: true });


                if (sendReminderAgain && !calendar?.internal) {
                    NotificationsService.updateReminders(appointment, patient, clientLocation);
                }


            } else {

                // create a new appointment
                const json: any = appointment.toJSON();
                json.createdAt = new Date();//firestore.FieldValue.serverTimestamp();


                // for new patients: if they have their second appointment, then they are no longer new patients
                patient = await PatientsService.setPatientToOldPatientStatus(patient, appointment.clientId, appointment.locationId);

                appointment.patient.newPatient = patient.newPatient;

                if (newAppointmentId !== "" && newAppointmentId !== undefined) {
                    await db.collection("clients")
                        .doc(client.id)
                        .collection("locations")
                        .doc(appointment.locationId)
                        .collection("appointments")
                        .doc(newAppointmentId)
                        .set(appointment.toJSON(), { merge: true });

                    appointment.id = newAppointmentId;
                } else {
                    const docRef = await db.collection("clients")
                        .doc(client.id)
                        .collection("locations")
                        .doc(appointment.locationId)
                        .collection("appointments")
                        .add(appointment.toJSON());
                    appointment.id = docRef.id;
                }

                console.log(`new appointment created: ${appointment.id} on ${appointment.start.toLocaleString()}`);

                const message = `${appointment.getTitle()} am ${DateUtils.getDateTimeString(appointment.start)} für ${appointment.calendar.name} erstellt`;
                LoggingService.log(LogItemType.userAction, appointment.id, "", appointment.patient.id, appointment.calendar.userId, userId, message, appointment.clientId, appointment.locationId);

                if (!calendar?.internal && appointment.calendarItemType !== "absence") {
                    NotificationsService.updateReminders(appointment, patient, clientLocation);
                }

                if (onAppointmentUpdated !== undefined) {
                    onAppointmentUpdated(EventType.create, appointment);
                }

                if (appointment.calendarItemType !== "absence") {
                    // send confirmation SMS
                    if (appointment.status !== "needsConfirmation" && !calendar?.internal) {
                        NotificationsService.sendConfirmation(appointment, patient, client, clientLocation);
                    }

                    // if status is treated, send rating request to patient
                    if (appointment.patientStatus === PatientStatus.treated && !calendar?.internal) {
                        RatingsService.createRatingRequest(appointment, client);
                    }
                }

            }


            // update cache
            AppointmentsService.cache[appointment.id] = appointment;

            if (appointment.calendarItemType !== "absence") {
                PatientsService.addToPatientAppointmentList(patient, appointment);

                // create a video room if this appointment is an online video call appointment
                if (appointment.isVideoCall) {
                    VideoRoomsService.createRoomFromAppointment(appointment);
                }

                if (updateChainItems) {
                    await AppointmentsService.updateChainAppointments(appointment, patient, client, clientLocation, userId, onAppointmentUpdated);
                }

                // important to avoid recursion
                if (appointment.parentRecallId === "") {
                    await AppointmentsService.updateRecalls(appointment, patient, client, clientLocation, userId, onAppointmentUpdated);
                }

                // important to avoid recursion
                if (appointment.predecessorId === "") {
                    await AppointmentsService.updateSuccessors(appointment, patient, client, clientLocation, userId, onAppointmentUpdated);
                }
            }

            return appointment.id;

        } catch (error) {
            console.log(`error in updateAppointment ${error}`);
            return null;
        }

    },

    // checks if a date is a public holiday, on weekend or not in the working hours
    // and tries to get the next free date
    async getFreeSlot(start: Date, end: Date, client: Client, locationId: string, calendarId: string, iterations?: number, publicHolidays?: PublicHoliday[]): Promise<[Date,Date]> {

        const _iterations = iterations ? iterations + 1 : 1;

        const pHolidays = publicHolidays ?? await PublicHolidaysService.getPublicHolidays(client.country, client.state, start.getFullYear(), 2);

        let slotStart = new Date(start);
        let slotEnd = new Date(end);

        let isPublicHoliday = false;

        // stop if we run into too many iterations
        if(_iterations > 20) {
            return [slotStart, slotEnd];
        }

        // first check if its on a weekend
        if (DateUtils.isOnWeekend(slotStart) && pHolidays) {
            slotStart = moment(slotStart).add(1, "d").toDate();
            slotEnd = moment(slotEnd).add(1, "d").toDate();
            return await AppointmentsService.getFreeSlot(slotStart, slotEnd, client, locationId, calendarId, _iterations, pHolidays as any);
        }

        // now check if its a public holiday
        if (pHolidays) {
            for (let i = 0; i < pHolidays.length; i++) {
                const pHoliday = pHolidays[i];

                if (pHoliday.isSameDay(slotStart)) {
                    isPublicHoliday = true;
                    break;
                }
            }

            // if our date is on a public holiday, try one day later
            if (isPublicHoliday) {
                slotStart = moment(slotStart).add(1, "d").toDate();
                slotEnd = moment(slotEnd).add(1, "d").toDate();
                return await AppointmentsService.getFreeSlot(slotStart, slotEnd, client, locationId, calendarId, _iterations, pHolidays as any);
            }
        }

        // now check if doctor has open and if it is not in a working pause
        const doctor = await UsersService.getUserByCalendarId(client.id, locationId, calendarId);
        if(doctor && doctor.openingHours && doctor.openingHours.enabled) {
            const workingDay = doctor.openingHours.getWorkingDayByIndex(slotStart.getDay());
            if(!workingDay.hasOpen || (workingDay.hasPause && DateUtils.isSlotIntersectingPause(start, end, workingDay.pause))) {
                slotStart = moment(slotStart).add(1, "d").toDate();
                slotEnd = moment(slotEnd).add(1, "d").toDate();
                return await AppointmentsService.getFreeSlot(slotStart, slotEnd, client, locationId, calendarId, _iterations, pHolidays as any);
            }

        } else {
            // otherwise check if its in the locations working hours
            const clientLocation = await ClientLocationsService.getLocation(client.id, locationId);
            if(clientLocation) {
                const workingDay = clientLocation.openingHours.getWorkingDayByIndex(slotStart.getDay());
                if(!workingDay.hasOpen || (workingDay.hasPause && DateUtils.isSlotIntersectingPause(start, end, workingDay.pause))) {
                    slotStart = moment(slotStart).add(1, "d").toDate();
                    slotEnd = moment(slotEnd).add(1, "d").toDate();
                    return await AppointmentsService.getFreeSlot(slotStart, slotEnd, client, locationId, calendarId, _iterations, pHolidays as any);
                }
            }

        }


        // now check if it intersects with other appointments
        // ToDo: check day in detail and not just jump to the next day
        const from = new Date(slotStart);
        from.setHours(0,0,0);

        const to = new Date(slotStart);
        to.setHours(23,59,59);

        const appointments = await AppointmentsService.getAppointmentsByDate(from, to, client.id, locationId, [calendarId]);
        if(appointments && DateUtils.isSlotIntersectingWithAnyAppointments(slotStart, slotEnd, appointments)){
            slotStart = moment(slotStart).add(1, "d").toDate();
            slotEnd = moment(slotEnd).add(1, "d").toDate();
            return await AppointmentsService.getFreeSlot(slotStart, slotEnd, client, locationId, calendarId, _iterations, pHolidays as any);
        }


        return [slotStart, slotEnd];
    },

    async updateRecalls(appointment: Appointment, patient: Patient, client: Client, clientLocation: ClientLocation, userId: string, onAppointmentUpdated?: (eventType: EventType, newAppointment: Appointment) => void): Promise<void> {
        try {

            // check if recalls are allowed globally
            const location = await ClientLocationsService.getLocation(client.id, appointment.locationId, false);
            if(location && !location.notificationsSettings.recallSmsEnabled) return;

            // create recalls only for real appointments
            if (appointment.status === "needsConfirmation") return;

            // get recall information from visit motive
            const visitMotive = await VisitMotivesService.getVisitMotive(appointment.visitMotive.id, false, client.id, appointment.locationId);

            // create recall appointment
            if (visitMotive && ((visitMotive.recurrenceCount > 0 && visitMotive.recurrenceCount > appointment.recurrenceCount) || (visitMotive?.recurrenceCount === -1)) && visitMotive.recallId) {

                // get successor visitMotive
                const recallVisitMotive = await VisitMotivesService.getVisitMotive(visitMotive.recallId, false, client.id, appointment.locationId);

                // number of hours between appointment and recall
                const hoursToRecall = DateUtils.getHoursFromTimeString(visitMotive.recurrenceInterval);

                if (hoursToRecall !== null && !appointment.recallId && recallVisitMotive) {
                    const recallAppointment = appointment.clone();
                    recallAppointment.id = "";
                    recallAppointment.predecessorId = "";
                    recallAppointment.successorId = "";
                    recallAppointment.comments = "";
                    recallAppointment.createdBy = "recaller";


                    let start = moment(recallAppointment.start).add(hoursToRecall, "h").toDate();
                    let end = moment(recallAppointment.end).add(hoursToRecall, "h").toDate();

                    //[start, end] = await AppointmentsService.getFreeSlot(start, end, client, appointment.locationId, appointment.resourceId);


                    recallAppointment.start = start;
                    recallAppointment.end = end;

                    recallAppointment.status = "needsConfirmation";

                    recallAppointment.parentRecallId = appointment.id;

                    recallAppointment.recurrenceCount = appointment.recurrenceCount + 1;

                    recallAppointment.visitMotive = recallVisitMotive.clone();

                    // now check if the patient has already an appointment near that recall date
                    const fromDate = moment(recallAppointment.start).subtract(30, "d").toDate();
                    const toDate =  moment(recallAppointment.start).add(30, "d").toDate();
                    const nearbyAppointments = await AppointmentsService.getAppointmentsByDateRange(fromDate, toDate, client.id, appointment.locationId, patient.id);

                    if(nearbyAppointments && nearbyAppointments.findIndex( a => a.visitMotive.id === recallVisitMotive.id) !== -1) {
                        // we found an appointment with the same visit motive for that patient
                        // so do not create a new recall here and just exit the function now
                        return;
                    }

                    // save the new recall
                    const recallId = await AppointmentsService.updateAppointment(recallAppointment, patient, client, clientLocation, userId, false, undefined, onAppointmentUpdated);

                    // now update appointment with recall information
                    if (recallId) {
                        await AppointmentsService.updateAppointmentProperty(appointment.id, client.id, appointment.locationId, "recallId", recallId);
                    }
                }

            }

        } catch (error) {
            console.log(`error in updateRecalls ${error}`);
        }
    },

    async updateSuccessors(appointment: Appointment, patient: Patient, client: Client, clientLocation: ClientLocation, userId: string, onAppointmentUpdated?: (eventType: EventType, newAppointment: Appointment) => void): Promise<void> {
        try {

            // check if successors are allowed globally
            const location = await ClientLocationsService.getLocation(client.id, appointment.locationId, false);
            if(location && !location.notificationsSettings.successorSmsEnabled) return;

            // create successor appointments only for real appointments
            if (appointment.status === "needsConfirmation") return;

            // get successor information from visit motive
            const visitMotive = await VisitMotivesService.getVisitMotive(appointment.visitMotive.id, false, client.id, appointment.locationId);

            // create successor appointment
            if (visitMotive && visitMotive.successorEnabled && visitMotive.successorId && !appointment.successorId) {

                // get successor visitMotive
                const successorVisitMotive = await VisitMotivesService.getVisitMotive(visitMotive.successorId, false, client.id, appointment.locationId);

                // number of hours between appointment and successor
                const hoursToSuccessor = DateUtils.getHoursFromTimeString(visitMotive.successorInterval);

                if (hoursToSuccessor !== null && successorVisitMotive) {
                    const successorAppointment = appointment.clone();
                    successorAppointment.id = "";
                    successorAppointment.parentRecallId = "";
                    successorAppointment.successorId = "";
                    successorAppointment.comments = "";
                    successorAppointment.createdBy = "predecessor";

                    const speciality = await SpecialitiesService.getSpeciality(client.id, appointment.locationId, visitMotive.specialityId);

                    successorAppointment.isVideoCall = speciality !== null && speciality.isVideoCall;

                    successorAppointment.visitMotive = successorVisitMotive.clone();

                    let start = moment(successorAppointment.start).add(hoursToSuccessor, "h").toDate();
                    let end = moment(successorAppointment.end).add(hoursToSuccessor, "h").toDate();

                    //[start, end] = await AppointmentsService.getFreeSlot(start, end, client, appointment.locationId, appointment.resourceId);

                    successorAppointment.start = start;
                    successorAppointment.end = end;

                    successorAppointment.status = "needsConfirmation";

                    successorAppointment.predecessorId = appointment.id;

                    successorAppointment.recurrenceCount = appointment.recurrenceCount + 1;


                    // now check if the patient has already an appointment near that successor date
                    const fromDate = moment(successorAppointment.start).subtract(30, "d").toDate();
                    const toDate =  moment(successorAppointment.start).add(30, "d").toDate();
                    const nearbyAppointments = await AppointmentsService.getAppointmentsByDateRange(fromDate, toDate, client.id, appointment.locationId, patient.id);

                    if(nearbyAppointments && nearbyAppointments.findIndex( a => a.visitMotive.id === successorVisitMotive.id) !== -1) {
                        // we found an appointment with the same visit motive for that patient
                        // so do not create a new recall here and just exit the function now
                        return;
                    }


                    // save the new successor appointment
                    const successorId = await AppointmentsService.updateAppointment(successorAppointment, patient, client, clientLocation, userId, false, undefined, onAppointmentUpdated);

                    // now update appointment with successor information
                    if (successorId) {
                        await AppointmentsService.updateAppointmentProperty(appointment.id, client.id, appointment.locationId, "successorId", successorId);
                    }
                }

            }

        } catch (error) {
            console.log(`error in updateSuccessors ${error}`);
        }
    },

    async deleteAppointment(appointment: Appointment, userId: string): Promise<boolean> {
        try {

            await AppointmentsService.deleteAppointmentById(
                appointment.id,
                appointment.patient.id,
                appointment.clientId,
                appointment.locationId,
                userId,
                appointment.chainId ? appointment.chainId : "",
                appointment.recallId ?? "",
                appointment.successorId ?? ""
            );

            let message = `${appointment.getTitle()} für ${appointment.patient.lastName} am ${moment(appointment.start).format("DD.MM.YYYY")} gelöscht`;;

            if(appointment.calendarItemType === "absence") {
                message = `${appointment.getTitle()} am ${moment(appointment.start).format("DD.MM.YYYY")} für ${appointment.calendar.name} gelöscht`;
            }

            const patient = await PatientsService.getPatient(appointment.patient.id, appointment.clientId, appointment.locationId);
            if(patient){
                await PatientsService.updatePatientStatus(patient, appointment.clientId, appointment.locationId);
            }

            return true;

        } catch (error) {
            console.log(`error in deleteAppointment: ${error}`);
            return false;
        }
    },

    async deleteAppointmentById(appointmentId: string, patientId: string, clientId: string, locationId: string, userId: string, chainId: string = "", recallId: string = "", successorId: string = ""): Promise<boolean> {
        try {

            const appointment = await AppointmentsService.getAppointment(appointmentId, clientId, locationId);

            if(!appointment) return false;

            const deleteAppointment = functions.httpsCallable('deleteAppointment');
            await deleteAppointment(
                {
                    appointmentId: appointmentId,
                    patientId: patientId,
                    clientId: clientId,
                    locationId: locationId,
                    chainId: chainId ?? "",
                    recallId: recallId ?? "",
                    successorId: successorId ?? ""
                }
            );

            const patient = await PatientsService.getPatient(appointment.patient.id, appointment.clientId, appointment.locationId);
            if(patient){
                await PatientsService.updatePatientStatus(patient, appointment.clientId, appointment.locationId);
            }

            const doctor = await UsersService.getUserByCalendarId(appointment.clientId, appointment.locationId, appointment.calendar.id);

            const message = `${appointment.getTitle()} am ${DateUtils.getDateTimeString(appointment.start)} für ${appointment.calendar.name} gelöscht`;
            LoggingService.log(LogItemType.userAction, appointment.id, "", patient?.id, doctor?.id, userId, message, appointment.clientId, appointment.locationId);

            return true;

        } catch (error) {
            console.log(`error in deleteAppointmentById: ${error}`);
            return false;
        }
    },


    async updateChainAppointments(appointment: Appointment, patient: Patient, client: Client, clientLocation: ClientLocation, userId: string, onAppointmentUpdated?: (eventType: EventType, newAppointment: Appointment) => void) {

        if (appointment && appointment.chain.columns.length > 1) {

            let previousStartDate = new Date(appointment.start);

            for (let c = 1; c < appointment.chain.columns.length; c++) {
                const column = appointment.chain.columns[c];

                if (column.rows.length > 0) {
                    const chainItem = column.rows[0];
                    const previousChainColumn = appointment.chain.columns[c - 1].rows.length > 0 ? appointment.chain.columns[c - 1] : null;

                    if (!previousChainColumn) {
                        break;
                    }

                    const timeTillNextItemString = previousChainColumn.timeTillNextItem; // in the format: '1y:6m', '2d', '4w', '1m'
                    let daysTillNextItem = DateUtils.getDaysFromTimeString(timeTillNextItemString);

                    const visitMotive = await VisitMotivesService.getVisitMotive(chainItem.visitMotiveId, false, client.id, appointment.locationId);

                    if (daysTillNextItem && visitMotive) {

                        let startDate = new Date(previousStartDate);
                        startDate.setDate(previousStartDate.getDate() + daysTillNextItem);

                        let endDate = new Date(startDate);
                        endDate.setMinutes(startDate.getMinutes() + visitMotive.duration);

                        const newAppointment = new Appointment();
                        newAppointment.clientId = appointment.clientId;
                        newAppointment.chainId = appointment.id;
                        newAppointment.visitMotive = visitMotive ? visitMotive : appointment.visitMotive;
                        newAppointment.patient = patient;
                        newAppointment.resourceId = appointment.resourceId;
                        newAppointment.status = "needsConfirmation";
                        newAppointment.start = startDate;
                        newAppointment.end = endDate;
                        newAppointment.calendar = appointment.calendar;
                        newAppointment.title = appointment.title;

                        const existingAppointment = await AppointmentsService.getAppointment(column.id, client.id, appointment.locationId);

                        if (existingAppointment) {
                            newAppointment.id = column.id;
                        }

                        await AppointmentsService.updateAppointment(newAppointment, patient, client, clientLocation, userId, false, column.id, onAppointmentUpdated);

                        previousStartDate = new Date(startDate);
                    }
                }

            }
        }
    },

    async setNewPatientStatus(appointmentId: string, client: Client, locationId: string, newStatus: PatientStatus) {

        try {

            // check if we have to send a rating request
            const appointment = await AppointmentsService.getAppointment(appointmentId, client.id, locationId, false);
            if (appointment) {

                const calendar = await CalendarsService.getCalendar(client.id, locationId, appointment.calendar.id);

                // if status changed to treated, send rating request to patient
                if (appointment.patientStatus !== newStatus && newStatus === PatientStatus.treated && !calendar?.internal) {
                    appointment.patientStatus = PatientStatus.treated;
                    RatingsService.createRatingRequest(appointment, client);
                }

                await db.collection("clients")
                    .doc(client.id)
                    .collection("locations")
                    .doc(locationId)
                    .collection("appointments")
                    .doc(appointmentId)
                    .update("patientStatus", newStatus);

            } else {
                console.log(`error in setting new patient status: cannot find appointment ${appointmentId}`);
            }


        } catch (error) {
            console.log(`error in setting new patient status: ${error}`);
        }

    },

    async updateAppointmentProperty(appointmentId: string, clientId: string, locationId: string, propertyName: string, newValue: any) {

        try {

            await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("appointments")
                .doc(appointmentId)
                .update(propertyName, newValue);

        } catch (error) {
            console.log(`error in setting appointment property: ${error}`);
        }

    },

    // we have to provide here a target visit motive
    // utcOffset (in minutes) is needed because the server handles date in utc
    async startImport(clientId: string, locationId: string, visitMotiveId: string, visitMotiveName, visitMotiveColor: string, specialityId: string, utcOffset: number): Promise<boolean> {
        try {
            const startAppointmentsImport = functions.httpsCallable('startAppointmentsImport');
            const result = await startAppointmentsImport(
                {
                    clientId: clientId,
                    locationId: locationId,
                    visitMotiveId: visitMotiveId,
                    visitMotiveName: visitMotiveName,
                    visitMotiveColor: visitMotiveColor,
                    specialityId: specialityId,
                    utcOffset: -utcOffset
                }
            );

            return true;

        } catch (error) {
            console.error(`error in startImport: ${error}`);
            return false;
        }
    },


    async confirmRecallAppointment(appointment: Appointment): Promise<void> {
        try {

            const _appointment: any = appointment.clone();

            _appointment.start = appointment.start.toJSON();
            _appointment.end = appointment.end.toJSON();

            const confirmRecallAppointmentFunc = functions.httpsCallable("confirmRecallAppointment");
            const result = await confirmRecallAppointmentFunc(
                {
                    appointment: _appointment
                });

        } catch (error) {
            console.log("Error confirming recall appointment: ", error);
        }
    }


}

export default AppointmentsService;