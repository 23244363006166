import React from 'react';

interface Props {
    src: string
    alt?: string;
    width: number;
}

const Avatar:React.FC<Props> = ({src, alt, width}) => {
  
    function getUrl(url) {
        return url ? url : "/images/doctor-avatar.jpg";
    }

    return (
        <div className="kt-avatar" style={{width: width, height: width}}>
            <img src={getUrl(src)} alt={alt} />
        </div>
    );
}

export default Avatar;