import DateUtils from "../../utils/dateUtils";
import LandingPageData from "../landingPageData";

export enum CampaignStatus {
    created,
    started,
    completed
}

export enum CampaignType {
    sms,
    phone,
    email,
}

export enum InsuranceFilter {
    all,
    private,
    statutory   // gesetzlich versichert
}

export class Campaign {

    id: string = "";

    name: string = "";

    type: CampaignType = CampaignType.sms;

    status: CampaignStatus = CampaignStatus.created;

    createdAt: Date;

    startDate: Date | null = null;

    endDate: Date | null = null;

    patientsSelected: number = 0;

    patientsReached: number = 0; // number of patients that have opened the landing page

    conversions: number = 0; // number of patients that have booked an appointment

    emailMessage: string = "";
    smsMessage: string = "";

    visitMotiveId: string = ""; // patients will be redirected to this visitMotive landing page
    visitMotiveName: string = "";

    calendarId: string = "";
    calendarName: string = "";

    doctorId: string = "";
    doctorName: string = "";

    checkForNearbyAppointments: boolean = true;

    landingPage: LandingPageData = new LandingPageData();


    constructor() {
        this.createdAt = new Date();
    }

    clone(): Campaign {
        const c = new Campaign();
        c.fromObject(this.id, this.toJSON());

        return c;
    }
    toJSON(): object {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            status: this.status,

            patientsSelected: this.patientsSelected,
            patientsReached: this.patientsReached,
            conversions: this.conversions,

            createdAt: this.createdAt,

            emailMessage: this.emailMessage,
            smsMessage: this.smsMessage,

            visitMotiveId: this.visitMotiveId,
            visitMotiveName: this.visitMotiveName,

            calendarId: this.calendarId,
            calendarName: this.calendarName,

            doctorId: this.doctorId,
            doctorName: this.doctorName,

            checkForNearbyAppointments: this.checkForNearbyAppointments,

            landingPage: this.landingPage.toJSON()
        };
    }

    fromObject(id: string | null, o: any) {

        this.id = id !== null ? id : o.id;

        this.name = o.name;

        this.type = o.type ?? CampaignType.phone;
        this.status = o.status ?? CampaignStatus.created

        this.patientsSelected = o.patientsSelected ?? 0;
        this.patientsReached = o.patientsReached ?? 0;
        this.conversions = o.conversions ?? 0;

        this.createdAt = DateUtils.getDate(o.createdAt) ?? new Date();

        this.emailMessage = o.emailMessage ?? "";
        this.smsMessage = o.smsMessage ?? "";

        this.visitMotiveId = o.visitMotiveId ?? "";
        this.visitMotiveName = o.visitMotiveName ?? "";

        this.calendarId = o.calendarId ?? "";
        this.calendarName = o.calendarName ?? "";

        this.doctorId = o.doctorId ?? "";
        this.doctorName = o.doctorName ?? "";

        this.checkForNearbyAppointments = o.checkForNearbyAppointments !== undefined ? o.checkForNearbyAppointments === true : true;

        if(o.landingPage){
            this.landingPage.fromObject(o.landingPage);
        }

    }

    getStatusString(): string {
        switch (this.status) {
            case CampaignStatus.created:
                return "erstellt";

            case CampaignStatus.started:
                return "gestartet";

            case CampaignStatus.completed:
                return "beendet";

            default:
                return "";
        }
    }

    getTypeString(): string {
        switch (this.type) {
            case CampaignType.email:
                return "Email";

            case CampaignType.phone:
                return "Telefon";

            default:
                return "SMS";
        }
    }

    getIconClassNameString(): string {
        switch (this.type) {
            case CampaignType.email:
                return "fal fa-envelope";

            case CampaignType.phone:
                return "fal fa-phone";

            default:
                return "fal fa-sms";
        }
    }

}