import React, { useEffect, useState } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Button from '@mui/material/Button';

export enum DialogDefaultButton {
    save,
    next,
    close,
    ok,
    sendDocuments
}

interface Props {
    visible: boolean;
    fullWidth?: boolean;
    children: any;
    title: string;
    titleIcon?: string;
    alertTitle?: string;
    tabs?: string[];
    defaultButton?: DialogDefaultButton;
    newTabSelection: string;
    onClose: () => void;
    onSave: () => void;
    onNext?: () => void;
    onSendDocuments?: () => void;
    onTabChange?: (newTab: string) => void;
}

const Dialog: React.FC<Props> = ({ visible, fullWidth, children, title, titleIcon, alertTitle, tabs, defaultButton, newTabSelection, onClose, onSave, onNext, onSendDocuments, onTabChange }) => {

    const [selectedTab, setSelectedTab] = useState("");

    useEffect(()=>{
        if(visible){
            if(newTabSelection){
                setSelectedTab(newTabSelection);
            } else {
                setSelectedTab((tabs && tabs.length > 0) ? tabs[0] : "");
            }
        }
    },[visible, newTabSelection, tabs]);

    useEffect(() => {

        window.addEventListener('keydown', listenKeyboard, true);

        // clean up
        return () => {
            window.removeEventListener('keydown', listenKeyboard, true);
        }

    }, []);


    function listenKeyboard(event) {
        if (event.keyCode === 27) {
            onClose();
        }
    }


    function handleOverlayClick() {
        //onClose();
    }


    function handleDialogClick(event) {
        event.stopPropagation();
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (defaultButton === DialogDefaultButton.next && typeof onNext === "function") {
            onNext();
        } else if (defaultButton === DialogDefaultButton.sendDocuments && typeof onSendDocuments === "function") {
            onSendDocuments();
        } else {
            onSave();
        }

    }

    function handleTabClick(newTab: string) {

        if (newTab !== selectedTab) {
            setSelectedTab(newTab);

            if (typeof onTabChange === "function") {
                onTabChange(newTab);
            }
        }
    }

    function getTabCaption(tabId: string) {

        switch (tabId) {
            case "patient.main":
                return "Kontakt";

            case "patient.history":
                return "Terminhistorie";

            case "patient.documents":
                return "p-sign";

            case "patient.comments":
                return "Bemerkungen";

            case "appointment.main":
                return "Termin";

            case "appointment.documents":
                return "p-sign";

            default:
                return "";
        }
    }

    function renderTabs() {
        if (tabs) {
            return tabs.map((tab) =>
                <div
                    key={tab}
                    className={selectedTab === tab ? "kt-selected-tab" : ""}
                    onClick={() => handleTabClick(tab)}>
                    {getTabCaption(tab)}
                </div>
            )
        }

        return "";
    }

    function renderDefaultButton() {

        switch (defaultButton) {
            case DialogDefaultButton.next:
                return <Button type="submit" color="primary" variant="contained" startIcon={<NavigateNextIcon />}>WEITER</Button>;

            case DialogDefaultButton.sendDocuments:
                return <Button type="submit" color="primary" variant="contained" startIcon={<PhoneIphoneIcon />}>Dokumente senden</Button>;

            case DialogDefaultButton.close:
                return null;

            case DialogDefaultButton.ok:
                return <Button type="submit" color="primary" variant="contained">OK</Button>;

            default:
                return <Button type="submit" color="primary" variant="contained" startIcon={<SaveIcon />}>SPEICHERN</Button>;
        }

    }



    // Render nothing if the "visible" prop is false
    if (!visible) {
        return null;
    }

    return (
        <div className="kt-dialog" onClick={handleOverlayClick} draggable="false">

            <div className="kt-dialog-wrapper" onClick={handleDialogClick} draggable="false">

                <form onSubmit={handleSubmit} autoComplete="new">
                    <div className="kt-dialog-header">
                        <span>
                            {titleIcon ? <i className={titleIcon} /> : null}
                            <span>{title}</span>
                            {alertTitle && <span style={{ color: "red" }}> {alertTitle}</span>}
                        </span>
                        <div onClick={onClose}><i className="fal fa-times"></i></div>
                    </div>
                    <div className="kt-dialog-tab-buttons">
                        {renderTabs()}
                    </div>

                    <div className={fullWidth === true ? "kt-dialog-body kt-full-width" : "kt-dialog-body"}>
                        {children}
                    </div>

                    <div className="kt-dialog-footer">
                        <div></div>
                        <Button color="secondary" variant='outlined' startIcon={<CloseIcon />} onClick={onClose}>SCHLIE<span style={{textTransform: "none"}}>ß</span>EN</Button>

                        {renderDefaultButton()}

                    </div>
                </form>

            </div>
        </div>
    )


}


export default Dialog;