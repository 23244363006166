import { useEffect, useContext, useState } from 'react';
import { Paper } from '@mui/material';

import ClonRMenu from '../clonR/clonRMenu';

import { GlobalContext } from '../../GlobalContext';
import ClonRAvatarsCtrl from '../clonR/clonRAvatarsCtrl';

import ClonRMergeCtrl from '../clonR/clonRMergeCtrl';
import ClonRVideosCtrl from '../clonR/clonRVideosCtrl';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import ClonRHomeCtrl from '../clonR/clonRHomeCtrl';
import ClonRVoicesCtrl from '../clonR/clonRVoicesCtrl';
import ClonRMobileMenu from '../clonR/clonRMobileMenu';
import ClonRSettingsCtrl from '../clonR/clonRSettingsCtrl';
import ClonRSettings from '../../shared/src/models/clonR/clonRSettings';
import ClonRSettingsService from '../../services/clonRSettingsService';
import ClonRCreditsCtrl from '../clonR/clonRCreditsCtrl';
import Client from '../../models/client';
import ClonRNoPremiumLicenseCtrl from '../clonR/clonRNoPremiumLicenseCtrl';


function ClonRPage() {

    const { currentUser, calendars } = useContext(GlobalContext);

    const { view, itemId } = useParams();

    const [clonRSettings, setClonRSettings] = useState(new ClonRSettings(""));

    useEffect(() => {
        loadClonRUserSettings();
    }, []);

    const loadClonRUserSettings = async () => {
        const _settings = await ClonRSettingsService.getSettings(currentUser.clientId, currentUser.id);
        setClonRSettings(_settings);
    }

    const handleOnSettingsChange = async (newSettings: ClonRSettings) => {
        setClonRSettings(newSettings);
        await ClonRSettingsService.updateSettings(newSettings, currentUser.clientId);
    }

    const MainView = () => {

        const pageView = view ? view.toLowerCase() : "";

        // first check if we have at least one "Premium" license,
        // if not we block ClonR and show a page to upgrade to a "Premium" license
        if (!Client.hasVideoClonR(calendars)) return <ClonRNoPremiumLicenseCtrl />

        switch (pageView) {
            case "video":
                return <ClonRVideosCtrl videoId={itemId} />

            case "newvideo":
                return <ClonRMergeCtrl clonRSettings={clonRSettings} />

            case "home":
                return <ClonRMergeCtrl clonRSettings={clonRSettings} />

            case "videoclone":
                return <ClonRAvatarsCtrl />

            case "voice":
                return <ClonRVoicesCtrl />

            case "settings":
                return <ClonRSettingsCtrl clonRSettings={clonRSettings} onSettingsChange={handleOnSettingsChange} />

            case "credits":
                return <ClonRCreditsCtrl />

            default:
                return <ClonRMergeCtrl clonRSettings={clonRSettings} />
        }
    }

    return (
        <>
            <ClonRMobileMenu view={view ? view.toLowerCase() : ""} />

            <div className="kt-clonr">

                <ClonRMenu view={view ? view.toLowerCase() : ""} />

                <MainView />

                <div className="kt-grid-footer">
                    <Paper className="kt-paper kt-text-center" style={{ padding: "15px", overflowY: "hidden" }}>
                        <span>© 2024 Pickadoc GmbH</span>
                    </Paper>
                </div>

            </div>

        </>
    )
}

export default ClonRPage;