
import React, { useContext, useState } from "react";

import Button from '@mui/material/Button';
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import VideoFormItem from "../../../src/shared/src/models/formEditor/videoFormItem";
import { FormItemCtrlProps } from "./formItemCtrl";
import { Align } from "../../../src/shared/src/models/formEditor/formItem";
import Utils from "../../shared/src/utils/utils";
import { GlobalContext } from "../../GlobalContext";
import CalendarVideosDlg from "../dialogs/calendarVideosDlg";
import { CalendarVideoGroup } from "../../shared/src/models/landingPageData";
import { VideoGroup } from "../../shared/src/models/clonR/clonRVideo";
import PatientsService from "../../services/patientsService";
import VideoPlayerCtrl from "../videoPlayerCtrl";
import VideoGroupSelectorCtrl from "../videoGroupSelectorCtrl";
import VideoSelectorCtrl from "../videoSelectorCtrl";


interface Props extends FormItemCtrlProps {
    item: VideoFormItem
}

const VideoFormItemCtrl: React.FC<Props> = (
    {
        patientId,
        documentId,
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode,
        formUser
    }) => {



    const { currentClient, currentLocation, currentUser } = useContext(GlobalContext);
    const [isDoctorVideosDlgVisible, setIsDoctorVideosDlgVisible] = useState(false);

    function renderHtml(): JSX.Element {

        const textAlignStrings: any = ["left", "center", "right"];
        const textAlignString = textAlignStrings[item.videoAlign];

        if (item.videoThumbnailUrl) {
            return (
                <div style={{ textAlign: textAlignString }}>
                    <img
                        style={{ width: item.videoWidth + "px" }}
                        src={item.videoThumbnailUrl}
                        alt=""
                    />
                </div>
            );
        // } else if (item.videoSource) {
        //     return (
        //         <div style={{ textAlign: textAlignString }}>
        //             <iframe
        //                 title="psign-video"
        //                 style={{ width: item.videoWidth + "px", height: item.videoWidth + "px" }}
        //                 className=""
        //                 allowFullScreen={true}
        //                 frameBorder="0"
        //                 src={`https://www.youtube.com/embed/${Utils.getYouTubeId(item.videoSource)}?autoplay=1&rel=0`}>
        //             </iframe>
        //         </div>
        //     );

        } else {
            return (
                <div style={{ textAlign: textAlignString }}>
                    <img
                        style={{ width: item.videoWidth + "px" }}
                        src="../../images/video-placeholder.svg"
                        alt=""
                    />
                </div>
            );
        }


    }

    function handleOnVideoChange(_videoId: string, _videoUrl: string, _thumbnailUrl: string) {
        handleOnInputChange(item, _videoId, "videoId");
        handleOnInputChange(item, _videoUrl, "videoSource");
        handleOnInputChange(item, _thumbnailUrl, "videoThumbnailUrl");
    }

    function renderProperties(): JSX.Element {
        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <strong>Video</strong>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <TextField
                            type="text"
                            label="YouTube Video ID"
                            value={item.videoSource}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "videoSource")}
                        /> */}

                        <VideoSelectorCtrl
                            clientId={currentClient.id}
                            videoId={item.videoId}
                            onChange={handleOnVideoChange}
                            fullWidth                            
                        />
{/*                                 
                        <CalendarVideosDlg
                            clientId={currentClient.id}
                            visible={isDoctorVideosDlgVisible}
                            customMessage={"Bitte wählen Sie ein Video für das Dokument aus."}
                            videoGroups={item.videoGroups}
                            onSave={handleDoctorVideosDlgSave}
                            onClose={() => setIsDoctorVideosDlgVisible(false)}
                        />

                        <Button
                            variant="contained"
                            style={{ color: "white" }}
                            onClick={() => setIsDoctorVideosDlgVisible(true)}
                            startIcon={<i className='fal fa-video'></i>}
                        >
                            ClonR Video wählen
                        </Button> */}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            type="number"
                            inputProps={{ min: "1", max: "600", step: "1" }}
                            label="Breite"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">px</InputAdornment>,
                            }}
                            value={item.videoWidth}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "videoWidth")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="outlined" size="small">
                            <Button
                                startIcon={<FormatAlignLeftIcon />}
                                variant={item.videoAlign === Align.left ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.left, "videoAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignCenterIcon />}
                                variant={item.videoAlign === Align.center ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.center, "videoAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignRightIcon />}
                                variant={item.videoAlign === Align.right ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.right, "videoAlign")}
                            />
                        </ButtonGroup>
                    </Grid>

                </Grid>

            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {
        const textAlignStrings: any = ["left", "center", "right"];
        const textAlignString = textAlignStrings[item.videoAlign];

        if (item.videoSource) {
            return (
                <div className="kt-videoFormItemCtrl">
                    <div style={{ textAlign: textAlignString }}>
                        <VideoPlayerCtrl
                            id={"vid" + item.id}
                            videoUrl={item.videoSource}
                            thumbnailUrl={item.videoThumbnailUrl}
                        />
                        {/* <iframe
                            title="psign-video"
                            style={{ width: item.videoWidth + "px", height: item.videoWidth + "px" }}
                            className=""
                            allowFullScreen={true}
                            src={`${item.videoSource}?autoplay=1&rel=0`}>
                        </iframe> */}
                        {/* <iframe
                            title="psign-video"
                            style={{ width: item.videoWidth + "px", height: item.videoWidth + "px" }}
                            className=""
                            allowFullScreen={true}
                            frameBorder="0"
                            src={`https://www.youtube.com/embed/${Utils.getYouTubeId(item.videoSource)}?autoplay=1&rel=0`}>
                        </iframe> */}
                    </div>
                </div>
            );

        } else {
            return (
                <div className="kt-videoFormItemCtrl" style={{ textAlign: textAlignString }}>
                    <img
                        style={{ width: item.videoWidth + "px" }}
                        src="../../images/video-placeholder.svg"
                        alt=""
                    />
                </div>
            );
        }
    }


    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }


}


export default VideoFormItemCtrl;