import database from "../components/database";
import ClonRSettings from "../shared/src/models/clonR/clonRSettings";

const db = database.firestore();

const ClonRSettingsService = {


    async getSettings(clientId: string, userId: string): Promise<ClonRSettings> {
        
        const doc = await db.collection("clients").doc(clientId)
            .collection("clonRSettings").doc(userId)
            .get();

        try {

            if (doc.exists) {

                const settings = new ClonRSettings(userId);
                settings.fromObject(userId, doc.data());

                return settings;

            } else {
                console.log("getSettings: No such document: " + userId);
            }

        } catch (error) {
            console.log("Error getting ClonR settings: ", error);
        };

        return new ClonRSettings(userId);
    },



    async updateSettings(settings: ClonRSettings, clientId: string): Promise<string | null> {

        try {

            if (settings.id) {
                await db.collection("clients").doc(clientId)
                    .collection("clonRSettings").doc(settings.id)
                    .set(settings.toJSON(), { merge: true });

                return settings.id;

            } else {
                // create a new ClonRSettings
                const docRef = await db.collection("clients").doc(clientId)
                    .collection("clonRAvatars")
                    .add(settings.toJSON());

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating ClonR settings: ", error);
        };

        return null;
    }

}

export default ClonRSettingsService;