export enum InvoiceItemType {
    none,
    appointment,
    appointmentVirtual,
    appointmentVirtualConfirmed,
    document,
    smsConfirmation,
    smsESign,       // Is now pSign
    smsMarketing,
    smsRating,
    smsRecall,      // obsolet
    smsReminder,
    smsSuccessor,    // obsolet
    discount
}

export class InvoiceItem {

    title: string = "";

    type: InvoiceItemType = InvoiceItemType.none;

    eventId: string = ""; // if an event like sending a confirmation sms trigerred this, then save the id of the appointment

    quantity: number = 0;
    price: number = 0;

    total: number = 0;

    createdAt: Date | null = null;


    constructor(title: string, type: InvoiceItemType, quantity: number, price: number, eventId?: string, createdAt?: Date | null) {
        this.title = title;
        this.type = type;
        this.quantity = quantity;
        this.price = price;
        this.total = quantity * price;

        this.createdAt = createdAt ?? null;

        this.eventId = eventId ?? "";
    }

    clone(): InvoiceItem {

        const c = new InvoiceItem(this.title, this.type, this.quantity, this.price);
        c.fromObject(this.toJSON(true));

        return c;
    }

    toJSON(useDate: boolean): object {

        const obj: any = {};

        if (this.title) {
            obj.title = this.title;
        }

        if (this.type !== InvoiceItemType.none) {
            obj.type = this.type;
        }

        if (this.eventId) {
            obj.eventId = this.eventId;
        }

        obj.quantity = this.quantity;

        obj.price = this.price;

        if (this.createdAt) {
            if(useDate){
                obj.createdAt = this.createdAt;
            } else {
                obj.createdAt = this.createdAt.toJSON();
            }
        }

        return obj;
    }

    fromObject(o: any) {

        this.title = o.title ?? "";

        this.type = o.type ?? InvoiceItemType.none;

        this.eventId = o.eventId ?? "";

        this.quantity = o.quantity ?? 0;

        this.price = o.price ?? 0;

        this.total = this.quantity * this.price;

        this.createdAt = o.createdAt ?? null;
    }

    getTitle(): string {

        if(this.title && this.title !== "") return this.title;

        switch (this.type) {
            case InvoiceItemType.appointment:
                return "Termin";

            case InvoiceItemType.appointmentVirtual:
                return "Recall-/Folgetermin unbestätigt";

            case InvoiceItemType.appointmentVirtualConfirmed:
                return "Recall-/Folgetermin bestätigt";

            case InvoiceItemType.document:
                return "p-sign Archivierung";

            case InvoiceItemType.smsConfirmation:
                return "Termin Bestätigung";

            case InvoiceItemType.smsESign:
                return "p-sign Erstellung";

            case InvoiceItemType.smsMarketing:
                return "Marketing";

            case InvoiceItemType.smsRating:
                return "Bewertung Anfrage";

            case InvoiceItemType.smsRecall:
                return "Recall SMS";

            case InvoiceItemType.smsReminder:
                return "Termin Erinnerung";

            case InvoiceItemType.smsSuccessor:
                return "Folgetermin Erinnerung";

            case InvoiceItemType.discount:
                return "Rabatt";

            default:
                return "";
        }
    }

}


export default InvoiceItem;