import React, { Fragment, useEffect, useState } from "react";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import OpeningHours, {TimeSpan, WorkingDay} from "../models/openingHours";


interface Props {
    showEnabledCheckbox: boolean;
    openingHours: OpeningHours;
    onInputChange: (changedOpeningHours: OpeningHours) => void;
}

const OpeningHoursInput:React.FC<Props> = ({showEnabledCheckbox, openingHours, onInputChange}) => {

    const [internalOpeningHours, setInernalOpeningHours] = useState(openingHours.clone());
    const [jsonHours, setJsonHours] = useState<any>(openingHours.toJSON());

    useEffect(()=>{
        if(openingHours){
            setInernalOpeningHours(openingHours.clone());
            setJsonHours(openingHours.toJSON(true));
        } else {
            const newHours = new OpeningHours();
            setInernalOpeningHours(newHours);
            setJsonHours(newHours.toJSON(true));
        }

    }, [openingHours]);

    function handleChange(value: string | boolean, day: string, property: string) {

        if(property === "enabled"){

            const o = internalOpeningHours.clone();
            o.enabled = value === true;

            onInputChange(o);

            return;

        } else if(jsonHours[day][property] !== null) {
            switch (property) {
                case "open":
                case "pause":
                    jsonHours[day][property] = value as string;
                    setJsonHours(JSON.parse(JSON.stringify(jsonHours)));
                    break;

                case "hasPause":
                    internalOpeningHours[day].hasPause = value as boolean;
                    setInernalOpeningHours(internalOpeningHours);
                    setJsonHours(JSON.parse(JSON.stringify(jsonHours))); // force a rerendering
                    onInputChange(internalOpeningHours.clone());
                    break;

                case "hasOpen":
                    internalOpeningHours[day].hasOpen = value as boolean;
                    setInernalOpeningHours(internalOpeningHours);
                    setJsonHours(JSON.parse(JSON.stringify(jsonHours))); // force a rerendering
                    onInputChange(internalOpeningHours.clone());
                    break;

                default:
                    break;
            }

        }
    }


    function handleBlur(value: string | boolean, day: string, property: string) {

        if(internalOpeningHours[day][property] !== null) {
            switch (property) {
                case "open":
                case "pause":
                    internalOpeningHours[day][property] = TimeSpan.parse(value as string)!;
                    break;

                default:
                    break;
            }
            setInernalOpeningHours(internalOpeningHours);

            onInputChange(internalOpeningHours.clone());
        }
    }

    function getTimeSpanColumn(day: WorkingDay, dayOpenString: string, dayPauseString: string, dayName: string) {
        return (
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={day.hasOpen}
                            onChange={(e)=>handleChange(e.target.checked, dayName, "hasOpen")}
                        />
                    }
                    label="Geöffnet"
                />

                {day.hasOpen &&
                    <TextField
                        name="open"
                        value={dayOpenString}
                        onChange={(e)=>handleChange(e.target.value, dayName, "open")}
                        onBlur={(e)=>handleBlur(e.target.value, dayName, "open")}
                    />
                }
                {day.hasOpen &&
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={day.hasPause}
                                onChange={(e)=>handleChange(e.target.checked, dayName, "hasPause")}
                            />
                        }
                        label="Pause von/bis"
                    />
                }
                {day.hasOpen && day.hasPause &&
                    <TextField
                        name="pause"
                        value={dayPauseString}
                        onChange={(e)=>handleChange(e.target.value, dayName, "pause")}
                        onBlur={(e)=>handleBlur(e.target.value, dayName, "pause")}
                    />
                }
            </div>
        );
    }

    return (
        <div className="kt-opening-hours">
            <div className="kt-control-wrapper">
                <div className="kt-day-wrapper">

                    {showEnabledCheckbox && <FormControlLabel
                        control={
                            <Checkbox
                                checked={internalOpeningHours.enabled}
                                onChange={(e)=>handleChange(e.target.checked, "enabled", "enabled")}
                            />
                        }
                        label="Eigene Zeiten definieren"
                    />}

                    {(internalOpeningHours.enabled || (!internalOpeningHours.enabled && !showEnabledCheckbox))&&
                        <Fragment>
                            <div className="kt-day">
                                <label className="kt-day-label">Montag</label>
                                <div className="kt-hours-wrapper">
                                    {internalOpeningHours.monday && jsonHours.monday && getTimeSpanColumn(internalOpeningHours.monday, jsonHours.monday.open, jsonHours.monday.pause, "monday")}
                                </div>
                            </div>
                            <div className="kt-day">
                                <label className="kt-day-label">Dienstag</label>
                                <div className="kt-hours-wrapper">
                                    {internalOpeningHours.tuesday && jsonHours.tuesday && getTimeSpanColumn(internalOpeningHours.tuesday, jsonHours.tuesday.open, jsonHours.tuesday.pause,  "tuesday")}
                                </div>
                            </div>
                            <div className="kt-day">
                                <label className="kt-day-label">Mittwoch</label>
                                <div className="kt-hours-wrapper">
                                    {internalOpeningHours.wednesday && jsonHours.wednesday && getTimeSpanColumn(internalOpeningHours.wednesday, jsonHours.wednesday.open, jsonHours.wednesday.pause,  "wednesday")}
                                </div>
                            </div>
                            <div className="kt-day">
                                <label className="kt-day-label">Donnerstag</label>
                                <div className="kt-hours-wrapper">
                                    {internalOpeningHours.thursday && jsonHours.thursday && getTimeSpanColumn(internalOpeningHours.thursday, jsonHours.thursday.open, jsonHours.thursday.pause,  "thursday")}
                                </div>
                            </div>
                            <div className="kt-day">
                                <label className="kt-day-label">Freitag</label>
                                <div className="kt-hours-wrapper">
                                    {internalOpeningHours.friday && jsonHours.friday && getTimeSpanColumn(internalOpeningHours.friday, jsonHours.friday.open, jsonHours.friday.pause,  "friday")}
                                </div>
                            </div>
                            <div className="kt-day">
                                <label className="kt-day-label">Samstag</label>
                                <div className="kt-hours-wrapper">
                                    {internalOpeningHours.saturday && jsonHours.saturday && getTimeSpanColumn(internalOpeningHours.saturday, jsonHours.saturday.open, jsonHours.saturday.pause,  "saturday")}
                                </div>
                            </div>
                            <div className="kt-day">
                                <label className="kt-day-label">Sonntag</label>
                                <div className="kt-hours-wrapper">
                                    {internalOpeningHours.sunday && jsonHours.sunday && getTimeSpanColumn(internalOpeningHours.sunday, jsonHours.sunday.open, jsonHours.sunday.pause,  "sunday")}
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>

            </div>

        </div>
    )
}

export default OpeningHoursInput;