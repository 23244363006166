import { v4 as uuid } from "uuid";
import { getFileExtension } from "../utils";
import { StorageError, TaskState, getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable, uploadString, deleteObject } from "firebase/storage";
import firebase from 'firebase/compat/app';

const functions = firebase.app().functions('europe-west3');

const FileUploadsService = {

    async uploadFile(file: File, targetLocation: string): Promise<string | null> {

        try {

            const fileExtension = getFileExtension(file.name);
            const fileName = `${uuid()}${fileExtension !== "" ? ("." + fileExtension) : ""}`;

            const storage = getStorage();

            const storageRef = ref(storage, `${targetLocation}/${fileName}`);

            await uploadBytes(storageRef, file);

            const url = await getDownloadURL(storageRef);

            return url;

        } catch (error) {
            console.error("Error uploading file: ", error);
        }

        return null;
    },

    async uploadFileToLocation(file: File, clientId: string, locationId: string, folder: string): Promise<string | null> {

        try {
            const targetPath = `clients/${clientId}/locations/${locationId}/${folder}`;
            return await FileUploadsService.uploadFile(file, targetPath);

        } catch (error) {
            console.error("Error uploading file to location: ", error);
        }

        return null;
    },

    async uploadBase64ImageToFile(base64Image: string, fileName: string, targetLocation: string): Promise<string | null> {

        try {

            if (base64Image && base64Image.indexOf("data:image") === 0) {

                const storage = getStorage();

                const storageRef = ref(storage, `${targetLocation}/${fileName}`);

                await uploadString(storageRef, base64Image, 'data_url');

                const url = await getDownloadURL(storageRef);

                return url;
            }

        } catch (error) {
            console.error("Error uploading base64 string to file: ", error);
        }

        return null;
    },

    async getDownloadURL(fullPath: string): Promise<string | null> {
        const storage = getStorage();

        const storageRef = ref(storage, `${fullPath}`);

        const url = await getDownloadURL(storageRef);

        return url;
    },

    uploadFileWithProgress(file: File, targetLocation: string, targetFileName: string | null, generateVideoThumbnail: boolean,
        stateChangedCallback: (progress: number, state: TaskState) => void,
        finishedCallback: (downloadUrl: string, fileExtension: string, videoThumbnailUrl: string) => void,
        errorCallback: (error: StorageError) => void) {

        try {

            const fileExtension = getFileExtension(file.name);
            const fileName = `${targetFileName ? targetFileName : uuid()}${fileExtension !== "" ? ("." + fileExtension) : ""}`;

            const storage = getStorage();

            const bucketPath = `${targetLocation}/${fileName}`;

            const storageRef = ref(storage, bucketPath);

            const uploadTask = uploadBytesResumable(storageRef, file);

            // Register three observers:
            // 1. 'state_changed' observer, called any time the state changes
            // 2. Error observer, called on failure
            // 3. Completion observer, called on successful completion
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    stateChangedCallback(progress, snapshot.state);
                },
                (error) => {
                    // Handle unsuccessful uploads
                    errorCallback(error);
                },
                async () => {
                    // Handle successful uploads on complete
                    const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

                    let videoThumbnailUrl = "";

                    if(generateVideoThumbnail) {
                        videoThumbnailUrl = await FileUploadsService.generateVideoThumbnail(bucketPath);
                    }

                    finishedCallback(downloadUrl, fileExtension ?? "", videoThumbnailUrl);
                }
            );


            return {
                uploadTask: uploadTask,
                fileName: fileName
            };

        } catch (error) {
            console.error("Error uploading file with progrss: ", error);
        }

        return null;

    },

    async deleteFile(fullPathWithFileName: string): Promise<boolean> {

        try {

            const storage = getStorage();

            const storageRef = ref(storage, `${fullPathWithFileName}`);

            await deleteObject(storageRef);

            return true;

        } catch (error) {
            console.error("Error deleting file: ", error);
        }

        return false;
    },

    async generateVideoThumbnail(videoBucketFilePath: string): Promise<string> {

        const generateVideoThumbnailFunc = functions.httpsCallable("generateVideoThumbnail");
        const result = await generateVideoThumbnailFunc(
            {
                videoBucketFilePath: videoBucketFilePath
            });

        if (result && result.data) {
            return result.data;
        }

        return "";
    }

}

export default FileUploadsService;