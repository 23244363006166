import React, { useEffect, useState } from "react";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ClonRVideo from "../shared/src/models/clonR/clonRVideo";
import ClonRVideosService from "../services/clonRVideosService";



interface Props {
    fullWidth?: boolean;
    readOnly?: boolean;    
    clientId: string;
    videoId: string;
    onChange: (videoId: string, videoUrl: string, videoThumbnailUrl: string) => void;
}

const VideoSelectorCtrl: React.FC<Props> = ({ fullWidth, readOnly, clientId, videoId, onChange }) => {

    const [videos, setVideos] = useState<ClonRVideo[]>([]);

    useEffect(() => {

        async function getVideos() {
            const _videos = await ClonRVideosService.getVideos(clientId);
            
            setVideos(_videos);
        }

        if (clientId) {
            getVideos();
        }

    }, [clientId]);

    

    const handleChange = (event: SelectChangeEvent) => {
        const newVideoId = event.target.value;
        const _video = videos.find(x => x.id === newVideoId);
        const videoUrl = _video?.videoUrl || "";
        const videoThumbnailUrl = _video?.thumbnailUrl || "";

        onChange(newVideoId, videoUrl, videoThumbnailUrl);
    };


    return (


        <FormControl fullWidth={fullWidth === true} className="kt-video-selector-ctrl">
            <InputLabel id="videoId-select-label">Video</InputLabel>
            <Select                
                disabled={readOnly === true}
                name="videoId"
                labelId="videoId-select-label"
                label="Video"
                id="videoId-select"
                value={videoId}
                onChange={handleChange}
            >
                <MenuItem value=""><strong>Wählen Sie ein Video aus</strong></MenuItem>

                {videos.map((video) =>
                    <MenuItem key={video.id} value={video.id}>
                        <div className="kt-video-selector-ctrl-popup kt-video">
                            <div className="kt-video-thumbnail" style={{ backgroundImage: `url("${video.thumbnailUrl}")` }}></div>
                            <div className="kt-video-name">{video.name}</div>
                        </div>
                    </MenuItem>
                )}

            </Select>
        </FormControl>

    );
}

export default VideoSelectorCtrl;