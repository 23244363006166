import {useState, useEffect, useContext} from "react";
import {useParams} from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import SpecialitiesService from "../../../services/specialitiesService";

import LoadingPage from "../administration/loadingPage";
import SaveStatus from "../../../models/saveStatus";
import SaveButton from "../../saveButton";
import Speciality from "../../../models/speciality";
import BackButton from "../../backButton";
import { GlobalContext } from "../../../GlobalContext";





function SpecialityPage() {

    const { specialityId } = useParams();

    const [speciality, setSpeciality] = useState<Speciality | null>(null);

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);

    const [saveStatus, setSaveStatus] = useState(SaveStatus.none);


    useEffect(() => {

        if(specialityId && currentClient){
            updateData(specialityId);
        }

        // eslint-disable-next-line
    }, [specialityId, currentClient]);



    async function updateData(sId:string) {

        if(!currentUser){
            return;
        }

        try {
            const cId = currentClient.id;

            setIsLoading(true);

            if(sId && sId.toLowerCase() === "create" && cId){
                const newSpeciality = new Speciality();

                setSpeciality(newSpeciality);


            } else {

                const result = await SpecialitiesService.getSpeciality(currentClient.id, currentUser.locationId, sId, true);

                if(result) {
                    setSpeciality(result);
                }
            }


            setIsLoading(false);

        } catch(error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if(speciality !== null && currentUser) {
            setSaveStatus(SaveStatus.isSaving);


            const newId = await SpecialitiesService.updateSpeciality(speciality, currentClient.id, currentUser.locationId);

            if(newId){

                setSaveStatus(SaveStatus.saved);

                window.history.back();

            } else {
                setSaveStatus(SaveStatus.none);
                alert("Die Besuchsgrund-Gruppe konnte nicht gespeichert werden!");
            }
        }
    }

    function onInputChange(event){
        if(speciality){
            const {name, value} = event.target;

            const s = speciality.clone();

            if(event.target.type === "checkbox"){
                s[name] = event.target.checked;
            } else {
                s[name] = value;
            }

            setSpeciality(s);
        }
    }




    if(isLoading) return <LoadingPage/>;

    if(!speciality) return (
        <div className="kt-page-content">
            <h2>Besuchsgrund-Gruppe</h2>

            <div>Diese Besuchsgrund-Gruppe existiert nicht. Bitte überprüfen Sie Ihre URL.</div>
        </div>
    );

    return (
        <Box className="kt-page">
            <h2>Besuchsgrund-Gruppe</h2>

            <form onSubmit={handleSubmit} autoComplete="turnitoff">

                <div className="kt-form-section">

                    <Grid container>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="name"
                                label="Name"
                                value={speciality.name}
                                onChange={onInputChange}
                                required
                                inputProps={{minLength:3}}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="shortName"
                                label="Kürzel"
                                value={speciality.shortName}
                                onChange={onInputChange}
                                required
                                inputProps={{minLength:3}}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <label>Farbe</label>
                            <input className="kt-color-picker" type="color" name="color" value={speciality.color} onChange={onInputChange} />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Checkbox checked={speciality.isVideoCall} onChange={onInputChange} name="isVideoCall" color="default"/>}
                                label="Online Videosprechstunden"
                            />
                        </Grid>

                    </Grid>
                </div>

                <div className="kt-page-footer">
                    <BackButton />

                    <SaveButton saveStatus={saveStatus} />
                </div>

            </form>
        </Box>
    );
}

export default SpecialityPage;