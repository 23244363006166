import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";

import DateUtils from '../../src/shared/src/utils/dateUtils';
import LogItem, { LogItemType } from "../../src/shared/src/models/logItem";
import { isCrawlerUserAgent, logDbReadOperations } from "../../src/utils";


const db = firebaseApp.firestore();
const functions = firebase.app().functions('europe-west3');

const LoggingService = {

    async getLogItems(clientId: string, locationId: string): Promise<LogItem[]> {

        const query = db.collection("clients").doc(clientId)
            .collection("locations").doc(locationId)
            .collection("logItems")
            .orderBy("createdAt", "desc");

        const snapshot = await query.get();

        const logItems: LogItem[] = [];

        snapshot.forEach(element => {
            const _item = element.data();
            const item = new LogItem(element.id, "", "", "", "", "", LogItemType.none, "");
            item.fromObject(_item);

            logItems.push(item);
        });

        return logItems;
    },


    async getLogItemsForDateRange(clientId: string, locationId: string, startDate: Date, endDate: Date): Promise<LogItem[]> {

        const query = db.collection("clients").doc(clientId)
            .collection("locations").doc(locationId)
            .collection("logItems")
            .orderBy("createdAt", "desc")
            .where("createdAt", ">=", startDate)
            .where("createdAt", "<=", endDate);


        const snapshot = await query.get();

        const logItems: LogItem[] = [];

        snapshot.forEach(element => {
            const _item = element.data();
            const item = new LogItem(element.id, "", "", "", "", "", LogItemType.none, "");
            item.fromObject(_item);

            logItems.push(item);
        });

        return logItems;
    },


    async getLogItemsForMonth(clientId: string, locationId: string, forYear: number, forMonth: number): Promise<LogItem[]> {

        const monthDateRange = DateUtils.getMonthDateRange(forYear, forMonth);

        return await LoggingService.getLogItemsForDateRange(clientId, locationId, monthDateRange.start, monthDateRange.end);
    },

    async getLogItemsForToday(clientId: string, locationId: string): Promise<LogItem[]> {

        const start = new Date();
        start.setHours(0, 0, 0);
        const end = new Date();
        end.setHours(23, 59, 59);

        return await LoggingService.getLogItemsForDateRange(clientId, locationId, start, end);
    },

    async getLogItemsForLast30Days(clientId: string, locationId: string): Promise<LogItem[]> {

        const start = new Date();
        start.setHours(0, 0, 0);
        start.setDate(start.getDate() - 30);

        const end = new Date();
        end.setHours(23, 59, 59);

        return await LoggingService.getLogItemsForDateRange(clientId, locationId, start, end);
    },


    startListenFor24hLogItems(clientId: string, locationId: string, changeCallback: (logItems: LogItem[]) => void): () => void {

        console.log("subscribe for log items");

        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);

        if (!clientId || !locationId) {
            return () => {
                console.log("error in startListenForLogItems: empty paramter");
            };
        }

        return db.collection("clients").doc(clientId)
            .collection("locations").doc(locationId)
            .collection("logItems")
            .where("createdAt", ">=", startDate)
            .orderBy("createdAt", "desc")
            .onSnapshot(function (querySnapshot) {
                const logItems: LogItem[] = [];

                querySnapshot.forEach(element => {
                    const _item = element.data();
                    const item = new LogItem(element.id, "", "", "", "", "", LogItemType.none, "");
                    item.fromObject(_item);

                    logItems.push(item);
                });

                logDbReadOperations("startListenForLogItems", logItems.length);

                changeCallback(logItems);
            });
    },


    async log(logItemType: LogItemType, appointmentId: string, documentId: string, patientId: string | undefined | null, doctorId: string | undefined | null, userId: string | undefined | null, message: string, clientId: string, locationId: string): Promise<void> {

        try {
            // don't log bots, crawlers..
            if (isCrawlerUserAgent()) return;

            const _patientId = patientId ?? "";
            const _doctorId = doctorId ?? "";
            const _userId = userId ?? "";

            const logItem = new LogItem("", appointmentId, documentId, _patientId, _doctorId, _userId, logItemType, message);

            const logFunc = functions.httpsCallable("log");
            await logFunc(
                {
                    logItemJson: logItem.toJSON(false),
                    clientId: clientId,
                    locationId: locationId
                });

        } catch (error) {
            console.log("Error logging to db: ", error);
        }
    }


}

export default LoggingService;