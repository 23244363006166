import React from "react";

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from "@mui/material/FormLabel";

import DropdownFormItem from "../../../src/shared/src/models/formEditor/dropdownFormItem";
import TextEvaluationService from "../../../src/services/textEvaluationService";
import { FormItemCtrlProps } from "./formItemCtrl";


interface Props extends FormItemCtrlProps {
    item: DropdownFormItem
}

const DropdownFormItemCtrl: React.FC<Props> = (
    {
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode,
        formUser
    }) => {


    function getStringFromList(): string {
        let result = "";

        for (let i = 0; i < item.list.length; i++) {
            const listItem = item.list[i];

            result += listItem;

            if (i < item.list.length - 1) {
                result += "\n";
            }
        }

        return result;
    }

    function handleListStringChange(newValue: string) {
        const newList = newValue.split("\n");

        handleOnInputChange(item, newList, "list");
    }

    function renderHtml(): JSX.Element {

        const langQuestion = item.getLanguageProperty(languageKey, "labels");

        return (
            <div >
                <div className="kt-inline-input-wrapper">
                    {item.required && <span>*</span>}

                    <input
                        type="text"
                        value={langQuestion}
                        onChange={(e) => handleOnInputChange(item, e.target.value, "labels")}
                        placeholder="Beschriftung"
                    />
                </div>

                <FormControl fullWidth disabled>
                    <Select></Select>
                </FormControl>

            </div>
        );

    }

    function renderProperties(): JSX.Element {

        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <strong>Auswahlliste</strong>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label="Pflichtfeld"
                            control={
                                <Checkbox
                                    checked={item.required}
                                    onChange={(e) => handleOnInputChange(item, e.target.checked, "required")}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            rows={5}
                            value={getStringFromList()}
                            onChange={(e) => handleListStringChange(e.target.value)}
                            label="Auswahlelemente"
                            multiline
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Darf ausfüllen:</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="patient"
                                name="radio-buttons-group"
                                value={item.inputUser}
                                onChange={(e) => handleOnInputChange(item, e.target.value, "inputUser")}
                            >
                                <FormControlLabel value="patient" control={<Radio />} label="Patient" />
                                <FormControlLabel value="doctor" control={<Radio />} label="Arzt" />
                                <FormControlLabel value="all" control={<Radio />} label="Jeder" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {

        let langQuestion = item.getLanguageProperty(languageKey, "labels");
        langQuestion = TextEvaluationService.evaluateDocumentText(langQuestion);

        return (

            <div className="kt-form-input-wrapper kt-white-input">

                <FormControl
                    fullWidth
                    disabled={item.inputUser === "doctor" && formUser === "patient"}
                >
                    <InputLabel id={`ddlabel-${item.id}`}>{langQuestion}</InputLabel>
                    <Select
                        name="privateInsurance"
                        labelId="privateInsurance-select-label"
                        label={langQuestion}
                        id="privateInsurance-select"
                        required={item.required}
                        value={item.value}
                        onChange={(e) => handleOnInputChange(item, e.target.value, "value")}
                    >
                        {item.list.map((listItem, itemIndex) =>
                            <MenuItem key={itemIndex} value={listItem}>{listItem}</MenuItem>
                        )}

                    </Select>
                </FormControl>

            </div>
        );

    }

    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }
}


export default DropdownFormItemCtrl;