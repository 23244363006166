import moment from "moment";
import DateUtils from "../../src/shared/src/utils/dateUtils";
import CalendarItem, { CalendarItemType } from "./calendarItem";
import Gender from "./gender";
import MessageStatus from "./messageStatus";

class Rating implements CalendarItem {
    id: string = "";
    clientId: string = "";
    locationId: string = "";

    public: boolean = false;

    patientId: string = "";
    patientGender: Gender = Gender.female;
    patientFirstName: string = "";
    patientLastName: string = "";
    patientPhone: string = "";
    patientEmail: string = "";

    appointmentId: string = "";
    appointmentDate: Date = new Date();

    doctorId: string = "";
    doctorName: string = "";

    visitMotiveId: string = "";
    visitMotiveName: string = "";

    calendarId: string = "";

    rating: number = 0; // 0 = not yet rated, rating values are 1..5 stars
    comments: string = "";

    createdAt: Date = new Date();
    sendAt: Date | null = null;
    ratedAt: Date | null = null;
    sendBy: "" | "email" | "sms" = "";

    messageFrom: string = "";
    messageText: string = "";
    messageHtml: string = "";
    messageStatus: MessageStatus = MessageStatus.unknown;

    // now CalendarItem interface properties
    start: Date = new Date();
    end: Date = new Date();
    title: string = "";
    resourceId: string = "";
    calendarItemType: CalendarItemType = "ratingRequest";
    color: string = "";

    clone(): Rating {
        const s = new Rating();

        s.id = this.id;
        s.clientId = this.clientId;
        s.locationId = this.locationId;

        s.public = this.public;

        s.patientId = this.patientId;
        s.patientGender = this.patientGender;
        s.patientFirstName = this.patientFirstName;
        s.patientLastName = this.patientLastName;
        s.patientPhone = this.patientPhone;
        s.patientEmail = this.patientEmail;

        s.appointmentId = this.appointmentId;
        s.appointmentDate = new Date(this.appointmentDate);

        s.doctorId = this.doctorId;
        s.doctorName = this.doctorName;

        s.visitMotiveId = this.visitMotiveId;
        s.visitMotiveName = this.visitMotiveName;

        s.calendarId = this.calendarId;

        s.rating = this.rating;
        s.comments = this.comments;

        s.createdAt = new Date(this.createdAt);
        s.sendAt = this.sendAt ? new Date(this.sendAt) : null;
        s.ratedAt = this.ratedAt ?  new Date(this.ratedAt) : null;
        s.sendBy = this.sendBy;

        s.messageFrom = this.messageFrom;
        s.messageText = this.messageText;
        s.messageHtml = this.messageHtml;
        s.messageStatus = this.messageStatus;

        s.start = new Date(this.start);
        s.end = new Date(this.end);
        s.title = this.title;
        s.resourceId = this.resourceId;
        s.color = this.color;

        return s;
    }

    toJSON(): object {
        return {
            id: this.id,
            clientId: this.clientId,
            locationId: this.locationId,

            public: this.public,

            patientId: this.patientId,
            patientGender: this.patientGender,
            patientFirstName: this.patientFirstName,
            patientLastName: this.patientLastName,
            patientPhone: this.patientPhone,
            patientEmail: this.patientEmail,

            appointmentId: this.appointmentId,
            appointmentDate: this.appointmentDate,

            doctorId: this.doctorId,
            doctorName: this.doctorName,

            visitMotiveId: this.visitMotiveId,
            visitMotiveName: this.visitMotiveName,

            calendarId: this.calendarId,

            rating: this.rating,
            comment: this.comments,

            createdAt: this.createdAt,
            sendAt: this.sendAt,
            ratedAt: this.ratedAt,
            sendBy: this.sendBy,

            messageFrom: this.messageFrom,
            messageText: this.messageText,
            messageHtml: this.messageHtml,
            messageStatus: this.messageStatus
        };
    }

    fromObject(id: string|null, o:any){

        this.id = id !== null ? id : o.id;
        this.clientId = o.clientId ?? "";
        this.locationId = o.locationId ?? "";

        this.public = o.public === true;

        this.patientId = o.patientId ?? "";
        this.patientGender = o.patientGender ?? Gender.female;
        this.patientFirstName = o.patientFirstName ?? "";
        this.patientLastName = o.patientLastName ?? "";
        this.patientPhone = o.patientPhone ?? "";
        this.patientEmail = o.patientEmail ?? "";

        this.appointmentId = o.appointmentId ?? "";
        const appointmentDate = DateUtils.getDate(o.appointmentDate);
        this.appointmentDate = appointmentDate ?? new Date();

        this.doctorId = o.doctorId ?? "";
        this.doctorName = o.doctorName ?? "";

        this.visitMotiveId = o.visitMotiveId ?? "";
        this.visitMotiveName = o.visitMotiveName ?? "";

        this.calendarId = o.calendarId ?? "";

        this.rating = o.rating ?? 0;
        this.comments = o.comment ?? "";

        const createdAt = DateUtils.getDate(o.createdAt);
        this.createdAt = createdAt ?? new Date();
        this.sendAt = o.sendAt ? DateUtils.getDate(o.sendAt) : null;
        this.ratedAt = o.ratedAt ? DateUtils.getDate(o.ratedAt) : null;

        this.messageFrom = o.messageFrom ?? "";
        this.messageText = o.messageText ?? "";
        this.messageHtml = o.messageHtml ?? "";
        this.messageStatus = o.messageStatus ?? MessageStatus.unknown;

        this.start = this.sendAt ?? new Date();
        this.end = moment(this.start).add(20, "minutes").toDate();
        this.title = `${this.patientLastName} ${this.patientFirstName}`;

        if(o.sendBy) {
            this.sendBy = o.sendBy;
        } else {
            // fix for old ratings
            this.sendBy = o.requestedBy ? o.requestedBy : (o.requestedBy ?? "");
        }

        // fix for old ratings
        if(this.id !== "" && this.sendBy === ""){
            if(this.patientEmail !== "" && this.messageHtml !== ""){
                this.sendBy = "email";
            } else if(this.patientPhone !== "" && this.messageText !== ""){
                this.sendBy = "sms";
            }
        }
    }


};

export default Rating;