
import React, { useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';


interface Props {
    visible: boolean;
    title: string;
    titleIconFaClassName: string;
    message: JSX.Element | string;
    size?: "md" | "sm" | "xs" | "lg" | "xl";
    fullScreen?: boolean;
    onClose: () => void
}

const MessageDialog: React.FC<Props> = ({ visible, title, titleIconFaClassName, message, size="sm", fullScreen=false, onClose }) => {

    useEffect(() => {

        window.addEventListener('keydown', listenKeyboard, true);

        // clean up
        return () => {
            window.removeEventListener('keydown', listenKeyboard, true);
        }

    }, []);
    
    function listenKeyboard(event) {
        if (event.keyCode === 27) {
            onClose();
        }
    }
    
    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={visible}
            maxWidth={size}
            fullScreen={fullScreen}
        >
            <DialogTitle id="customized-dialog-title">
                {(titleIconFaClassName !== "none" && titleIconFaClassName !== "") && <i className={titleIconFaClassName}></i>} {title}
            </DialogTitle>

            <DialogContent
                dividers
            >

                <Grid container>
                    <Grid item xs={12}>
                        {message}
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} autoFocus color='primary'>
                    OK
                </Button>
            </DialogActions>

        </Dialog>

    );
}

export default MessageDialog;
