import Utils from "./utils";

const moment = require('moment-timezone');
const patientTimezone = "Europe/Berlin";


const DateUtils = {

    addMonths(inDate: Date, months: number): Date {
        return moment(inDate).add(months, 'months').toDate();
    },
    subtractMonths(inDate: Date, months: number): Date {
        return moment(inDate).subtract(months, 'months').toDate();
    },

    addDays(inDate: Date, days: number): Date {
        return moment(inDate).add(days, 'days').toDate();
    },
    subtractDays(inDate: Date, days: number): Date {
        return moment(inDate).subtract(days, 'days').toDate();
    },

    addHours(inDate: Date, hours: number): Date {
        return moment(inDate).add(hours, 'hours').toDate();
    },
    subtractHours(inDate: Date, hours: number): Date {
        return moment(inDate).subtract(hours, 'hours').toDate();
    },

    addMinutes(inDate: Date, minutes: number): Date {
        return moment(inDate).add(minutes, 'minutes').toDate();
    },
    subtractMinutes(inDate: Date, minutes: number): Date {
        return moment(inDate).subtract(minutes, 'minutes').toDate();
    },


    // make sure to always work with a date object and not with the firstore timestamp object
    getDate(potentialDate: any): Date | null {

        if (potentialDate) {
            if (typeof potentialDate === "string") {

                if (potentialDate.indexOf(".") === 4) {
                    // should be something like 1987.11.23
                    return moment(potentialDate, ["YYYY.MM.DD", "YYYY.M.D", "YYYY.MM.D", "YYYY.M.DD"]).toDate();
                } else if (potentialDate.indexOf(".") === 2) {
                    // should be something like 23.11.1987
                    return moment(potentialDate, ["DD.MM.YYYY", "D.MM.YYYY", "DD.M.YYYY", "D.M.YYYY"]).toDate();
                }
            }

            return typeof potentialDate.getMonth === 'function' ? potentialDate : (typeof potentialDate.toDate === 'function' ? potentialDate.toDate() : new Date(potentialDate));

        } else {
            return null;
        }
    },

    // needed for example to save birthdates to the database
    toDateWithoutTimezoneInfo(date: Date): Date {
        const utcDate = moment.utc({ y: date.getFullYear(), M: date.getMonth(), d: date.getDate(), h: 0, m: 0, s: 0, ms: 0 }).toDate();
        return utcDate;
    },

    // needed for example to save birthdates to the database
    // use this function if we send to a cloud function
    // there we cannot send a date object
    toDateSecondsWithoutTimezoneInfo(date: Date): Date {
        const utcDate = moment.utc({ y: date.getFullYear(), M: date.getMonth(), d: date.getDate(), h: 0, m: 0, s: 0, ms: 0 }).toDate();
        return utcDate.getTime();
    },

    getDateString(inDate: any): string {
        if (inDate) {
            const _date = DateUtils.getDate(inDate);

            if (_date) {
                return moment(_date).format("DD.MM.YYYY");
            }
        }

        return "";
    },

    getTimeString(inDate: any, withSeconds: boolean = false): string {
        if (inDate) {
            const _inDate = DateUtils.getDate(inDate);

            if (_inDate) {
                let options: any = { hour: "numeric", minute: "2-digit" };

                if(withSeconds) {
                    options = { hour: "numeric", minute: "2-digit", second: "2-digit" };
                }

                return new Date(_inDate).toLocaleTimeString(undefined, options);
            }
        }

        return "";
    },

    getDateTimeString(inDate: any): string {
        if (inDate) {
            return DateUtils.getDateString(inDate) + " " + DateUtils.getTimeString(inDate);
        }

        return "";
    },

    getMonthStringInPatientTimezone(inDate: any): string {
        if (inDate) {
            const _date = DateUtils.getDate(inDate);

            if (_date) {
                return moment.tz(_date, patientTimezone).format("MMMM");
            }
        }

        return "";
    },

    getDateStringInPatientTimezone(inDate: any): string {
        if (inDate) {
            const _date = DateUtils.getDate(inDate);

            if (_date) {
                return moment.tz(_date, patientTimezone).format("DD.MM.YYYY");
            }
        }

        return "";
    },

    getTimeStringInPatientTimezone(inDate: any): string {
        if (inDate) {
            const _date = DateUtils.getDate(inDate);

            if (_date) {
                return moment.tz(_date, patientTimezone).format("H:mm");
            }
        }

        return "";
    },

    // dateString has no time zone information, we fix it here
    parseDateTimeString(dateString: string, utcOffset: number): Date {
        try {
            const startDate = moment.utc(dateString);
            startDate.add(utcOffset, "m");
            return startDate.toDate();
        } catch (error) {
            console.log(`error in parseDateTimeString: ${dateString} utcOffset: ${utcOffset} error: ${error}`);
            return new Date();
        }

    },

    getAge(birthDate: Date) {
        if (birthDate) {
            let timeDiff = Math.abs(Date.now() - birthDate.getTime());
            let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
            return age;
        }

        return 0;
    },

    getBirthdayString(birthDate: Date) {

        if (birthDate) {
            const _birthDate = DateUtils.getDate(birthDate);

            if (_birthDate) {
                var d = new Date(_birthDate);
                if (d.getFullYear() > 1) {
                    return DateUtils.getDateString(_birthDate) + ` (${DateUtils.getAge(_birthDate)})`;
                }
            }
        }

        return "";
    },

    getDurationString(start: any, end: any, isMobileDevice: boolean): string {
        // start time and end time
        var startTime = moment(start);
        var endTime = moment(end);

        // calculate total duration
        var duration = moment.duration(endTime.diff(startTime));

        // duration in hours
        var hours = duration.asHours();

        // duration in minutes
        var minutes = duration.asMinutes() % 60;
        minutes = Math.round(minutes);

        if(hours < 1){
            const minutesString = isMobileDevice ? "Min." : (minutes === 1 ? "Minute" : "Minuten");
            return `${minutes} ${minutesString}`;
        } else {
            hours = Math.round(hours);

            const hoursString = isMobileDevice ? "Std." : (hours === 1 ? "Stunde" : "Stunden");
            return `${hours}:${Utils.getWithLeadingZero(minutes)} ${hoursString}`;
        }
    },

    getDiffInMinutes(start: any, end: any): number {

        var startDate = DateUtils.getDate(start);
        var endDate = DateUtils.getDate(end);

        if (startDate && endDate) {
            var diff = (endDate.getTime() - startDate.getTime()) / 1000;
            diff /= 60;
            return Math.abs(Math.round(diff));
        }

        return 0;
    },

    getDiffInDays(start: any, end: any): number {
        return DateUtils.getDiffInMinutes(start, end) / 60 / 24;
    },

    getDiffInMonths(start: any, end: any): number {

        const diffInMinutes = DateUtils.getDiffInMinutes(start, end);

        const diffInMonths = diffInMinutes / 30 / 24 / 60;
        return diffInMonths;
    },

    getMonthDateRange(year: number, month: number) {
        var moment = require('moment');

        let startDate = moment(new Date(year,month,1));

        // Clone the value before .endOf()
        var endDate = moment(startDate).endOf('month');

        return {
            start: startDate.toDate(),
            end: endDate.toDate()
        };
    },

    getWeekDayNames(firstWeekDayIndex: number, useShortNames: boolean): string[] {

        var weekDayNames = useShortNames ? moment.weekdaysShort() : moment.weekdays();

        var result = weekDayNames;

        // check if we have to sort the weekday names
        // default is Su, Mo, Tu, We, Th, Fr, Sa
        if (firstWeekDayIndex > 0) {
            result = weekDayNames.slice(firstWeekDayIndex);
            result = result.concat(weekDayNames.slice(0, firstWeekDayIndex));
        }

        return result;
    },

    // timeString in the format: '1y:6m', '2d', '4w', '1m'
    getDaysFromTimeString(timeString: string): number | null {
        if (timeString) {
            const timeParts = timeString.split(":");
            let days = 0;

            for (let i = 0; i < timeParts.length; i++) {
                const part = timeParts[i];

                const partLength = part.length;
                if (partLength > 1) {
                    const timeUnitCharacter = part.substring(partLength - 1);
                    let timeUnitDaysFactor = 0;

                    switch (timeUnitCharacter) {
                        case "h":
                            timeUnitDaysFactor = 1/24;
                            break;

                        case "d":
                            timeUnitDaysFactor = 1;
                            break;

                        case "w":
                            timeUnitDaysFactor = 7;
                            break;

                        case "m":
                            timeUnitDaysFactor = 30;
                            break;

                        case "y":
                            timeUnitDaysFactor = 365;
                            break;


                        default:
                            break;
                    }

                    const leftFactorString = part.substring(0, part.length - 1);
                    const leftFactor = parseInt(leftFactorString);

                    if (timeUnitDaysFactor !== 0 && typeof leftFactor === "number") {
                        days += leftFactor * timeUnitDaysFactor;
                    }

                } else {
                    return null;
                }
            }

            return days;
        }

        return null;
    },

    getHoursFromTimeString(timeString: string): number | null {
        const days = DateUtils.getDaysFromTimeString(timeString);

        if(days){
            return days * 24;
        }

        return null;
    },

    formatInterval(interval: string): string {
        if (interval !== null && interval !== undefined) {
            const intervalParts = interval.split("-");
            if (intervalParts.length === 2) {
                const value = intervalParts[0];
                const unit = intervalParts[1];

                const valueInt = parseInt(value);

                if (valueInt > 0) {
                    let unitString = "";

                    if (valueInt === 1) {
                        switch (unit.toLowerCase()) {
                            case "h":
                                unitString = "Stunde";
                                break;

                            case "d":
                                unitString = "Tag";
                                break;

                            case "w":
                                unitString = "Woche";
                                break;

                            case "m":
                                unitString = "Monat";
                                break;

                            case "y":
                                unitString = "Jahr";
                                break;

                            default:
                                break;
                        }
                    } else {
                        switch (unit.toLowerCase()) {
                            case "h":
                                unitString = "Stunden";
                                break;

                            case "d":
                                unitString = "Tage";
                                break;

                            case "w":
                                unitString = "Wochen";
                                break;

                            case "m":
                                unitString = "Monate";
                                break;

                            case "y":
                                unitString = "Jahre";
                                break;

                            default:
                                break;
                        }
                    }

                    return `${value} ${unitString}`;
                }

            }
        }

        return "";
    },

    parseInterval(value: string): { value: number, unit: string } {
        const valueParts = value.split("-");
        if (valueParts.length === 2) {
            return {
                value: parseInt(valueParts[0]),
                unit: valueParts[1]
            }
        }

        return {
            value: 6,
            unit: "m"
        }
    },

    isInFuture(dateToCheck: Date | null): boolean {
        const now = new Date();
        return dateToCheck !== null && dateToCheck.getTime() > now.getTime();
    },

    isOnWeekend(dateToCheck: Date): boolean {
        const dayOfWeek = dateToCheck.getDay();
        return dayOfWeek === 6 || dayOfWeek === 0; // 6 = Saturday, 0 = Sunday
    },

    isSameDay(firstDate: Date,  secondDate: Date): boolean{
        return firstDate.getFullYear() === secondDate.getFullYear() &&
            firstDate.getMonth() === secondDate.getMonth() &&
            firstDate.getDate() === secondDate.getDate();
    },


    isSlotIntersectingWithAnyAppointments(timeSlotDateStart: Date, timeSlotDateEnd: Date, appointments: any[]): boolean {

        for(let a = 0; a < appointments.length; a++){
            const appointment = appointments[a];

            if(DateUtils.isSlotIntersectingAppointment(timeSlotDateStart, timeSlotDateEnd, appointment)) {
                return true;
            }
        }

        return false;
    },

    isSlotIntersectingAppointment(timeSlotDateStart: Date, timeSlotDateEnd: Date, appointment: any): boolean {

        const appointmentStart = (appointment.start as Date).getTime();
        const appointmentEnd =  (appointment.end as Date).getTime();

        const _timeSlotDateStart = timeSlotDateStart.getTime();
        const _timeSlotDateEnd = timeSlotDateEnd.getTime();

        return (_timeSlotDateStart >= appointmentStart && _timeSlotDateStart < appointmentEnd) ||
            (_timeSlotDateEnd > appointmentStart && _timeSlotDateEnd < appointmentEnd) ||
            (appointmentStart >= _timeSlotDateStart && appointmentStart < _timeSlotDateEnd) ||
            (appointmentEnd > _timeSlotDateStart && appointmentEnd < _timeSlotDateEnd);
    },


    isSlotIntersectingPause(timeSlotDateStart: Date, timeSlotDateEnd: Date, pause: any): boolean{

        const pauseStart = new Date(timeSlotDateStart);
        pauseStart.setHours(pause.start.hour);
        pauseStart.setMinutes(pause.start.minute);
        const _pauseStart = pauseStart.getTime();

        const pauseEnd = new Date(timeSlotDateEnd);
        pauseEnd.setHours(pause.end.hour);
        pauseEnd.setMinutes(pause.end.minute);
        const _pauseEnd = pauseEnd.getTime();

        const _timeSlotDateStart = timeSlotDateStart.getTime();
        const _timeSlotDateEnd = timeSlotDateEnd.getTime();


        return (_timeSlotDateStart >= _pauseStart && _timeSlotDateStart < _pauseEnd) ||
            (_timeSlotDateEnd > _pauseStart && _timeSlotDateEnd < _pauseEnd) ||
            (_pauseStart >= _timeSlotDateStart && _pauseStart < _timeSlotDateEnd) ||
            (_pauseEnd > _timeSlotDateStart && _pauseEnd < _timeSlotDateEnd);
    },

    isMultiDay(start: Date, end: Date){
        return DateUtils.getDiffInDays(start, end) > 1;
    },

    getOpeningHoursByDayIndex(openingHours: any, dayIndex: number, startIndex: number){
        if(openingHours){

            const tempArr = [openingHours.sunday, openingHours.monday, openingHours.tuesday, openingHours.wednesday, openingHours.thursday, openingHours.friday, openingHours.saturday];

            const _startIndex = startIndex ? startIndex : 0;

            let tempIndex = dayIndex + _startIndex;

            tempIndex = tempIndex > 6 ? tempIndex - 7 : tempIndex;
            tempIndex = tempIndex < 0 ? 0: tempIndex;

            return tempArr[tempIndex];
        }
    },

    sleep(ms: number) {
        return new Promise(
          resolve => setTimeout(resolve, ms)
        );
    },

    isValidDate(d: any) {
        return d instanceof Date && !isNaN(d as any);
    }

}

export default DateUtils;