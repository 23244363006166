import React, { useState, useEffect } from 'react';
import moment from 'moment';
import "moment/min/locales.min";

var datePickerViewType = {
    days: 1,
    months: 2,
    years: 3
};

interface Props {
    selectedDateProp: Date;
    showCalendarProp?: boolean;
    showDateInput: boolean;
    useDropDown?: boolean;
    locale?: string;
    neverHideCalendar?: boolean;
    firstWeekDay: number;
    className?: string;

    onDateChange: (newdate: Date) => void
}

const DatePicker: React.FC<Props> = ({ selectedDateProp, showCalendarProp, showDateInput, useDropDown, neverHideCalendar, locale, firstWeekDay, className, onDateChange }) => {

    const [selectedView, setSelectedView] = useState(datePickerViewType.days);
    const [selectedDate, setSelectedDate] = useState(selectedDateProp);
    const [selectedDecade, setSelectedDecade] = useState(selectedDateProp);
    const [showCalendar, setShowCalendar] = useState(showCalendarProp);


    useEffect(()=>{

        if(locale) {
            moment.locale(locale);
        }

        // function handleOutsideClick(e) {
        //     if(!e.target.closest(".kt-datePicker")){
        //         setShowCalendar(false);
        //     }
        // }

        // if(!neverHideCalendar){
        //     document.body.addEventListener('click', handleOutsideClick, true);
        //     return () => document.body.removeEventListener('click', handleOutsideClick);
        // }

    },[]);


    function setMonthDiff(diff) {
        var selDate = new Date(selectedDate);
        selDate.setMonth(selDate.getMonth() + diff);

        setSelectedDate(selDate);
    }

    function setYearDiff(diff) {
        var selDate = new Date(selectedDate);
        selDate.setFullYear(selDate.getFullYear() + diff);

        setSelectedDate(selDate);
    }

    function setDecadeDiff(diff) {
        var selDate = new Date(selectedDecade);
        selDate.setFullYear(selDate.getFullYear() + diff*10);

        setSelectedDecade(selDate);
    }


    function getMonth() {
        var months = moment.months();
        return months[selectedDate.getMonth()];
    }

    function getDecade() {
        const round = (n, to) => n - n % to;

        const now = new Date(selectedDecade);

        const start = new Date(round(now.getFullYear(), 10), 0, 1);
        // Go to the start of the next period ...
        const end = new Date(round(now.getFullYear(), 10) + 10, 0, 1);
        end.setDate(end.getDate() - 1); // then go one day back

        return `${start.getFullYear()}-${end.getFullYear()}`;
    }

    function pickDate(e) {
        var newDay = e.target.getAttribute("data-day");
        var newMonth = e.target.getAttribute("data-month");

        if(newDay !== null && newMonth !== null) {
            var newDate = new Date(selectedDate.getFullYear(), newMonth, newDay);

            setSelectedDate(newDate);

            onDateChange(newDate);

            if(!neverHideCalendar){
                setShowCalendar(false);
            }
        }
    }

    function pickMonth(e) {
        var newMonth = e.target.getAttribute("data-month");

        if(newMonth !== null){
            var selDate = new Date(selectedDate);
            selDate.setMonth(newMonth);

            setSelectedDate(selDate);

            setSelectedView(datePickerViewType.days);
        }
    }

    function pickYear(e) {
        var newYear = e.target.getAttribute("data-year");

        if(newYear !== null){
            var selDate = new Date(selectedDate);
            selDate.setFullYear(newYear);

            setSelectedDate(selDate);

            setSelectedView(datePickerViewType.days);
        }
    }


    function getWeekDayNames(firstWeekDayIndex, useShortNames) {

        var weekDayNames = useShortNames ? moment.weekdaysShort() : moment.weekdays();

        var result = weekDayNames;

        // check if we have to sort the weekday names
        // default is Su, Mo, Tu, We, Th, Fr, Sa
        if (firstWeekDayIndex > 0) {
            result = weekDayNames.slice(firstWeekDayIndex);
            result = result.concat(weekDayNames.slice(0, firstWeekDayIndex));
        }

        return result;
    }

    function getFirstWeekDayIndexForView(firstWeekDayIndex, startOfMonthDayIndex) {
        var result = startOfMonthDayIndex - firstWeekDayIndex;
        if (result < 0) {
            result += 7;
        }

        return result;
    }

    function getDayClassName(year, month, day, showInLightColor) {
        var inDate = moment(new Date(year, month, day));

        var mToday = moment();

        var mSelectedDate = moment(selectedDateProp);

        if (inDate.isSame(mSelectedDate, "day")) {
            return "kt-days-selected";
        }

        if (inDate.isSame(mToday, "day")) {
            return "kt-days-today";
        }

        if (showInLightColor) {
            return "kt-days-light";
        }

        return "";
    }

    function getDateString() {
        if(selectedDateProp){
            return moment(selectedDateProp).format("DD.MM.YYYY");
        }

        return "";
    }

    function renderDaysView() {

        var _selectedDate = moment(selectedDate);

        var daysCountMonth = _selectedDate.daysInMonth();

        var previusMonth = moment(selectedDate).subtract(1, 'months');
        var daysCountPreviousMonth = previusMonth.daysInMonth();

        var startOfMonth = _selectedDate.startOf("month");
        var startOfMonthDayIndex = startOfMonth.day();

        var firstWeekNumberOfMonth = startOfMonth.week();

        var weekDayNames = getWeekDayNames(firstWeekDay, true);

        var firstWeekDayIndexForView = getFirstWeekDayIndexForView(firstWeekDay, startOfMonthDayIndex);

        const days: {dayNumber:number, month:number, className:string}[] = [];
        let monthDayNumber = 1;
        let nextMonthDayNumber = 1;

        var month = _selectedDate.month();
        var year = _selectedDate.year();

        for (var i = 0; i < 42; i++) {

            if (i >= firstWeekDayIndexForView && monthDayNumber <= daysCountMonth) {
                // selected month
                days.push({ dayNumber: monthDayNumber, month: month, className: getDayClassName(year, month, monthDayNumber, false) });
                monthDayNumber++;

            } else if (monthDayNumber > daysCountMonth) {
                // next month
                days.push({ dayNumber: nextMonthDayNumber, month: month + 1, className: getDayClassName(year, month+1, nextMonthDayNumber, true) });
                nextMonthDayNumber++;

            }
            else if (firstWeekDayIndexForView > 0) {
                // previous month
                var monthNumber = daysCountPreviousMonth - firstWeekDayIndexForView + i + 1;
                days.push({ dayNumber: monthNumber, month: month - 1, className: getDayClassName(year, month-1, monthNumber, true) });
            }
        }

        const weeknumbers: string[] = ["\u00A0"]; // first row is an empty string, makes life easier
        for (var j = 0; j < 6; j++) {
            weeknumbers.push((j + firstWeekNumberOfMonth).toString());
        }


        return (
            <div className="kt-datePicker-days">

                <div className="kt-datePicker-header">
                    <div className="kt-datePicker-nav-button" onClick={(e) => setMonthDiff(-1)}><i className="fas fa-angle-left"></i></div>
                    <div onClick={(e) => setSelectedView(datePickerViewType.months)}>{getMonth()}</div>
                    <div onClick={(e) => setSelectedView(datePickerViewType.years)}>{selectedDate.getFullYear()}</div>
                    <div className="kt-datePicker-nav-button" onClick={(e) => setMonthDiff(1)}><i className="fas fa-angle-right"></i></div>
                </div>

                <div className="kt-datePicker-body">
                    <div className="kt-weekdays">
                        {weekDayNames.map((day, index) =>
                            <div key={index} data-day={index}>{day.slice(0,2)}</div>
                        )}
                    </div>

                    <div className="kt-days-wrapper" onClick={(e) => pickDate(e)}>
                        {days.map((day, index) =>
                            <div key={index} data-day={day.dayNumber} data-month={day.month} className={day.className}>
                                {day.dayNumber}
                            </div>
                        )}
                    </div>

                    <div className="kt-weeknumbers">
                        {weeknumbers.map((weeknumber, index) =>
                            <div key={index} data-day={index}>{weeknumber}</div>
                        )}
                    </div>
                </div>

            </div>
        );
    }

    function renderMonthsView() {
        var months = moment.monthsShort();

        return (
            <div className="kt-datePicker-months-years">
                <div className="kt-datePicker-header">
                    <div className="kt-datePicker-nav-button" onClick={(e) => setYearDiff(-1)}><i className="fas fa-angle-left"></i></div>
                    <div onClick={(e) => setSelectedView(datePickerViewType.years)}>{selectedDate.getFullYear()}</div>
                    <div className="kt-datePicker-nav-button" onClick={(e) => setYearDiff(1)}><i className="fas fa-angle-right"></i></div>
                </div>

                <div className="kt-datePicker-body" onClick={(e) => pickMonth(e)}>
                    {months.map((month, index) =>
                        <div key={index} data-month={index}>{month}</div>
                    )}
                </div>

            </div>
        );
    }

    function renderYearsView() {

        const round = (n, to) => n - n % to;

        const now = new Date(selectedDecade);

        const start = new Date(round(now.getFullYear(), 10), 0, 1);
        const startYear = start.getFullYear() - 1;
        const years: number[] = [];

        for (var i = 0; i < 12; i++) {
            years.push(startYear + i);
        }
        return (
            <div className="kt-datePicker-months-years">
                <div className="kt-datePicker-header">
                    <div className="kt-datePicker-nav-button" onClick={(e) => setDecadeDiff(-1)}><i className="fas fa-angle-left"></i></div>
                    <div onClick={(e) => setSelectedView(datePickerViewType.years)}>{getDecade()}</div>
                    <div className="kt-datePicker-nav-button" onClick={(e) => setDecadeDiff(1)}><i className="fas fa-angle-right"></i></div>
                </div>

                <div className="kt-datePicker-body" onClick={(e) => pickYear(e)}>
                    {years.map((year, index) =>
                        <div className="kt-datePicker-years" key={index} data-year={year}>{year}</div>
                    )}
                </div>

            </div>
        );
    }

    function renderSelectedView() {
        switch (selectedView) {
            case datePickerViewType.months:
                return renderMonthsView();

            case datePickerViewType.years:
                return renderYearsView();

            default:
                return renderDaysView();
        }
    }

    function handleInputClick() {
        setShowCalendar(!showCalendar);
    }

    return (
        <div className={className ? `${className} kt-datePicker noselect` : "kt-datePicker noselect"}>
            {showDateInput ? (
                <div className="kt-datePicker-input-wrapper kt-gridCell" onClick={handleInputClick}>
                    <span>{getDateString()}</span><i className="far fa-calendar-alt"></i>
                </div>
            ) : ""}

            {showCalendar || !showDateInput ? (
                <div className={useDropDown ? "kt-datePicker-calendar kt-datePicker-dropdown" : "kt-datePicker-calendar"}>
                    { renderSelectedView() }
                </div>
            ) : ""}

        </div>
    )

}

export default DatePicker;