import FormItem, { Align, FormItemEnum, FormItemType } from "./formItem";

export enum HeadlineSize {
    small,
    normal,
    big
}


export class HeadlineFormItem extends FormItem {

    textSize: HeadlineSize = HeadlineSize.normal;
    textAlign: Align = Align.left;

    constructor(rowId: string) {
        super(rowId);
        this.type = FormItemEnum.headline;
        this.setLanguageProperty("de", "Überschrift", "labels");
    }


    clone(): FormItemType {

        const c = new HeadlineFormItem(this.parentId);
        c.fromObject(null, this.toJSON(true));

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj: any = super.toJSON(includeUserInputs);

        obj.textSize = this.textSize;
        obj.textAlign = this.textAlign;

        return obj;
    }

    fromObject(id: string|null, o:any){
        super.fromObject(id, o);

        this.textSize = o.textSize !== undefined ? o.textSize : HeadlineSize.normal;
        this.textAlign = o.textAlign !== undefined ? o.textAlign : Align.left;
    }


}


export default HeadlineFormItem;