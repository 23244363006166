
import React from "react";

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";

import Utils from "../../../src/shared/src/utils/utils";
import CheckboxFormItem from "../../../src/shared/src/models/formEditor/checkboxFormItem";
import AnswerFormItem from "../../../src/shared/src/models/formEditor/answerFormItem";
import { FormItemCtrlProps } from "./formItemCtrl";
import AnswerFormItemCtrl from "./answerFormItemCtrl";
import TextEvaluationService from "../../../src/services/textEvaluationService";


interface Props extends FormItemCtrlProps {
    item: CheckboxFormItem
}

const CheckboxFormItemCtrl: React.FC<Props> = (
    {
        patientId,
        documentId,
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        setFormRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode,
        formUser
    }) => {



    function addAnswer() {
        const newAnswer = new AnswerFormItem(item.id);

        item.answers.push(newAnswer);

        handleOnInputChange(item.answers, [...item.answers], "answers");
    }

    function deleteAnswer(answer: AnswerFormItem) {

        item.answers = Utils.removeItemFromArray<AnswerFormItem>(answer, item.answers);

        handleOnInputChange(item.answers, [...item.answers], "answers");
    }


    function addRowToAnswer(answer: AnswerFormItem) {

        answer.addRow();

        handleOnInputChange(item.answers, [...item.answers], "answers");
    }


    function renderHtml(): JSX.Element {

        const langQuestion = item.getLanguageProperty(languageKey, "labels");

        return (
            <div >
                <div className="kt-inline-input-wrapper">
                    <input
                        type="text"
                        value={langQuestion}
                        onChange={(e) => handleOnInputChange(item, e.target.value, "labels")}
                        placeholder="Beschriftung"
                    />
                </div>

                {
                    item.answers.map((answer, index) => (
                        <div
                            className="kt-form-item kt-form-item-answer"
                            key={answer.id}
                        >
                            <div className="kt-item-gripper">
                                {/* <i className={`far fa-grip-lines`}></i> */}
                            </div>
                            <div className="kt-form-item-content">
                                <AnswerFormItemCtrl
                                    patientId={patientId}
                                    documentId={documentId}
                                    item={answer}
                                    required={false}
                                    languageKey={languageKey}
                                    handleOnInputChange={handleOnInputChange}
                                    handleOnSignatureDialogStateChange={handleOnSignatureDialogStateChange}
                                    formRows={formRows}
                                    setFormRows={setFormRows}
                                    onFormChange={onFormChange}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    renderMode="editor"
                                    formUser={formUser}
                                />
                            </div>
                            <div className="kt-item-edit-buttons">
                                <div onClick={() => addRowToAnswer(answer)}>
                                    <i className='far fa-layer-plus' />
                                </div>
                                <div onClick={() => deleteAnswer(answer)}>
                                    <i className='far fa-trash' />
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div className="kt-btn kt-btn-small" onClick={() => addAnswer()}>
                    <i className="fal fa-plus-circle"></i> Antwort hinzufügen
                </div>

            </div>
        );


    }

    function renderProperties(): JSX.Element {

        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <strong>Multiple-Choice-Frage</strong>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Darf ausfüllen:</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="patient"
                                name="radio-buttons-group"
                                value={item.inputUser}
                                onChange={(e) => handleOnInputChange(item, e.target.value, "inputUser")}
                            >
                                <FormControlLabel value="patient" control={<Radio />} label="Patient" />
                                <FormControlLabel value="doctor" control={<Radio />} label="Arzt" />
                                <FormControlLabel value="all" control={<Radio />} label="Jeder" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {

        let langQuestion = item.getLanguageProperty(languageKey, "labels");
        langQuestion = TextEvaluationService.evaluateDocumentText(langQuestion);

        return (

            <div className="kt-form-input-wrapper">

                <FormControl
                    component="fieldset"
                    variant="standard"
                    disabled={item.inputUser === "doctor" && formUser === "patient"}
                >
                    {langQuestion && <FormLabel component="legend">{langQuestion}</FormLabel>}
                    <FormGroup>
                        {item.answers.map(answer => {
                            return <AnswerFormItemCtrl
                                patientId={patientId}
                                documentId={documentId}
                                key={answer.id}
                                item={answer}
                                required={false}
                                languageKey={languageKey}
                                handleOnInputChange={handleOnInputChange}
                                handleOnSignatureDialogStateChange={handleOnSignatureDialogStateChange}
                                formRows={formRows}
                                setFormRows={setFormRows}
                                onFormChange={onFormChange}
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                                renderMode="final"
                                formUser={formUser}
                            />

                        })}
                    </FormGroup>
                </FormControl>

            </div>
        );
    }


    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }

}


export default CheckboxFormItemCtrl;