
class ToDo {
    id: string = "";
    name: string = "";
    done: boolean = false;
    createdAt: Date | null = null;

    clone(): ToDo {
        const t = new ToDo();

        t.id = this.id;
        t.name = this.name;
        t.done = this.done;
        t.createdAt = this.createdAt;

        return t;
    }

    toJSON(): object {
        return {
            id: this.id,
            name: this.name,
            done: this.done === true
        };
    }

    fromObject(id: string|null, o:any){
 
        this.id = id !== null ? id : o.id;

        this.name = o.name;
        this.done = o.done === true;
        this.createdAt = o.createdAt;

    }
};

export default ToDo;