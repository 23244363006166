import { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";

import LoadingPage from "../administration/loadingPage";

import { DataGrid, deDE } from '@mui/x-data-grid'
import Invoice from '../../../../src/shared/src/models/invoice/invoice';
import Box from '@mui/material/Box';
import { GlobalContext } from '../../../GlobalContext';


function InvoicesPage() {

    const [invoices] = useState([] as Invoice[]);

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);


    useEffect(() => {

        if (currentClient) {
            updateInvoices();
        }

        // eslint-disable-next-line
    }, [currentClient]);



    async function updateInvoices() {

        if (!currentUser) {
            return;
        }

        setIsLoading(true);

        try {
            //const result = await DocumentsService.getDocumentTemplates(currentClient.id, currentUser.locationId);

            setIsLoading(false);
            // if (result) {
            //     setInvoices(result)
            // }

        } catch (error) {
            setIsLoading(false);
        }
    }


    const columns: any[] = [
        {
            field: 'name', headerName: 'Berechnungszeitraum', width: 300, renderCell: (params) => (
                <Link to={`/settings/invoice/${params.row.id}`}>{params.row.name}</Link>
            )
        },
        {
            field: 'total', headerName: 'Summe', width: 120, renderCell: (params) => (
                <span>{params.row.total.toFixed(2)} €</span>
            )
        }
    ];



    if (isLoading) return <LoadingPage />;

    return (
        <Box className="kt-page">
            <h2>Rechnungen</h2>


            <Box className="kt-data-grid-container">
                <DataGrid
                    rows={invoices}
                    columns={columns}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>

        </Box>
    );
}

export default InvoicesPage;