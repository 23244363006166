import firebase from 'firebase/compat/app';
import { getDownloadURL, getStorage, ref } from "firebase/storage";

import moment from 'moment';

const functions = firebase.app().functions('europe-west3');


const PDFService = {


    async createPDF(clientId: string, locationId: string, patientId: string, documentJson: string): Promise<void> {

        try {

            const utcOffset = moment().utcOffset();

            const createPDF = functions.httpsCallable('createPDF');
            await createPDF(
                {
                    clientId: clientId,
                    locationId: locationId,
                    patientId: patientId,
                    document: documentJson,
                    utcOffset: utcOffset
                }
            );

        } catch (error) {
            console.error("Error creating PDF: ", error);
            console.log(documentJson);


            throw new Error(error as any);
        };

    },

    async getPatientPDF(clientId: string, locationId: string, patientId: string, documentId: string): Promise<string> {
        try {

            // Create a reference with an initial file path and name
            const storage = getStorage();

            // Create a reference from a Google Cloud Storage URI
            const gsReference = ref(storage, `clients/${clientId}/locations/${locationId}/patients/${patientId}/documents/${documentId}.pdf`);

            const url = await getDownloadURL(gsReference);
            return url;

        } catch(error) {
            console.log("Error getting patient document PDF: " + error);
            return "";
        }
    }

}

export default PDFService;